<template>
  <div class="upload-progress-viewer z-30" v-if="!hide">
    <div
      class="text-coconut-white-500 body-text3 py-4 px-4 h-12 bg-coal-pop-default flex flex-row justify-between items-center border-b border-ebony-grey-default"
    >
      <span>{{ completedUploads }} Upload(s) Complete</span>
      <div class="flex flex-row space-x-3">
        <button class="icon-button" @click="onEdit()">
          <EditIcon class="w-4 h-4" />
        </button>
        <button class="icon-button" @click="collapse = !collapse">
          <ChevronDownIcon
            class="w-4 h-4 transition-all"
            :class="{ 'transform rotate-180': collapse }"
          />
        </button>
        <button class="icon-button" @click="hideComponent">
          <XIcon class="w-4 h-4" />
        </button>
      </div>
    </div>
    <div
      class="flex flex-col max-h-44 overflow-y-auto transition-all transition-medium ease-out-quad"
      v-if="!collapse"
    >
      <UploadProgressItem
        v-for="video of videoUploads"
        :key="`uploading-${video.id}`"
        :video="video"
        :processed="false"
      />
      <UploadProgressItem
        v-for="video of processedVideos"
        :key="`processed-${video.id}`"
        :video="video"
        :processed="true"
      />
    </div>
  </div>
</template>
<script>
import { difference } from "lodash";
import { mapGetters } from "vuex";
import { CheckCircleFillIcon } from "@spitilabs/icon-library";
import UploadProgressItem from "./UploadProgressItem";
import {
  Edit_2Icon,
  ChevronDownIcon,
  XIcon,
  EditIcon,
} from "@spitilabs/icon-library";
import COLORS from "~/styles/colors.json";
export default {
  components: {
    CheckCircleFillIcon,
    XIcon,
    ChevronDownIcon,
    UploadProgressItem,
    Edit_2Icon,
    EditIcon,
  },
  data() {
    return {
      collapse: false,
      hide: true,
      COLORS,
      uploadingVideos: [],
      processedVideos: [],
    };
  },
  computed: {
    ...mapGetters({
      workspace: "workspace/defaultWorkspace",
      viewer: "auth/viewer",
      videoUploads: "video/videoUploads",
    }),
    completedUploads() {
      return (
        this.processedVideos.filter((video) => video.status !== "errored")
          .length +
        this.videoUploads.filter(
          (video) =>
            ["ready"].includes(video.status) ||
            (video.status === "waiting_for_upload" &&
              video.uploadProgress &&
              video.uploadProgress === 100),
        ).length
      );
    },
  },
  mounted() {
    this.$root.$on("tracker:add", (videoIds) => {
      this.hide = false;
      this.collapse = false;

      // Compute the videos that are already uploaded and adjust binding/query listeners to the rest
      this.processedVideos = [
        ...this.videoUploads.filter(
          (video) =>
            ["ready", "preparing", "errored"].includes(video.status) ||
            (video.status === "waiting_for_upload" &&
              video.uploadProgress &&
              video.uploadProgress === 100),
        ),
        ...this.processedVideos,
      ];
      const uploadingVideoIds = difference(
        this.uploadingVideos,
        this.processedVideos.map((each) => each.id),
      );
      this.uploadingVideos = [...videoIds, ...uploadingVideoIds];
    });
    this.$root.$on("tracker:collapse", () => {
      this.collapse = true;
    });
  },
  watch: {
    uploadingVideos: function () {
      if (!this.uploadingVideos.length) {
        return;
      }
      this.$store.dispatch("video/bindVideoUploads", this.uploadingVideos);
    },
  },
  methods: {
    hideComponent() {
      this.uploadingVideos = [];
      this.processedVideos = [];
      this.hide = true;
      this.$store.dispatch("video/unbindVideoUploads");
    },
    onEdit: function () {
      const videoIds = [
        ...this.videoUploads.map((video) => video.id),
        ...this.processedVideos.map((video) => video.id),
      ];
      this.$router.push({ name: "videos-edit", params: { videos: videoIds } });
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-button {
  @apply transition flex items-center justify-center text-coconut-white-default rounded-sm;

  @media (hover: hover) {
    &:hover {
      @apply bg-coconut-white-default bg-opacity-20;
    }
  }
  &:active {
    @apply bg-coconut-white-default bg-opacity-30;
  }
}
</style>
