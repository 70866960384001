import { isAgentMobile } from "~/services/site-meta";

/**
 * Middleware for mobile devices.
 */
export default function ({ req, store }) {
  const isMobile = process.server && isAgentMobile(req);

  // On mobile screens, we don't want to show the sidebar on page load
  // Since by default sidebar is set in root store, we override it to false here
  if (process.server && isMobile && store.getters["isSidebarOpen"]) {
    store.dispatch("closeSidebar");
  }
}
