<template>
  <header
    :class="{
      'border-b border-white border-opacity-10': sticky,
      'bg-black-300': (isMarketingMenuOpen || isFeaturesMenuOpen) && isMobile,
      'backdrop-blur backdrop-saturate-200':
        sticky && !isMarketingMenuOpen && !isFeaturesMenuOpen,
    }"
    class="fixed top-0 z-40 w-screen h-26 md:h-27 flex flex-col"
  >
    <div
      class="relative z-40 h-12 w-full flex flex-row space-x-2 justify-center px-5 md:px-0 items-center border-b border-opacity-15 border-white banner-bg"
    >
      <div class="h-12 overflow-hidden relative">
        <img
          src="~/assets/img/icons/sparkle/sparkle-left.svg"
          class="sparkle"
        />
      </div>
      <div
        v-if="showPHBanner"
        class="flex flex-row space-x-1 font-medium text-sm md:text-base text-white"
      >
        <span>🚀 We're live on Product Hunt. Check out our launch</span>
        <a
          class="underline font-semibold"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.producthunt.com/products/spiti"
          >here</a
        >
        <span>and show some love 🫶.</span>
      </div>
      <div
        v-else
        class="flex flex-row space-x-1 font-medium text-sm md:text-base text-white"
      >
        <span>🚀 Spiti Screen Recorder is live —</span>
        <span class="hidden sm:block"
          >record, edit, & share videos in a jiffy.</span
        >
        <nuxt-link class="underline font-semibold" to="/screen-recorder"
          >Try it now!</nuxt-link
        >
      </div>
      <div class="h-12 overflow-hidden relative">
        <img
          src="~/assets/img/icons/sparkle/sparkle-right.svg"
          class="sparkle"
        />
      </div>
    </div>
    <div
      class="relative z-40 h-14 md:h-15 w-full flex justify-between items-center px-5 md:px-0 max-w-6xl mx-auto"
    >
      <button @click="goToLandingPage()">
        <img
          src="~/assets/img/logos/spiti-long.svg"
          alt="Spiti"
          class="spiti-logo"
        />
      </button>
      <div
        class="hidden sm:flex flex-row space-x-6 md:space-x-10 lg:space-x-16 items-center"
      >
        <div
          v-for="(item, idx) in navItems"
          :key="item.name"
          :class="{ 'hidden md:block': idx === 1 }"
        >
          <marketing-dropdown-menu
            v-if="item.name === 'Product'"
            :isOpen="dropdownOpenState[item.name]"
            :overlay="false"
            direction="center"
            @premountHover="toggleNavBarItem(item.name)"
            :closeOnClickOutside="true"
            transition="translate-fade-down"
          >
            <button
              slot="trigger"
              type="button"
              @click="toggleNavBarItem(item.name)"
              @mouseenter="onHoverNavItem(item.name)"
              class="dropdown-toggle block md:hidden nav-item"
            >
              {{ item.name }}
            </button>
            <div
              slot="body"
              class="w-max flex flex-row divide-x-1 divide-white divide-opacity-15"
              style="background-color: #1d1d1d"
            >
              <div class="px-1 pt-2 pb-1.5 flex flex-col" style="width: 342px">
                <span
                  class="px-3 uppercase text-sm font-semibold"
                  style="color: #8d8484"
                  >Solutions</span
                >
                <div
                  class="mt-2 flex flex-row space-x-2.5 p-2.5 items-start dropdown-item-template"
                  @click="navigateTo({ path: '/features' })"
                >
                  <img
                    src="~/assets/img/icons/video-library/white.svg"
                    class="dropdown-icon"
                  />
                  <div class="flex flex-col space-y-2">
                    <span class="text-white heading">Team video library</span>
                    <span class="text-sm text-white text-opacity-80"
                      >With advanced collaboration features</span
                    >
                  </div>
                </div>
                <div
                  class="flex flex-row space-x-2.5 p-2.5 items-start dropdown-item-template"
                  @click="navigateTo({ path: '/ai-insights' })"
                >
                  <img
                    src="~/assets/img/icons/sparkle/sparkle-white.svg"
                    class="dropdown-icon"
                  />
                  <div class="flex flex-col space-y-2">
                    <div class="flex flex-row space-x-2.5 items-center">
                      <span class="text-white heading">AI-Insights</span>
                      <div class="new-tag">New</div>
                    </div>

                    <span class="text-sm text-white text-opacity-80"
                      >Generate Summary, extract action items</span
                    >
                  </div>
                </div>
                <div
                  class="flex flex-row space-x-2.5 p-2.5 items-start dropdown-item-template"
                  @click="navigateTo({ path: '/screen-recorder' })"
                >
                  <img
                    src="~/assets/img/icons/record/white.svg"
                    class="dropdown-icon"
                  />
                  <div class="flex flex-col space-y-2">
                    <div class="flex flex-row space-x-2.5 items-center">
                      <span class="text-white heading">Screen recorder</span>
                      <div class="new-tag">New</div>
                    </div>
                    <span class="text-sm text-white text-opacity-80"
                      >Record 4k videos with ease</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="px-1 py-2 flex flex-col text-white"
                style="width: 217px"
              >
                <span
                  class="px-3 uppercase text-sm font-semibold"
                  style="color: #8d8484"
                  >By function</span
                >
                <div
                  class="mt-2 p-2.5 dropdown-item-template"
                  @click="navigateTo({ path: '/product/spiti-for-hr' })"
                >
                  HR & Ops
                </div>
                <div
                  class="p-2.5 dropdown-item-template"
                  @click="
                    navigateTo({ path: '/product/spiti-for-engineering' })
                  "
                >
                  Engineering
                </div>
                <div
                  class="p-2.5 dropdown-item-template"
                  @click="navigateTo({ path: '/product/spiti-for-product' })"
                >
                  Product
                </div>
                <div
                  class="p-2.5 dropdown-item-template"
                  @click="navigateTo({ path: '/product/spiti-for-marketing' })"
                >
                  Marketing
                </div>
              </div>
            </div>
          </marketing-dropdown-menu>
          <button
            v-else-if="!item.items"
            class="nav-item"
            @mouseenter="onHoverNavItem(item.name)"
            @click="navigateTo(item)"
            overlayBgColor="transparent"
          >
            {{ item.name }}
          </button>
          <marketing-dropdown-menu
            v-else
            :isOpen="dropdownOpenState[item.name]"
            :overlay="false"
            direction="right"
            @premountHover="toggleNavBarItem(item.name)"
            :closeOnClickOutside="true"
            transition="translate-fade-down"
          >
            <button
              slot="trigger"
              type="button"
              @click="toggleNavBarItem(item.name)"
              @mouseenter="onHoverNavItem(item.name)"
              class="dropdown-toggle block md:hidden nav-item"
            >
              {{ item.name }}
            </button>
            <div slot="body" class="w-max dropdown-list">
              <div
                v-for="listItem of item.items"
                :key="listItem.name"
                class="dropdown-list-item"
                @click="navigateTo(listItem)"
              >
                <span>{{ listItem.name }}</span>
              </div>
            </div>
          </marketing-dropdown-menu>
        </div>
        <nuxt-link class="spiti-btn-primary-sm" to="/login">Login</nuxt-link>
      </div>
      <div class="flex flex-row space-x-1 sm:hidden items-center">
        <button
          v-if="!isMarketingMenuOpen"
          class="spiti-btn-primary-sm"
          @click="goToLogin()"
        >
          Login
        </button>
        <div class="transform scale-50">
          <Hamburger
            :on-click="toggleMarketingMenu"
            :state="isMarketingMenuOpen"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isMarketingMenuOpen"
      class="animate-mobile-menu absolute top-26 md:top-27 border-t border-white border-opacity-10 left-0 flex flex-col h-screen w-screen bg-black-300 divide-y-1 divide-white divide-opacity-10"
    >
      <div
        v-for="item in navItems"
        :key="item.name"
        class="px-8 py-4 flex flex-col space-y-4"
      >
        <button
          class="flex flex-row items-center w-full justify-between"
          @click="onClickMobileMenuItem(item)"
        >
          <span
            class="mobileMenuItem"
            :class="{
              'text-opacity-100': !item.items && expandMenuItem[item.name],
            }"
            >{{ item.name }}</span
          >
          <div v-if="item.items" classs="text-white">
            <ChevronRightIcon
              v-if="!expandMenuItem[item.name]"
              size="1.2x"
              class="text-white"
            ></ChevronRightIcon>
            <ChevronDownIcon
              v-else
              size="1.2x"
              class="text-white"
            ></ChevronDownIcon>
          </div>
        </button>
        <div
          class="pl-8 flex flex-col space-y-4"
          v-if="expandMenuItem[item.name]"
        >
          <div v-if="item.name === 'Product'" class="flex flex-col space-y-6">
            <div class="flex flex-col space-y-4">
              <span
                class="uppercase text-sm font-semibold"
                style="color: #8d8484"
                >Solutions</span
              >
              <button
                v-for="each in item.items[0].items"
                class="pl-4 mobileMenuItem text-left"
                :key="each.name"
                @click="navigateTo(each)"
              >
                <span> {{ each.name }} </span>
                <div class="new-tag" v-if="each.new">New</div>
              </button>
            </div>
            <div class="flex flex-col space-y-4">
              <span
                class="uppercase text-sm font-semibold"
                style="color: #8d8484"
                >By function</span
              >
              <button
                v-for="each in item.items[1].items"
                class="pl-4 mobileMenuItem text-left"
                @click="navigateTo(each)"
                :key="each.name"
              >
                {{ each.name }}
              </button>
            </div>
          </div>
          <button
            class="mobileMenuItem text-left"
            v-for="each in item.items"
            :key="each.name"
            @click="navigateTo(each)"
          >
            <span>{{ each.name }}</span>
          </button>
        </div>
      </div>
      <div class="px-8 py-4 w-full">
        <nuxt-link class="spiti-btn-primary-sm w-full" to="/login"
          ><span class="w-full text-center">Login</span></nuxt-link
        >
      </div>
      <div class="flex-grow"></div>
    </div>
  </header>
</template>

<script>
import Vue from "vue";
import { ChevronRightIcon, ChevronDownIcon } from "vue-feather-icons";
import { mapGetters } from "vuex";
import { directive as onClickaway } from "vue-clickaway";
import Hamburger from "~/components/buttons/Hamburger";
import MarketingDropdownMenu from "~/components/dropdowns/MarketingDropdownMenu.vue";

export default {
  name: "markering-navbar",
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    sticky: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ChevronRightIcon,
    ChevronDownIcon,
    MarketingDropdownMenu,
    Hamburger,
  },
  created() {
    this.updateBannerState();
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    this.renderDropdownNavItems = true;
  },
  data() {
    return {
      showPHBanner: false,
      renderDropdownNavItems: false,
      isMobile: null,
      dropdownOpenState: {},
      expandMenuItem: {},
      navItems: [
        {
          name: "Product",
          items: [
            {
              name: "Solution",
              items: [
                {
                  name: "Team video library",
                  path: "/features",
                },
                {
                  name: "Screen recorder",
                  path: "/screen-recorder",
                  new: true,
                },
                {
                  name: "AI-Insights",
                  path: "/ai-insights",
                  new: true,
                },
              ],
            },
            {
              name: "By function",
              items: [
                {
                  name: "HR & Ops",
                  path: "/product/spiti-for-hr",
                },
                {
                  name: "Engineering",
                  path: "/product/spiti-for-engineering",
                },
                {
                  name: "Product",
                  path: "/product/spiti-for-product",
                },
                {
                  name: "Marketing",
                  path: "/product/spiti-for-marketing",
                },
              ],
            },
          ],
        },
        {
          name: "Customers",
          path: "/customers",
        },
        {
          name: "Resources",
          items: [
            {
              name: "Blog",
              href: "//blog.spiti.xyz",
            },
            {
              name: "Documentation",
              path: "/docs",
            },
          ],
        },
        {
          name: "Pricing",
          path: "/pricing",
        },
        {
          name: "Company",
          items: [
            {
              name: "About",
              path: "/about",
            },
            {
              name: "Careers",
              href: "//angel.co/company/spiti-1/jobs",
            },
          ],
        },
      ],
    };
  },
  methods: {
    updateBannerState() {
      // Check current route
      this.showPHBanner = this.$route.path === "/screen-recorder";
    },
    goToLogin() {
      this.$store.dispatch("closeMarketingMenu");
      this.$router.push("/login");
    },
    goToLandingPage() {
      this.$store.dispatch("closeMarketingMenu");
      this.$router.push("/");
    },
    onClickMobileMenuItem(item) {
      if (!item.items) {
        this.navigateTo(item);
        return;
      }
      Vue.set(
        this.expandMenuItem,
        item.name,
        Boolean(!this.expandMenuItem[item.name]),
      );
    },
    toggleMarketingMenu() {
      this.$store.dispatch("closeFeaturesMenu");
      this.$store.dispatch("toggleMarketingMenu");
    },
    onHoverNavItem(item) {
      this.dropdownOpenState = {
        [item]: true,
      };
    },
    toggleNavBarItem(item) {
      Vue.set(this.dropdownOpenState, item, !this.dropdownOpenState[item]);
    },
    closeDropdowns() {
      this.dropdownOpenState = {};
    },
    navigateTo(item) {
      this.$store.dispatch("closeMarketingMenu");
      this.expandMenuItem = {};
      this.closeDropdowns();
      const { path, href } = item;
      if (path) {
        this.$router.push(path);
      } else if (href) {
        window.location.href = href;
      }
    },
  },
  computed: {
    ...mapGetters(["isMarketingMenuOpen", "isFeaturesMenuOpen"]),
  },
  watch: {
    $route() {
      this.updateBannerState();
    },
  },
};
</script>

<style lang="scss" scoped>
.mobileMenuItem {
  @apply text-white font-normal text-opacity-80 flex flex-row space-x-2 items-center text-sm;
  &:active {
    @apply text-opacity-100 font-medium;
  }
}

.spiti-logo {
  height: 33px;
  @media (max-width: 768px) {
    height: 24px;
  }
}
.nav-item {
  @apply text-white font-normal opacity-80 flex items-center text-base transition-opacity duration-300;
  @media (hover: hover) {
    &:hover {
      @apply opacity-100;
    }
  }
}
.dropdown-list {
  @apply flex flex-col p-1 rounded;
  background: #1d1d1d;
  min-width: 12rem;
}

.dropdown-item-template {
  @apply cursor-pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.07);
    border-radius: 2px;
  }
  .heading {
    line-height: 18px;
    font-size: 18px;
    font-weight: 400;
  }
  .dropdown-icon {
    height: 18px;
    width: 18px;
    pointer-events: none;
  }
}

.dropdown-list-item {
  @apply text-white flex flex-row space-x-2 items-center font-normal p-2.5 cursor-pointer text-base rounded-sm transition-colors duration-300;
  @media (hover: hover) {
    &:hover {
      @apply bg-white bg-opacity-5;
    }
  }
}

.backdrop-blur {
  @apply bg-offBlack bg-opacity-90;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop-blur {
    @apply bg-offBlack bg-opacity-20;
    -webkit-backdrop-filter: blur(2em) saturate(2);
    backdrop-filter: blur(2em) saturate(2);
  }
}

@keyframes menuTransition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
  }
}
.animate-mobile-menu {
  animation-name: menuTransition;
  animation-duration: 400ms;
  animation-delay: 0s;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-fill-mode: both;
}

.banner-bg {
  @apply backdrop-blur-none backdrop-saturate-0;
  background: linear-gradient(
    270.09deg,
    rgba(95, 237, 255, 0.6) 0%,
    rgba(2, 99, 255, 0.6) 100%
  );
  backdrop-filter: blur(30px);
}

@keyframes translateinfinite {
  0% {
    transform: translateY(-147px);
  }
  100% {
    transform: translateY(48px);
  }
}

.sparkle {
  animation: translateinfinite 3s linear infinite;
  @apply hidden sm:block h-max w-max;
}
</style>
