import { AnalyticsBrowser } from "@segment/analytics-next";

export class SegmentService {
  constructor($config) {
    const { appBaseUrl, segmentWriteKey } = $config;
    this.mock = appBaseUrl === "http://localhost:3000";
    this.analytics = AnalyticsBrowser.load({
      writeKey: segmentWriteKey,
      cdnURL: "https://sg-cdn.spiti.xyz",
    });
  }

  async identify(identifier, payload) {
    if (this.mock) return;
    try {
      this.analytics.identify(identifier, payload);
    } catch (err) {
      console.log(`Failed to identify user with err - ${err}`);
    }
  }

  async group(groupId, traits) {
    if (this.mock) return;
    try {
      this.analytics.group(groupId, traits);
    } catch (err) {
      console.log(`Failed to group with err - ${err}`);
    }
  }

  async page(name, payload, context) {
    if (this.mock) return;
    try {
      this.analytics.page(name, payload, context);
    } catch (err) {
      console.log(`Failed to track page with err - ${err}`);
    }
  }

  async reset() {
    if (this.mock) return;
    try {
      this.analytics.reset();
    } catch (err) {
      console.log(`Failed to reset analytics with err - ${err}`);
    }
  }

  async track(event, payload, context) {
    if (this.mock) return;
    try {
      this.analytics.track(event, payload, context);
    } catch (err) {
      console.log(`Failed to track event with err - ${err}`);
    }
  }
}
