<template>
  <div class="flex flex-row">
    <div class="flex flex-row items-center" v-for="step of total" :key="step">
      <div
        class="flex flex-row items-center justify-center w-8 h-8 rounded-full border bg-opacity-20 heading-title3"
        :class="{
          'bg-dew-jerry-default border-dew-jerry-default text-dew-jerry-default':
            step === current,
          'border-basil-green-default bg-basil-green-default text-basil-green-default':
            step < current,
          'border-coconut-white-700 text-coconut-white-700': step > current,
        }"
      >
        <span v-if="step >= current">{{ step }}</span>
        <CheckIcon v-else class="w-4 h-4" />
      </div>
      <div
        class="flex flex-row items-center justify-center w-16 md:w-24 lg:w-26 border-b transition duration-300"
        v-if="step < total"
        :class="{
          'border-coconut-white-700 border-dotted': step >= current,
          'border-basil-green-default': step < current,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from "vue-feather-icons";

export default {
  components: {
    CheckIcon,
  },
  props: {
    total: {
      type: Number,
      required: true,
    },
    current: {
      type: Number,
      required: true,
    },
  },
};
</script>
