import { COLLECTIONS } from "~/services/firebase/firestore/types";
import { sanitizeText } from "~/utils/formatters";
import { firestoreDocumentSerializer } from "~/utils/serializers";

/**
 * Video Service provides helper methods to interact with firestore videos collection
 */
export default class {
  /**
   * Available statuses for a video in the firestore video collection
   */
  #VIDEO_STATUSES = ["queued", "waiting_for_upload", "preparing", "ready"];

  constructor($fire, $fireModule) {
    this.$fire = $fire;
    this.$fireModule = $fireModule;
  }

  /**
   *
   * @param {String} videoId as referenced in the firestore video collection
   * @returns video document data from firestore
   */
  async getVideo(videoId) {
    const videoRef = await this.$fire.firestore
      .collection(COLLECTIONS.VIDEOS)
      .doc(videoId)
      .get();
    return firestoreDocumentSerializer(videoRef);
  }

  /**
   *
   * @param {String} videoId as referenced in the firestore video collection
   * @param {Map} fields with updated values
   */
  async updateVideo(videoId, fields) {
    await this.$fire.firestore
      .collection(COLLECTIONS.VIDEOS)
      .doc(videoId)
      .update(fields);
  }

  /**
   *
   * @param {String} userId as referenced in the firestore user collection
   * @param {String} workspaceId of the workspace user is currently in
   * @param {Array} of videos that are available in the user's library and are imported from zoom
   */
  async fetchZoomImports(userId, workspaceId) {
    const videosSnapshot = await this.$fire.firestore
      .collection(COLLECTIONS.VIDEOS)
      .where("isTrashed", "==", false)
      .where("status", "in", this.#VIDEO_STATUSES)
      .where("ownerId", "==", userId)
      .where("workspaceId", "==", workspaceId)
      .where("source", "==", "zoom")
      .orderBy("createdAt", "desc")
      .get();
    return videosSnapshot.docs.map((doc) => firestoreDocumentSerializer(doc));
  }

  /**
   *
   * @param {Array<String>} videoIds of documents as referenced in the firestore video collection
   */
  fetchVideosByIds(videoIds) {
    const videosSnapshot = this.$fire.firestore
      .collection(COLLECTIONS.VIDEOS)
      .where(this.$fireModule.firestore.FieldPath.documentId(), "in", videoIds)
      .get();
    return videosSnapshot.docs.map((doc) => firestoreDocumentSerializer(doc));
  }

  /**
   *
   * @param {String} videoId as referenced in the firestore video collection
   * @param {FirestoreQuery} raw query which when executed fetches a video by id from firestore
   */
  fetchVideoByIdQuery(videoId) {
    return this.$fire.firestore.collection(COLLECTIONS.VIDEOS).doc(videoId);
  }

  /**
   *
   * @param {Array<String>} videoIds of documents as referenced in the firestore video collection
   * @param {FirestoreQuery} raw query which when executed fetches all videos requested from firestore
   */
  fetchVideosByIdsQuery(videoIds) {
    return this.$fire.firestore
      .collection(COLLECTIONS.VIDEOS)
      .where(this.$fireModule.firestore.FieldPath.documentId(), "in", videoIds);
  }

  /**
   *
   * @param {String} userId as referenced in the firestore user collection
   * @param {String} workspaceId of the workspace user is currently in
   * @param {FirestoreQuery} raw query which when executed fetches all videos in a user's library from firestore
   */
  fetchUserLibrayQuery(userId, workspaceId) {
    return this.$fire.firestore
      .collection(COLLECTIONS.VIDEOS)
      .where("isTrashed", "==", false)
      .where("ownerId", "==", userId)
      .where("workspaceId", "==", workspaceId)
      .where("status", "in", this.#VIDEO_STATUSES)
      .orderBy("createdAt", "desc");
  }

  /**
   *
   * @param {String} workspaceId of the workspace for which videos are given access to
   * @param {FirestoreQuery} raw query which when executed fetches videos published in the current workspace by workspace members.
   */
  fetchWorkspaceFeedQuery(workspaceId) {
    return this.$fire.firestore
      .collection(COLLECTIONS.VIDEOS)
      .where("isTrashed", "==", false)
      .where("status", "==", "ready")
      .where("acl.workspaceIds", "array-contains", workspaceId)
      .orderBy("createdAt", "desc");
  }

  /**
   *
   * @param {String} userId of the user for whom videos are given access to
   * @param {FirestoreQuery} raw query which when executed fetches videos shared with the logged-in user.
   */
  fetchUserFeedQuery(userId) {
    return this.$fire.firestore
      .collection(COLLECTIONS.VIDEOS)
      .where("isTrashed", "==", false)
      .where("status", "==", "ready")
      .where("acl.userIds", "array-contains", userId)
      .orderBy("createdAt", "desc");
  }

  /**
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   * @param {String} workspaceId to which the playlist is given access to
   */
  addWorkspaceToVideoACL(videoId, workspaceId, author) {
    return this.$fire.firestore
      .collection(COLLECTIONS.VIDEOS)
      .doc(videoId)
      .set(
        {
          acl: {
            workspaceIds:
              this.$fireModule.firestore.FieldValue.arrayUnion(workspaceId),
            workspaces: {
              [workspaceId]: {
                createdAt:
                  this.$fireModule.firestore.FieldValue.serverTimestamp(),
                createdBy: author,
              },
            },
          },
        },
        { merge: true },
      );
  }

  /**
   * @param {String} videoId as referenced in the firestore video collection
   * @param query as referenced in the firestore video collection
   */
  bookmarkPrompt(videoId, query) {
    return this.$fire.firestore
      .collection(COLLECTIONS.VIDEOS)
      .doc(videoId)
      .collection(COLLECTIONS.AI_INSIGHTS)
      .add({
        createdAt: this.$fireModule.firestore.FieldValue.serverTimestamp(),
        prompt: sanitizeText(query.prompt),
        response: sanitizeText(query.response),
        userId: query.userId,
        workspaceId: query.workspaceId,
        isPrivate: true,
      });
  }

  /**
   *
   * @param {*} videoId as referenced in the firestore video collection
   * @param {*} query as referenced in the firestore video collection
   * @returns
   */

  updateBookmark(videoId, query) {
    return this.$fire.firestore
      .collection(COLLECTIONS.VIDEOS)
      .doc(videoId)
      .collection(COLLECTIONS.AI_INSIGHTS)
      .doc(query.id)
      .update({
        prompt: query.prompt,
        response: query.response,
        isPrivate: query.isPrivate,
      });
  }
  /**
   *
   * @param {*} videoId as referenced in the firestore video collection
   * @param {*} query as referenced in the firestore video collection
   */

  deleteBookmark(videoId, query) {
    return this.$fire.firestore
      .collection(COLLECTIONS.VIDEOS)
      .doc(videoId)
      .collection(COLLECTIONS.AI_INSIGHTS)
      .doc(query.id)
      .delete();
  }

  /**
   *
   * @param {String} workspaceId as referenced in the firestore workspaceId collection
   * @param {String} userId to whom the playlist is given access to
   * @param {String} videoId as referenced in the firestore video collection
   */
  fetchUserBookmarksQuery(videoId, workspaceId, userId) {
    return this.$fire.firestore
      .collection(COLLECTIONS.VIDEOS)
      .doc(videoId)
      .collection(COLLECTIONS.AI_INSIGHTS)
      .where("workspaceId", "==", workspaceId)
      .where("userId", "==", userId)
      .orderBy("userId")
      .orderBy("createdAt", "asc");
  }

  /**
   *
   * @param {String} workspaceId as referenced in the firestore workspaceId collection
   * @param {String} userId to whom the playlist is given access to
   * @param {String} videoId as referenced in the firestore video collection
   */
  fetchPublicBookmarksQuery(videoId, workspaceId, userId) {
    return this.$fire.firestore
      .collection(COLLECTIONS.VIDEOS)
      .doc(videoId)
      .collection(COLLECTIONS.AI_INSIGHTS)
      .where("isPrivate", "==", false)
      .where("workspaceId", "==", workspaceId)
      .where("userId", "!=", userId)
      .orderBy("userId")
      .orderBy("createdAt", "asc");
  }
}
