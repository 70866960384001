/**
 * Map route to a page name
 * These names are used in the segment service to track page views
 *
 * Note:
 * 1/ Route should be in sync with each page and extend.routes.json
 * 2/ These names are not to be changed as they are used in segment and will affect existing data metrics
 */

const MARKETING_PAGES = {
  landing: "Landing",
  about: "About",
  pricing: "Pricing",
  features: "Features",
  "feature-details": "Feature Details",
  customers: "Customers",
  customer: "Case Study",
  "write-for-us": "Write For Us",
  manifesto: "Manifesto",
  privacy: "Privacy",
  tos: "Terms of Service",
  integrations: "Integrations Landing",
  "integrations-drive": "Google Drive Integration Landing",
  "integrations-slack": "Slack Integration Landing",
  "integrations-zoom": "Zoom Integration Landing",
  "docs-slug": "Documentation",
};

const APP_PAGES = {
  login: "Login",
  signup: "Signup",
  "create-workspace": "Create Workspace",
  invite: "Workspace Invite",
  "join-workspace": "Auto Join Workspaces",
  home: "Feed",
  "workspace-slug": "Feed",
  videos: "Personal Library",
  "videos-id": "Video Details",
  "playlists-id": "Playlist",
  player: "Player",
  "settings-general": "Workspace Details",
  "settings-members": "Workspace Members",
  "settings-billing": "Workspace Billing",
  "settings-integrations": "Workspace Integrations",
};

const PUBLIC_APP_PAGES = {
  "public-videos-id": "Public Player",
  "public-player": "Public Player",
  "public-playlists-id": "Public Playlist",
};

export default {
  ...MARKETING_PAGES,
  ...APP_PAGES,
  ...PUBLIC_APP_PAGES,
};
