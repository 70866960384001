const ZOOM_REDIRECT_COOKIE = "int_re_zoom";
const ONBOARDING_CONNECT_ZOOM_COOKIE = "onboarding_connect_zoom";
const ONBOARDING_CONNECT_DRIVE_COOKIE = "onboarding_connect_drive";
const ONBOARDING_INTENT_COOKIE = "onboarding_intent";

// For tracking if a new feature modal had been shown to the user
const NEW_FEATURE_MODAL_COOKIE = "new_feature";

module.exports = {
  ZOOM_REDIRECT_COOKIE,
  ONBOARDING_CONNECT_ZOOM_COOKIE,
  ONBOARDING_CONNECT_DRIVE_COOKIE,
  ONBOARDING_INTENT_COOKIE,
  NEW_FEATURE_MODAL_COOKIE,
};
