<template>
  <div
    class="item"
    :class="{
      'cursor-pointer': link,
    }"
    @click="onClickNotification()"
  >
    <div
      class="flex-shrink-0 mt-1 mr-3 items-center"
      v-if="author"
      :class="{
        'opacity-70': status === NOTIFICATION_STATUS.READ,
      }"
    >
      <img
        v-if="author.avatar"
        :src="author.avatar"
        class="rounded-full h-6 w-6"
      />
      <div
        class="md:w-8 md:h-8 w-6 h-6 object-cover border uppercase rounded-full body-subtitle2 border-pastel-brown text-coconut-white-default bg-slate-grey-default flex items-center justify-center"
        v-else
      >
        {{ getInitials(author.displayName) }}
      </div>
    </div>
    <div
      class="flex-shrink-0 mt-1 mr-4 self-baseline items-center"
      v-else-if="icon === 'clipboard'"
      :class="{
        'opacity-70': status === NOTIFICATION_STATUS.READ,
      }"
    >
      <ClipboardIcon style="height: 18px; width: 18px" />
    </div>
    <div
      class="flex-shrink-0 mt-1 self-baseline items-center"
      style="width: 34px"
      v-else-if="icon === 'sparkles'"
      :class="{
        'opacity-70': status === NOTIFICATION_STATUS.READ,
      }"
    >
      <SparklesPulse
        class="md"
        :pulse="status !== NOTIFICATION_STATUS.READ"
      ></SparklesPulse>
    </div>
    <div class="flex-grow-1 flex flex-col space-y-1 font-normal">
      <div
        class="body-subtitle3 text-coconut-white-600"
        v-html="sanitizeText(text)"
      ></div>
      <span class="body-text3 text-coconut-white-700">{{ createdAt }}</span>
    </div>
    <div
      class="text-pastel-pink"
      v-if="tags.length > 0 && tags.includes(PLAYLIST_TAGS.SPITI_CONNECT)"
    >
      <SpitiConnectIcon size="1x" />
    </div>
    <div
      class="ml-1 flex-shrink-0 self-start items-center"
      :class="{
        'opacity-70': status === NOTIFICATION_STATUS.READ,
      }"
      v-if="tags.length > 0 && tags.includes('NEW')"
    >
      <div class="new-tag1">New</div>
    </div>
  </div>
</template>
<script>
import { NOTIFICATION_STATUS } from "./types";
import { ClipboardIcon, SpitiConnectIcon } from "@spitilabs/icon-library";
import { PLAYLIST_TAGS } from "~/types/playlist";
import SparklesPulse from "~/components/icons/SparklesPulse";
import TrackingMixin from "~/components/mixins/tracking";
import { getInitials, sanitizeText } from "~/utils/formatters";

export default {
  mixins: [TrackingMixin],
  props: {
    status: {
      type: String,
      required: true,
    },
    author: {
      type: Object,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: false,
    },
    tags: {
      type: Array,
      required: false,
      default: () => [],
    },
    createdAt: {
      type: String,
      required: false,
    },
  },
  components: {
    ClipboardIcon,
    SpitiConnectIcon,
    SparklesPulse,
  },
  data() {
    return {
      NOTIFICATION_STATUS,
      PLAYLIST_TAGS,
    };
  },
  methods: {
    onClickNotification() {
      this.trackNotificationItemClicked();
      if (this.link) {
        this.$router.push(this.link);
      }
    },
    getInitials,
    sanitizeText,
  },
};
</script>
<style lang="scss" scoped>
.item {
  @apply flex flex-row px-6 py-3 transition duration-300;

  &:hover {
    @apply bg-coal-pop-200;
  }
  &:active {
    @apply bg-coal-pop-100;
  }
}
.new-tag1 {
  @apply text-coconut-white-400 font-medium;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 6px;
  font-size: 7.5px;
  line-height: 9px;
  width: 28.5px;
  height: 15px;
  border: 0.75px solid transparent;
  border-radius: 37.5px;
  background:
    linear-gradient(#292437 0 0) padding-box,
    linear-gradient(
        103.39deg,
        #26d7a0 5.29%,
        #26d7a0 5.3%,
        rgba(38, 215, 160, 0.06) 94.23%
      )
      border-box;
  backdrop-filter: blur(0.375px);
}

.item:hover .new-tag1 {
  background:
    linear-gradient(#39324d 0 0) padding-box,
    linear-gradient(
        103.39deg,
        #26d7a0 5.29%,
        #26d7a0 5.3%,
        rgba(38, 215, 160, 0.06) 94.23%
      )
      border-box;
  backdrop-filter: blur(0.375px);
}
</style>
