<template>
  <div class="relative">
    <button
      class="menu-item"
      @click="handleClickDropdown"
      v-if="view === VIDEO_DROPDOWN_VIEWS.FOOTER"
    >
      <add-video-icon class="text-currentColor item-icon" />
      <span class="heading-title4 ml-2">New Video</span>
    </button>

    <button
      v-else-if="view === VIDEO_DROPDOWN_VIEWS.EMPTY_STATE"
      class="btn-lg rounded-sm px-16 space-x-2 flex button primary"
      @click="handleClickDropdown"
    >
      <add-video-icon class="text-currentColor" />
      <span>Import Video</span>
    </button>
    <button
      class="flex flex-row space-x-2 items-center justify-center button md:py-1.5 md:px-3 md:btn-md rounded-sm navbar-button"
      @click="handleClickDropdown"
      :class="{
        active: isOpen,
      }"
      id="import-video-dropdown"
      v-else
    >
      <add-video-icon class="text-currentColor" />
      <span class="hidden sm:block heading-title3">New video</span>
    </button>
    <div
      v-if="isOpen && !isMobile"
      v-on-clickaway="closeDropdown"
      class="text-left bg-coal-pop-default border-0 border-coal-pop-default rounded flex flex-col items-start justify-start divide-ebony-grey-200 divide-y-1"
      :class="{
        'absolute left-0 bottom-full mb-2 w-64 ':
          view === VIDEO_DROPDOWN_VIEWS.FOOTER,
        'absolute right-0 mt-2 w-60 top-full':
          view === VIDEO_DROPDOWN_VIEWS.NAV_BAR,
      }"
    >
      <div v-if="isChrome && isMacintosh" class="section">
        <button
          class="option dropdown-item body-text2 rounded-t"
          @click="onClickScreenRecord()"
        >
          <RecordIcon style="height: 18px; width: 18px" />
          <span> Record a video</span>
        </button>
      </div>
      <div class="section h-11">
        <input
          id="direct-video-upload"
          accept="video/*"
          type="file"
          multiple="multiple"
          name="file-input"
          @change="handleVideoFilesInput"
          class="bg-transparent opacity-0 hidden"
        />
        <label
          for="direct-video-upload"
          class="option h-11 body-text2"
          :class="{ 'rounded-t': !(isChrome && isMacintosh) }"
        >
          <UploadIcon style="height: 18px; width: 18px" />
          <span>Direct Upload</span>
        </label>
      </div>

      <div class="section" style="height: 88px; border-bottom-width: 0">
        <button class="option body-text2 h-11" @click="onClickZoomImport()">
          <ZoomMonochromeIcon style="height: 18px; width: 18px" />
          <span>Import from Zoom </span>
        </button>
        <button
          class="option body-text2 h-11 rounded-b"
          @click="onClickDriveImport()"
        >
          <DriveMonochromeIcon style="height: 18px; width: 18px" />
          <span> Import from Google Drive™ </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AddVideoIcon,
  RecordIcon,
  UploadIcon,
  ZoomMonochromeIcon,
  DriveMonochromeIcon,
} from "@spitilabs/icon-library";
import { mapGetters } from "vuex";
import ImportZoomVideoModal from "~/components/modals/ImportZoomVideoModal";
import InformationModal from "~/components/modals/InformationModal";
import DirectVideoUploader from "~/components/mixins/direct-video-uploader";
// import { AddVideoIcon } from "@spitilabs/icon-library";
import DrivePicker from "~/components/mixins/drive-picker";
import { directive as onClickaway } from "vue-clickaway";
import { isMobileDevice } from "~/utils/validator";
import { VIDEO_IMPORT } from "~/types/intents";
import { ZOOM_REDIRECT_COOKIE } from "~/types/cookies";

const VIDEO_DROPDOWN_VIEWS = {
  FOOTER: "footer",
  NAV_BAR: "nav-bar",
  EMPTY_STATE: "empty-state",
};

export default {
  mixins: [DirectVideoUploader, DrivePicker],
  directives: {
    onClickaway: onClickaway,
  },
  components: {
    UploadIcon,
    ImportZoomVideoModal,
    InformationModal,
    AddVideoIcon,
    RecordIcon,
    ZoomMonochromeIcon,
    DriveMonochromeIcon,
  },
  props: {
    view: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      VIDEO_DROPDOWN_VIEWS,
    };
  },
  computed: {
    ...mapGetters({
      isZoomConfigured: "auth/isZoomConfigured",
      isDriveConfigured: "auth/isDriveConfigured",
      driveAppSecrets: "auth/driveAppSecrets",
      defaultWorkspace: "workspace/defaultWorkspace",
      workspaceLimits: "billing/limits",
      viewer: "auth/viewer",
    }),
    isChrome() {
      return process.client && /Chrome/.test(navigator.userAgent);
    },
    isMacintosh() {
      return process.client && /Macintosh/.test(navigator.userAgent);
    },
    isMobile() {
      return isMobileDevice();
    },
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    onClickScreenRecord() {
      const deeplinkUrl = `${this.$config.screenRecorder.protocol}://`;
      //variable will check app installed or not
      let change = false;
      setTimeout(() => {
        if (!change) {
          window.location = `${this.$config.appBaseUrl}/screen-recorder`;
        }
      }, 1000);
      window.location = deeplinkUrl;
      //handle event to check app installed or not
      window.onblur = function () {
        change = true;
      };
      window.onfocus = function () {
        change = false;
      };
    },
    onClickZoomImport() {
      if (!this.isZoomConfigured) {
        const redirectParams = {
          name: this.$route.name,
          params: this.$route.params,
        };
        this.$cookie.set(ZOOM_REDIRECT_COOKIE, JSON.stringify(redirectParams), {
          expires: "5m",
        });
        window.location.href = this.$config.zoomConfigurationUrl;
        return;
      }
      this.$root.$emit("modal:open", ImportZoomVideoModal.name);
      this.isOpen = false;
    },
    handleVideoFilesInput(e) {
      this.isOpen = false;
      const files = e.target.files;
      if (!files) {
        return;
      }
      this.uploadVideoFiles(files);
    },
    async onClickDriveImport() {
      if (!this.isDriveConfigured) {
        window.location.href = this.$config.driveConfigurationUrl;
        return;
      }
      await this.openDrivePicker(VIDEO_IMPORT.DEFAULT);
      this.isOpen = false;
    },
    formatFileName: function (name) {
      const supportedFileFormats = ["mp4", "mkv", "3gp", "mov"];
      const fileExtension = name.split(".").pop();
      if (fileExtension && supportedFileFormats.includes(fileExtension)) {
        return name.replace(`.${fileExtension}`, "");
      }
      return name;
    },
    handleClickDropdown() {
      if (this.isMobile) {
        this.$root.$emit(
          "modal:open",
          InformationModal.name,
          "To import a video to your workspace, please login from a web-browser.",
        );
      } else {
        this.isOpen = !this.isOpen;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  @apply h-11 w-full flex flex-col content-center;
}
.option {
  @apply flex px-3 space-x-2 py-3 items-center  cursor-pointer text-coconut-white-500 transition duration-300;
  @media (hover: hover) {
    &:hover {
      @apply bg-coal-pop-200;
    }
  }
  &:active {
    @apply text-coconut-white-default;
  }
}
.navbar-button {
  @apply text-dew-jerry-default;
  @media (hover: hover) {
    &:hover {
      @apply text-dew-jerry-200  transition duration-300;
      background: rgba(144, 128, 214, 0.08);
    }
  }
  &:active,
  &.active {
    @apply text-dew-jerry-100  bg-opacity-20;
    background: rgba(144, 128, 214, 0.16);
  }
}
.menu-item {
  @apply w-full flex flex-row h-8 py-2 px-2 pl-0 items-center  cursor-pointer text-coconut-white-600 active:text-coconut-white-400 rounded-sm responsive:hover:bg-slate-grey-200 transition-all;

  .item-icon {
    width: 18px;
    height: 18px;
    margin-left: 14px;
  }
}
</style>
