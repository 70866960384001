<template>
  <div
    class="flex flex-col border-t border-ebony-grey-400 divide-y-2 divide-ebony-grey-400 flex-shrink-0"
  >
    <client-only>
      <ConnectAppsDropdown />
    </client-only>
    <div class="flex flex-col p-4 pl-2.5">
      <ImportVideoDropdown :view="VIDEO_DROPDOWN_VIEWS.FOOTER" />
      <nuxt-link
        :to="{ name: 'videos' }"
        class="menu-item"
        id="video-library-button"
      >
        <stack-video-icon class="item-icon" />
        <span class="heading-title4 ml-2">Your Library</span>
      </nuxt-link>
      <nuxt-link :to="{ name: 'settings-general' }" class="menu-item">
        <gear-icon class="item-icon" />
        <span class="heading-title4 ml-2">Team Settings</span>
      </nuxt-link>
    </div>
  </div>
</template>
<script>
import { StackVideoIcon, GearIcon } from "@spitilabs/icon-library";
import { mapGetters } from "vuex";
import ImportVideoDropdown from "~/components/dropdowns/ImportVideoDropdown";
import ConnectAppsDropdown from "~/components/dropdowns/ConnectAppsDropdown";

const VIDEO_DROPDOWN_VIEWS = {
  FOOTER: "footer",
  NAV_BAR: "nav-bar",
};

export default {
  components: {
    StackVideoIcon,
    GearIcon,
    ImportVideoDropdown,
    ConnectAppsDropdown,
  },
  head() {
    return {
      script: [
        {
          hid: "gapi",
          src: "https://apis.google.com/js/api.js",
          defer: true,
          // Changed after script load
          callback: () => {
            this.isGapiLoaded = true;
          },
        },
      ],
    };
  },
  data() {
    return {
      isGapiLoaded: false,
      pickerApiLoaded: false,
      VIDEO_DROPDOWN_VIEWS,
    };
  },
  async mounted() {
    if (this.viewer?.integrations?.drive) {
      // Fetch user's drive app secrets
      await this.$store.dispatch("auth/fetchDriveAppSecrets");
    }
  },
  computed: {
    ...mapGetters({
      defaultWorkspace: "workspace/defaultWorkspace",
      viewer: "auth/viewer",
      isZoomConfigured: "auth/isZoomConfigured",
    }),
  },
  watch: {
    isGapiLoaded: function (isLoaded) {
      if (isLoaded) {
        window.gapi.load("picker", {
          callback: () => {
            this.pickerApiLoaded = true;
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-item {
  @apply w-full flex flex-row h-8 py-2 px-2 pl-0 items-center cursor-pointer text-coconut-white-600 active:text-coconut-white-400 rounded-sm responsive:hover:bg-slate-grey-200 transition-all;
  .item-icon {
    width: 18px;
    height: 18px;
    margin-left: 14px;
  }
}
</style>
