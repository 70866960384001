<template>
  <a href="https://www.wikiaitools.com/" target="_blank"
    ><img
      src="https://www.wikiaitools.com/images/share-rate/featured_badge.png"
      alt="Featured Badge"
  /></a>
</template>

<script>
export default {
  name: "wiki-tools",
};
</script>
