<template>
  <div>
    <MarketingNavbar :sticky="sticky" />
    <Nuxt />
    <MarketingFooter />
  </div>
</template>

<script>
import MarketingNavbar from "~/components/marketing/MarketingNavbar";
import MarketingFooter from "~/components/marketing/MarketingFooter";
import pages from "~/constants/pages";

export default {
  components: {
    MarketingNavbar,
    MarketingFooter,
  },
  data() {
    return {
      sticky: false,
    };
  },
  mounted() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
    this.trackPageVisit();
    this.handleMobileLayout();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    $route() {
      this.sticky = false;
      this.trackPageVisit();
    },
  },
  methods: {
    handleMobileLayout() {
      let isMobile = window.innerWidth < 768;

      // Set isSmallScreen for the current device
      this.$store.dispatch("setScreenSmall", isMobile);
    },
    trackPageVisit() {
      const page = pages[this.$route.name];
      if (!page) {
        return;
      }
      this.$analytics.page(page, {}, {});
    },
    handleScroll() {
      if (window.scrollY > 0) {
        this.sticky = true;
      } else {
        this.sticky = false;
      }
    },
  },
};
</script>
