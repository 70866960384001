<template>
  <div
    class="flex flex-col flex-grow overflow-y-auto"
    :class="{
      'justify-center items-center':
        loading || !videosListDisplay || videosListDisplay.length == 0,
    }"
  >
    <loader v-if="loading" text="Getting videos from Zoom"></loader>
    <div
      class="flex flex-col space-y-10 h-full items-center justify-center"
      v-else-if="videosListDisplay.length == 0"
    >
      <img src="~/assets/img/illustrations/finder.svg" class="w-16" />
      <span class="text-xs italic text-coconut-white-500"
        >No zoom recordings found in the last 30 days :(</span
      >
    </div>
    <div v-else class="py-4 flex flex-col space-y-6">
      <div
        v-for="video of videosListDisplay"
        :key="video.id"
        class="px-6 flex flex-row items-center space-x-2 justify-between"
      >
        <div class="flex flex-col space-y-2 sm:w-1/2">
          <div class="flex flex-row items-center space-x-1">
            <span class="line-clamp-1 body-text2 text-coconut-white-default">{{
              video.title
            }}</span>
            <CheckCircleFillIcon
              v-if="isVideoImported(video)"
              class="w-4 h-4 text-dew-jerry-default"
            />
          </div>
          <div
            class="body-text3 text-coconut-white-700 flex flex-row items-center gap-x-2"
          >
            <ClockIcon class="h-4 w-4" />
            <p>{{ video.startTime.format("LLLL") }}</p>
          </div>
        </div>
        <div class="flex flex-row space-x-2">
          <nuxt-link
            class="base-btn-cls button tertiary"
            :to="{
              name: 'videos-id',
              params: { id: videoMap[video.id] },
            }"
            v-if="isVideoImported(video)"
          >
            <VideoLibraryIcon class="h-4 w-4 text-coconut-white-default" />
            <span class="heading-title4 text-coconut-white-default"
              >View in Your Library</span
            >
          </nuxt-link>
          <a
            class="base-btn-cls button tertiary"
            :href="video.playUrl"
            target="_blank"
            rel="noopener noreferrer"
            v-if="!isVideoImported(video)"
          >
            <ExternalLinkIcon class="h-4 w-4 text-coconut-white-default" />
            <span class="heading-title4 text-coconut-white-default">Play</span>
          </a>
          <button
            class="base-btn-cls button tertiary"
            :disabled="planLimitReached || importLoadingVideoId[video.id]"
            :class="{
              'btn-disabled':
                planLimitReached || importLoadingVideoId[video.id],
            }"
            @click="importVideo(video)"
            v-if="!isVideoImported(video)"
            v-tooltip="
              planLimitReached && {
                content: 'Workspace limit reached',
                placement: 'top',
              }
            "
          >
            <RotateCwIcon
              class="animate-spin h-4 w-4"
              v-if="importLoadingVideoId[video.id]"
            />
            <DownloadCloudFillIcon class="h-4 w-4" v-else />
            <span>Import</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Loader from "~/components/placeholders/Loader";
import { FUNCTIONS } from ".";
import { forEach, includes, keys, cloneDeep } from "lodash";
import {
  CheckCircleFillIcon,
  ClockIcon,
  VideoLibraryIcon,
  ExternalLinkIcon,
  DownloadCloudFillIcon,
  RotateCwIcon,
} from "@spitilabs/icon-library";
import TrackingMixin from "~/components/mixins/tracking";

import { VIDEO_IMPORT } from "~/types/intents";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { ZOOM_REDIRECT_COOKIE } from "~/types/cookies";
dayjs.extend(localizedFormat);

// we only care about video meetings; maintain a whitelist of allowed file types
const ALLOWED_RECORDING_FILE_TYPES = ["MP4"];

export default {
  props: {
    intent: {
      type: String,
      default: VIDEO_IMPORT.DEFAULT,
    },
    playlistId: {
      type: String,
      default: null,
    },
  },
  mixins: [TrackingMixin],
  components: {
    CheckCircleFillIcon,
    VideoLibraryIcon,
    ClockIcon,
    DownloadCloudFillIcon,
    ExternalLinkIcon,
    RotateCwIcon,
    Loader,
  },
  data() {
    return {
      loading: true,
      importLoadingVideoId: {},
      videoMap: {},
      videos: [],
      VIDEO_IMPORT_INTENT: VIDEO_IMPORT,
    };
  },
  computed: {
    ...mapGetters({
      viewer: "auth/viewer",
      workspace: "workspace/defaultWorkspace",
      importedZoomVideos: "video/importedZoomVideos",
      workspaceLimits: "billing/limits",
    }),
    planLimitReached: function () {
      if (this.workspace.videoMinutes >= this.workspaceLimits.videoMinutes) {
        return true;
      }
      return false;
    },
    /***
     * Return the display-ready list of videos after transforming the response
     * from Zoom.
     */
    videosListDisplay: function () {
      const videos = [];

      // traverse on each object in the meetings key for each recorded meeting
      forEach(this.videos.data.meetings, function (meeting) {
        const meetingMeta = {
          meetingUuid: meeting.uuid,
          meetingId: meeting.id,
          accountId: meeting.account_id,
          title: meeting.topic,
          timezone: meeting.timezone,
          duration: meeting.duration,
        };

        // each recorded meeting can have multiple recorded videos
        // traverse over the recording_files key
        forEach(meeting.recording_files, function (recording) {
          // move along if the file_type isn't in our whitelist
          if (!includes(ALLOWED_RECORDING_FILE_TYPES, recording.file_type)) {
            return;
          }

          const recordingData = {
            id: recording.id,
            startTime: dayjs(recording.recording_start),
            playUrl: recording.play_url,
            downloadUrl: recording.download_url,
            fileSize: recording.file_size,
          };

          videos.push({
            ...meetingMeta,
            ...recordingData,
          });
        });
      });

      return videos;
    },
  },
  async mounted() {
    this.videos = await this.getZoomVideos();
    await this.$store.dispatch("video/fetchImportedZoomVideos", {
      userId: this.viewer.uid,
      workspaceId: this.workspace.id,
    });
    this.loading = false;
  },
  methods: {
    async getZoomVideos() {
      try {
        const zoom_videos = await this.$fire.functions.httpsCallable(
          FUNCTIONS.getZoomVideos,
        )({
          user: this.viewer,
        });
        return zoom_videos;
      } catch (err) {
        await this.handleErrorFetchingZoomVideos(err);
      }
    },
    isVideoImported(video) {
      if (this.intent === VIDEO_IMPORT.ONBOARDING) {
        return false;
      }
      return includes(keys(this.videoMap), video.id);
    },
    async importVideo(video) {
      // Check if video duration is within plan limits
      if (
        video.duration / 60 + this.workspace.videoMinutes >
        this.workspaceLimits.videoMinutes
      ) {
        this.$toast.error("You are importing beyond plan limits.");
        return;
      }

      Vue.set(this.importLoadingVideoId, video.id, true);
      try {
        const resp = await this.$fire.functions.httpsCallable(
          FUNCTIONS.importVideoFromZoom,
        )({
          downloadUrl: video.downloadUrl,
          zoomVideoId: video.id,
          name: video.title,
          workspaceId: this.workspace.id,
          fileSize: video.fileSize,
          duration: video.duration,
        });
        Vue.set(this.importLoadingVideoId, video.id, false);

        if (resp.data.status === "error") {
          this.$toast.error(resp.data.description);
          return;
        }

        Vue.set(this.videoMap, video.id, resp.data.videoId);
        this.$root.$emit("tracker:add", [resp.data.videoId]);
        // Publish video to playlist if playlistId is present
        if (this.playlistId) {
          await this.$store.dispatch("queue/addVideoToPlaylist", {
            options: {
              playlistId: this.playlistId,
              author: this.viewer.uid,
              workspaceId: this.workspace.id,
            },
            videoId: resp.data.videoId,
          });
        }
        this.trackVideoImported(video.id, {
          source: "zoom",
          fileSizeMb: parseFloat(video.fileSize / 1000000).toFixed(1),
          durationInSeconds: video.duration,
        });
        if (this.intent === VIDEO_IMPORT.ONBOARDING) {
          this.$router.push({
            name: "onboarding-publish",
            query: {
              video: resp.data.videoId,
            },
          });
        }
      } catch (err) {
        Vue.set(this.importLoadingVideoId, video.id, false);
        this.$toast.error("Failed to import zoom video");
        console.error(err);
      }
    },
    async handleErrorFetchingZoomVideos(error) {
      if (
        error.code === "functions/unauthenticated" &&
        error.message === "Error refreshing access token"
      ) {
        // "functions/unauthenticated" -  Display Error toast and Retrigger OAuth
        this.$toast.error(
          "Zoom integration failed to connect. Attempting to reconnect",
        );

        const redirectParams = {
          name: this.$route.name,
          params: this.$route.params,
          modalParams: { playlistId: this.playlistId },
        };

        // Trigger Re Oauth workflow with delay of 1s
        setTimeout(() => {
          this.$cookie.set(
            ZOOM_REDIRECT_COOKIE,
            JSON.stringify(redirectParams),
            {
              expires: "5m",
            },
          );
          window.location.href = this.$config.zoomConfigurationUrl;
        }, 1000);
      } else {
        // Unknown error - Display Generic Error toast and Log the error
        this.$toast.error("Failed to fetch zoom videos");
        console.log(error);
      }
    },
  },
  watch: {
    importedZoomVideos: function () {
      this.videoMap = cloneDeep(this.importedZoomVideos);
    },
  },
};
</script>
<style lang="scss" scoped>
.base-btn-cls {
  @apply flex flex-row space-x-2 items-center justify-center px-4 py-2;
}
</style>
