<template>
  <a
    href="https://www.producthunt.com/posts/spiti-screen-recorder-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-spiti&#0045;screen&#0045;recorder&#0045;2"
    target="_blank"
    ><img
      loading="lazy"
      :src="productHuntImg"
      alt="Spiti&#0032;Screen&#0032;Recorder - Instantly&#0032;record&#0032;&#0038;&#0032;collaborate&#0032;on&#0032;explainer&#0032;videos&#0032;:sparkles: | Product Hunt"
  /></a>
</template>

<script>
export default {
  name: "product-hunt",
  data() {
    return {
      productHuntImg: `https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=392455&theme=light&period=daily`,
    };
  },
};
</script>
