import { isAgentBrowser } from "~/services/site-meta";

const GUARD_PAGES = [
  "workspace-slug",
  "videos",
  "videos-edit",
  "videos-id",
  "playlists-id",
  "player",
  "settings-billing",
  "settings-general",
  "settings-integrations",
  "settings-members",
  "heathrow-drive-oauth",
  "heathrow-zoom-oauth",
  "heathrow-slack-oauth",
  "heathrow-stripe-subscription",
  "heathrow-connect-playlist",
  "onboarding-create-ws",
  "onboarding-connect",
  "onboarding-import",
  "onboarding-publish",
];

/**
 * Middleware for pages that are supposed to be open by logged in users only.
 * Auth guard should take care of routing and handling redirects between login and app pages.
 */
export default function ({ route, req, store, redirect }) {
  const isBrowser = process.server && isAgentBrowser(req);

  // Case 1: spiti.xyz to redirect to home page if logged in
  // Note: If the user context is already passed from client side to server, we redirect to login (login redirects to home)
  if (
    process.server &&
    isBrowser &&
    route.path === "/" &&
    store.getters["auth/viewer"]?.uid
  ) {
    redirect("/login");
    return;
  }

  // Case 2: Handle magic links (magic links are video links with workspace join token in the url)
  // Note: For magic links, we first get the user to join workspace and then redirect to the video
  if (
    !store.getters["auth/isViewerAuthenticated"] &&
    (process.client || isBrowser) &&
    route.name === "player" &&
    route.query.token
  ) {
    redirect(
      `/invite?token=${route.query.token}&redirectTo=${encodeURIComponent(
        `/player?video=${route.query.video}`,
      )}`,
    );
  }

  // Case 3: Handle unauthenticated users trying to access app pages
  // Note: We gracefully redirect to login if the user is not authenticated, and then once logged in, we redirect to the page they were trying to access
  if (
    !store.getters["auth/isViewerAuthenticated"] &&
    (process.client || isBrowser) &&
    GUARD_PAGES.includes(route.name)
  ) {
    redirect(`/login?redirectTo=${encodeURIComponent(route.fullPath)}`);
  }
}
