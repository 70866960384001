function setFavicon() {
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    const favicon = document.querySelector("[data-dark]");

    // No icon found
    if (!favicon) {
      return;
    }

    // Dark version available
    if (favicon.dataset.dark) {
      favicon.href = favicon.dataset.dark;
    }
  }
}

window.addEventListener("DOMContentLoaded", setFavicon);

export default {};
