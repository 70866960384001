<template>
  <header class="docs-navbar navbar md:px-8 px-5 gap-x-4">
    <a :href="workspaceUrl" target="_blank" rel="noopener noreferrer">
      <img
        src="~/assets/img/full-logo-white.svg"
        alt="Spiti"
        class="h-6 opacity-100 transition-opacity duration-300"
      />
    </a>
    <div class="flex-grow"></div>
    <a
      href="///twitter.com/spitidotxyz"
      target="_blank"
      v-tooltip="'Twitter'"
      rel="noopener noreferrer"
    >
      <img src="~/assets/img/logo-twitter.svg" alt="Twitter" class="h-6" />
    </a>
    <a
      href="//discord.gg/HKKFGdDYqt"
      target="_blank"
      v-tooltip="'Discord'"
      rel="noopener noreferrer"
    >
      <img src="~/assets/img/logo-discord.svg" alt="Discord" class="h-6" />
    </a>
    <a
      href="//linkedin.com/company/spitilabs"
      target="_blank"
      v-tooltip="'Linkedin'"
      rel="noopener noreferrer"
    >
      <img src="~/assets/img/logo-linkedin.svg" alt="Linkedin" class="h-6" />
    </a>
  </header>
</template>

<script>
import { MenuIcon } from "vue-feather-icons";
import { mapGetters } from "vuex";

export default {
  name: "docs-navbar",
  components: {
    MenuIcon,
  },
  computed: {
    ...mapGetters({
      defaultWorkspace: "workspace/defaultWorkspace",
    }),
    workspaceUrl() {
      return this.defaultWorkspace?.shortcode
        ? `${this.$config.appBaseUrl}/workspace/${this.defaultWorkspace.shortcode}`
        : this.$config.appBaseUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.docs-navbar {
  @apply fixed top-0 z-40 bg-black-300 md:bg-slate-grey-default w-screen border-b border-ebony-grey-default flex flex-row items-center;
}
</style>
