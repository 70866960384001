import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e7d1c7a2 = () => interopDefault(import('../pages/marketing/index.vue' /* webpackChunkName: "pages/marketing/index" */))
const _3729026a = () => interopDefault(import('../pages/player.vue' /* webpackChunkName: "pages/player" */))
const _20bd78c5 = () => interopDefault(import('../pages/videos/index.vue' /* webpackChunkName: "pages/videos/index" */))
const _b332d100 = () => interopDefault(import('../pages/auth/actions.vue' /* webpackChunkName: "pages/auth/actions" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _12eab98a = () => interopDefault(import('../pages/auth/message.vue' /* webpackChunkName: "pages/auth/message" */))
const _261ab74b = () => interopDefault(import('../pages/auth/recorder.vue' /* webpackChunkName: "pages/auth/recorder" */))
const _0fdb26a6 = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _9b880836 = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _1ccdc29c = () => interopDefault(import('../pages/embed/playlist.vue' /* webpackChunkName: "pages/embed/playlist" */))
const _4928f34b = () => interopDefault(import('../pages/embed/video.vue' /* webpackChunkName: "pages/embed/video" */))
const _73fe8b3b = () => interopDefault(import('../pages/heathrow/spiti-connect.vue' /* webpackChunkName: "pages/heathrow/spiti-connect" */))
const _52bb4930 = () => interopDefault(import('../pages/heathrow/subscription.vue' /* webpackChunkName: "pages/heathrow/subscription" */))
const _6c5f416a = () => interopDefault(import('../pages/marketing/about.vue' /* webpackChunkName: "pages/marketing/about" */))
const _7d7ff696 = () => interopDefault(import('../pages/marketing/customers/index.vue' /* webpackChunkName: "pages/marketing/customers/index" */))
const _67ecd21a = () => interopDefault(import('../pages/marketing/features/index.vue' /* webpackChunkName: "pages/marketing/features/index" */))
const _d32d4056 = () => interopDefault(import('../pages/marketing/integrations/index.vue' /* webpackChunkName: "pages/marketing/integrations/index" */))
const _72a7c9fd = () => interopDefault(import('../pages/marketing/manifesto.vue' /* webpackChunkName: "pages/marketing/manifesto" */))
const _2e809be3 = () => interopDefault(import('../pages/marketing/pricing.vue' /* webpackChunkName: "pages/marketing/pricing" */))
const _2b3d6ea5 = () => interopDefault(import('../pages/marketing/privacy.vue' /* webpackChunkName: "pages/marketing/privacy" */))
const _0080a216 = () => interopDefault(import('../pages/marketing/tos.vue' /* webpackChunkName: "pages/marketing/tos" */))
const _4b670d03 = () => interopDefault(import('../pages/marketing/write-for-us.vue' /* webpackChunkName: "pages/marketing/write-for-us" */))
const _1f474760 = () => interopDefault(import('../pages/onboarding/connect.vue' /* webpackChunkName: "pages/onboarding/connect" */))
const _2e14cac3 = () => interopDefault(import('../pages/onboarding/create-ws.vue' /* webpackChunkName: "pages/onboarding/create-ws" */))
const _8342d1c2 = () => interopDefault(import('../pages/onboarding/import.vue' /* webpackChunkName: "pages/onboarding/import" */))
const _d83a5df6 = () => interopDefault(import('../pages/onboarding/publish.vue' /* webpackChunkName: "pages/onboarding/publish" */))
const _5ad68482 = () => interopDefault(import('../pages/public/video.vue' /* webpackChunkName: "pages/public/video" */))
const _211306f9 = () => interopDefault(import('../pages/settings/billing.vue' /* webpackChunkName: "pages/settings/billing" */))
const _4ff4eee6 = () => interopDefault(import('../pages/settings/general.vue' /* webpackChunkName: "pages/settings/general" */))
const _d4f3cede = () => interopDefault(import('../pages/settings/integrations.vue' /* webpackChunkName: "pages/settings/integrations" */))
const _6f1d2e97 = () => interopDefault(import('../pages/settings/members.vue' /* webpackChunkName: "pages/settings/members" */))
const _2839fde7 = () => interopDefault(import('../pages/videos/edit.vue' /* webpackChunkName: "pages/videos/edit" */))
const _0a83f2c0 = () => interopDefault(import('../pages/workspace/create.vue' /* webpackChunkName: "pages/workspace/create" */))
const _1eea73a6 = () => interopDefault(import('../pages/workspace/invite.vue' /* webpackChunkName: "pages/workspace/invite" */))
const _06dd4ece = () => interopDefault(import('../pages/workspace/join.vue' /* webpackChunkName: "pages/workspace/join" */))
const _43993a4f = () => interopDefault(import('../pages/heathrow/oauth/drive.vue' /* webpackChunkName: "pages/heathrow/oauth/drive" */))
const _85e96556 = () => interopDefault(import('../pages/heathrow/oauth/slack.vue' /* webpackChunkName: "pages/heathrow/oauth/slack" */))
const _a5df4fc4 = () => interopDefault(import('../pages/heathrow/oauth/zoom.vue' /* webpackChunkName: "pages/heathrow/oauth/zoom" */))
const _39c46f7e = () => interopDefault(import('../pages/marketing/features/ai-insights.vue' /* webpackChunkName: "pages/marketing/features/ai-insights" */))
const _65ba2a23 = () => interopDefault(import('../pages/marketing/features/details.vue' /* webpackChunkName: "pages/marketing/features/details" */))
const _7cff5d60 = () => interopDefault(import('../pages/marketing/features/screen-recorder.vue' /* webpackChunkName: "pages/marketing/features/screen-recorder" */))
const _750db48d = () => interopDefault(import('../pages/marketing/integrations/drive.vue' /* webpackChunkName: "pages/marketing/integrations/drive" */))
const _230070da = () => interopDefault(import('../pages/marketing/integrations/slack.vue' /* webpackChunkName: "pages/marketing/integrations/slack" */))
const _c3b6c3c0 = () => interopDefault(import('../pages/marketing/integrations/zoom.vue' /* webpackChunkName: "pages/marketing/integrations/zoom" */))
const _80ed3926 = () => interopDefault(import('../pages/marketing/customers/_slug.vue' /* webpackChunkName: "pages/marketing/customers/_slug" */))
const _14be58c7 = () => interopDefault(import('../pages/marketing/product/_slug.vue' /* webpackChunkName: "pages/marketing/product/_slug" */))
const _41b129c0 = () => interopDefault(import('../pages/docs/_slug.vue' /* webpackChunkName: "pages/docs/_slug" */))
const _526d6212 = () => interopDefault(import('../pages/playlists/_id.vue' /* webpackChunkName: "pages/playlists/_id" */))
const _7aad8fdc = () => interopDefault(import('../pages/public-playlists/_id.vue' /* webpackChunkName: "pages/public-playlists/_id" */))
const _586ce86d = () => interopDefault(import('../pages/videos/_id.vue' /* webpackChunkName: "pages/videos/_id" */))
const _79f0c2d4 = () => interopDefault(import('../pages/workspace/_slug.vue' /* webpackChunkName: "pages/workspace/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/marketing",
    component: _e7d1c7a2,
    name: "marketing"
  }, {
    path: "/player",
    component: _3729026a,
    name: "player"
  }, {
    path: "/videos",
    component: _20bd78c5,
    name: "videos"
  }, {
    path: "/auth/actions",
    component: _b332d100,
    name: "auth-actions"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login"
  }, {
    path: "/auth/message",
    component: _12eab98a,
    name: "auth-message"
  }, {
    path: "/auth/recorder",
    component: _261ab74b,
    name: "auth-recorder"
  }, {
    path: "/auth/reset-password",
    component: _0fdb26a6,
    name: "auth-reset-password"
  }, {
    path: "/auth/signup",
    component: _9b880836,
    name: "auth-signup"
  }, {
    path: "/embed/playlist",
    component: _1ccdc29c,
    name: "embed-playlist"
  }, {
    path: "/embed/video",
    component: _4928f34b,
    name: "embed-video"
  }, {
    path: "/heathrow/spiti-connect",
    component: _73fe8b3b,
    name: "heathrow-spiti-connect"
  }, {
    path: "/heathrow/subscription",
    component: _52bb4930,
    name: "heathrow-subscription"
  }, {
    path: "/marketing/about",
    component: _6c5f416a,
    name: "marketing-about"
  }, {
    path: "/marketing/customers",
    component: _7d7ff696,
    name: "marketing-customers"
  }, {
    path: "/marketing/features",
    component: _67ecd21a,
    name: "marketing-features"
  }, {
    path: "/marketing/integrations",
    component: _d32d4056,
    name: "marketing-integrations"
  }, {
    path: "/marketing/manifesto",
    component: _72a7c9fd,
    name: "marketing-manifesto"
  }, {
    path: "/marketing/pricing",
    component: _2e809be3,
    name: "marketing-pricing"
  }, {
    path: "/marketing/privacy",
    component: _2b3d6ea5,
    name: "marketing-privacy"
  }, {
    path: "/marketing/tos",
    component: _0080a216,
    name: "marketing-tos"
  }, {
    path: "/marketing/write-for-us",
    component: _4b670d03,
    name: "marketing-write-for-us"
  }, {
    path: "/onboarding/connect",
    component: _1f474760,
    name: "onboarding-connect"
  }, {
    path: "/onboarding/create-ws",
    component: _2e14cac3,
    name: "onboarding-create-ws"
  }, {
    path: "/onboarding/import",
    component: _8342d1c2,
    name: "onboarding-import"
  }, {
    path: "/onboarding/publish",
    component: _d83a5df6,
    name: "onboarding-publish"
  }, {
    path: "/public/video",
    component: _5ad68482,
    name: "public-video"
  }, {
    path: "/settings/billing",
    component: _211306f9,
    name: "settings-billing"
  }, {
    path: "/settings/general",
    component: _4ff4eee6,
    name: "settings-general"
  }, {
    path: "/settings/integrations",
    component: _d4f3cede,
    name: "settings-integrations"
  }, {
    path: "/settings/members",
    component: _6f1d2e97,
    name: "settings-members"
  }, {
    path: "/videos/edit",
    component: _2839fde7,
    name: "videos-edit"
  }, {
    path: "/workspace/create",
    component: _0a83f2c0,
    name: "workspace-create"
  }, {
    path: "/workspace/invite",
    component: _1eea73a6,
    name: "workspace-invite"
  }, {
    path: "/workspace/join",
    component: _06dd4ece,
    name: "workspace-join"
  }, {
    path: "/heathrow/oauth/drive",
    component: _43993a4f,
    name: "heathrow-oauth-drive"
  }, {
    path: "/heathrow/oauth/slack",
    component: _85e96556,
    name: "heathrow-oauth-slack"
  }, {
    path: "/heathrow/oauth/zoom",
    component: _a5df4fc4,
    name: "heathrow-oauth-zoom"
  }, {
    path: "/marketing/features/ai-insights",
    component: _39c46f7e,
    name: "marketing-features-ai-insights"
  }, {
    path: "/marketing/features/details",
    component: _65ba2a23,
    name: "marketing-features-details"
  }, {
    path: "/marketing/features/screen-recorder",
    component: _7cff5d60,
    name: "marketing-features-screen-recorder"
  }, {
    path: "/marketing/integrations/drive",
    component: _750db48d,
    name: "marketing-integrations-drive"
  }, {
    path: "/marketing/integrations/slack",
    component: _230070da,
    name: "marketing-integrations-slack"
  }, {
    path: "/marketing/integrations/zoom",
    component: _c3b6c3c0,
    name: "marketing-integrations-zoom"
  }, {
    path: "/marketing/customers/:slug",
    component: _80ed3926,
    name: "marketing-customers-slug"
  }, {
    path: "/marketing/product/:slug?",
    component: _14be58c7,
    name: "marketing-product-slug"
  }, {
    path: "/docs/:slug?",
    component: _41b129c0,
    name: "docs-slug"
  }, {
    path: "/playlists/:id?",
    component: _526d6212,
    name: "playlists-id"
  }, {
    path: "/public-playlists/:id?",
    component: _7aad8fdc,
    name: "public-playlists-id"
  }, {
    path: "/videos/:id",
    component: _586ce86d,
    name: "videos-id"
  }, {
    path: "/workspace/:slug?",
    component: _79f0c2d4,
    name: "workspace-slug"
  }, {
    path: "/workspace/:slug",
    component: _79f0c2d4,
    name: "home"
  }, {
    path: "/",
    component: _e7d1c7a2,
    name: "landing"
  }, {
    path: "/screen-recorder",
    component: _7cff5d60,
    name: "screen-recorder"
  }, {
    path: "/ai-insights",
    component: _39c46f7e,
    name: "ai-insights"
  }, {
    path: "/features",
    component: _67ecd21a,
    name: "features"
  }, {
    path: "/features/details",
    component: _65ba2a23,
    name: "feature-details"
  }, {
    path: "/about",
    component: _6c5f416a,
    name: "about"
  }, {
    path: "/pricing",
    component: _2e809be3,
    name: "pricing"
  }, {
    path: "/manifesto",
    component: _72a7c9fd,
    name: "manifesto"
  }, {
    path: "/privacy",
    component: _2b3d6ea5,
    name: "privacy"
  }, {
    path: "/tos",
    component: _0080a216,
    name: "tos"
  }, {
    path: "/write-for-us",
    component: _4b670d03,
    name: "write-for-us"
  }, {
    path: "/integrations",
    component: _d32d4056,
    name: "integrations"
  }, {
    path: "/integrations/drive",
    component: _750db48d,
    name: "integrations-drive"
  }, {
    path: "/integrations/zoom",
    component: _c3b6c3c0,
    name: "integrations-zoom"
  }, {
    path: "/integrations/slack",
    component: _230070da,
    name: "integrations-slack"
  }, {
    path: "/customers",
    component: _7d7ff696,
    name: "customers"
  }, {
    path: "/customers/:slug",
    component: _80ed3926,
    name: "customer"
  }, {
    path: "/product/:slug",
    component: _14be58c7,
    name: "product"
  }, {
    path: "/embedp/:id",
    component: _1ccdc29c,
    name: "embedp"
  }, {
    path: "/embedv/:id",
    component: _4928f34b,
    name: "embedv"
  }, {
    path: "/login",
    component: _8e1272a8,
    name: "login"
  }, {
    path: "/signup",
    component: _9b880836,
    name: "signup"
  }, {
    path: "/reset-password",
    component: _0fdb26a6,
    name: "reset-password"
  }, {
    path: "/auth-action",
    component: _b332d100,
    name: "auth-action"
  }, {
    path: "/heathrow-drive-oauth",
    component: _43993a4f,
    name: "heathrow-drive-oauth"
  }, {
    path: "/heathrow-zoom-oauth",
    component: _a5df4fc4,
    name: "heathrow-zoom-oauth"
  }, {
    path: "/heathrow-slack-oauth",
    component: _85e96556,
    name: "heathrow-slack-oauth"
  }, {
    path: "/heathrow-spiti-connect",
    component: _73fe8b3b,
    name: "heathrow-connect-playlist"
  }, {
    path: "/heathrow-subscription",
    component: _52bb4930,
    name: "heathrow-stripe-subscription"
  }, {
    path: "/create-workspace",
    component: _0a83f2c0,
    name: "create-workspace"
  }, {
    path: "/join-workspace",
    component: _06dd4ece,
    name: "join-workspace"
  }, {
    path: "/invite",
    component: _1eea73a6,
    name: "invite"
  }, {
    path: "/public-videos/:id",
    component: _5ad68482,
    name: "public-videos-id"
  }, {
    path: "/public-player",
    component: _5ad68482,
    name: "public-player"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
