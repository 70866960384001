var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-grow overflow-y-auto",class:{
    'justify-center items-center':
      _vm.loading || !_vm.videosListDisplay || _vm.videosListDisplay.length == 0,
  }},[(_vm.loading)?_c('loader',{attrs:{"text":"Getting videos from Zoom"}}):(_vm.videosListDisplay.length == 0)?_c('div',{staticClass:"flex flex-col space-y-10 h-full items-center justify-center"},[_c('img',{staticClass:"w-16",attrs:{"src":require("assets/img/illustrations/finder.svg")}}),_vm._v(" "),_c('span',{staticClass:"text-xs italic text-coconut-white-500"},[_vm._v("No zoom recordings found in the last 30 days :(")])]):_c('div',{staticClass:"py-4 flex flex-col space-y-6"},_vm._l((_vm.videosListDisplay),function(video){return _c('div',{key:video.id,staticClass:"px-6 flex flex-row items-center space-x-2 justify-between"},[_c('div',{staticClass:"flex flex-col space-y-2 sm:w-1/2"},[_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('span',{staticClass:"line-clamp-1 body-text2 text-coconut-white-default"},[_vm._v(_vm._s(video.title))]),_vm._v(" "),(_vm.isVideoImported(video))?_c('CheckCircleFillIcon',{staticClass:"w-4 h-4 text-dew-jerry-default"}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"body-text3 text-coconut-white-700 flex flex-row items-center gap-x-2"},[_c('ClockIcon',{staticClass:"h-4 w-4"}),_vm._v(" "),_c('p',[_vm._v(_vm._s(video.startTime.format("LLLL")))])],1)]),_vm._v(" "),_c('div',{staticClass:"flex flex-row space-x-2"},[(_vm.isVideoImported(video))?_c('nuxt-link',{staticClass:"base-btn-cls button tertiary",attrs:{"to":{
            name: 'videos-id',
            params: { id: _vm.videoMap[video.id] },
          }}},[_c('VideoLibraryIcon',{staticClass:"h-4 w-4 text-coconut-white-default"}),_vm._v(" "),_c('span',{staticClass:"heading-title4 text-coconut-white-default"},[_vm._v("View in Your Library")])],1):_vm._e(),_vm._v(" "),(!_vm.isVideoImported(video))?_c('a',{staticClass:"base-btn-cls button tertiary",attrs:{"href":video.playUrl,"target":"_blank","rel":"noopener noreferrer"}},[_c('ExternalLinkIcon',{staticClass:"h-4 w-4 text-coconut-white-default"}),_vm._v(" "),_c('span',{staticClass:"heading-title4 text-coconut-white-default"},[_vm._v("Play")])],1):_vm._e(),_vm._v(" "),(!_vm.isVideoImported(video))?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            _vm.planLimitReached && {
              content: 'Workspace limit reached',
              placement: 'top',
            }
          ),expression:"\n            planLimitReached && {\n              content: 'Workspace limit reached',\n              placement: 'top',\n            }\n          "}],staticClass:"base-btn-cls button tertiary",class:{
            'btn-disabled':
              _vm.planLimitReached || _vm.importLoadingVideoId[video.id],
          },attrs:{"disabled":_vm.planLimitReached || _vm.importLoadingVideoId[video.id]},on:{"click":function($event){return _vm.importVideo(video)}}},[(_vm.importLoadingVideoId[video.id])?_c('RotateCwIcon',{staticClass:"animate-spin h-4 w-4"}):_c('DownloadCloudFillIcon',{staticClass:"h-4 w-4"}),_vm._v(" "),_c('span',[_vm._v("Import")])],1):_vm._e()],1)])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }