var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[(_vm.view === _vm.VIDEO_DROPDOWN_VIEWS.FOOTER)?_c('button',{staticClass:"menu-item",on:{"click":_vm.handleClickDropdown}},[_c('add-video-icon',{staticClass:"text-currentColor item-icon"}),_vm._v(" "),_c('span',{staticClass:"heading-title4 ml-2"},[_vm._v("New Video")])],1):(_vm.view === _vm.VIDEO_DROPDOWN_VIEWS.EMPTY_STATE)?_c('button',{staticClass:"btn-lg rounded-sm px-16 space-x-2 flex button primary",on:{"click":_vm.handleClickDropdown}},[_c('add-video-icon',{staticClass:"text-currentColor"}),_vm._v(" "),_c('span',[_vm._v("Import Video")])],1):_c('button',{staticClass:"flex flex-row space-x-2 items-center justify-center button md:py-1.5 md:px-3 md:btn-md rounded-sm navbar-button",class:{
      active: _vm.isOpen,
    },attrs:{"id":"import-video-dropdown"},on:{"click":_vm.handleClickDropdown}},[_c('add-video-icon',{staticClass:"text-currentColor"}),_vm._v(" "),_c('span',{staticClass:"hidden sm:block heading-title3"},[_vm._v("New video")])],1),_vm._v(" "),(_vm.isOpen && !_vm.isMobile)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"text-left bg-coal-pop-default border-0 border-coal-pop-default rounded flex flex-col items-start justify-start divide-ebony-grey-200 divide-y-1",class:{
      'absolute left-0 bottom-full mb-2 w-64 ':
        _vm.view === _vm.VIDEO_DROPDOWN_VIEWS.FOOTER,
      'absolute right-0 mt-2 w-60 top-full':
        _vm.view === _vm.VIDEO_DROPDOWN_VIEWS.NAV_BAR,
    }},[(_vm.isChrome && _vm.isMacintosh)?_c('div',{staticClass:"section"},[_c('button',{staticClass:"option dropdown-item body-text2 rounded-t",on:{"click":function($event){return _vm.onClickScreenRecord()}}},[_c('RecordIcon',{staticStyle:{"height":"18px","width":"18px"}}),_vm._v(" "),_c('span',[_vm._v(" Record a video")])],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"section h-11"},[_c('input',{staticClass:"bg-transparent opacity-0 hidden",attrs:{"id":"direct-video-upload","accept":"video/*","type":"file","multiple":"multiple","name":"file-input"},on:{"change":_vm.handleVideoFilesInput}}),_vm._v(" "),_c('label',{staticClass:"option h-11 body-text2",class:{ 'rounded-t': !(_vm.isChrome && _vm.isMacintosh) },attrs:{"for":"direct-video-upload"}},[_c('UploadIcon',{staticStyle:{"height":"18px","width":"18px"}}),_vm._v(" "),_c('span',[_vm._v("Direct Upload")])],1)]),_vm._v(" "),_c('div',{staticClass:"section",staticStyle:{"height":"88px","border-bottom-width":"0"}},[_c('button',{staticClass:"option body-text2 h-11",on:{"click":function($event){return _vm.onClickZoomImport()}}},[_c('ZoomMonochromeIcon',{staticStyle:{"height":"18px","width":"18px"}}),_vm._v(" "),_c('span',[_vm._v("Import from Zoom ")])],1),_vm._v(" "),_c('button',{staticClass:"option body-text2 h-11 rounded-b",on:{"click":function($event){return _vm.onClickDriveImport()}}},[_c('DriveMonochromeIcon',{staticStyle:{"height":"18px","width":"18px"}}),_vm._v(" "),_c('span',[_vm._v(" Import from Google Drive™ ")])],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }