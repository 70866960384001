<template>
  <div v-if="PLAYLIST_TYPE" class="rounded-sm bg-slate-grey-200">
    <LockIcon
      v-if="type === PLAYLIST_TYPE.PRIVATE"
      class="playlist-icon text-pastel-brown"
    />
    <UsersPlusIcon
      v-else-if="type === PLAYLIST_TYPE.SHARED"
      class="playlist-icon text-pastel-green"
    />
    <WorkspaceIcon
      v-else-if="type === PLAYLIST_TYPE.WORKSPACE"
      class="playlist-icon text-pastel-blue"
    />
  </div>
</template>
<script>
import {
  WorkspaceIcon,
  UsersPlusIcon,
  LockIcon,
} from "@spitilabs/icon-library";
import { PLAYLIST_TYPE } from "~/types/playlist";
export default {
  name: "playlist-icons",
  components: {
    WorkspaceIcon,
    UsersPlusIcon,
    LockIcon,
  },
  data() {
    return {
      PLAYLIST_TYPE,
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
</script>
