<template>
  <header class="app-navbar navbar">
    <app-navbar-placeholder v-if="loading" />
    <div
      class="w-screen flex flex-row space-x-3 items-center md:px-6 px-5"
      v-else
    >
      <div class="flex" style="max-width: calc(50% - 40px)">
        <button
          class="text-coconut-white-default focus:outline-none navbar-burger"
          :class="{ 'md:hidden': !isPlayerPage }"
          @click="toggleSidebar()"
          id="hamburger-menu"
        >
          <MenuIcon
            class="hidden md:block h-6 w-6 md:mr-4"
            id="hamburger-menu"
          />
          <img
            src="~/assets/img/icons/menu-mobile.svg"
            class="md:hidden block h-3 mr-2"
            style="min-width: 18px"
            id="hamburger-menu"
          />
        </button>
        <nuxt-link
          v-if="defaultWorkspace"
          :to="{
            name: 'home',
            params: { slug: defaultWorkspace.shortcode },
          }"
          class="flex flex-row items-center gap-x-3 w-full"
        >
          <img
            src="~/assets/img/logo-white.svg"
            alt="Spiti"
            class="hidden md:block h-6"
          />
          <div
            v-if="displayWorkspaceName"
            class="whitespace-nowrap overflow-hidden font-semibold text-sm md:font-semibold md:text-base text-coconut-white-default"
            :style="{
              'text-overflow': 'ellipsis',
            }"
          >
            {{ displayWorkspaceName }}
          </div>
        </nuxt-link>
        <div
          class="pill body-subtitle3 ml-1 md:ml-2 capitalize"
          v-if="isSubscriptionActive"
        >
          {{ this.currentSubscription.plan }}
        </div>
      </div>
      <div class="flex-grow"></div>
      <nuxt-link
        v-if="isMacintosh"
        target="_blank"
        class="download-button"
        to="/screen-recorder"
      >
        <img src="~/assets/img/icons/download.svg" />
        <span>Get Screen recorder</span>
      </nuxt-link>
      <div class="flex flex-shrink-0 space-x-3 items-center">
        <button class="search-button py-1.5 gap-2" @click="openSearchModal()">
          <search-icon class="md:ml-2 h-6 w-6" />
          <span class="hidden sm:block heading-title3 mr-3">Search</span>
        </button>
        <workspace-search-modal></workspace-search-modal>
        <ImportVideoDropdown
          :view="VIDEO_DROPDOWN_VIEWS.NAV_BAR"
        ></ImportVideoDropdown>
        <Notifications></Notifications>
        <AccountDropdown
          v-if="viewer && viewer.uid"
          :avatarUrl="viewer.avatar"
          :displayName="fullName"
        />
      </div>
    </div>
    <information-modal
      text="To import a video to your workspace, please login from a web-browser."
    ></information-modal>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { MenuIcon, SearchIcon } from "@spitilabs/icon-library";
import SearchBar from "~/components/SearchBar";
import AppNavbarPlaceholder from "~/components/placeholders/AppNavbar.vue";
import AccountDropdown from "~/components/dropdowns/AccountDropdown";
import ImportVideoDropdown from "~/components/dropdowns/ImportVideoDropdown";
import InformationModal from "~/components/modals/InformationModal";
import WorkspaceSearchModal from "~/components/modals/WorkspaceSearchModal";
import Notifications from "~/components/notifications";
import TrackingMixin from "~/components/mixins/tracking";
import { truncateString } from "~/utils/formatters";

const VIDEO_DROPDOWN_VIEWS = {
  FOOTER: "footer",
  NAV_BAR: "nav-bar",
};

export default {
  name: "app-navbar",
  mixins: [TrackingMixin],
  components: {
    AccountDropdown,
    AppNavbarPlaceholder,
    SearchBar,
    SearchIcon,
    ImportVideoDropdown,
    InformationModal,
    WorkspaceSearchModal,
    Notifications,
    MenuIcon,
  },
  data() {
    return {
      VIDEO_DROPDOWN_VIEWS,
      isPlayerPage: false,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      viewer: "auth/viewer",
      defaultWorkspace: "workspace/defaultWorkspace",
      isSubscriptionActive: "billing/isActive",
      currentSubscription: "billing/subscription",
    }),
    fullName() {
      return this.viewer?.displayName ? this.viewer.displayName : "";
    },
    isMacintosh() {
      return process.client && /Macintosh/.test(navigator.userAgent);
    },
    displayWorkspaceName() {
      // Truncate workspace name beyond 15 characters
      return this.defaultWorkspace.name;
    },
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch("toggleSidebar");
    },
    changeLayoutOnPlayerPage() {
      // The layout is different on the player page - Sidebar collapses on the left
      // so we need to show the hamburger icon
      this.isPlayerPage = this.$route.name === "player";
    },
    openSearchModal: function () {
      this.$root.$emit("modal:open", WorkspaceSearchModal.name);
      this.trackAdvancedSearchClicked();
    },
  },
  async mounted() {
    this.changeLayoutOnPlayerPage();
    this.loading = false;
  },
  watch: {
    $route: async function () {
      this.changeLayoutOnPlayerPage();
    },
  },
};
</script>
<style lang="scss" scoped>
.pill {
  @apply hidden md:block bg-slate-grey-100 text-dew-jerry-default px-3 py-1;
  border-radius: 50px;
}

.app-navbar {
  @apply fixed top-0 z-40 bg-slate-grey-default w-screen border-b border-ebony-grey-default flex items-center justify-between;
}

.right-navbar {
  @apply relative flex-auto flex flex-row items-center justify-end pr-5 space-x-2.5;

  @media (min-width: 640px) {
    padding-right: 30px;
  }
}
.search-button {
  @apply flex items-center transition duration-300 justify-center text-coconut-white-500 rounded-sm;
  @media (hover: hover) {
    &:hover {
      @apply bg-slate-grey-200 text-coconut-white-500;
    }
  }

  &:active {
    @apply bg-slate-grey-200 text-coconut-white-default;
  }
}

.download-button {
  @apply hidden lg:flex h-9 px-4 flex-row space-x-2 items-center justify-center transition duration-300 text-coconut-white-default font-semibold rounded-sm;
  background: linear-gradient(
    93.05deg,
    #f2436a 0%,
    #84489d 0.01%,
    #443b91 100%
  );
  @media (hover: hover) {
    &:hover {
      background: linear-gradient(
        93.05deg,
        #f2436a 0%,
        #672682 0.01%,
        #2e2480 100%
      );
    }
  }

  &:active {
    background: linear-gradient(
      93.05deg,
      #a86ac1 0.01%,
      #5950ab 100%
    ) !important;
  }
}
</style>
