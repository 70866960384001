export const COLLECTIONS = {
  USERS: "users",
  NOTIFICATIONS: "notifications",
  PRODUCT_UPDATES: "product-updates",
  WORKSPACES: "workspaces",
  VIDEOS: "videos",
  TRANSCRIPTS: "transcripts",
  PARAGRAPHS: "paragraphs",
  SUMMARIES: "summaries",
  SUMMARY_FEEDBACK: "summary-feedback",
  PLAYLISTS: "playlists",
  INVITATIONS: "invitations",
  COMMENTS: "comments",
  SUBSCRIPTIONS: "subscriptions",
  VIDEO_LIKES: "video-likes",
  AI_INSIGHTS: "ai-insights",
};
