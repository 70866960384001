<template>
  <div class="flex flex-col">
    <label v-if="label" class="form-label">
      {{ label }}
    </label>
    <div
      class="text-field body-text2 flex flex-row items-center"
      v-bind:tabindex="tabindex"
      :class="{
        error: errors && errors[0],
        'md:px-3.5 px-2 py-3.5': size === 'md',
        'px-2 py-2': size === 'sm',
        'p-2 pb-0': size === 'sm b-0',
        'pointer-events-none': readonly,
      }"
    >
      <span class="text-coconut-white-600" v-if="prefix && prefix !== ''">{{
        prefix
      }}</span>
      <input
        v-if="!long"
        :ref="refId"
        :name="refId"
        autocomplete="off"
        :placeholder="placeholder"
        class="apprearance-none bg-transparent outline-none pl-px w-full"
        :type="type"
        @input="$emit('input', $event.target.value)"
        v-bind:readonly="readonly"
      />
      <textarea
        v-else
        :ref="refId"
        :name="refId"
        autocomplete="off"
        :placeholder="placeholder"
        class="apprearance-none bg-transparent outline-none pl-px w-full resize-none"
        :type="type"
        :rows="rows"
        @input="$emit('input', $event.target.value)"
        v-bind:readonly="readonly"
      ></textarea>
    </div>
    <div v-if="errors && errors[0]" class="mt-1 form-error">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  $_veeValidate: {
    // value getter
    value() {
      return this.$el.value;
    },
    // name getter
    name() {
      return this.refId;
    },
  },
  props: {
    refId: {
      type: String,
      required: false,
      default: "text-input",
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    errors: {
      type: Array,
      default: () => [],
    },
    long: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: 0,
    },

    rows: {
      type: Number,
      default: 5,
    },
    size: {
      type: String,
      default: "md",
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  mounted() {
    // sync the input to the initial value
    setTimeout(() => {
      if (this.refId) this.$refs[this.refId].value = this.value;
    }, 100);
  },
};
</script>
