<template>
  <div class="h-screen overflow-y-auto w-full">
    <ForbiddenState v-if="error.statusCode === 403" />
    <NotFoundState v-else :statusCode="error.statusCode" />
  </div>
</template>

<script>
import ForbiddenState from "~/components/error-states/403.vue";
import NotFoundState from "~/components/error-states/404.vue";

const defaultMessages = {
  404: "Resource not found",
  500: "Internal server error",
  403: "Permission denied",
};

export default {
  name: "error",
  data() {
    return defaultMessages;
  },
  components: {
    ForbiddenState,
    NotFoundState,
  },
  transition: "fade",
  props: ["error"],
  layout: "default",
};
</script>
