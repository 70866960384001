// Firebase functions
export const FUNCTIONS = {
  // Workspace callables
  isWorkspaceShortcodeAvailable: "isWorkspaceShortcodeAvailable",
  createNewWorkspace: "createNewWorkspace",
  joinWorkspace: "joinWorkspace",
  getWorkspaceFromToken: "getWorkspaceFromToken",
  resetTeamInvitationToken: "resetTeamInvitationToken",
  changeMemberRole: "changeMemberRole",
  removeMember: "removeMember",
  autoJoinWorkspace: "autoJoinWorkspace",
  listAutoJoinWorkspaces: "listAutoJoinWorkspaces",

  // Zoom callables
  connectZoomForUser: "connectZoomForUser",
  getZoomVideos: "getZoomVideos",
  importVideoFromZoom: "importVideoFromZoom",

  // Slack callables
  connectSlackForWorkspace: "connectSlackForWorkspace",

  // Drive callables
  connectDriveForUser: "connectDriveForUser",
  getDriveAccessTokenForUser: "getDriveAccessTokenForUser",
  queueDriveVideoImport: "queueDriveVideoImport",

  // Video & Playlist callables
  incrementVideoView: "incrementVideoView",
  getVideoDirectUploadUrl: "getVideoDirectUploadUrl",
  getVideoPlayerArtifacts: "getVideoPlayerArtifacts",
  fetchPlaylistFromEmbedHash: "fetchPlaylistFromEmbedHash",
  fetchVideoFromEmbedHash: "fetchVideoFromEmbedHash",
  connectPlaylistToWorkspace: "connectPlaylistToWorkspace",
  fetchPlaylistConnections: "fetchPlaylistConnections",
  sendPlaylistInviteEmail: "sendPlaylistInviteEmail",

  // Video AI callables
  summarizeVideo: "summarizeVideo",
  generateVideoInsights: "generateVideoInsights",
  fetchVideoPrompts: "fetchVideoPrompts",

  // Billing callables
  createCheckoutSession: "createCheckoutSession",
  changeCurrentWorkspacePlan: "changeCurrentWorkspacePlan",
  cancelCurrentSubscription: "cancelCurrentSubscription",
  revokeSubscriptionCancellationRequest:
    "revokeSubscriptionCancellationRequest",
  createCustomerPortalSession: "createCustomerPortalSession",

  // Misc
  trackUserActivity: "trackUserActivity",
  trackLastVisitToBellNotifications: "trackLastVisitToBellNotifications",
  fetchMeta: "fetchMeta",
  importSampleVideoFromMux: "importSampleVideoFromMux",
};

// All user roles in a spiti workspace
export const WorkspaceMembershipRoles = {
  ADMIN: "ADMIN",
  MEMBER: "MEMBER",
};

// Different pricing plans that Spiti supports
export const PricingPlans = {
  FREE: "FREE",
  STARTER: "STARTER",
  PRO: "PRO",
  CUSTOM: "CUSTOM",
};

// For common components between marketing and billing pages
export const ComponentViews = {
  MARKETING: "MARKETING",
  BILLING: "BILLING",
};

// For integrations, configuration status
export const IntegrationStatus = {
  CONFIGURED: "CONFIGURED",
  NOT_CONFIGURED: "NOT_CONFIGURED",
  CONFIGURING: "CONFIGURING",
};

// Video player's page type for mux data analytics
export const PAGE_TYPE = {
  EMBED: "EMBED",
  UNLISTED: "UNLISTED",
  WORKSPACE: "WORKSPACE",
  EDIT: "EDIT",
};

// AI Insights
export const AI_INSIGHTS = {
  SUMMARIZE: "SUMMARIZE",
  KEY_TAKEAWAYS: "KEY_TAKEAWAYS",
  ACTION_ITEMS: "ACTION_ITEMS",
};

// AI Insights Status
export const AI_INSIGHTS_STATUS = {
  QUEUED: "QUEUED",
  PROCESSING: "PROCESSING",
  ERRORED: "ERRORED",
  COMPLETED: "COMPLETED",
};

// Prompt Keys for video summary
export const PROMPT_KEYS = {
  SUMMARIZE: "SUMMARIZE",
  KEY_TAKEAWAYS: "KEY_TAKEAWAYS",
  ACTION_ITEMS: "ACTION_ITEMS",
  VIDEO_DESCRIPTION: "VIDEO_DESCRIPTION",
};

// Video transcription lifecycle
export const TRANSCRIPT_STATUS = {
  QUEUED: "queued",
  ERROR_QUEUEING: "error_queueing",
  TRANSCRIBING: "transcribing",
  ERROR_TRANSCRIBING: "error_transcribing",
  PROCESSING: "processing",
  ERROR_PROCESSING: "error_processing",
  NO_SPEECH_DETECTED: "no_speech_detected",
  COMPLETED: "completed",
};

// Transcript handler
// Currently transcripts are rendered in the player page under tabs and in video fullscreen mode alongside the video
export const TRANSCRIPT_HANDLER = {
  PLAYER_PAGE: "player_page",
  VIDEO_LIBRARY: "video_library",
  VIDEO_PLAYER_FULLSCREEN: "video_player_fullscreen",
};

// Each transcript plot is either a speaker label or spoken text
export const TRANSCRIPT_PLOT_TYPE = {
  SPEAKER_LABEL: "speaker-label",
  SPEAKER_TURN: "speaker-turn",
};

// ASK_SPITT_INSTANCES
export const ASK_SPITI_HANDLER = {
  ASK_SPITI_TAB: "ASK_SPITI_TAB",
  ASK_SPITI_MODAL: "ASK_SPITI_MODAL",
};
