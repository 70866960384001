<template>
  <div class="loader">
    <img
      v-if="showLogo"
      src="~/assets/img/logo-white.svg"
      alt="Spiti"
      class="opacity-75"
      :class="{
        'h-10 w-10': size === 'sm',
        'h-12': size === 'md',
      }"
    />
    <span
      v-if="text"
      :class="{
        'body-subtitle2 text-coconut-white-default': size === 'sm',
        'heading-title3 text-dew-jerry-default': size === 'md',
      }"
      >{{ text }}</span
    >
    <div class="grid gap-2 w-20 h-12" v-if="showIndicator">
      <div class="loading-indicator"></div>
    </div>
  </div>
</template>

<script>
export default {
  title: "Loader",
  props: {
    showLogo: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: "",
    },
    showIndicator: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "sm",
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-indicator {
  @apply flex relative items-center justify-center space-x-2 text-coconut-white-default w-3 h-3 rounded-full;
  margin: 15px auto;
  animation: loadingdots 2s linear infinite both;
}
.loader {
  @apply flex flex-col items-center justify-center space-y-4 h-full w-full;
}
</style>
