<template>
  <div
    class="bg-black-300 md:bg-space-blue-default min-h-screen flex"
    @dragover.prevent
    @drop.prevent
  >
    <docs-navbar></docs-navbar>
    <div class="w-full pl-0 md:pl-64" :class="isSidebarOpen ? 'overlay' : ''">
      <documentation-sidepanel />
      <article
        class="dashboard-container"
        :class="{
          'opacity-60 transition-opacity duration-100':
            isSidebarOpen && isMobile,
        }"
      >
        <Nuxt />
      </article>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DocumentationSidepanel from "~/components/sidepanels/DocumentationSidepanel.vue";
import DocsNavbar from "~/components/navbars/DocsNavbar.vue";
import pages from "~/constants/pages";

export default {
  name: "documentation",
  components: {
    DocumentationSidepanel,
    DocsNavbar,
  },
  watch: {
    $route: function () {
      // if the route changes, close all open modals
      this.$root.$emit("modal:close:all");

      // Close sidebar on mobile screens
      if (window.outerWidth < 768) {
        this.$store.dispatch("closeSidebar");
      }
      this.trackPageVisit();
    },
  },
  data() {
    return {
      isMobile: false,
    };
  },
  computed: {
    ...mapGetters(["isSidebarOpen"]),
  },
  mounted() {
    this.trackPageVisit();
    this.isMobile = window.outerWidth < 768;

    // On mobile screens, the sidebar should initially be closed
    if (this.isMobile && this.isSidebarOpen) {
      this.$store.dispatch("closeSidebar");
    }

    // Toggle sidebar if not open already on non-mobile screens
    // On non-mobile screens, the sidebar should always be open
    if (!this.isMobile && !this.isSidebarOpen) {
      this.$store.dispatch("toggleSidebar");
    }
  },
  methods: {
    trackPageVisit() {
      const page = pages["docs-slug"];
      const { slug: article } = this.$route.params;
      this.$analytics.page(page, { article }, {});
    },
  },
};
</script>
