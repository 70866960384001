<template>
  <BaseSidepanel>
    <div class="flex flex-col h-full flex-auto">
      <div
        class="flex items-center px-6 pt-4 pb-2 ml-px heading-header2 text-coconut-white-700"
      >
        Playlists
      </div>
      <sidebar-placeholder v-if="!playlists" />
      <client-only v-else>
        <div
          class="flex-grow spiti-scroll overflow-x-hidden flex flex-col space-y-2 pl-6 pr-2.5"
        >
          <playlist-list
            :type="PLAYLIST_TYPE.WORKSPACE"
            :playlists="workspacePlaylists"
          ></playlist-list>
          <playlist-list
            v-if="sharedPlaylists.length"
            :type="PLAYLIST_TYPE.SHARED"
            :playlists="sharedPlaylists"
          ></playlist-list>
          <playlist-list
            :type="PLAYLIST_TYPE.PRIVATE"
            :playlists="privatePlaylists"
          ></playlist-list>
        </div>
      </client-only>
      <app-footer></app-footer>
    </div>
    <create-new-playlist-modal></create-new-playlist-modal>
  </BaseSidepanel>
</template>

<script>
import { mapGetters } from "vuex";
import { PlusIcon } from "vue-feather-icons";
import BaseSidepanel from "~/components/sidepanels/BaseSidepanel";
import AppFooter from "~/components/AppFooter";
import PlaylistList from "~/components/PlaylistList";
import CreateNewPlaylistModal from "~/components/modals/CreateNewPlaylistModal";
import SidebarPlaceholder from "~/components/placeholders/Sidebar";
import { PLAYLIST_TYPE } from "~/types/playlist";

export default {
  components: {
    AppFooter,
    PlaylistList,
    CreateNewPlaylistModal,
    PlusIcon,
    SidebarPlaceholder,
    BaseSidepanel,
  },
  data() {
    return {
      PLAYLIST_TYPE,
      isMobile: false,
      privatePlaylists: [],
      workspacePlaylists: [],
      sharedPlaylists: [],
    };
  },
  async mounted() {
    this.isMobile = window.outerWidth < 768;
    this.groupPlaylists(this.playlists);
  },
  computed: {
    ...mapGetters({
      isSidebarOpen: "isSidebarOpen",
      defaultWorkspace: "workspace/defaultWorkspace",
      playlists: "workspace/playlists",
      viewer: "auth/viewer",
    }),
  },
  watch: {
    playlists(val) {
      this.groupPlaylists(val);
    },
  },
  methods: {
    isPlaylistPrivate(playlist) {
      const { isPrimary, connectedWorkspaces, acl, isPublic } = playlist;
      if (
        isPrimary &&
        !isPublic &&
        !connectedWorkspaces.length &&
        !acl.workspaceIds.length &&
        acl.userIds.length === 1 &&
        acl.userIds[0] === this.viewer.uid
      ) {
        return true;
      }
      return false;
    },
    isPlaylistWorkspace(playlist) {
      const { isPrimary, connectedWorkspaces, acl, isPublic } = playlist;
      if (
        isPrimary &&
        !isPublic &&
        !connectedWorkspaces.length &&
        acl.workspaceIds.length === 1 &&
        acl.workspaceIds[0] === this.defaultWorkspace.id
      ) {
        return true;
      }
      return false;
    },
    groupPlaylists(playlists) {
      if (!this.viewer?.uid || !this.defaultWorkspace?.id) {
        return;
      }

      const workspacePlaylists = [];
      const sharedPlaylists = [];
      const privatePlaylists = [];
      (playlists || []).forEach((playlist) => {
        if (this.isPlaylistWorkspace(playlist)) {
          workspacePlaylists.push(playlist);
        } else if (this.isPlaylistPrivate(playlist)) {
          privatePlaylists.push(playlist);
        } else {
          sharedPlaylists.push(playlist);
        }
      });
      this.workspacePlaylists = workspacePlaylists;
      this.sharedPlaylists = sharedPlaylists;
      this.privatePlaylists = privatePlaylists;
    },
  },
};
</script>
