import { FUNCTIONS } from "~/components/index";
import { COLLECTIONS } from "~/store";
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from "vuex";
import TrackingMixin from "~/components/mixins/tracking";
import { VIDEO_IMPORT } from "~/types/intents";

export default {
  mixins: [TrackingMixin],
  computed: {
    ...mapGetters({
      isDriveConfigured: "auth/isDriveConfigured",
      driveAppSecrets: "auth/driveAppSecrets",
      defaultWorkspace: "workspace/defaultWorkspace",
      workspaceLimits: "billing/limits",
      viewer: "auth/viewer",
    }),
  },
  data() {
    return {
      loading: false,
      intent: VIDEO_IMPORT.DEFAULT,
      playlistId: null,
    };
  },
  methods: {
    openDrivePicker: async function (
      intent = VIDEO_IMPORT.DEFAULT,
      playlistId = null,
    ) {
      this.intent = intent;
      this.playlistId = playlistId;
      if (!this.driveAppSecrets?.token) {
        this.$toast.error(
          "Unable to open Drive picker. Please try again later.",
        );
        return;
      }

      let token = this.driveAppSecrets.token;
      // If access token has already expired, fetch a new one
      if (this.driveAppSecrets.lastUpdatedAt + 3600000 < Date.now()) {
        token = (await this.$store.dispatch("auth/fetchDriveAppSecrets")).token;
      }
      const view = new window.google.picker.DocsView(
        window.google.picker.ViewId.DOCS,
      );
      view.setIncludeFolders(true);
      const picker =
        intent === VIDEO_IMPORT.DEFAULT
          ? new window.google.picker.PickerBuilder()
              .setOAuthToken(token)
              .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
              .addView(view)
              .setCallback(this.pickerCallback)
              .build()
          : new window.google.picker.PickerBuilder()
              .setOAuthToken(token)
              .addView(view)
              .setCallback(this.pickerCallback)
              .build();
      picker.setVisible(true);
    },
    createNewDriveVideoEntry: async function (
      fileId,
      fileName,
      fileSize,
      duration,
    ) {
      const videoData = {
        createdAt: this.$fireModule.firestore.FieldValue.serverTimestamp(),
        source: "drive",
        sourceVideoId: fileId,
        status: "queued",
        duration: duration,
        ownerId: this.viewer.uid,
        workspaceId: this.defaultWorkspace.id,
        name: fileName || "Untitled Video",
        description: "",
        fileSizeMB: fileSize || 0,
        numViews: 0,
        numLikes: 0,
        playlists: [],
        isTrashed: false,
        isPublic: false,
        embedKey: uuidv4(),
        acl: {
          workspaceIds: [],
          workspaces: {},
          userIds: [this.viewer.uid],
          users: {
            [this.viewer.uid]: {
              createdAt:
                this.$fireModule.firestore.FieldValue.serverTimestamp(),
              createdBy: this.viewer.uid,
            },
          },
        },
      };
      const videoRef = await this.$fire.firestore
        .collection(COLLECTIONS.VIDEOS)
        .add(videoData);
      const video = await videoRef.get();
      return video.id;
    },
    driveImportUtil: async function (file) {
      file.name = this.formatFileName(file.name);
      const videoId = await this.createNewDriveVideoEntry(
        file.id,
        file.name,
        parseFloat(file.sizeBytes / 1000000).toFixed(1),
        file.duration,
      );

      if (this.intent === VIDEO_IMPORT.DEFAULT) {
        this.$root.$emit("tracker:add", [videoId]);
      }

      if (this.playlistId) {
        await this.$store.dispatch("queue/addVideoToPlaylist", {
          options: {
            playlistId: this.playlistId,
            author: this.viewer.uid,
            workspaceId: this.defaultWorkspace.id,
          },
          videoId,
        });
      }

      try {
        await this.$fire.functions.httpsCallable(
          FUNCTIONS.queueDriveVideoImport,
        )({
          videoId,
        });
        this.trackVideoImported(videoId, {
          source: "drive",
          fileSizeMb: parseFloat(file.sizeBytes / 1000000).toFixed(1),
          durationInSeconds: file.duration,
        });
        return videoId;
      } catch (err) {
        console.error(err);
        this.$toast.error("Failed to import drive video");

        // Mark video as failed
        await this.$fire.firestore
          .collection(COLLECTIONS.VIDEOS)
          .doc(videoId)
          .update({
            status: "errored",
          });
        return null;
      }
    },
    isUserImportingBeyondPlanLimits: function (files) {
      // Check if total video duration is within the plan limit
      let duration = 0;
      for (const file of files) {
        duration += file.duration / 60;
      }
      return (
        duration + this.defaultWorkspace.videoMinutes >
        this.workspaceLimits.videoMinutes
      );
    },
    pickerCallback: async function (data) {
      if (data.action !== window.google.picker.Action.PICKED) {
        return;
      }
      const files = data.docs.filter((each) => each.type === "video");

      // prevent video uploads beyond workspace plan quota
      if (this.isUserImportingBeyondPlanLimits(files)) {
        this.$toast.error("You are importing beyond your plan limit");
        return;
      }

      if (this.intent === VIDEO_IMPORT.ONBOARDING) {
        this.loading = true;
        const videoId = await this.driveImportUtil(files[0]);
        videoId &&
          this.$router.push({
            name: "onboarding-publish",
            query: {
              video: videoId,
            },
          });
        this.loading = false;
      } else {
        for (let i = 0; i < files.length; i++) {
          this.driveImportUtil(files[i]);
        }
      }
    },
    formatFileName: function (name) {
      const supportedFileFormats = ["mp4", "mkv", "3gp", "mov"];
      const fileExtension = name.split(".").pop();
      if (fileExtension && supportedFileFormats.includes(fileExtension)) {
        return name.replace(`.${fileExtension}`, "");
      }
      return name;
    },
  },
};
