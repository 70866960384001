const addToClassList = (element, className = "tour-box") => {
  document.getElementById(element).classList.add(className);
};

const removeFromClassList = (element, className = "tour-box") => {
  document.getElementById(element).classList.remove(className);
};

const initTour = () => {
  const wt = new WebTour();

  addToClassList("connect-apps-dropdown");
  const steps = [
    {
      element: "#connect-apps-dropdown",
      title: "",
      content: "Integrate with Zoom and Google Drive",
      placement: "right-start",
      onNext: function () {
        removeFromClassList("connect-apps-dropdown");
        addToClassList("import-video-dropdown");
      },
      onPrevious: function () {
        removeFromClassList("connect-apps-dropdown");
      },
    },
    {
      element: "#import-video-dropdown",
      title: "",
      content: "Import videos from Zoom, Google Drive, and your computer",
      placement: "bottom",
      onNext: function () {
        removeFromClassList("import-video-dropdown");
        addToClassList("video-library-button");
      },
      onPrevious: function () {
        removeFromClassList("import-video-dropdown");
        addToClassList("connect-apps-dropdown");
      },
    },
    {
      element: "#video-library-button",
      title: "",
      content: "Take edit actions on videos you've imported",
      placement: "right-end",
      onNext: function () {
        removeFromClassList("video-library-button");
        addToClassList("create-playlist-button");
      },
      onPrevious: function () {
        removeFromClassList("video-library-button");
        addToClassList("import-video-dropdown");
      },
    },
    {
      element: "#create-playlist-button",
      title: "",
      content: "Create a new playlist",
      placement: "right-start",
      onNext: function () {
        removeFromClassList("create-playlist-button");
        addToClassList("add-video-button");
      },
      onPrevious: function () {
        removeFromClassList("create-playlist-button");
        addToClassList("video-library-button");
      },
    },
    {
      element: "#add-video-button",
      title: "",
      content: "Add videos to this playlist from Your Library",
      placement: "bottom",
      onNext: function () {
        removeFromClassList("add-video-button");
        addToClassList("playlist-controls");
      },
      onPrevious: function () {
        removeFromClassList("add-video-button");
        addToClassList("create-playlist-button");
      },
    },
    {
      element: "#playlist-controls",
      title: "",
      content: "Edit, Share, and lock this Playlist",
      placement: "bottom",
      onNext: function () {
        removeFromClassList("playlist-controls");
      },
      onPrevious: function () {
        removeFromClassList("playlist-controls");
        addToClassList("add-video-button");
      },
    },
  ];

  wt.setSteps(steps);
  wt.start();
};

export { initTour };
