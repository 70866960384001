import { v4 as uuidv4 } from "uuid";
import { COLLECTIONS } from "~/services/firebase/firestore/types";
import { PLAYLIST_TYPE } from "~/types/playlist";
import { firestoreDocumentSerializer } from "~/utils/serializers";

/**
 * Playlist Service provides helper methods to interact with firestore playlists collection
 */
export default class {
  constructor($fire, $fireModule) {
    this.$fire = $fire;
    this.$fireModule = $fireModule;
  }

  /**
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   * @returns playlist document data from firestore
   */
  async getPlaylist(playlistId) {
    const playlistRef = await this.$fire.firestore
      .collection(COLLECTIONS.PLAYLISTS)
      .doc(playlistId)
      .get();
    return firestoreDocumentSerializer(playlistRef);
  }

  /**
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   */
  async deletePlaylist(playlistId) {
    await this.$fire.firestore
      .collection(COLLECTIONS.PLAYLISTS)
      .doc(playlistId)
      .delete();
  }

  /**
   *
   * @param {String} playlistType refers to the type of playlist being created - Workspace, Private
   * @returns computed ACL for the playlist
   */
  #computeNewPlaylistACL(playlistType, userId, workspaceId) {
    if (playlistType === PLAYLIST_TYPE.WORKSPACE) {
      return {
        userIds: [],
        users: {},
        workspaceIds: [workspaceId],
        workspaces: {
          [workspaceId]: {
            createdAt: this.$fireModule.firestore.FieldValue.serverTimestamp(),
            createdBy: userId,
          },
        },
      };
    }
    return {
      userIds: [userId],
      users: {
        [userId]: {
          createdAt: this.$fireModule.firestore.FieldValue.serverTimestamp(),
          createdBy: userId,
        },
      },
      workspaceIds: [],
      workspaces: {},
    };
  }

  /**
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   * @returns playlist document id from firestore
   */
  async createPlaylist({
    name = "",
    description = "",
    ownerId,
    workspaceId,
    type: playlistType,
  }) {
    const playlist = {
      name,
      description,
      ownerId,
      workspaceId,
      createdAt: this.$fireModule.firestore.FieldValue.serverTimestamp(),
      videos: [],
      tags: [],
      isLocked: false,
      isPublic: false,
      embedKey: uuidv4(),
      memberInvitationToken: uuidv4(),
      isPrimary: true,
      connectedWorkspaces: [],
      primaryPlaylist: null,
      acl: this.#computeNewPlaylistACL(playlistType, ownerId, workspaceId),
    };
    const ref = await this.$fire.firestore
      .collection(COLLECTIONS.PLAYLISTS)
      .add(playlist);
    return {
      ...playlist,
      id: ref.id,
    };
  }

  /**
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   * @param {Map} fields with updated values
   */
  updatePlaylist(playlistId, fields) {
    return this.$fire.firestore
      .collection(COLLECTIONS.PLAYLISTS)
      .doc(playlistId)
      .update(fields);
  }

  /**
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   * @param {String} videoId of the video to be added to the playlist
   */
  addVideoToPlaylist(playlistId, videoId) {
    return this.updatePlaylist(playlistId, {
      videos: this.$fireModule.firestore.FieldValue.arrayUnion(videoId),
    });
  }

  /**
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   * @param {String} videoId of the video to be removed to the playlist
   */
  removeVideoFromPlaylist(playlistId, videoId) {
    return this.updatePlaylist(playlistId, {
      videos: this.$fireModule.firestore.FieldValue.arrayRemove(videoId),
    });
  }

  /**
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   * @param {FirestoreQuery} raw query which when executed fetches a playlist by id from firestore
   */
  fetchPlaylistByIdQuery(playlistId) {
    return this.$fire.firestore
      .collection(COLLECTIONS.PLAYLISTS)
      .doc(playlistId);
  }

  /**
   *
   * @param {String} workspaceId of the workspace for which to fetch playlists
   * @param {FirestoreQuery} raw query which when executed fetches all workspace level playlists from firestore
   */
  fetchWorkspacePlaylistsQuery(workspaceId) {
    return this.$fire.firestore
      .collection(COLLECTIONS.PLAYLISTS)
      .where("workspaceId", "==", workspaceId)
      .where("acl.workspaceIds", "array-contains", workspaceId)
      .orderBy("createdAt", "desc");
  }

  /**
   *
   * @param {String} userId of the user for which to fetch playlists
   * @param {FirestoreQuery} raw query which when executed fetches all playlists that a user has access to from firestore
   */
  fetchUserPlaylistsQuery(userId, workspaceId) {
    return this.$fire.firestore
      .collection(COLLECTIONS.PLAYLISTS)
      .where("workspaceId", "==", workspaceId)
      .where("acl.userIds", "array-contains", userId)
      .orderBy("createdAt", "desc");
  }

  /**
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   * @param {Object} acl with updated values
   */
  #updatePlaylistACL(playlistId, acl) {
    return this.$fire.firestore
      .collection(COLLECTIONS.PLAYLISTS)
      .doc(playlistId)
      .set({ acl }, { merge: true });
  }

  /**
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   * @param {String} workspaceId to which the playlist is given access to
   */
  addWorkspaceToPlaylistACL(playlistId, workspaceId, author) {
    return this.#updatePlaylistACL(playlistId, {
      workspaceIds:
        this.$fireModule.firestore.FieldValue.arrayUnion(workspaceId),
      workspaces: {
        [workspaceId]: {
          createdAt: this.$fireModule.firestore.FieldValue.serverTimestamp(),
          createdBy: author,
        },
      },
    });
  }

  /**
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   * @param {String} workspaceId for which the playlist is revoked access from
   * @param {String} author refers to the user who is performing the action
   */
  removeWorkspaceFromPlaylistACL(playlistId, workspaceId, author) {
    return this.#updatePlaylistACL(playlistId, {
      workspaceIds:
        this.$fireModule.firestore.FieldValue.arrayRemove(workspaceId),
      workspaces: {
        [workspaceId]: this.$fireModule.firestore.FieldValue.delete(),
      },
      userIds: this.$fireModule.firestore.FieldValue.arrayUnion(author),
      users: {
        [author]: {
          createdAt: this.$fireModule.firestore.FieldValue.serverTimestamp(),
          createdBy: author,
        },
      },
    });
  }

  /**
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   * @param {String} userIds refers to the users to whom the playlist is given access to
   */
  addUsersToPlaylistACL(playlistId, userIds, author) {
    const users = {};
    userIds.forEach((userId) => {
      users[userId] = {
        createdAt: this.$fireModule.firestore.FieldValue.serverTimestamp(),
        createdBy: author,
      };
    });
    return this.#updatePlaylistACL(playlistId, {
      userIds: this.$fireModule.firestore.FieldValue.arrayUnion(...userIds),
      users,
    });
  }

  /**
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   * @param {String} userId refers to the user to whom the playlist is revoked access from
   */
  removeUserFromPlaylistACL(playlistId, userId) {
    return this.#updatePlaylistACL(playlistId, {
      userIds: this.$fireModule.firestore.FieldValue.arrayRemove(userId),
      users: {
        [userId]: this.$fireModule.firestore.FieldValue.delete(),
      },
    });
  }
}
