<template>
  <div class="flex flex-col">
    <div
      class="flex flex-row h-8 space-x-1 py-2 pr-1 rounded-sm items-center responsive:hover:bg-slate-grey-200 active:text-coconut-white-500 transition-all cursor-pointer"
      :class="active ? 'text-coconut-white-500' : 'text-coconut-white-700'"
    >
      <playlist-icons :type="type" />
      <span class="heading-title5 capitalize">{{ type.toLowerCase() }}</span>
      <button class="flex-grow" @click="expand = !expand">
        <ChevronDownFillIcon
          :style="{ transform: expand ? 'rotate(0deg)' : 'rotate(-90deg)' }"
          width="18px"
          height="18px"
          class="text-currentColor"
        />
      </button>
      <button
        v-if="type !== PLAYLIST_TYPE.SHARED"
        @click="openCreateModal()"
        class="text-coconut-white-700 responsive:hover:bg-slate-grey-400 active:bg-slate-grey-200 active:text-coconut-white-default transition-all"
        :class="{
          'text-coconut-white-700  responsive:hover:bg-slate-grey-200 ': expand,
        }"
        style="border-radius: 1px; padding: 1px"
        id="create-playlist-button"
      >
        <PlusIcon width="16px" height="16px" />
      </button>
    </div>
    <div v-if="expand" class="flex flex-row ml-6 space-x-1">
      <div
        class="bg-ebony-grey-300 border-0 border-ebony-grey-300 rounded h-full"
        style="width: 1.5px"
      />
      <div class="flex flex-col w-full">
        <nuxt-link
          :to="{ name: 'playlists-id', params: { id: playlist.id } }"
          v-for="playlist of playlists"
          :key="playlist.id"
          class="transition-all responsive:hover:bg-slate-grey-200 px-1 py-1.5 rounded-sm active:text-coconut-white-default body-text2 active:bg-slate-grey-100"
          :class="{
            'bg-slate-grey-200 ': selectedPlaylistId === playlist.id,
            'text-coconut-white-default': selectedPlaylistId === playlist.id,
          }"
          v-tooltip="
            playlist.name.length > 25 && {
              content: playlist.name,
              placement: 'top',
            }
          "
        >
          <span
            class="flex items-center break-all line-clamp-1"
            :class="
              selectedPlaylistId === playlist.id
                ? 'text-coconut-white-default'
                : unreadPlaylists.has(playlist.id)
                  ? 'text-coconut-white-default font-semibold'
                  : 'text-gray-200'
            "
          >
            {{ playlist.name }}
          </span>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ChevronDownFillIcon, PlusIcon } from "@spitilabs/icon-library";
import PlaylistIcons from "~/components/icons/PlaylistIcons";
import InformationModal from "~/components/modals/InformationModal";

import CreateNewPlaylistModal from "~/components/modals/CreateNewPlaylistModal";
import { PLAYLIST_TYPE } from "~/types/playlist";
export default {
  components: {
    PlusIcon,
    PlaylistIcons,
    ChevronDownFillIcon,
  },
  props: {
    playlists: {
      type: Array,
      default: function () {
        return [];
      },
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      PLAYLIST_TYPE,
      expand: true,
      selectedPlaylistId: null,
    };
  },
  watch: {
    $route() {
      this.fetchCurrentPlaylist();
    },
  },
  mounted() {
    this.fetchCurrentPlaylist();
  },
  computed: {
    ...mapGetters({
      unreadPlaylists: "user/unreadPlaylists",
      isMobile: "isScreenSmall",
    }),
    active: function () {
      if (!this.selectedPlaylistId) {
        return false;
      }
      for (const playlist of this.playlists) {
        if (playlist.id === this.selectedPlaylistId) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    fetchCurrentPlaylist() {
      switch (this.$route.name) {
        case "playlists-id":
          this.selectedPlaylistId = this.$route.params.id;
          break;
        case "player":
          this.selectedPlaylistId = this.$route.query.playlist;
          break;
        default:
          this.selectedPlaylistId = null;
      }
    },
    openCreateModal: function () {
      if (this.isMobile) {
        this.$root.$emit(
          "modal:open",
          InformationModal.name,
          "To create a playlist in your workspace, please login from a web-browser.",
        );
      } else {
        this.$root.$emit("modal:open", CreateNewPlaylistModal.name, {
          type: this.type,
        });
      }
    },
  },
};
</script>
