<template>
  <Modal :name="NAME" align="top">
    <div class="search-modal flex flex-col">
      <div class="p-4 border-b border-gray-300 border-opacity-50 w-full">
        <div class="flex flex-row items-center space-x-2 w-full">
          <div
            style="
               {
                width: 18px;
                height: 18px;
              }
            "
          >
            <search-icon
              v-if="!loading"
              class="text-coconut-white-700 h-4.5 w-4.5"
            />
            <img
              src="~/assets/img/icons/loading.svg"
              v-else
              alt="loading"
              class="h-4.5 w-4.5 animate-spin"
            />
          </div>
          <input
            autocomplete="off"
            type="text"
            v-model="query"
            placeholder="Search Workspace..."
            class="outline-none bg-transparent body-text2 text-coconut-white-default flex-grow placeholder-coconut-white-700"
            autofocus
            id="workspace-search-input"
          />
          <button @click="handleEsc()">
            <x-icon
              class="h-4 w-4 text-coconut-white-700 opacity-50 responsive:hover:opacity-100 transition-all"
            />
          </button>
        </div>
      </div>
      <div class="p-4 flex flex-row items-center space-x-2">
        <div
          class="filter-pill group"
          :class="{
            pressed: filterOnlyVideos,
          }"
          @click="toggleVideoFilter()"
        >
          <stack-video-icon class="h-4 w-4" />
          <span class="body-text3">Show only videos</span>
        </div>
        <div
          class="filter-pill group"
          :class="{
            pressed: filterOnlyPlaylists,
          }"
          @click="togglePlaylistFilter()"
        >
          <playlist-fill-icon class="h-4 w-4" />
          <span class="body-text3">Show only playlists</span>
        </div>
      </div>
      <div
        class="p-0 spiti-scroll overflow-x-hidden"
        ref="searchResults"
        :style="{
          'max-height': isMobile ? '100%' : `${searchBodyHeight}px`,
          height: isMobile && '100%',
        }"
      >
        <div
          v-if="!query || query === ''"
          class="px-4 pt-10 pb-14 flex flex-col space-y-2 text-coconut-white-600 text-center"
        >
          <span class="heading-title4">Search across your workspace</span>
          <span class="body-text2"
            >Search for videos, playlists, transcripts and in descriptions</span
          >
        </div>
        <div
          v-else-if="totalResults === null"
          class="px-4 pt-10 pb-14 flex flex-col items-center justify-center"
        >
          <img
            src="~/assets/img/icons/loading.svg"
            alt="loading"
            class="h-12 w-12 animate-spin"
          />
        </div>
        <div
          v-else-if="totalResults === 0 && totalResults !== null"
          class="px-4 pt-10 pb-14 flex flex-col space-y-2 text-coconut-white-600 text-center"
        >
          <span class="heading-title4">Oops! No results found</span>
          <span class="body-text2"
            >It seems like you're searching for something not added to Spiti
            yet.</span
          >
        </div>

        <div
          v-else
          class="flex flex-col space-y-2 body-text3 text-coconut-white-700"
        >
          <span class="px-6">Search results for “{{ query }}”</span>
          <div
            class="flex flex-col"
            v-if="
              (filterOnlyVideos || !filterOnlyPlaylists) &&
              groupedVideoSearchResults.length > 0
            "
          >
            <span class="px-6 py-2 text-coconut-white-700 body-subtitle2"
              >Videos</span
            >
            <div
              v-for="(video, videoIdx) in groupedVideoSearchResults"
              :key="`ws-${video.id}`"
              :ref="`ws-${video.id}`"
              class="px-6 py-2 flex flex-row space-x-2 cursor-pointer"
              :class="
                videoCursor === videoIdx ? 'search-item-hover' : 'search-item'
              "
              @click="navigateToVideo(video)"
              @mouseenter="onHoverVideo(videoIdx)"
            >
              <div class="flex-shrink-0 h-14 w-24">
                <img
                  class="h-full w-full object-cover"
                  :src="video.thumbnail"
                />
              </div>
              <div
                class="flex-grow flex flex-col space-y-2 text-coconut-white-600"
              >
                <div
                  class="px-2 text-sm"
                  v-html="sanitizeText(video.name)"
                ></div>
                <div
                  class="px-2 body-text3 line-clamp-2"
                  v-if="
                    video.description &&
                    video.description.length > 0 &&
                    (!video.transcripts || video.transcripts.length === 0)
                  "
                  v-html="sanitizeText(video.description)"
                ></div>
                <div
                  class="flex flex-col"
                  v-if="video.transcripts && video.transcripts.length > 0"
                >
                  <div
                    v-for="(transcript, transcriptIdx) in video.transcripts"
                    :key="transcript.id"
                    class="flex flex-row items-center space-x-2 px-2 py-1"
                    @click.stop="navigateToTranscript(video, transcript)"
                    :class="
                      videoCursor === videoIdx &&
                      transcriptCursor === transcriptIdx
                        ? 'transcript-item-hover'
                        : 'transcript-item'
                    "
                    @mouseenter="onHoverTranscript(videoIdx, transcriptIdx)"
                  >
                    <div
                      class="body-text4 transcript-time px-1 py-0.5 flex-shrink-0 flex items-center justify-center w-10"
                    >
                      {{ formatTranscriptStartTime(transcript.startTime) }}
                    </div>
                    <div
                      class="body-text3"
                      v-html="sanitizeText(transcript.text)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col"
            v-if="
              (filterOnlyPlaylists || !filterOnlyVideos) &&
              playlistSearchResults &&
              playlistSearchResults.length > 0
            "
          >
            <span class="px-6 py-2 text-coconut-white-700 body-subtitle2"
              >Playlists</span
            >
            <div
              v-for="(playlist, playlistIdx) in playlistSearchResults"
              :key="`ws-${playlist.id}`"
              :ref="`ws-${playlist.id}`"
              class="py-2 px-6 flex flex-row space-x-2 cursor-pointer"
              :class="
                playlistCursor === playlistIdx
                  ? 'search-item-hover'
                  : 'search-item'
              "
              @click="navigateToPlaylist(playlist)"
              @mouseenter="onHoverPlaylist(playlistIdx)"
            >
              <div class="text-coconut-white-600 w-4 h-4">
                <playlist-fill-icon width="16px" height="16px" />
              </div>
              <div class="flex flex-col space-y-2 text-coconut-white-600">
                <div
                  class="body-text2 leading-4"
                  v-html="sanitizeText(playlist.name)"
                ></div>
                <div
                  class="body-text3 line-clamp-2"
                  v-if="playlist.description && playlist.description.length > 0"
                  v-html="sanitizeText(playlist.description)"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="query && query !== '' && totalResults > 0"
        class="px-4 py-2 flex flex-row items-center space-x-1 border-t border-ebony-grey-200 border-opacity-50 text-coconut-white-700"
      >
        <span class="heading-title4">{{ totalResults }}</span>
        <span class="body-text2">result{{ totalResults > 1 ? "s" : "" }}</span>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import { sanitizeText } from "~/utils/formatters";
import {
  StackVideoIcon,
  SearchIcon,
  PlaylistFillIcon,
} from "@spitilabs/icon-library";
import { XIcon } from "vue-feather-icons";
import Modal from "~/components/Modal.vue";
import SearchService from "~/services/search";
import { formatSearchResultSnippets } from "~/utils/formatters";

const NAME = "workspace-search-modal";

export default {
  name: NAME,
  components: {
    SearchIcon,
    XIcon,
    StackVideoIcon,
    PlaylistFillIcon,
    Modal,
  },
  data() {
    return {
      NAME,
      loading: false,
      query: "",
      isModalOpen: false,
      filterOnlyPlaylists: false,
      filterOnlyVideos: false,
      playlistSearchResults: null,
      videoSearchResults: null,
      transcriptSearchResults: null,
      groupedVideoSearchResults: null,
      playlistCursor: null,
      videoCursor: null,
      transcriptCursor: null,
      searchClient: null,
      searchBodyHeight: 0,
    };
  },
  mounted() {
    this.initializeSearchClient();
    this.autoResetData();
    this.installShortcuts();
    this.computeSearchBodyHeight();
  },
  methods: {
    computeSearchBodyHeight() {
      // TODO: This is a hacky way to compute the height of the search body
      //      and should be replaced with a better solution.
      // window inner height - (2 * top space)
      // top space class is top-24 = 6rem = 96px
      this.searchBodyHeight = window.innerHeight - 4 * 96;
    },
    async initializeSearchClient() {
      if (!this.newSearchApiKey || !this.$config.searchDomain) {
        return;
      }
      this.searchClient = new SearchService(
        this.newSearchApiKey,
        this.$config.searchDomain,
      );
      await this.fetchVideoMeta();
    },
    installShortcuts() {
      // cmd/ctrl + k should open the search modal
      this.$mousetrap.bind("/", () => {
        this.$root.$emit("modal:open", NAME);
        return false;
      });
    },
    onHoverPlaylist(idx) {
      this.videoCursor = null;
      this.transcriptCursor = null;
      this.playlistCursor = idx;
    },
    onHoverVideo(idx) {
      this.playlistCursor = null;
      this.transcriptCursor = null;
      this.videoCursor = idx;
    },
    onHoverTranscript(videoIdx, transcriptIdx) {
      this.playlistCursor = null;
      this.videoCursor = videoIdx;
      this.transcriptCursor = transcriptIdx;
    },
    installNavigationShortcuts() {
      document.addEventListener("keydown", this.routeShortcut);
    },
    uninstallNavigationShortcuts() {
      document.removeEventListener("keydown", this.routeShortcut);
    },
    routeShortcut(e) {
      if (!this.isModalOpen) {
        return;
      }
      if (["ArrowUp", "ArrowDown", "Enter", "Escape"].includes(e.key)) {
        this.preventEventPropagation(e);
      }

      switch (e.key) {
        case "ArrowLeft":
          this.handleArrowLeft();
          break;
        case "ArrowRight":
          this.handleArrowRight();
          break;
        case "ArrowUp":
          this.handleArrowUp();
          break;
        case "ArrowDown":
          this.handleArrowDown();
          break;
        case "Enter":
          this.handleEnter();
          break;
        case "Escape":
          this.handleEsc();
          break;
        case "Backspace":
          this.handleBackspace(e);
          break;
      }
    },
    preventEventPropagation(e) {
      // Do not propagate the event if we are in the search modal
      e.stopPropagation();
      e.preventDefault();
    },
    handleBackspace(e) {
      if (this.playlistCursor !== null || this.videoCursor !== null) {
        this.preventEventPropagation(e);
      }
      this.playlistCursor = null;
      this.videoCursor = null;
      this.transcriptCursor = null;
      this.focusSearchInput();
    },
    handleEsc() {
      this.$root.$emit("modal:close", NAME);
    },
    handleEnter() {
      if (this.playlistCursor !== null) {
        const playlist = this.playlistSearchResults[this.playlistCursor];
        this.$router.push(`/playlists/${playlist.id}`);
        return;
      }

      const video = this.groupedVideoSearchResults[this.videoCursor];
      const url =
        this.transcriptCursor === null
          ? this.getVideoURL(video)
          : this.getTranscriptURL(
              video,
              video.transcripts[this.transcriptCursor],
            );
      this.$router.push(url);
    },
    shortcutArrowKeyRouter(direction) {
      switch (true) {
        case this.playlistCursor === null && this.videoCursor === null:
          if (this.filterOnlyPlaylists) {
            if (this.playlistSearchResults.length > 0) {
              direction === 1 && (this.playlistCursor = 0);
            }
          } else if (this.filterOnlyVideos) {
            if (this.groupedVideoSearchResults.length > 0) {
              direction === 1 && (this.videoCursor = 0);
            }
          } else {
            if (this.groupedVideoSearchResults.length > 0) {
              direction === 1 && (this.videoCursor = 0);
            } else if (this.playlistSearchResults.length > 0) {
              direction === 1 && (this.playlistCursor = 0);
            }
          }
          break;
        case this.playlistCursor !== null:
          if (this.filterOnlyPlaylists) {
            if (
              this.playlistCursor + direction >= 0 &&
              this.playlistCursor + direction <
                this.playlistSearchResults.length
            ) {
              this.playlistCursor += direction;
            } else if (this.playlistCursor + direction < 0) {
              this.playlistCursor = null;
              this.focusSearchInput();
            }
          } else if (this.filterOnlyVideos) {
          } else {
            if (
              this.playlistCursor + direction >= 0 &&
              this.playlistCursor + direction <
                this.playlistSearchResults.length
            ) {
              this.playlistCursor += direction;
            } else if (this.playlistCursor + direction < 0) {
              if (this.groupedVideoSearchResults.length > 0) {
                this.playlistCursor = null;
                this.videoCursor = this.groupedVideoSearchResults.length - 1;
              } else {
                this.playlistCursor = null;
                this.focusSearchInput();
              }
            }
          }
          break;
        case this.videoCursor !== null && this.transcriptCursor === null:
          if (this.filterOnlyPlaylists) {
          } else if (this.filterOnlyVideos) {
            if (
              this.videoCursor + direction >= 0 &&
              this.videoCursor + direction <
                this.groupedVideoSearchResults.length
            ) {
              this.videoCursor += direction;
            } else if (this.videoCursor + direction < 0) {
              this.videoCursor = null;
              this.focusSearchInput();
            }
          } else {
            if (
              this.videoCursor + direction >= 0 &&
              this.videoCursor + direction <
                this.groupedVideoSearchResults.length
            ) {
              this.videoCursor += direction;
            } else if (this.videoCursor + direction < 0) {
              this.videoCursor = null;
              this.focusSearchInput();
            } else if (
              this.videoCursor + direction >=
                this.groupedVideoSearchResults.length &&
              this.playlistSearchResults.length > 0
            ) {
              this.videoCursor = null;
              this.playlistCursor = 0;
            }
          }
          break;
        case this.videoCursor !== null && this.transcriptCursor !== null:
          if (this.filterOnlyPlaylists) {
          } else if (this.filterOnlyVideos) {
            if (
              this.transcriptCursor + direction >= 0 &&
              this.transcriptCursor + direction <
                this.groupedVideoSearchResults[this.videoCursor].transcripts
                  .length
            ) {
              this.transcriptCursor += direction;
            } else if (this.transcriptCursor + direction < 0) {
              this.transcriptCursor = null;
              if (this.videoCursor - 1 >= 0) {
                this.videoCursor -= 1;
              } else {
                this.videoCursor = null;
                this.focusSearchInput();
              }
            } else if (
              this.transcriptCursor + direction >=
              this.groupedVideoSearchResults[this.videoCursor].transcripts
                .length
            ) {
              if (
                this.videoCursor + 1 <
                this.groupedVideoSearchResults.length
              ) {
                this.videoCursor += 1;
                this.transcriptCursor = null;
              }
            }
          } else {
            if (
              this.transcriptCursor + direction >= 0 &&
              this.transcriptCursor + direction <
                this.groupedVideoSearchResults[this.videoCursor].transcripts
                  .length
            ) {
              this.transcriptCursor += direction;
            } else if (this.transcriptCursor + direction < 0) {
              this.transcriptCursor = null;
              if (this.videoCursor - 1 >= 0) {
                this.videoCursor -= 1;
              } else {
                this.videoCursor = null;
                this.focusSearchInput();
              }
            } else if (
              this.transcriptCursor + direction >=
              this.groupedVideoSearchResults[this.videoCursor].transcripts
                .length
            ) {
              if (
                this.videoCursor + 1 <
                this.groupedVideoSearchResults.length
              ) {
                this.videoCursor += 1;
                this.transcriptCursor = null;
              } else if (
                this.videoCursor + 1 >= this.groupedVideoSearchResults.length &&
                this.playlistSearchResults.length > 0
              ) {
                this.videoCursor = null;
                this.transcriptCursor = null;
                this.playlistCursor = 0;
              }
            }
          }
          break;
      }
      this.scrollResultsIntoView();
    },
    scrollResultsIntoView() {
      if (this.playlistCursor === null && this.videoCursor === null) {
        return;
      }
      const rect = this.$refs.searchResults.getBoundingClientRect();
      if (this.playlistCursor !== null) {
        const playlistId = this.playlistSearchResults[this.playlistCursor]?.id;
        const refId = playlistId && `ws-${playlistId}`;
        if (!refId) {
          return;
        }
        const elRect = this.$refs[refId][0].getBoundingClientRect();
        if (elRect.top < rect.top) {
          this.$refs.searchResults.scrollTop -= rect.top - elRect.top;
        } else if (elRect.bottom > rect.bottom) {
          this.$refs.searchResults.scrollTop += elRect.bottom - rect.bottom;
        }
      }
      if (this.videoCursor !== null) {
        const videoId = this.groupedVideoSearchResults[this.videoCursor]?.id;
        const refId = videoId && `ws-${videoId}`;
        if (!refId) {
          return;
        }
        const elRect = this.$refs[refId][0].getBoundingClientRect();
        if (elRect.top < rect.top) {
          this.$refs.searchResults.scrollTop -= rect.top - elRect.top;
        } else if (elRect.bottom > rect.bottom) {
          this.$refs.searchResults.scrollTop += elRect.bottom - rect.bottom;
        }
      }
    },
    handleArrowDown() {
      if (
        this.playlistSearchResults.length === 0 &&
        this.groupedVideoSearchResults.length === 0
      ) {
        return;
      }
      this.unfocusSearchInput();
      this.shortcutArrowKeyRouter(1);
    },
    handleArrowUp() {
      if (
        this.playlistSearchResults.length === 0 &&
        this.groupedVideoSearchResults.length === 0
      ) {
        return;
      }
      this.shortcutArrowKeyRouter(-1);
    },
    handleArrowLeft() {
      this.transcriptCursor = null;
    },
    handleArrowRight() {
      if (
        this.videoCursor !== null &&
        this.groupedVideoSearchResults[this.videoCursor]
      ) {
        const video = this.groupedVideoSearchResults[this.videoCursor];
        if (video.transcripts.length > 0) {
          this.transcriptCursor = 0;
        }
      }
    },
    autoResetData() {
      this.$root.$on("modal:opened", (name) => {
        if (name === NAME) {
          this.isModalOpen = true;
          this.resetData();
          setTimeout(() => {
            this.focusSearchInput();
          }, 100);
        }
      });
      this.$root.$on("modal:closed", (name) => {
        if (name === NAME) {
          this.isModalOpen = false;
        }
      });
    },
    focusSearchInput() {
      document.getElementById("workspace-search-input")?.focus();
    },
    unfocusSearchInput() {
      document.getElementById("workspace-search-input")?.blur();
    },
    resetData() {
      this.query = "";
      this.filterOnlyPlaylists = false;
      this.filterOnlyVideos = false;
      this.loading = false;
      this.playlistSearchResults = null;
      this.videoSearchResults = null;
      this.transcriptSearchResults = null;
      this.groupedVideoSearchResults = null;
      this.resetCursors();
    },
    resetCursors() {
      this.playlistCursor = null;
      this.videoCursor = null;
      this.transcriptCursor = null;
    },
    togglePlaylistFilter() {
      this.filterOnlyVideos = false;
      this.filterOnlyPlaylists = !this.filterOnlyPlaylists;
      this.resetCursors();
    },
    toggleVideoFilter() {
      this.filterOnlyPlaylists = false;
      this.filterOnlyVideos = !this.filterOnlyVideos;
      this.resetCursors();
    },
    async fetchVideoMeta() {
      try {
        const { hits } = await this.searchClient.searchCollection(
          "videos",
          "*",
        );
        await this.$store.dispatch(
          "meta/pushVideos",
          hits.map((each) => each.document),
        );
      } catch (error) {
        console.error(error);
      }
    },
    async fetchSearchResults(searchText) {
      this.loading = true;
      try {
        const { results } = await this.searchClient.multiSearch(searchText);
        await this.parseVideoSearchResults(
          results[1].hits,
          results[2].grouped_hits,
        );
        this.parsePlaylistSearchResults(results[0].hits);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    parsePlaylistSearchResults(items) {
      this.playlistSearchResults = items.map((each) => {
        const { document, highlights } = each;
        const { id, name, description } = document;
        const item = { id };
        for (const highlight of highlights) {
          const { field, snippet } = highlight;
          item[field] = snippet;
        }
        if (!item.name) {
          item.name = name;
        }
        if (!item.description) {
          item.description = description;
        }
        return item;
      });
    },
    async parseVideoSearchResults(videoItems, transcriptItems) {
      const videoMap = {};
      for (const each of videoItems) {
        const { document, highlights, text_match } = each;
        const { id, name, description, thumbnail, playlists } = document;
        const item = { id, score: text_match, thumbnail, playlists };
        for (const highlight of highlights) {
          const { field, snippet } = highlight;
          item[field] =
            field === "description"
              ? formatSearchResultSnippets(snippet)
              : snippet;
        }
        if (!item.name) {
          item.name = name;
        }
        if (!item.description) {
          item.description = description;
        }
        videoMap[id] = item;
      }
      for (const each of transcriptItems) {
        const { group_key, hits } = each;
        const videoId = group_key[0];
        let item = videoMap[videoId];
        if (!item && this.videoMeta[videoId]) {
          const { name, description, thumbnail, playlists } =
            this.videoMeta[videoId];
          item = {
            id: videoId,
            name,
            description,
            thumbnail,
            playlists,
          };
        }
        let score = -1;
        for (const hit of hits) {
          score = Math.max(score, hit.text_match);
        }
        item.score = item.score ? Math.max(score, item.score) : score;
        const transcripts = hits.map((hit) => {
          const { document, highlights } = hit;
          return {
            ...document,
            text: formatSearchResultSnippets(highlights[0].snippet),
          };
        });
        item.transcripts = transcripts.sort(
          (a, b) => a.startTime - b.startTime,
        );
        videoMap[videoId] = item;
      }
      this.groupedVideoSearchResults = Object.values(videoMap).sort(
        (a, b) => b.score - a.score,
      );
    },
    formatTranscriptStartTime(time) {
      const timeInSec = time / 1000;
      const minutes = Math.floor(timeInSec / 60);
      const seconds = Math.floor(timeInSec % 60);
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    },
    getVideoURL(video) {
      if (video.playlists && video.playlists.length > 0) {
        const workspacePlaylists = (this.playlists || []).map(
          (playlist) => playlist.id,
        );
        const videoPlaylists = workspacePlaylists.filter((playlistId) =>
          video.playlists.includes(playlistId),
        );
        if (videoPlaylists.length > 0) {
          return `/player?playlist=${videoPlaylists[0]}&video=${video.id}`;
        }
      } else {
        const videoAcl = this.feed.find((each) => each.id === video.id)?.acl;
        if (
          videoAcl &&
          videoAcl.workspaceIds.length > 0 &&
          videoAcl.workspaceIds.includes(this.defaultWorkspace?.id)
        ) {
          return `/player?video=${video.id}`;
        }
      }
      return `/videos/${video.id}`;
    },
    getTranscriptURL(video, transcript) {
      const videoUrl = this.getVideoURL(video);
      return videoUrl.startsWith("/videos")
        ? `${videoUrl}?startTime=${transcript.startTime}`
        : `${videoUrl}&startTime=${transcript.startTime}`;
    },
    navigateToVideo(video) {
      this.$router.push(this.getVideoURL(video));
    },
    navigateToTranscript(video, transcript) {
      this.$router.push(this.getTranscriptURL(video, transcript));
    },
    navigateToPlaylist(playlist) {
      this.$router.push(`/playlists/${playlist.id}`);
    },
    sanitizeText,
  },
  watch: {
    newSearchApiKey() {
      if (!this.searchClient) {
        this.initializeSearchClient();
      }
    },
    isModalOpen: {
      handler(isModalOpen) {
        if (isModalOpen) {
          this.installNavigationShortcuts();
        } else {
          this.uninstallNavigationShortcuts();
        }
      },
    },
    query: {
      handler: debounce(async function (searchText) {
        this.resetCursors();
        if (searchText === "") {
          this.playlistSearchResults = null;
          this.videoSearchResults = null;
          this.transcriptSearchResults = null;
          this.groupedVideoSearchResults = null;
          this.loading = false;
          return;
        }
        await this.fetchSearchResults(searchText);
      }, 300),
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      viewer: "auth/viewer",
      videoMeta: "meta/videos",
      feed: "workspace/feed",
      playlists: "workspace/playlists",
      defaultWorkspace: "workspace/defaultWorkspace",
      isMobile: "isScreenSmall",
    }),
    totalResults() {
      if (
        this.playlistSearchResults === null &&
        this.groupedVideoSearchResults === null &&
        this.transcriptSearchResults === null &&
        this.videoSearchResults === null
      ) {
        return null;
      }
      console.log(
        !this.filterOnlyPlaylists && !this.filterOnlyVideos
          ? this.groupedVideoSearchResults?.length ||
              0 + this.playlistSearchResults?.length ||
              0
          : this.filterOnlyPlaylists
            ? this.playlistSearchResults?.length || 0
            : this.groupedVideoSearchResults?.length || 0,
      );
      return !this.filterOnlyPlaylists && !this.filterOnlyVideos
        ? this.groupedVideoSearchResults?.length ||
            0 + this.playlistSearchResults?.length ||
            0
        : this.filterOnlyPlaylists
          ? this.playlistSearchResults?.length || 0
          : this.groupedVideoSearchResults?.length || 0;
    },
    newSearchApiKey() {
      return (
        this.defaultWorkspace?.id &&
        this.viewer?.workspaces[this.defaultWorkspace.id]?.newSearchApiKey
      );
    },
  },
};
</script>
<style scoped lang="scss">
.search-item {
  @apply transition-colors duration-75;
  &:active {
    @apply bg-coal-pop-100;
  }
}

.search-item-hover {
  @apply bg-coal-pop-200;
  &:active {
    @apply bg-coal-pop-100;
  }
}

.transcript-time {
  @apply bg-midnight-black-100 rounded-sm text-coconut-white-default;
}

.transcript-item {
  @apply transition-colors duration-75;
  &:active {
    @apply bg-coal-pop-default;
  }
}

.transcript-item-hover {
  @apply bg-coal-pop-400;
  &:active {
    @apply bg-coal-pop-default;
  }
}

.search-modal {
  width: 720px;
  max-height: 100%;
  @apply border-0 md:rounded bg-coal-pop-default;
  @media (max-width: 768px) {
    @apply fixed w-screen h-screen mt-0 top-0 left-0 z-50;
  }
}
</style>
