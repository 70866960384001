/**
 * Ensure all events defined are in accordance with "Object-Action" framework
 */

/**
 * Auth events
 */
const AUTH_EVENTS = {
  SIGNED_UP: "Signed Up",
  SIGNED_IN: "Signed In",
  SIGNED_OUT: "Signed Out",
  WEB_APP_VISITED: "Web App Visited",
};

/**
 * Workspace events
 */
const WORKSPACE_EVENTS = {
  WORKSPACE_CREATED: "Workspace Created",
  WORKSPACE_META_UPDATED: "Workspace Meta Updated",
  WORKSPACE_DOMAINS_UPDATED: "Workspace Domains Updated",
  WORKSPACE_UPGRADED: "Workspace Upgraded",
  WORKSPACE_DOWNGRADED: "Workspace Downgraded",
  WORKSPACE_INVITE_LINK_COPIED: "Workspace Invite Link Copied",
};

/**
 * User membership update events
 */
const USER_MEMBERSHIP_EVENTS = {
  WORKSPACE_JOINED: "Workspace Joined",
  USER_REMOVED_FROM_WORKSPACE: "User Removed From Workspace",
  WORKSPACE_MEMBER_ROLE_UPDATED: "Workspace Member Role Updated",
};

/**
 * Zoom, Drive, Slack and Spiti Connect events
 */
const SPITI_APP_CONNECTIONS = {
  ZOOM_APP_INSTALLED: "Zoom App Installed",
  DRIVE_APP_INSTALLED: "Drive App Installed",
  SLACK_APP_INSTALLED: "Slack App Installed",
};

/**
 * Video events
 */
const VIDEO_EVENTS = {
  VIDEO_IMPORTED: "Video Imported",
  VIDEO_META_UPDATED: "Video Meta Updated",
  VIDEO_PUBLISHED: "Video Published",
  VIDEO_MADE_PUBLIC: "Video Made Public",
  PUBLIC_VIDEO_LINK_COPIED: "Public Video Link Copied",
  EMBED_VIDEO_LINK_COPIED: "Embed Video Link Copied",
  VIDEO_DELETED: "Video Deleted",
  VIDEO_LIKED: "Video Liked",
  VIDEO_COMMENTED: "Video Commented",
  VIDEO_CHAPTERS_EDITED: "Video Chapters Edited",
  VIDEO_TRANSCRIPT_SPEAKER_LABELS_EDITED:
    "Video Transcript Speaker Labels Edited",
  VIDEO_TRANSCRIPT_EDITED: "Video Transcript Edited",
  VIDEO_TRANSCRIPT_COPIED: "Video Transcript Copied",
};

/**
 * AI events
 *
 */
const AI_EVENTS = {
  SUMMARY_GENERATED: "Summary Generated",
  SUMMARY_EDITED: "Summary Edited",
  SUMMARY_DISCARDED: "Summary Discarded",
  SUMMARY_REGENERATED: "Summary Regenerated",
  SUMMARY_FEEDBACK_GIVEN: "Summary Feedback Given",
};

/**
 * Ask Spiti events
 *
 */
//TODO: Name these events better
const ASK_SPITI_EVENTS = {
  ASK_SPITI_VIDEO_QUESTION_ANSWERED: "Ask Spiti Video Question Answered",
  ASK_SPITI_VIDEO_ANSWER_REGENERATED: "Ask Spiti Video Answer Regenerated",
  ASK_SPITI_VIDEO_ANSWER_COPIED: "Ask Spiti Video Answer Copied",
  ASK_SPITI_BOOKMARK_ADDED: "Ask Spiti Bookmark Added",
  ASK_SPITI_BOOKMARK_REMOVED: "Ask Spiti Bookmark Removed",
  ASK_SPITI_BOOKMARK_PUBLIC: "Ask Spiti Bookmark Public",
  ASK_SPITI_BOOKMARK_PRIVATE: "Ask Spiti Bookmark Private",
};

/**
 * Playlist events
 */
const PLAYLIST_EVENTS = {
  PLAYLIST_CREATED: "Playlist Created",
  PLAYLIST_META_UPDATED: "Playlist Meta Updated",
  PLAYLIST_LOCKED: "Playlist Locked",
  PLAYLIST_DELETED: "Playlist Deleted",
  PLAYLIST_VIDEO_LIST_UPDATED: "Playlist Video List Updated",
  PLAYLIST_MADE_PUBLIC: "Playlist Made Public",
  PUBLIC_PLAYLIST_LINK_COPIED: "Public Playlist Link Copied",
  EMBED_PLAYLIST_LINK_COPIED: "Embed Playlist Link Copied",
  PLAYLIST_ACL_UPDATED: "Playlist ACL Updated",
  PLAYLIST_SPITI_CONNECT_LINK_COPIED: "Playlist Spiti Connect Link Copied",
  PLAYLIST_SPITI_CONNECT_INVITE_SENT: "Playlist Spiti Connect Invite Sent",
  PLAYLIST_SPITI_CONNECT_INVITE_RESENT: "Playlist Spiti Connect Invite Resent",
  PLAYLIST_SPITI_CONNECT_INVITE_REVOKED:
    "Playlist Spiti Connect Invite Revoked",
  PLAYLIST_SPITI_CONNECT_INVITE_ACCEPTED:
    "Playlist Spiti Connect Invite Accepted",
  PLAYLIST_SPITI_CONNECT_ACCESS_REVOKED:
    "Playlist Spiti Connect Access Revoked",
};

/**
 * Screen recorder events
 */
const SCREEN_RECORDER_EVENTS = {
  SCREEN_RECORDER_DOWNLOADED: "Screen Recorder Downloaded",
};

/**
 * Misc events
 */
const MISC_EVENTS = {
  BELL_ICON_CLICKED: "Bell Icon Clicked",
  NOTIFICATION_ITEM_CLICKED: "Notification Item Clicked",
  ADVANCED_SEARCH_CLICKED: "Advanced Search Clicked",
  MAGIC_VIDEO_LINK_COPIED: "Magic Video Link Copied",
};

export default {
  ...AUTH_EVENTS,
  ...WORKSPACE_EVENTS,
  ...USER_MEMBERSHIP_EVENTS,
  ...SPITI_APP_CONNECTIONS,
  ...AI_EVENTS,
  ...ASK_SPITI_EVENTS,
  ...VIDEO_EVENTS,
  ...PLAYLIST_EVENTS,
  ...MISC_EVENTS,
  ...SCREEN_RECORDER_EVENTS,
};
