import {
  NOTIFICATION_STATUS,
  NOTIFICATION_TYPE,
} from "~/components/notifications/types";
import { COLLECTIONS } from "~/services/firebase/firestore/types";

/**
 * User Service provides helper methods to interact with firestore users collection
 */
export default class {
  constructor($fire, $fireModule) {
    this.$fire = $fire;
    this.$fireModule = $fireModule;
  }

  /**
   *
   * @param {String} userId refers to the user whose notifications are being taken action on
   * @param {String} workspaceId of the workspace user is currently in
   * @param {String} playlistId refers to the playlist to which the notifications belong to
   * @param {FirestoreQuery} raw query which when executed marks all relevant notifications as read
   */
  async markNewPlaylistNotificationsAsRead({
    userId,
    workspaceId,
    playlistId,
  }) {
    const snapshot = await this.$fire.firestore
      .collection(COLLECTIONS.USERS)
      .doc(userId)
      .collection(COLLECTIONS.NOTIFICATIONS)
      .where("type", "in", [
        NOTIFICATION_TYPE.NEW_PLAYLIST_CREATED,
        NOTIFICATION_TYPE.PLAYLIST_SHARED,
      ])
      .where("status", "==", NOTIFICATION_STATUS.UNREAD)
      .where("workspaceId", "==", workspaceId)
      .where("payload.playlistId", "==", playlistId)
      .get();
    snapshot.docs.forEach(async (doc) => {
      await doc.ref.update({ status: NOTIFICATION_STATUS.READ });
    });
  }

  /**
   *
   * @param {String} userId refers to the user whose notifications are being taken action on
   * @param {String} workspaceId of the workspace user is currently in
   * @param {String} playlistId refers to the playlist to which the notifications belong to
   * @param {FirestoreQuery} raw query which when executed marks all relevant notifications as read
   */
  async markPlaylistNotificationsAsRead({ userId, workspaceId, playlistId }) {
    const snapshot = await this.$fire.firestore
      .collection(COLLECTIONS.USERS)
      .doc(userId)
      .collection(COLLECTIONS.NOTIFICATIONS)
      .where("type", "in", [
        NOTIFICATION_TYPE.NEW_PLAYLIST_CREATED,
        NOTIFICATION_TYPE.NEW_VIDEOS_IN_PLAYLIST,
        NOTIFICATION_TYPE.PLAYLIST_SHARED,
        NOTIFICATION_TYPE.SPITI_CONNECT_INVITATION_ACCEPTED,
      ])
      .where("status", "==", NOTIFICATION_STATUS.UNREAD)
      .where("workspaceId", "==", workspaceId)
      .where("payload.playlistId", "==", playlistId)
      .get();
    snapshot.docs.forEach(async (doc) => {
      await doc.ref.update({ status: NOTIFICATION_STATUS.READ });
    });
  }

  /**
   *
   * @param {String} userId refers to the user whose notifications are being taken action on
   * @param {String} workspaceId of the workspace user is currently in
   * @param {String} videoId refers to the video to which the notification belongs to
   * @param {FirestoreQuery} raw query which when executed marks all relevant notifications as read
   */
  async markVideoNotificationsAsRead({ userId, workspaceId, videoId }) {
    const snapshot = await this.$fire.firestore
      .collection(COLLECTIONS.USERS)
      .doc(userId)
      .collection(COLLECTIONS.NOTIFICATIONS)
      .where("type", "in", [
        NOTIFICATION_TYPE.NEW_VIDEO_PUBLISHED,
        NOTIFICATION_TYPE.NEW_VIDEO_COMMENT,
        NOTIFICATION_TYPE.NEW_VIDEO_LIKE,
        NOTIFICATION_TYPE.TRANSCRIPTS_READY,
        NOTIFICATION_TYPE.VIDEO_READY_FOR_SUMMARIZATION,
      ])
      .where("status", "==", NOTIFICATION_STATUS.UNREAD)
      .where("workspaceId", "==", workspaceId)
      .where("payload.videoId", "==", videoId)
      .get();
    snapshot.docs.forEach(async (doc) => {
      await doc.ref.update({ status: NOTIFICATION_STATUS.READ });
    });
  }

  /**
   *
   * @param {String} userId refers to the user for whom the notifications are being fetched
   * @param {String} workspaceId of the workspace user is currently in
   * @param {FirestoreQuery} raw query which when executed fetches notifications for the user from firestore
   */
  fetchUserNotificationsQuery({ userId, workspaceId, limit = 100 }) {
    return this.$fire.firestore
      .collection(COLLECTIONS.USERS)
      .doc(userId)
      .collection(COLLECTIONS.NOTIFICATIONS)
      .where("workspaceId", "==", workspaceId)
      .orderBy("createdAt", "desc")
      .limit(limit);
  }

  /**
   *
   * @param {String} userId refers to the user for whom the notifications are being fetched
   * @param {FirestoreQuery} raw query which when executed fetches notifications for the user from firestore
   */
  fetchProductUpdatesQuery({ userId, limit = 10 }) {
    return this.$fire.firestore
      .collection(COLLECTIONS.USERS)
      .doc(userId)
      .collection(COLLECTIONS.PRODUCT_UPDATES)
      .orderBy("createdAt", "desc")
      .limit(limit);
  }

  /**
   * markProductUpdateNotificationAsRead marks the product update notification as read in firestore
   */
  async markProductUpdateNotificationAsRead({
    userId,
    type = "NEW_FEATURE",
    change,
  }) {
    const snapshot = await this.$fire.firestore
      .collection(COLLECTIONS.USERS)
      .doc(userId)
      .collection(COLLECTIONS.PRODUCT_UPDATES)
      .where("type", "==", type)
      .where("change", "==", change)
      .where("status", "==", NOTIFICATION_STATUS.UNREAD)
      .get();
    snapshot.docs.forEach(async (doc) => {
      await doc.ref.update({ status: NOTIFICATION_STATUS.READ });
    });
  }
}
