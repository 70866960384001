<template>
  <div
    class="bg-space-blue-default min-h-screen h-full flex flex-wrap"
    @dragover.prevent
    @drop.prevent
  >
    <header class="fixed top-0 z-40 bg-slate-grey-default w-full navbar">
      <div
        class="w-full border-b border-ebony-grey-default flex items-center justify-between h-full"
      >
        <div class="flex">
          <div class="w-full flex flex-row justify-center items-center">
            <a :href="landingPageUrl" target="_blank" rel="noopener noreferrer">
              <img
                src="~/assets/img/full-logo-white.svg"
                alt="Spiti"
                class="h-6 opacity-100 transition-opacity duration-300 ml-4 mr-1"
              />
            </a>
          </div>
        </div>
        <client-only>
          <div class="flex items-center relative px-4">
            <div
              class="flex md:space-x-6 space-x-4 items-center"
              v-if="avatarUrl && firstName && defaultWorkspace"
            >
              <nuxt-link
                v-if="!isMobile"
                class="flex button btn-md px-6 secondary border-1 items-center space-x-2"
                :to="{
                  name: 'home',
                  params: { slug: defaultWorkspace.shortcode },
                }"
              >
                <span>My Workspace</span>
                <ArrowUpIcon class="transform rotate-90 h-5 w-5" />
              </nuxt-link>
              <nuxt-link
                v-if="isMobile"
                class="flex text-dew-jerry-default active:bg-slate-grey-100"
                :to="{
                  name: 'home',
                  params: { slug: defaultWorkspace.shortcode },
                }"
              >
                <LogInIcon class="h-6 w-6" />
              </nuxt-link>
              <AccountDropdown
                :avatarUrl="avatarUrl"
                :displayName="firstName"
              />
            </div>
            <button
              v-else
              class="text-coconut-white-default button primary btn-md rounded-sm space-x-2 items-center flex"
              @click="handleTrySpitiButtonClick()"
            >
              <span>Try Spiti</span>
              <ArrowUpIcon
                class="hidden md:block transform rotate-90 h-5 w-5 md:h-5 md:w-5"
              />
            </button>
          </div>
        </client-only>
      </div>
    </header>
    <article class="dashboard-container w-full">
      <Nuxt />
    </article>
  </div>
</template>

<script>
import { LogInIcon, ArrowUpIcon } from "@spitilabs/icon-library";
import { mapGetters } from "vuex";
import AccountDropdown from "~/components/dropdowns/AccountDropdown";

export default {
  name: "public-app",
  components: {
    AccountDropdown,
    ArrowUpIcon,
    LogInIcon,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    async $route() {
      // if the route changes, close all open modals and collapse the sidebar
      this.$root.$emit("modal:close:all");
    },
  },
  mounted() {
    this.handleMobileLayout();
  },
  computed: {
    ...mapGetters({
      viewer: "auth/viewer",
      isMobile: "isScreenSmall",
      defaultWorkspace: "workspace/defaultWorkspace",
    }),
    avatarUrl() {
      return this.viewer?.avatar;
    },
    firstName() {
      if (this.viewer?.displayName) {
        return this.viewer.displayName.split(" ")[0];
      }
      return this.viewer?.email;
    },
    landingPageUrl() {
      return (
        process.client && `${window.location.protocol}//${window.location.host}`
      );
    },
  },
  methods: {
    async handleTrySpitiButtonClick() {
      this.$router.push({ name: "login" });
    },
    handleMobileLayout() {
      let isMobile = window.outerWidth < 768;

      // Set isSmallScreen for the current device
      this.$store.dispatch("setScreenSmall", isMobile);

      //Add Resize handler
      window.addEventListener("resize", this.windowResizeHandler);
    },
  },
};
</script>
