<template>
  <div class="flex flex-col space-y-1">
    <span class="whitespace-pre-line">{{
      expand ? text : text.slice(0, maxChars)
    }}</span>
    <button
      class="text-coconut-white-700 ml-0.5 text-left"
      @click="toggleExpand"
      v-if="text.length > maxChars"
    >
      {{ expand ? " (hide)" : " (read more)" }}
    </button>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    maxChars: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      expand: false,
    };
  },
  methods: {
    toggleExpand() {
      this.expand = !this.expand;
    },
  },
};
</script>
