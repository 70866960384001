import { COLLECTIONS } from "~/services/firebase/firestore/types";

/**
 * Workspace Service provides helper methods to interact with firestore workspaces collection
 */
export default class {
  constructor($fire, $fireModule) {
    this.$fire = $fire;
    this.$fireModule = $fireModule;
  }

  /**
   *
   * @param {String} workspaceId as referenced in the firestore workspaces collection
   * @param {Map} fields with updated values
   */
  async updateWorkspace(workspaceId, fields) {
    await this.$fire.firestore
      .collection(COLLECTIONS.WORKSPACES)
      .doc(workspaceId)
      .update(fields);
  }

  /**
   *
   * @param {String} workspaceId of the workspace for which to fetch the members
   * @param {FirestoreQuery} raw query which when executed fetches all users in a workspace from firestore
   */
  fetchWorkspaceMembersQuery(workspaceId) {
    return this.$fire.firestore
      .collection(COLLECTIONS.WORKSPACES)
      .doc(workspaceId)
      .collection("members")
      .orderBy("createdAt");
  }
}
