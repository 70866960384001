import { vuexfireMutations } from "vuexfire";
import { isAgentMobile } from "~/services/site-meta";
import { cloneDeep } from "lodash";
export const COLLECTIONS = {
  USERS: "users",
  WORKSPACES: "workspaces",
  VIDEOS: "videos",
  TRANSCRIPTS: "transcripts",
  PARAGRAPHS: "paragraphs",
  PLAYLISTS: "playlists",
  INVITATIONS: "invitations",
  COMMENTS: "comments",
  SUBSCRIPTIONS: "subscriptions",
  VIDEO_LIKES: "video-likes",
  NOTIFICATIONS: "notifications",
  AI_INSIGHTS: "ai-insights",
};

export const state = () => ({
  isSidebarOpen: true,
  isMarketingMenuOpen: false,
  isFeaturesMenuOpen: false,
  modals: [],
  isScreenSmall: null,
});

export const getters = {
  isSidebarOpen: (state) => {
    return state.isSidebarOpen;
  },
  isMarketingMenuOpen: (state) => {
    return state.isMarketingMenuOpen;
  },
  isFeaturesMenuOpen: (state) => {
    return state.isFeaturesMenuOpen;
  },
  isScreenSmall: (state) => {
    return state.isScreenSmall;
  },
  currentModal: (state) => {
    // Current modal is the first modal in the array
    // if there is one return it otherwise return null
    return state.modals.length > 0 ? state.modals[0] : null;
  },
  modals: (state) => {
    // Return the modals array
    return state.modals;
  },
};

export const actions = {
  nuxtServerInit({ commit }, { res, req }) {
    if (res?.locals?.user) {
      const { allClaims } = res.locals.user;
      const { name: displayName, picture: avatar, uid, email } = allClaims;
      commit("auth/SET_VIEWER", { displayName, avatar, uid, email });
    }
    if (isAgentMobile(req)) {
      commit("SET_SCREEN_SMALL", true);
    }
  },
  toggleSidebar({ commit }) {
    commit("TOGGLE_SIDEBAR");
  },
  setScreenSmall({ commit }, value) {
    commit("SET_SCREEN_SMALL", value);
  },
  closeSidebar({ commit }) {
    commit("CLOSE_SIDEBAR");
  },
  openSidebar({ commit }) {
    commit("OPEN_SIDEBAR");
  },
  toggleMarketingMenu({ commit }) {
    commit("TOGGLE_MARKETING_MENU");
  },
  closeMarketingMenu({ commit }) {
    commit("CLOSE_MARKETING_MENU");
  },
  toggleFeaturesMenu({ commit }) {
    commit("TOGGLE_FEATURES_MENU");
  },
  closeFeaturesMenu({ commit }) {
    commit("CLOSE_FEATURES_MENU");
  },
  setCurrentModal({ commit, state }, modal) {
    // Clone the modals array
    const clonedModals = cloneDeep(state.modals);
    // Add the modal to the beginning of the array
    clonedModals.unshift(modal);
    // Set the modals
    commit("SET_MODALS", clonedModals);
  },
  removeCurrentModal({ commit, state }, modal) {
    // Clone the modals array
    const clonedModals = cloneDeep(state.modals);
    // If the modal is the first modal in the array remove it
    if (modal === clonedModals[0]) {
      // Remove the modal from the beginning of the array
      clonedModals.shift();
      // Set the modals
      commit("SET_MODALS", clonedModals);
    }
  },
};

export const mutations = {
  TOGGLE_SIDEBAR(state) {
    state.isSidebarOpen = !state.isSidebarOpen;
  },
  CLOSE_SIDEBAR(state) {
    state.isSidebarOpen = false;
  },
  OPEN_SIDEBAR(state) {
    state.isSidebarOpen = true;
  },
  TOGGLE_MARKETING_MENU(state) {
    state.isMarketingMenuOpen = !state.isMarketingMenuOpen;
  },
  CLOSE_MARKETING_MENU(state) {
    state.isMarketingMenuOpen = false;
  },
  TOGGLE_FEATURES_MENU(state) {
    state.isFeaturesMenuOpen = !state.isFeaturesMenuOpen;
  },
  CLOSE_FEATURES_MENU(state) {
    state.isFeaturesMenuOpen = false;
  },
  SET_MODALS(state, modal) {
    state.modals = modal;
  },
  SET_SCREEN_SMALL(state, value) {
    state.isScreenSmall = value;
  },
  ...vuexfireMutations,
};
