var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"name":_vm.NAME,"align":"top"}},[_c('div',{staticClass:"search-modal flex flex-col"},[_c('div',{staticClass:"p-4 border-b border-gray-300 border-opacity-50 w-full"},[_c('div',{staticClass:"flex flex-row items-center space-x-2 w-full"},[_c('div',{staticStyle:{"{\n              width":"18px","height":"18px"}},[(!_vm.loading)?_c('search-icon',{staticClass:"text-coconut-white-700 h-4.5 w-4.5"}):_c('img',{staticClass:"h-4.5 w-4.5 animate-spin",attrs:{"src":require("assets/img/icons/loading.svg"),"alt":"loading"}})],1),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],staticClass:"outline-none bg-transparent body-text2 text-coconut-white-default flex-grow placeholder-coconut-white-700",attrs:{"autocomplete":"off","type":"text","placeholder":"Search Workspace...","autofocus":"","id":"workspace-search-input"},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing){ return; }_vm.query=$event.target.value}}}),_vm._v(" "),_c('button',{on:{"click":function($event){return _vm.handleEsc()}}},[_c('x-icon',{staticClass:"h-4 w-4 text-coconut-white-700 opacity-50 responsive:hover:opacity-100 transition-all"})],1)])]),_vm._v(" "),_c('div',{staticClass:"p-4 flex flex-row items-center space-x-2"},[_c('div',{staticClass:"filter-pill group",class:{
          pressed: _vm.filterOnlyVideos,
        },on:{"click":function($event){return _vm.toggleVideoFilter()}}},[_c('stack-video-icon',{staticClass:"h-4 w-4"}),_vm._v(" "),_c('span',{staticClass:"body-text3"},[_vm._v("Show only videos")])],1),_vm._v(" "),_c('div',{staticClass:"filter-pill group",class:{
          pressed: _vm.filterOnlyPlaylists,
        },on:{"click":function($event){return _vm.togglePlaylistFilter()}}},[_c('playlist-fill-icon',{staticClass:"h-4 w-4"}),_vm._v(" "),_c('span',{staticClass:"body-text3"},[_vm._v("Show only playlists")])],1)]),_vm._v(" "),_c('div',{ref:"searchResults",staticClass:"p-0 spiti-scroll overflow-x-hidden",style:({
        'max-height': _vm.isMobile ? '100%' : (_vm.searchBodyHeight + "px"),
        height: _vm.isMobile && '100%',
      })},[(!_vm.query || _vm.query === '')?_c('div',{staticClass:"px-4 pt-10 pb-14 flex flex-col space-y-2 text-coconut-white-600 text-center"},[_c('span',{staticClass:"heading-title4"},[_vm._v("Search across your workspace")]),_vm._v(" "),_c('span',{staticClass:"body-text2"},[_vm._v("Search for videos, playlists, transcripts and in descriptions")])]):(_vm.totalResults === null)?_c('div',{staticClass:"px-4 pt-10 pb-14 flex flex-col items-center justify-center"},[_c('img',{staticClass:"h-12 w-12 animate-spin",attrs:{"src":require("assets/img/icons/loading.svg"),"alt":"loading"}})]):(_vm.totalResults === 0 && _vm.totalResults !== null)?_c('div',{staticClass:"px-4 pt-10 pb-14 flex flex-col space-y-2 text-coconut-white-600 text-center"},[_c('span',{staticClass:"heading-title4"},[_vm._v("Oops! No results found")]),_vm._v(" "),_c('span',{staticClass:"body-text2"},[_vm._v("It seems like you're searching for something not added to Spiti\n          yet.")])]):_c('div',{staticClass:"flex flex-col space-y-2 body-text3 text-coconut-white-700"},[_c('span',{staticClass:"px-6"},[_vm._v("Search results for “"+_vm._s(_vm.query)+"”")]),_vm._v(" "),(
            (_vm.filterOnlyVideos || !_vm.filterOnlyPlaylists) &&
            _vm.groupedVideoSearchResults.length > 0
          )?_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"px-6 py-2 text-coconut-white-700 body-subtitle2"},[_vm._v("Videos")]),_vm._v(" "),_vm._l((_vm.groupedVideoSearchResults),function(video,videoIdx){return _c('div',{key:("ws-" + (video.id)),ref:("ws-" + (video.id)),refInFor:true,staticClass:"px-6 py-2 flex flex-row space-x-2 cursor-pointer",class:_vm.videoCursor === videoIdx ? 'search-item-hover' : 'search-item',on:{"click":function($event){return _vm.navigateToVideo(video)},"mouseenter":function($event){return _vm.onHoverVideo(videoIdx)}}},[_c('div',{staticClass:"flex-shrink-0 h-14 w-24"},[_c('img',{staticClass:"h-full w-full object-cover",attrs:{"src":video.thumbnail}})]),_vm._v(" "),_c('div',{staticClass:"flex-grow flex flex-col space-y-2 text-coconut-white-600"},[_c('div',{staticClass:"px-2 text-sm",domProps:{"innerHTML":_vm._s(_vm.sanitizeText(video.name))}}),_vm._v(" "),(
                  video.description &&
                  video.description.length > 0 &&
                  (!video.transcripts || video.transcripts.length === 0)
                )?_c('div',{staticClass:"px-2 body-text3 line-clamp-2",domProps:{"innerHTML":_vm._s(_vm.sanitizeText(video.description))}}):_vm._e(),_vm._v(" "),(video.transcripts && video.transcripts.length > 0)?_c('div',{staticClass:"flex flex-col"},_vm._l((video.transcripts),function(transcript,transcriptIdx){return _c('div',{key:transcript.id,staticClass:"flex flex-row items-center space-x-2 px-2 py-1",class:_vm.videoCursor === videoIdx &&
                    _vm.transcriptCursor === transcriptIdx
                      ? 'transcript-item-hover'
                      : 'transcript-item',on:{"click":function($event){$event.stopPropagation();return _vm.navigateToTranscript(video, transcript)},"mouseenter":function($event){return _vm.onHoverTranscript(videoIdx, transcriptIdx)}}},[_c('div',{staticClass:"body-text4 transcript-time px-1 py-0.5 flex-shrink-0 flex items-center justify-center w-10"},[_vm._v("\n                    "+_vm._s(_vm.formatTranscriptStartTime(transcript.startTime))+"\n                  ")]),_vm._v(" "),_c('div',{staticClass:"body-text3",domProps:{"innerHTML":_vm._s(_vm.sanitizeText(transcript.text))}})])}),0):_vm._e()])])})],2):_vm._e(),_vm._v(" "),(
            (_vm.filterOnlyPlaylists || !_vm.filterOnlyVideos) &&
            _vm.playlistSearchResults &&
            _vm.playlistSearchResults.length > 0
          )?_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"px-6 py-2 text-coconut-white-700 body-subtitle2"},[_vm._v("Playlists")]),_vm._v(" "),_vm._l((_vm.playlistSearchResults),function(playlist,playlistIdx){return _c('div',{key:("ws-" + (playlist.id)),ref:("ws-" + (playlist.id)),refInFor:true,staticClass:"py-2 px-6 flex flex-row space-x-2 cursor-pointer",class:_vm.playlistCursor === playlistIdx
                ? 'search-item-hover'
                : 'search-item',on:{"click":function($event){return _vm.navigateToPlaylist(playlist)},"mouseenter":function($event){return _vm.onHoverPlaylist(playlistIdx)}}},[_c('div',{staticClass:"text-coconut-white-600 w-4 h-4"},[_c('playlist-fill-icon',{attrs:{"width":"16px","height":"16px"}})],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col space-y-2 text-coconut-white-600"},[_c('div',{staticClass:"body-text2 leading-4",domProps:{"innerHTML":_vm._s(_vm.sanitizeText(playlist.name))}}),_vm._v(" "),(playlist.description && playlist.description.length > 0)?_c('div',{staticClass:"body-text3 line-clamp-2",domProps:{"innerHTML":_vm._s(_vm.sanitizeText(playlist.description))}}):_vm._e()])])})],2):_vm._e()])]),_vm._v(" "),(_vm.query && _vm.query !== '' && _vm.totalResults > 0)?_c('div',{staticClass:"px-4 py-2 flex flex-row items-center space-x-1 border-t border-ebony-grey-200 border-opacity-50 text-coconut-white-700"},[_c('span',{staticClass:"heading-title4"},[_vm._v(_vm._s(_vm.totalResults))]),_vm._v(" "),_c('span',{staticClass:"body-text2"},[_vm._v("result"+_vm._s(_vm.totalResults > 1 ? "s" : ""))])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }