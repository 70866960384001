import events from "~/constants/events";
import { PROMPT_KEYS } from "~/components/";
import { mapGetters } from "vuex";

export default {
  methods: {
    /**
     * Helper methods to interact with the segment service
     */
    groupCall: function () {
      this.$analytics.group(this.groupId, {
        ...this.groupTraits,
        userId: this.userId,
      });
    },
    trackEventWithTraits: function (event, properties) {
      this.$analytics.track(event, properties, this.eventCtx);
    },
    trackEvent: function (event) {
      this.trackEventWithTraits(event, {});
    },

    /**
     * Tracking workspace events
     */
    trackNewWorkspaceCreated() {
      this.groupCall();
      this.trackEvent(events.WORKSPACE_CREATED);
    },
    trackWorkspaceJoined() {
      this.groupCall();
      this.trackEvent(events.WORKSPACE_JOINED);
    },
    trackWorkspaceUpgraded(from, to) {
      this.trackEventWithTraits(events.WORKSPACE_UPGRADED, { from, to });
    },
    trackWorkspaceDowngraded(from, to) {
      this.trackEventWithTraits(events.WORKSPACE_DOWNGRADED, { from, to });
    },
    trackWorkspaceMetaUpdated(fields) {
      this.trackEvent(events.WORKSPACE_META_UPDATED, { fields });
    },
    trackWorkspaceInviteLinkCopied() {
      this.trackEvent(events.WORKSPACE_INVITE_LINK_COPIED);
    },
    trackWorkspaceDomainsUpdated() {
      this.trackEvent(events.WORKSPACE_DOMAINS_UPDATED);
    },
    trackUserRemovedFromWorkspace(affectedUser) {
      this.trackEventWithTraits(events.USER_REMOVED_FROM_WORKSPACE, {
        affectedUser,
      });
    },
    trackWorkspaceMemberRoleUpdated(affectedUser) {
      this.trackEventWithTraits(events.WORKSPACE_MEMBER_ROLE_UPDATED, {
        affectedUser,
      });
    },

    /**
     * Tracking Spiti App installations – Zoom, Slack, Google Drive
     */
    trackZoomAppInstalled() {
      this.trackEvent(events.ZOOM_APP_INSTALLED);
    },
    trackDriveAppInstalled() {
      this.trackEvent(events.DRIVE_APP_INSTALLED);
    },
    trackSlackAppInstalled() {
      this.trackEvent(events.SLACK_APP_INSTALLED);
    },

    /**
     * Track playlist events
     */
    trackPlaylistCreated(playlistId) {
      this.trackEventWithTraits(events.PLAYLIST_CREATED, { playlistId });
    },
    trackPlaylistLocked(playlistId) {
      this.trackEventWithTraits(events.PLAYLIST_LOCKED, { playlistId });
    },
    trackPlaylistMetaUpdated(playlistId, fields) {
      this.trackEventWithTraits(events.PLAYLIST_META_UPDATED, {
        playlistId,
        fields,
      });
    },
    trackPlaylistDeleted(playlistId) {
      this.trackEventWithTraits(events.PLAYLIST_DELETED, { playlistId });
    },
    trackPlaylistVideoListUpdated(playlistId) {
      this.trackEventWithTraits(events.PLAYLIST_VIDEO_LIST_UPDATED, {
        playlistId,
      });
    },
    trackPlaylistMadePublic(playlistId) {
      this.trackEventWithTraits(events.PLAYLIST_MADE_PUBLIC, { playlistId });
    },
    trackPublicPlaylistLinkCopied(playlistId) {
      this.trackEventWithTraits(events.PUBLIC_PLAYLIST_LINK_COPIED, {
        playlistId,
      });
    },
    trackEmbedPlaylistLinkCopied(playlistId) {
      this.trackEventWithTraits(events.EMBED_PLAYLIST_LINK_COPIED, {
        playlistId,
      });
    },
    trackPlaylistSpitiConnectLinkCopied(playlistId) {
      this.trackEventWithTraits(events.PLAYLIST_SPITI_CONNECT_LINK_COPIED, {
        playlistId,
      });
    },
    trackPlaylistSpitiConnectInviteSent(playlistId) {
      this.trackEventWithTraits(events.PLAYLIST_SPITI_CONNECT_INVITE_SENT, {
        playlistId,
      });
    },
    trackPlaylistSpitiConnectInviteResent(playlistId) {
      this.trackEventWithTraits(events.PLAYLIST_SPITI_CONNECT_INVITE_RESENT, {
        playlistId,
      });
    },
    trackPlaylistSpitiConnectInviteRevoked(playlistId) {
      this.trackEventWithTraits(events.PLAYLIST_SPITI_CONNECT_INVITE_REVOKED, {
        playlistId,
      });
    },
    trackPlaylistSpitiConnectAccessRevoked(playlistId) {
      this.trackEventWithTraits(events.PLAYLIST_SPITI_CONNECT_ACCESS_REVOKED, {
        playlistId,
      });
    },
    trackPlaylistSpitiConnectInviteAccepted(playlistId) {
      this.trackEventWithTraits(events.PLAYLIST_SPITI_CONNECT_INVITE_ACCEPTED, {
        playlistId,
      });
    },
    trackPlaylistACLUpdated(playlistId) {
      this.trackEventWithTraits(events.PLAYLIST_ACL_UPDATED, { playlistId });
    },

    /**
     * Track AI Summary events
     */

    trackAISummaryUpdates(event, summaryId, props) {
      this.trackEventWithTraits(event, {
        summaryId,
        ...props,
      });
    },
    trackAISummaryFeedbacks(summaryId, feedbackId, props) {
      this.trackEventWithTraits(events.SUMMARY_FEEDBACK_GIVEN, {
        summaryId,
        feedbackId,
        ...props,
      });
    },

    /**
     * Track Ask Spiti events
     *
     */
    trackAskSpitiVideoQuestionAnswered(videoId, props) {
      this.trackEventWithTraits(events.ASK_SPITI_VIDEO_QUESTION_ANSWERED, {
        videoId,
        ...props,
      });
    },
    trackAskSpitiVideoAnswerRegenerated(videoId, props) {
      this.trackEventWithTraits(events.ASK_SPITI_VIDEO_ANSWER_REGENERATED, {
        videoId,
        ...props,
      });
    },
    trackAskSpitiVideoAnswerCopied(videoId, props) {
      this.trackEventWithTraits(events.ASK_SPITI_VIDEO_ANSWER_COPIED, {
        videoId,
        ...props,
      });
    },
    trackAskSpitiBookmarkAdded(videoId, bookmarkId, userId) {
      this.trackEventWithTraits(events.ASK_SPITI_BOOKMARK_ADDED, {
        videoId,
        bookmarkId,
        userId,
      });
    },
    trackAskSpitiBookmarkRemoved(videoId, bookmarkId, userId) {
      this.trackEventWithTraits(events.ASK_SPITI_BOOKMARK_REMOVED, {
        videoId,
        bookmarkId,
        userId,
      });
    },
    trackAskSpitiBookmarkPrivacyToggled(
      videoId,
      bookmarkId,
      userId,
      isPrivate,
    ) {
      if (isPrivate) {
        this.trackEventWithTraits(events.ASK_SPITI_BOOKMARK_PRIVATE, {
          videoId,
          bookmarkId,
          userId,
        });
      } else {
        this.trackEventWithTraits(events.ASK_SPITI_BOOKMARK_PUBLIC, {
          videoId,
          bookmarkId,
          userId,
        });
      }
    },
    /**
     * Track video events
     */
    trackVideoImported(videoId, props) {
      this.trackEventWithTraits(events.VIDEO_IMPORTED, { videoId, ...props });
    },
    trackVideoDeleted(videoId) {
      this.trackEventWithTraits(events.VIDEO_DELETED, { videoId });
    },
    trackVideoMetaUpdated(videoId, fields) {
      this.trackEventWithTraits(events.VIDEO_META_UPDATED, { videoId, fields });
    },
    trackVideoMadePublic(videoId) {
      this.trackEventWithTraits(events.VIDEO_MADE_PUBLIC, { videoId });
    },
    trackPublicVideoLinkCopied(videoId) {
      this.trackEventWithTraits(events.PUBLIC_VIDEO_LINK_COPIED, { videoId });
    },
    trackEmbedVideoLinkCopied(videoId) {
      this.trackEventWithTraits(events.EMBED_VIDEO_LINK_COPIED, { videoId });
    },
    trackVideoPublished(videoId) {
      this.trackEventWithTraits(events.VIDEO_PUBLISHED, { videoId });
    },
    trackVideoLiked(videoId) {
      this.trackEventWithTraits(events.VIDEO_LIKED, { videoId });
    },
    trackVideoCommented(videoId) {
      this.trackEventWithTraits(events.VIDEO_COMMENTED, { videoId });
    },
    trackVideoChaptersEdited(videoId) {
      this.trackEventWithTraits(events.VIDEO_CHAPTERS_EDITED, { videoId });
    },
    trackVideoTranscriptEdited(videoId) {
      this.trackEventWithTraits(events.VIDEO_TRANSCRIPT_EDITED, { videoId });
    },
    trackVideoTranscriptSpeakerLabelsEdited(videoId) {
      this.trackEventWithTraits(events.VIDEO_TRANSCRIPT_SPEAKER_LABELS_EDITED, {
        videoId,
      });
    },
    trackVideoTranscriptCopied(videoId) {
      this.trackEventWithTraits(events.VIDEO_TRANSCRIPT_COPIED, {
        videoId,
      });
    },

    /**
     * Track screen recorder events
     */
    trackScreenRecorderDownloaded(platform, arch) {
      this.trackEvent(events.SCREEN_RECORDER_DOWNLOADED, { platform, arch });
    },

    /**
     * Misc events - e.g. user profile, settings, notifications, advanced search etc.
     */
    trackBellIconClicked() {
      this.trackEvent(events.BELL_ICON_CLICKED);
    },
    trackNotificationItemClicked() {
      this.trackEvent(events.NOTIFICATION_ITEM_CLICKED);
    },
    trackAdvancedSearchClicked() {
      this.trackEvent(events.ADVANCED_SEARCH_CLICKED);
    },
    trackWebAppVisited() {
      this.trackEvent(events.WEB_APP_VISITED);
    },
    trackMagicVideoLinkCopied() {
      this.trackEvent(events.MAGIC_VIDEO_LINK_COPIED);
    },
  },
  computed: {
    ...mapGetters({
      viewer: "auth/viewer",
      defaultWorkspace: "workspace/defaultWorkspace",
      isSubscriptionActive: "billing/isActive",
      currentSubscription: "billing/subscription",
    }),
    userId: function () {
      return this.viewer?.uid;
    },
    groupId: function () {
      return this.defaultWorkspace?.id;
    },
    groupTraits: function () {
      if (!this.defaultWorkspace) {
        return {};
      }

      const {
        name,
        numVideos: videos,
        numPlaylists: playlists,
        numPublicPlaylists: publicPlaylists,
        members: users,
        videoMinutes: videoMinutes,
      } = this.defaultWorkspace;
      return {
        name,
        videos,
        playlists,
        publicPlaylists,
        users,
        videoMinutes,
        plan: this.currentPlan,
      };
    },
    eventCtx: function () {
      return {
        groupId: this.groupId,
      };
    },
    currentPlan: function () {
      if (this.isSubscriptionActive) {
        return this.currentSubscription?.plan?.toLowerCase();
      }
      return "free";
    },
  },
};
