<template>
  <div class="relative">
    <button
      class="flex flex-row items-center justify-center avatar-actionable rounded-sm md:h-9 md:w-9 w-7 h-7 focus:outline-none"
      @click="isOpen = !isOpen"
      :class="{ active: isOpen }"
    >
      <div
        class="relative z-10 block md:w-8 md:h-8 w-6 h-6 m-0.5 rounded-full overflow-hidden focus:outline-none"
      >
        <img
          class="h-full w-full object-cover"
          v-if="avatarUrl"
          :src="avatarUrl"
          :alt="displayName"
        />

        <div
          class="md:w-8 md:h-8 w-6 h-6 object-cover border rounded-full uppercase border-pastel-brown text-coconut-white-default bg-slate-grey-default flex items-center justify-center"
          v-if="!avatarUrl && displayName"
          :class="{ 'body-subtitle2': !isMobile, 'body-subtitle3': isMobile }"
        >
          {{ getInitials(displayName) }}
        </div>
      </div>
    </button>
    <button
      v-if="isOpen"
      tabindex="-1"
      @click="isOpen = false"
      class="fixed inset-0 h-full w-full opacity-0 cursor-default"
    ></button>
    <div v-if="isOpen" class="absolute md:top-10 top-8 right-0">
      <div
        class="w-48 bg-coal-pop-default text-coconut-white-500 body-text2 border-coal-pop-default rounded flex flex-1 flex-col"
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          class="menu-item h-10 rounded-t border-b border-ebony-grey-200 border-opacity-50"
          href="https://spiti.xyz/public-playlists/mZqOqHRxiNnKg6mbEuOC"
        >
          <youtube-icon class="responsive-icon"></youtube-icon>
          <span> Demo</span>
        </a>
        <nuxt-link class="menu-item h-10" to="/docs">
          <HelpCircleIcon class="responsive-icon" />
          <span>FAQs</span>
        </nuxt-link>
        <button class="menu-item h-10" @click="toggleChat()">
          <MessageSquareIcon class="responsive-icon" />
          <span>Chat with us</span>
        </button>
        <button
          class="menu-item h-10 rounded-b border-t border-ebony-grey-200 border-opacity-50"
          @click="signout()"
        >
          <log-out-icon class="responsive-icon"></log-out-icon>
          <span>Sign out</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { YoutubeIcon } from "vue-feather-icons";
import {
  LogOutIcon,
  HelpCircleIcon,
  MessageSquareIcon,
} from "@spitilabs/icon-library";
import { getInitials } from "~/utils/formatters";
import { mapGetters } from "vuex";
export default {
  components: {
    HelpCircleIcon,
    LogOutIcon,
    YoutubeIcon,
    MessageSquareIcon,
  },
  props: {
    avatarUrl: {
      type: String,
      required: false,
    },
    displayName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  mounted() {
    const handleEscape = (e) => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.isOpen = false;
      }
    };
    document.addEventListener("keydown", handleEscape);
    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", handleEscape);
    });
  },
  computed: {
    ...mapGetters({
      isMobile: "isScreenSmall",
    }),
  },
  methods: {
    toggleChat() {
      this.isOpen = false;
    },
    async signout() {
      /**
       * Log the current user out and redirect them to the login page.
       */
      await this.$store.dispatch("auth/signout");
      this.$router.push("/");
    },
    getInitials,
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  @apply relative z-10 flex w-9 h-9 rounded-full overflow-hidden;

  &-actionable {
    @media (hover: hover) {
      &:hover {
        @apply bg-slate-grey-200;
      }
    }
    &:active,
    &.active {
      @apply bg-slate-grey-100;
    }
  }
}
.menu-item {
  @apply pl-3 space-x-2 active:text-coconut-white-default flex flex-row items-center responsive:hover:bg-coal-pop-200 transition-all;
}
.responsive-icon {
  @apply h-4 w-4;
  @media (min-width: 640px) {
    width: 18px;
    height: 18px;
  }
}
</style>
