<template>
  <div
    class="bg-space-blue-default h-screen overflow-y-auto w-full p-8 flex flex-col"
  >
    <div class="flex">
      <img src="~/assets/img/logos/spiti-long.svg" alt="Spiti" class="h-8" />
    </div>
    <div class="flex justify-center pt-7 md:pt-0 pb-14" v-if="intent">
      <step-progress-bar :total="totalSteps" :current="currentStep" />
    </div>
    <article class="py-16 h-full" v-if="intent">
      <Nuxt />
    </article>
  </div>
</template>

<script>
import StepProgressBar from "~/components/loaders/StepProgressBar.vue";
import { ONBOARDING as ONBOARDING_INTENT } from "~/types/intents";

const ONBOARDING_INTENT_COOKIE = "onboarding_intent";
const PRODUCT_TOUR_COOKIE = "product_tour";

export default {
  name: "onboarding",
  components: {
    StepProgressBar,
  },
  data() {
    return {
      intent: null,
    };
  },
  created() {
    const { intent } = this.$route.params;
    this.intent = intent || ONBOARDING_INTENT.CREATE_WORKSPACE;
  },
  mounted() {
    this.$cookie.set(ONBOARDING_INTENT_COOKIE, this.intent, {
      expires: "5m",
    });
    this.$cookie.set(PRODUCT_TOUR_COOKIE, true, {
      expires: "20m",
    });
  },
  computed: {
    totalSteps() {
      return this.intent === ONBOARDING_INTENT.CREATE_WORKSPACE ? 4 : 3;
    },
    currentStep() {
      switch (this.$route.name) {
        case "onboarding-create-ws":
          return 1;
        case "onboarding-connect":
          return this.intent === ONBOARDING_INTENT.CREATE_WORKSPACE ? 2 : 1;
        case "onboarding-import":
          return this.intent === ONBOARDING_INTENT.CREATE_WORKSPACE ? 3 : 2;
        case "onboarding-publish":
          return this.intent === ONBOARDING_INTENT.CREATE_WORKSPACE ? 4 : 3;
        default:
          return 1;
      }
    },
  },
};
</script>
