import { COLLECTIONS } from ".";
import { invokeMap, keys, pick } from "lodash";
import { firestoreAction } from "vuexfire";
import { firestoreDocumentSerializer } from "~/utils/serializers";
import { mergeFirestoreDocuments } from "~/utils/merge";

/**
 * ALLOWED_UPDATE_FIELDS is a list of data properties that can (only) be updated by the user.
 * Firestore security rules anyway prevent the user from updating any other properties. This is just a safety measure.
 */
const ALLOWED_UPDATE_FIELDS = ["name", "shortcode", "autoLoginDomains"];

/**
 * Initial state of the video store.
 */
export const state = () => ({
  workspaces: [],
  members: [],
  workspacePlaylists: null,
  userPlaylists: null,
  workspaceFeed: null,
  userFeed: null,
  onboardingVideos: [],
});

/**
 * Getters are used to retrieve data from the store.
 * Pages and components can use these getters to retrieve data and whenever accessed, the data is assumed to always be up-to-date.
 */
export const getters = {
  /**
   * Return a list of workspaces under the current user.
   */
  workspaces: (state) => {
    return state.workspaces;
  },

  /**
   * Returns the first workspace of the current user.
   */
  defaultWorkspace: (state) => {
    return state.workspaces[0];
  },

  /**
   * Returns the playlists in the current workspace.
   */
  playlists: (state) => {
    const { workspacePlaylists, userPlaylists } = state;
    if (!workspacePlaylists && !userPlaylists) {
      return null;
    }
    return mergeFirestoreDocuments(
      workspacePlaylists || [],
      userPlaylists || [],
    );
  },

  /**
   * Returns all members of the current workspace.
   */
  members: (state) => {
    return state.members;
  },

  /**
   * Returns a list of videos in the current workspace's feed.
   */
  feed: (state) => {
    const { workspaceFeed, userFeed } = state;
    if (!workspaceFeed && !userFeed) {
      return null;
    }
    const filteredUserFeed = userFeed
      ? userFeed.filter(
          (video) =>
            video.acl.workspaceIds.length !== 0 || video.acl.userIds.length > 1,
        )
      : [];
    return mergeFirestoreDocuments(workspaceFeed || [], filteredUserFeed || []);
  },

  /**
   * Returns onboarding videos (if not dismissed yet) added by Spiti Bot in the current workspace.
   */
  onboardingVideos: (state) => {
    return state.onboardingVideos;
  },

  /**
   * Returns true if the current workspace has Slack app installed.
   */
  isSlackConfigured: (state) => {
    return state.workspaces[0]?.integrations?.slack;
  },
};

/**
 * Actions can be considered as an entry point to modify the state.
 * Infact actions are the only methods exposed to pages and components to help modify the state.
 */
export const actions = {
  /**
   * "getWorkspaces" action is used to retrieve all workspaces the current user is a member of.
   *
   * Note:
   * This function doesnot rely on the firestore services, but instead accesses the firestore sdk directly.
   * This is because getWorkspaces action is triggered on auth state change by firebase auth, by which time serivces are not yet initialized.
   *
   * Todo:
   * This function should be refactored to use firestore services.
   */
  async getWorkspaces({ commit }, userUid) {
    const thisUser = await this.$fire.firestore
      .collection(COLLECTIONS.USERS)
      .doc(userUid)
      .get();
    const workspaceIds = keys(
      thisUser.data() ? thisUser.data().workspaces : {},
    );
    if (!workspaceIds.length) {
      return;
    }
    const workspacesSnapshot = await this.$fire.firestore
      .collection(COLLECTIONS.WORKSPACES)
      .where(
        this.$fireModule.firestore.FieldPath.documentId(),
        "in",
        workspaceIds,
      )
      .get();
    const workspaces = invokeMap(workspacesSnapshot.docs, "data");

    // add ids to workspace objects that we'll need later
    for (let i = 0; i < workspaces.length; i++) {
      const workspace = workspaces[i];
      workspace.id = workspaceIds[i];
    }
    commit("SET_WORKSPACES", workspaces);
  },

  /**
   * "bindWorkspaces" action is used to listen in real-time to all workspace updates the current user is a member of.
   *
   * Note:
   * This function doesnot rely on the firestore services, but instead accesses the firestore sdk directly.
   * This is because getWorkspaces action is triggered on auth state change by firebase auth, by which time serivces are not yet initialized.
   *
   * Todo:
   * This function should be refactored to use firestore services.
   */
  bindWorkspaces: firestoreAction(async function ({ bindFirestoreRef }, user) {
    const thisUser = await this.$fire.firestore
      .collection(COLLECTIONS.USERS)
      .doc(user.uid)
      .get();
    const workspaceIds = keys(thisUser.data().workspaces);
    if (!workspaceIds.length) {
      return;
    }
    await bindFirestoreRef(
      "workspaces",
      this.$fire.firestore
        .collection(COLLECTIONS.WORKSPACES)
        .where(
          this.$fireModule.firestore.FieldPath.documentId(),
          "in",
          workspaceIds,
        ),
      {
        wait: true,
        serialize: firestoreDocumentSerializer,
      },
    );
  }),

  /**
   * "unbindWorkspaces" action is used to break the listener to the user workspaces.
   */
  unbindWorkspaces: firestoreAction(function ({ unbindFirestoreRef }) {
    unbindFirestoreRef("workspaces", false);
  }),

  /**
   * "bindPlaylists" action is used to listen to the current workspace's playlists.
   *
   * @param {String} workspaceId refers to the workspace id of the current workspace.
   */
  bindPlaylists: firestoreAction(async function (
    { bindFirestoreRef },
    { userId, workspaceId },
  ) {
    await Promise.all([
      bindFirestoreRef(
        "workspacePlaylists",
        this.$playlistService.fetchWorkspacePlaylistsQuery(workspaceId),
        {
          wait: true,
          serialize: firestoreDocumentSerializer,
        },
      ),
      bindFirestoreRef(
        "userPlaylists",
        this.$playlistService.fetchUserPlaylistsQuery(userId, workspaceId),
        {
          wait: true,
          serialize: firestoreDocumentSerializer,
        },
      ),
    ]);
  }),

  /**
   * "unbindPlaylists" action is used to break the listener to the playlists in the current workspace.
   */
  unbindPlaylists: firestoreAction(function ({ unbindFirestoreRef }) {
    unbindFirestoreRef("workspacePlaylists", false);
    unbindFirestoreRef("userPlaylists", false);
  }),

  /**
   * "updateWorkspace" action is used to update one (or more) data properties of the workspace in the database.
   * Before updating the database, it does a check to see if only allowed fields are being updated.
   *
   * @param {String} workspaceId refers to the workspace to be updated.
   * @param {Map} fields with updated values
   */
  async updateWorkspace({ dispatch, rootGetters }, { workspaceId, fields }) {
    const parsedFields = pick(fields, ALLOWED_UPDATE_FIELDS);
    await this.$workspaceService.updateWorkspace(workspaceId, parsedFields);
    await dispatch("getWorkspaces", rootGetters["auth/viewer"].uid);
  },

  /**
   * "bindMembers" action is used to listen to
   *
   * @param {String} workspaceId refers to the workspace id of the current workspace.
   */
  bindMembers: firestoreAction(async function (
    { bindFirestoreRef },
    workspaceId,
  ) {
    await bindFirestoreRef(
      "members",
      this.$workspaceService.fetchWorkspaceMembersQuery(workspaceId),
      {
        wait: true,
        serialize: firestoreDocumentSerializer,
      },
    );
  }),

  /**
   * "unbindMembers" action is used to break the listener to the members collection of a workspace.
   */
  unbindMembers: firestoreAction(function ({ unbindFirestoreRef }) {
    unbindFirestoreRef("members", false);
  }),

  /**
   * "bindVideoFeed" action is used to listen to videos published in the current workspace by workspace members.
   *
   * @param {String} workspaceId refers to the workspace id of the current workspace.
   * @param {String} userId refers to the logged in user
   */
  bindVideoFeed: firestoreAction(async function (
    { bindFirestoreRef },
    { workspaceId, userId },
  ) {
    const workspaceFeed = bindFirestoreRef(
      "workspaceFeed",
      this.$videoService.fetchWorkspaceFeedQuery(workspaceId),
      {
        wait: true,
        serialize: firestoreDocumentSerializer,
      },
    );
    const userFeed = bindFirestoreRef(
      "userFeed",
      this.$videoService.fetchUserFeedQuery(userId),
      {
        wait: true,
        serialize: firestoreDocumentSerializer,
      },
    );
    Promise.allSettled([workspaceFeed, userFeed]);
  }),

  /**
   * "unbindVideoFeed" action is used to break the listener to videos published in the current workspace by workspace members.
   */
  unbindVideoFeed: firestoreAction(function ({ unbindFirestoreRef }) {
    unbindFirestoreRef("workspaceFeed", false);
    unbindFirestoreRef("userFeed", false);
  }),

  /**
   * "fetchOnboardingVideos" action is used to fetch the videos that are added to the current workspace at the time of onboarding by Spiti Bot.
   *
   * Note: These videos are dismissible. Meaning if one of the workspace members dismisses the onboarding playlist, the videos will not be shown again.
   */
  async fetchOnboardingVideos({ commit, rootGetters }) {
    const playlists = rootGetters["workspace/playlists"];
    const onboardingPlaylist = (playlists || []).find(
      (each) => each.dismissible,
    );
    if (!onboardingPlaylist?.videos?.length) {
      return;
    }

    const videoRefs = onboardingPlaylist.videos.map((videoId) =>
      this.$fire.firestore.collection(COLLECTIONS.VIDEOS).doc(videoId).get(),
    );
    const videoMap = {};
    const videoDocs = await Promise.all(videoRefs);
    videoDocs.forEach(function (doc) {
      const video = doc.data();
      video.id = doc.id;
      video.playlists = [onboardingPlaylist.id];
      videoMap[doc.id] = video;
    });

    // Preserve the order of the videos in the playlist
    const videos = [];
    onboardingPlaylist.videos.forEach((videoId) => {
      videoMap[videoId] && videos.push(videoMap[videoId]);
    });
    commit("SET_ONBOARDING_VIDEOS", videos);
  },

  /**
   * "clearOnboardingVideos" action removes the onboarding videos from the workspace store.
   * This action is triggered once the onboarding playlist is deleted by the user.
   *
   * Note: This action is called from the playlist store as a result of the playlist being deleted.
   */
  async clearOnboardingVideos({ commit }) {
    commit("SET_ONBOARDING_VIDEOS", []);
  },

  /**
   *
   * "clearStore" action sets the state of this store to the initial state.
   * This actions also unbinds any firestore query listeners.
   */
  async clearStore({ commit, dispatch }) {
    await dispatch("unbindVideoFeed");
    await dispatch("unbindWorkspaces");
    await dispatch("unbindPlaylists");
    await dispatch("unbindMembers");
    commit("CLEAR_STATE");
  },
};

/**
 * Mutations are used to update the state of the store and to be used only by actions.
 * Consider mutations as helper functions to actions.
 *
 * Note: Always add a default value to the mutation (like below). This helps prevent the state properties from being undefined.
 */
export const mutations = {
  /**
   * "SET_WORKSPACES" mutation is used to set (replace) the workspaces associated with the current user.
   */
  SET_WORKSPACES(state, data) {
    state.workspaces = data || [];
  },

  /**
   * "SET_MEMBERS" mutation is used to set (replace) the members in the current workspace.
   */
  SET_MEMBERS(state, data) {
    state.members = data || [];
  },

  /**
   * "SET_ONBOARDING_VIDEOS" mutation is used to set (replace) the onboarding videos added to the current workspace by Spiti Bot.
   */
  SET_ONBOARDING_VIDEOS(state, data) {
    state.onboardingVideos = data || [];
  },

  /**
   * "CLEAR_STATE" mutation is used to set the state back to how it was when the application was started.
   */
  CLEAR_STATE(state) {
    state.workspaces = [];
    state.workspacePlaylists = [];
    state.userPlaylists = [];
    state.workspaceFeed = [];
    state.userFeed = [];
    state.members = [];
    state.onboardingVideos = [];
  },
};
