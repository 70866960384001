const VIDEO_IMPORT = {
  DEFAULT: "default",
  ONBOARDING: "onboarding",
};

const ONBOARDING = {
  CREATE_WORKSPACE: "create_workspace",
  JOIN_WORKSPACE: "join_workspace",
};

module.exports = {
  VIDEO_IMPORT,
  ONBOARDING,
};
