<template>
  <div :class="pulse ? 'heart' : 'stale'">
    <svg
      v-if="gradient"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2L6.72533 5.87533C6.6601 6.07367 6.5492 6.25392 6.40156 6.40156C6.25392 6.5492 6.07367 6.6601 5.87533 6.72533L2 8L5.87533 9.27467C6.07367 9.3399 6.25392 9.4508 6.40156 9.59844C6.5492 9.74608 6.6601 9.92633 6.72533 10.1247L8 14L9.27467 10.1247C9.3399 9.92633 9.4508 9.74608 9.59844 9.59844C9.74608 9.4508 9.92633 9.3399 10.1247 9.27467L14 8L10.1247 6.72533C9.92633 6.6601 9.74608 6.5492 9.59844 6.40156C9.4508 6.25392 9.3399 6.07367 9.27467 5.87533L8 2Z"
        fill="url(#paint0_linear_7268_63682)"
        stroke="url(#paint1_linear_7268_63682)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M3.33301 2V4.66667V2Z" fill="url(#paint2_linear_7268_63682)" />
      <path
        d="M3.33301 2V4.66667"
        stroke="url(#paint3_linear_7268_63682)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.667 11.334V14.0007"
        stroke="url(#paint4_linear_7268_63682)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 3.33398H4.66667"
        stroke="url(#paint5_linear_7268_63682)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.333 12.666H13.9997"
        stroke="url(#paint6_linear_7268_63682)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7268_63682"
          x1="1.05263"
          y1="5.24138"
          x2="12.8991"
          y2="5.98621"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1997A4" />
          <stop offset="1" stop-color="#5C40D0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7268_63682"
          x1="1.05263"
          y1="5.24138"
          x2="12.8991"
          y2="5.98621"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1997A4" />
          <stop offset="1" stop-color="#5C40D0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7268_63682"
          x1="3.25406"
          y1="2.72031"
          x2="4.24462"
          y2="2.74366"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1997A4" />
          <stop offset="1" stop-color="#5C40D0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7268_63682"
          x1="3.25406"
          y1="2.72031"
          x2="4.24462"
          y2="2.74366"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1997A4" />
          <stop offset="1" stop-color="#5C40D0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7268_63682"
          x1="12.588"
          y1="12.0543"
          x2="13.5786"
          y2="12.0776"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1997A4" />
          <stop offset="1" stop-color="#5C40D0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_7268_63682"
          x1="1.78947"
          y1="3.6041"
          x2="4.36017"
          y2="4.03511"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1997A4" />
          <stop offset="1" stop-color="#5C40D0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_7268_63682"
          x1="11.1225"
          y1="12.9361"
          x2="13.6932"
          y2="13.3671"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1997A4" />
          <stop offset="1" stop-color="#5C40D0" />
        </linearGradient>
      </defs>
    </svg>
    <svg
      v-else
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2L6.72533 5.87533C6.6601 6.07367 6.5492 6.25392 6.40156 6.40156C6.25392 6.5492 6.07367 6.6601 5.87533 6.72533L2 8L5.87533 9.27467C6.07367 9.3399 6.25392 9.4508 6.40156 9.59844C6.5492 9.74608 6.6601 9.92633 6.72533 10.1247L8 14L9.27467 10.1247C9.3399 9.92633 9.4508 9.74608 9.59844 9.59844C9.74608 9.4508 9.92633 9.3399 10.1247 9.27467L14 8L10.1247 6.72533C9.92633 6.6601 9.74608 6.5492 9.59844 6.40156C9.4508 6.25392 9.3399 6.07367 9.27467 5.87533L8 2Z"
        fill="#7366AB"
        stroke="#7366AB"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M3.33301 2V4.66667Z" fill="#7366AB" />
      <path
        d="M3.33301 2V4.66667"
        stroke="#7366AB"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.667 11.334V14.0007"
        stroke="#7366AB"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 3.33398H4.66667"
        stroke="#7366AB"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.333 12.666H13.9997"
        stroke="#7366AB"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "sparkles-pulse",
  props: {
    pulse: {
      type: Boolean,
      default: false,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.heart {
  &.md {
    width: 16px;
    height: 16px;
    animation: beat 0.5s infinite alternate;
  }
  transform-origin: center;
  svg {
    width: 16px;
    height: 16px;
  }
  &.sm {
    width: 12px;
    height: 12px;
    animation: beatsm 0.5s infinite alternate;
    svg {
      width: 12px;
      height: 12px;
    }
  }
  &.lg {
    width: 20px;
    height: 20px;
    animation: beatsm 0.5s infinite alternate;
    > svg {
      width: 20px;
      height: 20px;
    }
  }
}

.stale {
  width: 18px;
  height: 18px;
  opacity: 0.7;
  &.sm {
    width: 16px;
    height: 16px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  &.lg {
    opacity: 1;
    width: 28px;
    height: 28px;
    > svg {
      width: 28px;
      height: 28px;
    }
  }
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.2);
  }
}
@keyframes beatsm {
  to {
    transform: scale(1.3);
  }
}
</style>
