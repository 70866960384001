import {
  NOTIFICATION_STATUS,
  NOTIFICATION_TYPE,
} from "~/components/notifications/types";
import { COLLECTIONS } from "~/services/firebase/firestore/types";
import { firestoreDocumentSerializer } from "~/utils/serializers";

export default class {
  constructor($fire, $fireModule) {
    this.$fire = $fire;
    this.$fireModule = $fireModule;
  }

  /**
   * getSummary gets the summary document data from firestore
   * @param {String} summaryId as referenced in the firestore transcript collection
   * @returns summary document data from firestore
   */
  async getSummary(summaryId) {
    const summaryRef = await this.$fire.firestore
      .collection(COLLECTIONS.SUMMARIES)
      .doc(summaryId)
      .get();
    return this.parseSummary(summaryRef);
  }

  /**
   * parseSummary parses the summary document data from firestore
   *
   * uses firestoreDocumentSerializer to parse the document data
   *
   * @param {Object} summary document data from firestore
   * @returns parsed summary document data from firestore
   */
  parseSummary(summary) {
    // Parse according to structure
    return firestoreDocumentSerializer(summary);
  }

  /**
   * getSummaryByvideoId gets the latest summary document data for the given videoId from firestore
   * @param {String} videoId as referenced in the firestore transcript collection
   * @param {String} promptKey as referenced in the firestore transcript collection
   * @returns the latest summary document data for the given videoId from firestore
   * @returns empty object if no summary is found
   */
  async getSummaryByvideoId(videoId, promptKey) {
    const summaryRef = await this.$fire.firestore
      .collection(COLLECTIONS.SUMMARIES)
      .where("videoId", "==", videoId)
      .where("prompt.key", "==", promptKey)
      .orderBy("createdAt", "desc")
      .limit(1)
      .get();
    if (summaryRef.size > 0) {
      return this.parseSummary(summaryRef.docs[0]);
    }
    return null;
  }

  /**
   * getSummaryFeedback gets the summary feedback document data from firestore
   * @param {String} summaryId as referenced in the firestore transcript collection
   * @param {String} userId as referenced in the firestore transcript collection
   * @param {String} workspaceId as referenced in the firestore transcript collection
   * @returns summary feedback document data from firestore
   * @returns empty object if no summary feedback is found
   */
  async getSummaryFeedback(summaryId, userId, workspaceId) {
    const summaryFeedbackRef = await this.$fire.firestore
      .collection(COLLECTIONS.SUMMARIES)
      .doc(summaryId)
      .collection(COLLECTIONS.SUMMARY_FEEDBACK)
      .where("workspaceId", "==", workspaceId)
      .where("userId", "==", userId)
      .get();

    if (summaryFeedbackRef.size != 0) {
      return firestoreDocumentSerializer(summaryFeedbackRef.docs[0]);
    }
    return null;
  }

  /**
   * updateSummaryFeedback updates the summary feedback document data in firestore
   * @param {String} summaryId as referenced in the firestore transcript collection
   * @param {String} feedbackId as referenced in the firestore transcript collection
   * @param {String} feedback as referenced in the firestore transcript collection
   *
   */
  async updateSummaryFeedback(summaryId, feedbackId, feedback) {
    const summaryFeedbackRef = this.$fire.firestore
      .collection(COLLECTIONS.SUMMARIES)
      .doc(summaryId)
      .collection(COLLECTIONS.SUMMARY_FEEDBACK)
      .doc(feedbackId);

    await summaryFeedbackRef.update({
      feedback,
    });

    let summary = await summaryFeedbackRef.get();
    return firestoreDocumentSerializer(summary);
  }

  /**
   *
   * deleteSummaryFeedback deletes the summary feedback document data in firestore
   * @param {String} summaryId as referenced in the firestore transcript collection
   * @param {String} feedbackId as referenced in the firestore transcript collection
   *
   */
  //TODO data structure is unknown as of present we'll update this after we define the component
  async updateSummary(summaryId, text) {
    const summary = text;
    await this.$fire.firestore
      .collection(COLLECTIONS.SUMMARIES)
      .doc(summaryId)
      .update({
        summary,
        updatedAt: this.$fireModule.firestore.FieldValue.serverTimestamp(),
      });
    return this.getSummary(summaryId);
  }

  async deleteSummaryFeedback(summaryId, feedbackId) {
    await this.$fire.firestore
      .collection(COLLECTIONS.SUMMARIES)
      .doc(summaryId)
      .collection(COLLECTIONS.SUMMARY_FEEDBACK)
      .doc(feedbackId)
      .delete();
  }

  /**
   * createSummaryFeedback creates the summary feedback document data in firestore
   * @param {String} summaryId as referenced in the firestore transcript collection
   * @param {String} feedback as referenced in the firestore transcript collection
   * @param {String} userId as referenced in the firestore transcript collection
   * @param {String} workspaceId as referenced in the firestore transcript collection
   *
   */
  async createSummaryFeedback(summaryId, userId, workspaceId, feedback) {
    const payload = {
      feedback,
      userId,
      workspaceId,
      createdAt: this.$fireModule.firestore.FieldValue.serverTimestamp(),
    };

    const summaryRefId = await this.$fire.firestore
      .collection(COLLECTIONS.SUMMARIES)
      .doc(summaryId)
      .collection(COLLECTIONS.SUMMARY_FEEDBACK)
      .add(payload);

    payload.id = summaryRefId.id;

    return payload;
  }

  /**
   * markSummaryNotificationAsRead marks the summary notification as read in firestore
   */
  async markSummaryNotificationAsRead({
    userId,
    workspaceId,
    videoId,
    promptKeys,
  }) {
    const snapshot = await this.$fire.firestore
      .collection(COLLECTIONS.USERS)
      .doc(userId)
      .collection(COLLECTIONS.NOTIFICATIONS)
      .where("type", "==", NOTIFICATION_TYPE.SUMMARY_GENERATED)
      .where("status", "==", NOTIFICATION_STATUS.UNREAD)
      .where("workspaceId", "==", workspaceId)
      .where("payload.videoId", "==", videoId)
      .where("payload.promptKey", "in", promptKeys)
      .get();
    snapshot.docs.forEach(async (doc) => {
      await doc.ref.update({ status: NOTIFICATION_STATUS.READ });
    });
  }
}
