const middleware = {}

middleware['anonymous-only'] = require('../middleware/anonymous-only.js')
middleware['anonymous-only'] = middleware['anonymous-only'].default || middleware['anonymous-only']

middleware['auth-guard'] = require('../middleware/auth-guard.js')
middleware['auth-guard'] = middleware['auth-guard'].default || middleware['auth-guard']

middleware['mobile'] = require('../middleware/mobile.js')
middleware['mobile'] = middleware['mobile'].default || middleware['mobile']

middleware['notifications'] = require('../middleware/notifications.js')
middleware['notifications'] = middleware['notifications'].default || middleware['notifications']

middleware['third-party-redirects'] = require('../middleware/third-party-redirects.js')
middleware['third-party-redirects'] = middleware['third-party-redirects'].default || middleware['third-party-redirects']

export default middleware
