/**
 * Firebase realtime database Service provides helper methods to interact with the database
 */
export default class {
  constructor($fire, $fireModule) {
    this.$fire = $fire;
    this.$fireModule = $fireModule;
  }

  /**
   *
   * @param {String} userId refers to the user who is currently logged in
   * @param {String} workspaceId of the workspace user is currently in
   */
  trackUserPresence({ userId, workspaceId }) {
    // Create a reference to this user's specific status node.
    // This is where we will store data about being online/offline.
    const userStatusDatabaseRef = this.$fire.database.ref(
      `/users/${userId}/workspaces/${workspaceId}`,
    );

    // We'll create two constants which we will write to
    // the Realtime database when this device is offline
    // or online.
    const isOfflineForDatabase = {
      state: "offline",
      last_changed: this.$fireModule.database.ServerValue.TIMESTAMP,
    };

    const isOnlineForDatabase = {
      state: "online",
      last_changed: this.$fireModule.database.ServerValue.TIMESTAMP,
    };

    // Create a reference to the special '.info/connected' path in
    // Realtime Database. This path returns `true` when connected
    // and `false` when disconnected.
    this.$fire.database.ref(".info/connected").on("value", function (snapshot) {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() == false) {
        return;
      }

      // If we are currently connected, then use the 'onDisconnect()'
      // method to add a set which will only trigger once this
      // client has disconnected by closing the app,
      // losing internet, or any other means.
      userStatusDatabaseRef
        .onDisconnect()
        .update(isOfflineForDatabase)
        .then(function () {
          // The promise returned from .onDisconnect().set() will
          // resolve as soon as the server acknowledges the onDisconnect()
          // request, NOT once we've actually disconnected:
          // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

          // We can now safely set ourselves as 'online' knowing that the
          // server will mark us as offline once we lose connection.
          userStatusDatabaseRef.update(isOnlineForDatabase);
        });
    });
  }

  /**
   *
   * @param {String} userId refers to the user who is currently logged in
   * @param {String} workspaceId of the workspace user is currently in
   * @param {String} route refers to the route to which the user is currently navigating to
   */
  trackCurrentRoute({ userId, workspaceId, route }) {
    return this.$fire.database
      .ref(`/users/${userId}/workspaces/${workspaceId}`)
      .update({
        last_route: route,
      });
  }
}
