<template>
  <div>
    <button
      class="flex items-center justify-center text-gray-200 responsive:hover:text-gray-100 transition-colors duration-100 focus:outline-none"
      @click="openSearchModal()"
    >
      <search-icon class="mr-2" size="1.1x"></search-icon>
      <span class="hidden sm:block">Search</span>
    </button>
    <search-modal
      placeholder="Search for playlists..."
      :results="playlists || []"
      :search-keys="SEARCH_KEYS"
      :modal-name="SEARCH_MODAL_NAME"
    >
      <template v-slot:results="data">
        <div class="flex flex-col my-2">
          <nuxt-link
            :to="{ name: 'playlists-id', params: { id: result.item.id } }"
            class="text-gray-100 px-4 py-2 block responsive:hover:bg-black-200"
            v-for="(result, idx) in data.results"
            :key="idx"
          >
            <div>{{ result.item.name }}</div>
          </nuxt-link>
        </div>
        <div
          v-if="data.results && !data.results.length"
          class="text-gray-200 self-center py-24"
        >
          <div
            v-if="data.query"
            class="flex flex-col items-center justify-center space-y-2"
          >
            <span>No playlists found for the query</span>
            <span class="text-gray-100">"{{ data.query }}"</span>
          </div>
          <div
            v-else
            class="flex flex-col items-center justify-center space-y-2"
          >
            <span>No playlists found in this workspace :(</span>
          </div>
        </div>
      </template>
    </search-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SearchIcon } from "vue-feather-icons";
import SearchModal from "~/components/modals/SearchModal";

const SEARCH_KEYS = ["name", "description"];

export default {
  name: "search-bar",
  components: {
    SearchIcon,
    SearchModal,
  },
  methods: {
    openSearchModal: function () {
      this.$root.$emit("modal:open", this.SEARCH_MODAL_NAME);
    },
  },
  computed: {
    ...mapGetters({
      playlists: "workspace/playlists",
    }),
  },
  data() {
    return {
      data: [],
      SEARCH_KEYS,
      SEARCH_MODAL_NAME: "search-playlists",
    };
  },
};
</script>
