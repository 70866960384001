const searchFilter = function (fuse, data) {
  const list = data.map((doc, idx) => ({
    item: doc,
    score: 1,
    refIndex: idx,
  }));

  return (query) => {
    let results = fuse.search(query);
    return query.length ? results : list;
  };
};

export { searchFilter };
