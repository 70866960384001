const PRICING_PLANS = {
  FREE: "free",
  STARTER: "starter",
  PRO: "pro",
};

const SUBSCRIPTION_CYCLE = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
};

const PRICE_ITEM_ACTION = {
  ACTIVE: "active",
  UPGRADE: "upgrade",
  DOWNGRADE: "downgrade",
};

module.exports = {
  PRICING_PLANS,
  SUBSCRIPTION_CYCLE,
  PRICE_ITEM_ACTION,
};
