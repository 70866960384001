const PLAYLIST_TYPE = {
  WORKSPACE: "WORKSPACE",
  PRIVATE: "PRIVATE",
  SHARED: "SHARED",
};

const ACCESS_CONTROLS = {
  FULL_ACCESS: "FULL_ACCESS",
  NO_ACCESS: "NO_ACCESS",
};

const PLAYLIST_TAGS = {
  PUBLIC: "PUBLIC",
  SPITI_CONNECT: "SPITI_CONNECT",
};

module.exports = {
  PLAYLIST_TYPE,
  ACCESS_CONTROLS,
  PLAYLIST_TAGS,
};
