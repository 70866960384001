<template>
  <div class="relative">
    <button
      class="notification-icon"
      :class="{
        active: isOpen,
      }"
      @click.prevent="toggleNotifications()"
    >
      <BellIcon
        class="md:h-6 h-4"
        :style="{
          fill:
            unreadCount > 0
              ? isOpen
                ? 'rgba(115, 102, 171, 1)'
                : 'rgba(204, 204, 204, 1)'
              : 'rgba(204, 204, 204, 0)',
          color:
            unreadCount > 0
              ? isOpen
                ? 'rgba(115, 102, 171, 1)'
                : 'rgba(204, 204, 204, 1)'
              : isOpen
                ? 'rgba(115, 102, 171, 1)'
                : 'inherit',
        }"
      />

      <span class="notification-indicator" v-if="unreadCount > 0">
        <span class="static" style="height: 8px; width: 8px"></span>
        <span
          class="static opacity-40"
          style="height: 14px; width: 14px; top: -3px; left: -3px"
        ></span>
        <span
          class="pulse"
          style="height: 18px; width: 18px; top: -5px; left: -5px"
        ></span>
      </span>
    </button>

    <div
      class="notification-panel"
      v-on-clickaway="closeNotifications"
      v-if="isOpen"
    >
      <div
        class="px-6 py-2 flex justify-between border-b border-ebony-grey-200 border-opacity-50"
      >
        <span class="text-coconut-white-500 heading-title4">Notifications</span>
        <XIcon
          class="md:hidden block text-coconut-white-700 h-4"
          @click.prevent="toggleNotifications()"
        />
      </div>
      <div
        class="flex flex-col overflow-y-auto divide-y-1 divide-ebony-grey-200 divide-opacity-50 overscroll-contain"
        :style="{ 'max-height': isMobile ? 'calc( 100vh - 40px )' : '480px' }"
        @click="isOpen = false"
      >
        <NotificationItem
          :key="index"
          v-for="(each, index) in allNotifications"
          :status="each.status"
          :author="each.author"
          :icon="each.icon"
          :text="each.text"
          :link="each.link"
          :tags="each.tags"
          :createdAt="each.formattedTimestamp"
        />
      </div>
      <div
        class="flex flex-col space-y-2 items-center justify-center text-gray-200 py-4"
        v-if="!allNotifications.length"
      >
        <BellIcon size="4x" />
        <span class="text-sm">Your notifications live here</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import COLORS from "~/styles/colors.json";
import { XIcon } from "vue-feather-icons";
import { BellIcon } from "@spitilabs/icon-library";
import NotificationItem from "./NotificationItem.vue";
import { NOTIFICATION_STATUS, NOTIFICATION_TYPE } from "./types";
import { formatFirebaseTimestamp } from "~/utils/date";
import TrackingMixin from "~/components/mixins/tracking";
import { directive as onClickaway } from "vue-clickaway";

export default {
  mixins: [TrackingMixin],
  components: {
    BellIcon,
    NotificationItem,
    XIcon,
  },
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      COLORS,
      isOpen: false,
      parsedNotifications: [],
      parsedProductUpdates: [],
    };
  },
  mounted() {
    this.parseNotifications(this.notifications || []);
    this.parseProductUpdates(this.productUpdates || []);
  },
  watch: {
    isOpen(isOpen) {
      this.parseNotifications(this.notifications || []);
      isOpen && this.$store.dispatch("user/trackLastVisitToBellNotifications");
    },
    notifications: {
      handler(newList) {
        this.parseNotifications(newList || []);
      },
      deep: true,
    },
    productUpdates: {
      handler(newList) {
        this.parseProductUpdates(newList || []);
      },
      deep: true,
    },
    userMeta: {
      handler(newMeta) {
        this.parseNotifications(this.notifications || []);
      },
      deep: true,
    },
    playlistMeta: {
      handler(newMeta) {
        this.parseNotifications(this.notifications || []);
      },
      deep: true,
    },
    videoMeta: {
      handler(newMeta) {
        this.parseNotifications(this.notifications || []);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      defaultWorkspace: "workspace/defaultWorkspace",
      playlists: "workspace/playlists",
      feed: "workspace/feed",
      viewer: "auth/viewer",
      notifications: "user/notifications",
      productUpdates: "user/productUpdates",
      userMeta: "meta/users",
      playlistMeta: "meta/playlists",
      videoMeta: "meta/videos",
      workspaceMeta: "meta/workspaces",
      isMobile: "isScreenSmall",
    }),
    unreadCount() {
      if (
        !this.defaultWorkspace?.id ||
        !this.viewer?.workspaces[this.defaultWorkspace.id]
          ?.lastVisitedBellNotificationsAt
      ) {
        return 0;
      }
      const lastVisitedNotificationsAt =
        this.viewer.workspaces[this.defaultWorkspace.id]
          .lastVisitedBellNotificationsAt;
      return (this.notifications || []).filter(
        (notification) =>
          notification.status === NOTIFICATION_STATUS.UNREAD &&
          notification.createdAt > lastVisitedNotificationsAt,
      ).length;
    },
    allNotifications() {
      return [
        ...(this.parsedNotifications || []),
        ...(this.parsedProductUpdates || []),
      ].sort((a, b) => b.createdAt - a.createdAt);
    },
  },
  methods: {
    async toggleNotifications() {
      this.isOpen = !this.isOpen;
      this.isOpen && this.trackBellIconClicked();
    },
    async closeNotifications() {
      this.isOpen = false;
    },
    highlightActivity(status, activity) {
      if (status === NOTIFICATION_STATUS.READ) {
        return activity;
      }
      return `<span class="text-coconut-white-default">${activity}</span>`;
    },
    highlightFeature(status, feature) {
      if (status === NOTIFICATION_STATUS.READ) {
        return `<span class="italic">${feature}</span>`;
      }
      return `<span class="italic text-dew-jerry-100">${feature}</span>`;
    },
    textWrapper(status, content) {
      if (status === NOTIFICATION_STATUS.READ) {
        return `<div class="text-coconut-white-700">${content}</div>`;
      }
      return `<div class="text-coconut-white-600">${content}</div>`;
    },
    constructNewPlaylistNotificationMessage(status, payload) {
      const { playlistId, author } = payload;
      const user = this.highlightActivity(
        status,
        this.userMeta[author].displayName.split(" ")[0],
      );
      const playlist = this.highlightActivity(
        status,
        this.playlistMeta[playlistId].name,
      );
      const content = `${user} created a new playlist ${playlist}.`;
      return {
        text: this.textWrapper(status, content),
        link: `/playlists/${playlistId}`,
      };
    },
    constructPlaylistSharedNotificationMessage(status, payload) {
      const { playlistId, author, parentPlaylist, parentWorkspace } = payload;
      const user = this.highlightActivity(
        status,
        this.userMeta[author].displayName.split(" ")[0],
      );
      let content = "";
      const playlist = this.highlightActivity(
        status,
        this.playlistMeta[playlistId].name,
      );
      if (!parentPlaylist) {
        content = `${user} shared a new playlist ${playlist} with you.`;
      } else {
        const parentWorkspaceName = this.highlightActivity(
          status,
          this.workspaceMeta[parentWorkspace].name,
        );
        content = `${user} from ${parentWorkspaceName} shared ${playlist} via Spiti Connect. See what they have shared with you.`;
      }
      return {
        text: this.textWrapper(status, content),
        link: `/playlists/${playlistId}`,
        tags: this.playlistMeta[playlistId].tags,
      };
    },
    constructTranscriptsReadyNotificationMessage(status, payload) {
      const { videoId } = payload;
      const video = this.highlightActivity(
        status,
        this.videoMeta[videoId].name,
      );
      const content = `${video} has been fully transcribed. Click here to check it out and make corrections, if necessary.`;
      return {
        text: this.textWrapper(status, content),
        link: this.getVideoUrl(videoId),
        icon: "clipboard",
      };
    },
    constructVideoReadyForSummarizationNotificationMessage(status, payload) {
      const { videoId } = payload;
      const video = this.highlightActivity(
        status,
        this.videoMeta[videoId].name,
      );
      const content = `${video} is ready for summarization. ${this.highlightFeature(
        status,
        "Generate AI Summary",
      )} and ${this.highlightFeature(status, "List Action Items.")}`;
      return {
        text: this.textWrapper(status, content),
        link: this.getVideoUrlWithTabCtx(videoId, "AI Summary"),
        icon: "sparkles",
      };
    },
    getVideoUrl(videoId) {
      // If the video is not a part of any playlist, then redirect to the video library page
      // Else redirect to the player page
      const playlist = (this.playlists || []).find((playlist) =>
        playlist.videos.includes(videoId),
      );
      return playlist
        ? `/player?playlist=${playlist.id}&video=${videoId}`
        : `/videos/${videoId}`;
    },
    getVideoUrlWithTabCtx(videoId, tab) {
      // If the video is not a part of any playlist, then redirect to the video library page
      // Else redirect to the player page
      const playlist = (this.playlists || []).find((playlist) =>
        playlist.videos.includes(videoId),
      );
      return playlist
        ? `/player?playlist=${playlist.id}&video=${videoId}&tab=${tab}`
        : `/videos/${videoId}?tab=${tab}`;
    },
    getSummaryType(promptKey) {
      switch (promptKey) {
        case "VIDEO_DESCRIPTION":
          return "AI Description";
        case "SUMMARIZE":
        case "KEY_TAKEAWAYS":
          return "AI Summary";
        case "ACTION_ITEMS":
          return "AI Action Items";
        default:
          return "AI Summary";
      }
    },
    getSummaryTab(promptKey) {
      switch (promptKey) {
        case "SUMMARIZE":
        case "KEY_TAKEAWAYS":
          return "AI Summary";
        case "ACTION_ITEMS":
          return "Action Items";
        default:
          return "AI Summary";
      }
    },
    constructSummaryGeneratedNotificationMessage(status, payload) {
      const { videoId, author, promptKey } = payload;
      const user = this.highlightActivity(
        status,
        this.userMeta[author].displayName.split(" ")[0],
      );
      const video = this.highlightActivity(
        status,
        this.videoMeta[videoId].name,
      );
      const action = this.getSummaryType(promptKey);
      const content = `${user} generated ${action} of ${video}. Click to read, regenerate, edit, and share.`;
      const tab = this.getSummaryTab(promptKey);
      return {
        text: this.textWrapper(status, content),
        link: this.getVideoUrlWithTabCtx(videoId, tab),
        icon: "sparkles",
      };
    },
    constructSpitiConnectInvitationAcceptedNotificationMessage(
      status,
      payload,
    ) {
      const { playlistId, author, childWorkspace } = payload;
      const user = this.highlightActivity(
        status,
        this.userMeta[author].displayName.split(" ")[0],
      );
      const playlist = this.highlightActivity(
        status,
        this.playlistMeta[playlistId].name,
      );
      const workspaceName = this.highlightActivity(
        status,
        this.workspaceMeta[childWorkspace].name,
      );
      const content = `${user} from ${workspaceName} accepted your invitation to playlist ${playlist}.`;
      return {
        text: this.textWrapper(status, content),
        link: `/playlists/${playlistId}`,
        tags: this.playlistMeta[playlistId].tags,
      };
    },
    constructNewVideoPublishedNotificationMessage(status, payload) {
      const { videoId, author } = payload;
      const user = this.highlightActivity(
        status,
        this.userMeta[author].displayName.split(" ")[0],
      );
      const video = this.highlightActivity(
        status,
        this.videoMeta[videoId].name,
      );
      const content = `${user} published video ${video} to ${this.defaultWorkspace.name}.`;
      return {
        text: this.textWrapper(status, content),
        link: `/player?video=${videoId}`,
      };
    },
    constructNewVideosInPlaylistNotificationMessage(status, payload) {
      const { playlistId, author, videos, parentWorkspace } = payload;
      const user = this.highlightActivity(
        status,
        this.userMeta[author].displayName.split(" ")[0],
      );
      const playlist = this.highlightActivity(
        status,
        this.playlistMeta[playlistId].name,
      );
      const video = this.highlightActivity(
        status,
        this.videoMeta[videos[0]].name,
      );
      const parentWorkspaceName =
        parentWorkspace &&
        this.highlightActivity(
          status,
          this.workspaceMeta[parentWorkspace].name,
        );
      const prefix = parentWorkspace
        ? `${user} from ${parentWorkspaceName}`
        : user;
      const textAboutVideo =
        videos.length > 1
          ? `${video} and ${videos.length - 1} other ${
              videos.length - 1 > 1 ? "videos" : "video"
            }`
          : video;
      const content = `${prefix} added ${textAboutVideo} to ${playlist}. Watch now.`;
      return {
        text: this.textWrapper(status, content),
        link: `/playlists/${playlistId}`,
        tags: this.playlistMeta[playlistId].tags,
      };
    },
    constructNewVideoLikeNotificationMessage(status, payload) {
      const { videoId, author, playlistId, childWorkspace } = payload;
      const user = this.highlightActivity(
        status,
        this.userMeta[author].displayName.split(" ")[0],
      );
      const video = this.highlightActivity(
        status,
        this.videoMeta[videoId].name,
      );
      if (!playlistId) {
        const content = `${user} liked video ${video}.`;
        return {
          text: this.textWrapper(status, content),
          link: `/player?video=${videoId}`,
        };
      }

      const playlist = this.highlightActivity(
        status,
        this.playlistMeta[playlistId].name,
      );
      const childWorkspaceName =
        childWorkspace &&
        this.highlightActivity(status, this.workspaceMeta[childWorkspace].name);
      const prefix = childWorkspace
        ? `${user} from ${childWorkspaceName}`
        : user;
      const content = `${prefix} liked video ${video} in ${playlist}.`;
      return {
        text: this.textWrapper(status, content),
        link: `/player?video=${videoId}&playlist=${playlistId}`,
        tags: this.playlistMeta[playlistId].tags,
      };
    },
    constructNewVideoCommentNotificationMessage(status, payload) {
      const { videoId, author, playlistId, parentWorkspace, childWorkspace } =
        payload;
      const user = this.highlightActivity(
        status,
        this.userMeta[author].displayName.split(" ")[0],
      );
      const video = this.highlightActivity(
        status,
        this.videoMeta[videoId].name,
      );
      if (!playlistId) {
        const content = `${user} commented on video ${video}.`;
        return {
          text: this.textWrapper(status, content),
          link: `/player?video=${videoId}`,
        };
      }

      const playlist = this.highlightActivity(
        status,
        this.playlistMeta[playlistId].name,
      );
      const childWorkspaceName =
        childWorkspace &&
        this.highlightActivity(status, this.workspaceMeta[childWorkspace].name);
      const parentWorkspaceName =
        parentWorkspace &&
        this.highlightActivity(
          status,
          this.workspaceMeta[parentWorkspace].name,
        );
      const prefix = childWorkspace
        ? `${user} from ${childWorkspaceName}`
        : parentWorkspace
          ? `${user} from ${parentWorkspaceName}`
          : user;
      const content = `${prefix} commented on video ${video} in ${playlist}.`;
      return {
        text: this.textWrapper(status, content),
        link: `/player?video=${videoId}&playlist=${playlistId}`,
        tags: this.playlistMeta[playlistId].tags,
      };
    },
    constructNotificationMessage(type, status, payload) {
      const { playlistId, videos, videoId } = payload;
      if (
        (playlistId && !this.playlistMeta[playlistId]) ||
        (videoId && !this.videoMeta[videoId]) ||
        (videos && videos.length && videos[0] && !this.videoMeta[videos[0]])
      ) {
        return null;
      }
      switch (type) {
        case NOTIFICATION_TYPE.NEW_PLAYLIST_CREATED:
          return this.constructNewPlaylistNotificationMessage(status, payload);
        case NOTIFICATION_TYPE.NEW_VIDEOS_IN_PLAYLIST:
          return this.constructNewVideosInPlaylistNotificationMessage(
            status,
            payload,
          );
        case NOTIFICATION_TYPE.NEW_VIDEO_PUBLISHED:
          return this.constructNewVideoPublishedNotificationMessage(
            status,
            payload,
          );
        case NOTIFICATION_TYPE.NEW_VIDEO_LIKE:
          return this.constructNewVideoLikeNotificationMessage(status, payload);
        case NOTIFICATION_TYPE.NEW_VIDEO_COMMENT:
          return this.constructNewVideoCommentNotificationMessage(
            status,
            payload,
          );
        case NOTIFICATION_TYPE.PLAYLIST_SHARED:
          return this.constructPlaylistSharedNotificationMessage(
            status,
            payload,
          );
        case NOTIFICATION_TYPE.TRANSCRIPTS_READY:
          return this.constructTranscriptsReadyNotificationMessage(
            status,
            payload,
          );
        case NOTIFICATION_TYPE.VIDEO_READY_FOR_SUMMARIZATION:
          return this.constructVideoReadyForSummarizationNotificationMessage(
            status,
            payload,
          );
        case NOTIFICATION_TYPE.SUMMARY_GENERATED:
          return this.constructSummaryGeneratedNotificationMessage(
            status,
            payload,
          );
        case NOTIFICATION_TYPE.SPITI_CONNECT_INVITATION_ACCEPTED:
          return this.constructSpitiConnectInvitationAcceptedNotificationMessage(
            status,
            payload,
          );
        default:
          return null;
      }
    },
    async fetchMetaIfNeeded(notifications) {
      const userIds = [];
      const playlistIds = [];
      const videoIds = [];
      const workspaceIds = [];

      notifications.forEach((notification) => {
        const { payload } = notification;
        const {
          author,
          playlistId,
          videoId,
          videos,
          parentPlaylist,
          parentWorkspace,
          childPlaylist,
          childWorkspace,
        } = payload;
        author && !this.userMeta[author] && userIds.push(author);
        playlistId &&
          !this.playlistMeta[playlistId] &&
          playlistIds.push(playlistId);
        parentPlaylist &&
          !this.playlistMeta[parentPlaylist] &&
          playlistIds.push(parentPlaylist);
        childPlaylist &&
          !this.playlistMeta[childPlaylist] &&
          playlistIds.push(childPlaylist);
        parentWorkspace &&
          !this.workspaceMeta[parentWorkspace] &&
          workspaceIds.push(parentWorkspace);
        childWorkspace &&
          !this.workspaceMeta[childWorkspace] &&
          workspaceIds.push(childWorkspace);
        videoId && !this.videoMeta[videoId] && videoIds.push(videoId);
        videos &&
          videos[0] &&
          !this.videoMeta[videos[0]] &&
          videoIds.push(videos[0]);
      });
      await Promise.all([
        this.$store.dispatch("meta/fetchUsers", userIds),
        this.$store.dispatch("meta/fetchPlaylists", playlistIds),
        this.$store.dispatch("meta/fetchVideos", videoIds),
        this.$store.dispatch("meta/fetchWorkspaces", workspaceIds),
      ]);
    },
    italicize(text) {
      return `<span class="italic">${text}</span>`;
    },
    wrapProductUpdateMessage(status, text) {
      if (status === NOTIFICATION_STATUS.UNREAD) {
        return `<div class="text-coconut-white-default">${text}</div>`;
      }
      return `<div class="text-coconut-white-700">${text}</div>`;
    },
    getLatestVideoUrlWithTabCtx(tab) {
      if (!this.feed || !this.feed.length) {
        return null;
      }
      const latestVideo = this.feed[0];
      return this.getVideoUrlWithTabCtx(latestVideo.id, tab);
    },
    constructProductUpdateMessage(type, status, change) {
      switch (change) {
        case "AI_SUMMARY":
          return {
            text: this.wrapProductUpdateMessage(
              status,
              `${this.italicize(
                "Generate AI Summary",
              )} of your videos. Try now`,
            ),
            link: this.getLatestVideoUrlWithTabCtx("AI Summary"),
          };
        case "ACTION_ITEMS":
          return {
            text: this.wrapProductUpdateMessage(
              status,
              `${this.italicize(
                "List Action Items",
              )} from your videos. Try now`,
            ),
            link: this.getLatestVideoUrlWithTabCtx("Action Items"),
          };
        default:
          return null;
      }
    },
    parseProductUpdates(updates) {
      this.parsedProductUpdates = updates.map((update) => {
        const { status, type, change, createdAt } = update;
        const item = this.constructProductUpdateMessage(type, status, change);
        if (!item) {
          return null;
        }
        const { text, link } = item;
        return {
          status,
          icon: "sparkles",
          text,
          link,
          tags: ["NEW"],
          formattedTimestamp: formatFirebaseTimestamp(createdAt),
          createdAt,
        };
      });
    },
    async parseNotifications(notifications) {
      await this.fetchMetaIfNeeded(notifications);
      this.parsedNotifications = notifications
        .map((notification) => {
          const { status, type, payload, createdAt } = notification;
          const item = this.constructNotificationMessage(type, status, payload);
          if (!item) {
            return null;
          }
          const { text, link, tags, icon } = item;
          return {
            status,
            author: payload.author && this.userMeta[payload.author],
            icon,
            text,
            link,
            tags: tags || [],
            formattedTimestamp: formatFirebaseTimestamp(createdAt),
            createdAt,
          };
        })
        .filter((item) => item);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-panel {
  @apply absolute right-0 mt-2 bg-coal-pop-default text-coconut-white-500 border border-coal-pop-default rounded shadow-xl;
  width: 408px;
  @media (max-width: 768px) {
    @apply fixed w-screen h-screen mt-0 top-0 z-50;
  }
}
.notification-icon {
  @apply text-coconut-white-500 transition duration-300 flex items-center justify-center rounded focus:outline-none relative md:w-9 md:h-9 h-6 w-6;
  @media (hover: hover) {
    &:hover {
      @apply bg-slate-grey-200;
    }
  }
  &:active,
  &.active {
    @apply bg-slate-grey-100 text-dew-jerry-default;
  }
}
.notification-indicator {
  @apply absolute flex;
  left: 55.56%;
  right: 22.22%;
  top: 16.67%;
  bottom: 61.11%;
  .static {
    @apply bg-blush-red-default rounded-full absolute;
  }
  .pulse {
    @apply absolute inline-flex bg-blush-red-default opacity-20 rounded-full animate-pulse duration-200;
  }
}
</style>
