<template>
  <modal :name="'information-modal'">
    <template v-slot="{ payload }">
      <div class="info-modal">
        <div class="text-left body-subtitle3 text-coconut-white-500">
          {{ payload }}
        </div>
        <div class="flex flex-row gap-x-6 justify-end">
          <button
            class="body-subtitle3 text-coconut-white-default font-medium"
            @click="closeModal()"
          >
            Close
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "~/components/Modal";

const NAME = "information-modal";

export default {
  name: NAME,
  data: function () {
    return {
      loading: false,
    };
  },
  components: {
    Modal,
  },

  methods: {
    closeModal: async function () {
      this.$root.$emit("modal:close:all");
    },
  },
};
</script>
<style lang="scss" scoped>
.info-modal {
  height: 108px;
  width: 320px;
  @apply bg-coal-pop-default border border-coal-pop-default rounded p-4 flex flex-col space-y-4;
}
</style>
