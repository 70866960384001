var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex flex-row h-8 space-x-1 py-2 pr-1 rounded-sm items-center responsive:hover:bg-slate-grey-200 active:text-coconut-white-500 transition-all cursor-pointer",class:_vm.active ? 'text-coconut-white-500' : 'text-coconut-white-700'},[_c('playlist-icons',{attrs:{"type":_vm.type}}),_vm._v(" "),_c('span',{staticClass:"heading-title5 capitalize"},[_vm._v(_vm._s(_vm.type.toLowerCase()))]),_vm._v(" "),_c('button',{staticClass:"flex-grow",on:{"click":function($event){_vm.expand = !_vm.expand}}},[_c('ChevronDownFillIcon',{staticClass:"text-currentColor",style:({ transform: _vm.expand ? 'rotate(0deg)' : 'rotate(-90deg)' }),attrs:{"width":"18px","height":"18px"}})],1),_vm._v(" "),(_vm.type !== _vm.PLAYLIST_TYPE.SHARED)?_c('button',{staticClass:"text-coconut-white-700 responsive:hover:bg-slate-grey-400 active:bg-slate-grey-200 active:text-coconut-white-default transition-all",class:{
        'text-coconut-white-700  responsive:hover:bg-slate-grey-200 ': _vm.expand,
      },staticStyle:{"border-radius":"1px","padding":"1px"},attrs:{"id":"create-playlist-button"},on:{"click":function($event){return _vm.openCreateModal()}}},[_c('PlusIcon',{attrs:{"width":"16px","height":"16px"}})],1):_vm._e()],1),_vm._v(" "),(_vm.expand)?_c('div',{staticClass:"flex flex-row ml-6 space-x-1"},[_c('div',{staticClass:"bg-ebony-grey-300 border-0 border-ebony-grey-300 rounded h-full",staticStyle:{"width":"1.5px"}}),_vm._v(" "),_c('div',{staticClass:"flex flex-col w-full"},_vm._l((_vm.playlists),function(playlist){return _c('nuxt-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          playlist.name.length > 25 && {
            content: playlist.name,
            placement: 'top',
          }
        ),expression:"\n          playlist.name.length > 25 && {\n            content: playlist.name,\n            placement: 'top',\n          }\n        "}],key:playlist.id,staticClass:"transition-all responsive:hover:bg-slate-grey-200 px-1 py-1.5 rounded-sm active:text-coconut-white-default body-text2 active:bg-slate-grey-100",class:{
          'bg-slate-grey-200 ': _vm.selectedPlaylistId === playlist.id,
          'text-coconut-white-default': _vm.selectedPlaylistId === playlist.id,
        },attrs:{"to":{ name: 'playlists-id', params: { id: playlist.id } }}},[_c('span',{staticClass:"flex items-center break-all line-clamp-1",class:_vm.selectedPlaylistId === playlist.id
              ? 'text-coconut-white-default'
              : _vm.unreadPlaylists.has(playlist.id)
                ? 'text-coconut-white-default font-semibold'
                : 'text-gray-200'},[_vm._v("\n          "+_vm._s(playlist.name)+"\n        ")])])}),1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }