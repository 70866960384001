<template>
  <nuxt-link :to="toParams">
    <div
      v-if="!processed"
      class="flex flex-row justify-between py-4 px-4 text-coconut-white-default body-text2 items-center transition-all responsive:hover:bg-slate-grey-300 active:bg-slate-grey-200"
    >
      <!---Video Name-->
      <span class="w-3/4 break-all line-clamp-1">{{ video.name }}</span>

      <!---Status Text-->
      <div class="body-text4 mr-4 ml-1">
        <span
          v-if="video.status === 'waiting_for_upload'"
          class="text-pastel-yellow"
          >uploading...</span
        >
        <span
          v-else-if="video.status === 'preparing'"
          class="text-basil-green-default"
          >processing...</span
        >
        <span
          v-else-if="video.status === 'errored'"
          class="text-blush-red-default"
          >errored</span
        >
      </div>
      <!---Status Indicators-->
      <div class="ml-0.5">
        <div
          v-if="video.status === 'errored'"
          class="rounded-full bg-spiti-red p-1 flex items-center justify-center"
        >
          <XIcon size="1x" />
        </div>
        <div
          class="flex flex-row space-x-3 items-center text-midnight-black-100 heading-title1"
          v-else
        >
          <circle-progress-bar
            v-if="video.uploadProgress !== 100"
            stroke="#2D3748"
            :active-stroke="COLORS['pastel-yellow']"
            width="20px"
            :strokeWidth="15"
            :activeWidth="15"
            height="20px"
            :progress="video.uploadProgress || 0"
          />

          <CheckCircleFillIcon v-else class="w-5 h-5 text-pastel-yellow" />

          <circle-progress-bar
            stroke="#2D3748"
            v-if="video.status !== 'ready'"
            :active-stroke="COLORS['basil-green']['default']"
            width="20px"
            height="20px"
            :strokeWidth="15"
            :activeWidth="15"
            :progress="estimatedProgress"
          >
            <template slot="child">
              <div
                class="text-midnight-black-100 body-subtitle1 absolute tracking-tighter"
                style="transform: translateY(-12px); height: 10px"
              >
                ...
              </div>
            </template>
          </circle-progress-bar>
          <CheckCircleFillIcon
            v-else
            class="w-5 h-5 text-basil-green-default"
          />
        </div>
      </div>
    </div>
    <div
      class="flex flex-row justify-between items-center py-4 px-4 text-coconut-white-default body-text2 transition-all responsive:hover:bg-slate-grey-300 active:bg-slate-grey-200"
      v-else
      :key="`processed-${video.id}`"
    >
      <span class="w-3/4 break-all line-clamp-1">{{ video.name }}</span>
      <div class="flex flex-row space-x-3 items-center">
        <CheckCircleFillIcon class="w-5 h-5 text-pastel-yellow" />
        <CheckCircleFillIcon class="w-5 h-5 text-basil-green-default" />
      </div>
      <div
        v-if="video.status === 'errored'"
        class="rounded-full bg-spiti-red p-1 flex items-center justify-center"
      >
        <XIcon size="1x" />
      </div>
    </div>
  </nuxt-link>
</template>
<script>
import { CheckCircleFillIcon } from "@spitilabs/icon-library";
import CircleProgressBar from "~/components/loaders/CircleProgressBar";
import { XIcon, EditIcon } from "@spitilabs/icon-library";
import COLORS from "~/styles/colors.json";
import UploadProgressMixin from "~/components/mixins/uploadprogress";

export default {
  props: {
    processed: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Object,
      required: true,
    },
  },
  mixins: [UploadProgressMixin],
  components: {
    CheckCircleFillIcon,
    XIcon,
    CircleProgressBar,
    EditIcon,
  },
  data() {
    return {
      COLORS,
    };
  },
  computed: {
    toParams() {
      return {
        name: "videos-id",
        params: {
          id: this.video.id,
        },
      };
    },
  },
};
</script>
