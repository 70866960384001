<template>
  <footer
    ref="marketingFooter"
    class="pt-20 sm:pt-24 md:pt-28 lg:pt-32 relative"
  >
    <div class="absolute footer-gradient w-full h-full"></div>
    <div
      class="px-8 md:px-4 lg:px-0 max-w-6xl mx-auto flex flex-col space-y-6 sm:space-y-8 md:space-y-10 lg:space-y-12 text-left"
    >
      <section
        class="z-20 grid grid-cols-1 gap-y-8 md:grid-cols-6 md:gap-y-0 sm:justify-items-stretch"
      >
        <div class="order-2 flex">
          <img
            src="~/assets/img/logos/spiti-long.svg"
            alt="Spiti"
            class="h-8"
          />
        </div>
        <div
          class="order-4 flex flex-col space-y-3 md:space-y-5"
          v-for="section of Object.values(SECTIONS)"
          :key="section"
        >
          <span class="section-title">{{ section }}</span>
          <div class="flex flex-col space-y-2 md:space-y-4">
            <div
              v-for="link of LINKS[section]"
              :key="link.title"
              class="flex flex-row space-x-2 items-center"
            >
              <nuxt-link
                v-if="link.path"
                class="section-item"
                @click.native="handleSameRouteClick(link.path)"
                :to="link.path"
              >
                {{ link.title }}
              </nuxt-link>
              <a
                v-else
                class="section-item"
                :href="link.href || `mailto:${link.mail}`"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ link.title }}
              </a>
              <div class="new-tag" v-if="link.newTag">New</div>
              <div class="beta-tag" v-if="link.beta">Beta</div>
            </div>
          </div>
        </div>
        <div class="order-3 md:order-5 flex flex-col space-y-6 w-40">
          <div class="flex flex-row space-x-4 md:justify-between">
            <a
              href="///twitter.com/spitidotxyz"
              target="_blank"
              rel="noopener noreferrer"
              @mouseenter="isTwitterHovered = true"
              @mouseleave="isTwitterHovered = false"
            >
              <TwitterIcon
                size="1.2x"
                :fill="!isTwitterHovered ? '#fff' : '#1DA1F2'"
                stroke="none"
              ></TwitterIcon>
            </a>
            <a
              href="//linkedin.com/company/spitilabs"
              target="_blank"
              rel="noopener noreferrer"
              @mouseenter="isLinkedInHovered = true"
              @mouseleave="isLinkedInHovered = false"
            >
              <LinkedinIcon
                size="1.2x"
                :fill="!isLinkedInHovered ? '#fff' : '#0A66C2'"
                stroke="none"
              ></LinkedinIcon>
            </a>
            <a
              href="//facebook.com/getspiti"
              target="_blank"
              rel="noopener noreferrer"
              @mouseenter="isFacebookHovered = true"
              @mouseleave="isFacebookHovered = false"
            >
              <FacebookIcon
                size="1.2x"
                :fill="!isFacebookHovered ? '#fff' : '#4267B2'"
                stroke="none"
              ></FacebookIcon>
            </a>
          </div>
          <div class="flex flex-col space-y-6 items-center sm:items-start">
            <product-hunt />
            <wiki-tools />
          </div>
        </div>
      </section>
      <div class="z-20 text-white text-opacity-80 text-sm md:text-base">
        © 2023, Spiti Inc. All rights reserved.
      </div>
    </div>
  </footer>
</template>

<script>
const SECTIONS = {
  PRODUCT: "Product",
  RESOURCES: "Resources",
  COMPANY: "Company",
  SUPPORT: "Support",
};

const LINKS = {
  [SECTIONS.PRODUCT]: [
    {
      title: "Pricing",
      path: "/pricing",
    },
    {
      title: "Integrations",
      path: "/integrations",
    },
    {
      title: "Features",
      path: "/features",
    },
    {
      title: "Feature Requests",
      mail: "support@spiti.xyz",
    },
    {
      title: "Screen recorder",
      path: "/screen-recorder",
      beta: true,
    },
  ],
  [SECTIONS.RESOURCES]: [
    {
      title: "Blog",
      href: "//blog.spiti.xyz",
    },
    {
      title: "Documentation",
      path: "/docs",
    },
  ],
  [SECTIONS.COMPANY]: [
    {
      title: "About",
      path: "/about",
    },
    {
      title: "Customers",
      path: "/customers",
    },
    {
      title: "Careers",
      href: "//angel.co/company/spiti-1/jobs",
    },
    {
      title: "Write for us",
      path: "/write-for-us",
      newTag: true,
    },
    {
      title: "Manifesto",
      path: "/manifesto",
    },
    {
      title: "Privacy policy",
      path: "/privacy",
    },
    {
      title: "Terms of Service",
      path: "/tos",
    },
    {
      title: "Press Enquires",
      mail: "support@spiti.xyz",
    },
  ],
  [SECTIONS.SUPPORT]: [
    {
      title: "Contact Sales",
      mail: "support@spiti.xyz",
    },
    {
      title: "Join Discord Server",
      href: "//discord.gg/HKKFGdDYqt",
    },
  ],
};

import { TwitterIcon, LinkedinIcon, FacebookIcon } from "vue-feather-icons";
import ProductHunt from "~/components/marketing/ProductHunt";
import WikiTools from "~/components/marketing/WikiTools";

export default {
  components: {
    TwitterIcon,
    LinkedinIcon,
    FacebookIcon,
    ProductHunt,
    WikiTools,
  },
  data() {
    return {
      // Fields to render footer sections in the template
      currentDate: new Date(),
      SECTIONS,
      LINKS,

      // Fields to control social icons hover state
      isTwitterHovered: false,
      isLinkedInHovered: false,
      isFacebookHovered: false,

      currentPath: this.$route.path,
    };
  },
  computed: {
    currentYear: function () {
      return this.currentDate.getFullYear();
    },
  },
  methods: {
    handleSameRouteClick(path) {
      if (path === this.currentPath) {
        this.$refs.marketingFooter?.parentNode?.scrollTo({ top: 0 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-gradient {
  background: -o-linear-gradient(
    88.77deg,
    #1f101a 0.62%,
    #3a1b33 17.2%,
    #2f1530 55.7%,
    #1e0b22 81.16%,
    #100613 98.53%
  );
  background: -moz-linear-gradient(
    88.77deg,
    #1f101a 0.62%,
    #3a1b33 17.2%,
    #2f1530 55.7%,
    #1e0b22 81.16%,
    #100613 98.53%
  );
  background: -webkit-linear-gradient(
    88.77deg,
    #1f101a 0.62%,
    #3a1b33 17.2%,
    #2f1530 55.7%,
    #1e0b22 81.16%,
    #100613 98.53%
  );
  background: -ms-linear-gradient(
    88.77deg,
    #1f101a 0.62%,
    #3a1b33 17.2%,
    #2f1530 55.7%,
    #1e0b22 81.16%,
    #100613 98.53%
  );
  background: linear-gradient(
    88.77deg,
    #1f101a 0.62%,
    #3a1b33 17.2%,
    #2f1530 55.7%,
    #1e0b22 81.16%,
    #100613 98.53%
  );
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -webkit-filter: blur(200px);
  filter: blur(200px);
}

.section-title {
  @apply text-white font-bold flex items-center text-lg;
}

.section-item {
  @apply text-white text-opacity-70 font-normal flex items-center text-base;
  @media (hover: hover) {
    &:hover {
      @apply text-opacity-100 font-medium;
    }
  }
}
</style>
