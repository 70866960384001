<template>
  <div class="hamburger-icon" id="icon">
    <div class="icon-1" id="a"></div>
    <div class="icon-2" id="b"></div>
    <div class="icon-3" id="c"></div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    const icon = document.getElementById("icon");
    icon.addEventListener("click", () => {
      this.onClick();
    });
  },
  methods: {
    toggleClass() {
      const icon1 = document.getElementById("a");
      const icon2 = document.getElementById("b");
      const icon3 = document.getElementById("c");
      icon1.classList.toggle("a");
      icon2.classList.toggle("c");
      icon3.classList.toggle("b");
    },
  },
  watch: {
    state() {
      this.toggleClass();
    },
  },
};
</script>
<style scoped lang="scss">
.icon-1,
.icon-2,
.icon-3 {
  position: absolute;
  left: 25%;
  top: 50%;
  width: 32px;
  height: 3px;
  background-color: white;
  transition: all 400ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
}

.icon-1 {
  transform: translateY(-8px);
  animation-delay: 100ms;
}

.icon-3 {
  transform: translateY(8px);
  animation-delay: 250ms;
}

.hamburger-icon {
  position: relative;
  height: 60px;
  width: 60px;
  top: 10%;
  left: 3%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.icon-1.a {
  transform: rotate(40deg);
}
.icon-3.b {
  transform: rotate(-40deg);
}
.icon-2.c {
  opacity: 0;
}

.clear {
  clear: both;
}

@keyframes slideIn {
  0% {
    width: 0%;
    opacity: 0;
  }

  100% {
    width: 50%;
    opacity: 1;
  }
}
</style>
