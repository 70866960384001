<template>
  <div
    class="bg-space-blue-default min-h-screen w-full flex"
    style="min-width: fit-content"
    @dragover.prevent
    @drop.prevent
  >
    <portal-target name="modals"></portal-target>
    <app-navbar></app-navbar>
    <div
      class="app-main"
      :class="{
        overlay: isSidebarOpen,
        'pl-0': isPlayerPage,
        'sm:pl-0 sidebar': !isPlayerPage,
      }"
    >
      <settings-sidepanel v-if="$route.name.includes('settings')" />
      <app-sidepanel v-else />
      <article
        class="dashboard-container"
        :class="{
          'opacity-60 transition-opacity duration-100':
            isSidebarOpen && (isPlayerPage || isMobile),
        }"
      >
        <Nuxt />
      </article>
    </div>
    <upload-progress-tracker></upload-progress-tracker>
    <product-tour-modal></product-tour-modal>
    <import-zoom-video-modal></import-zoom-video-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppSidepanel from "~/components/sidepanels/AppSidepanel.vue";
import SettingsSidepanel from "~/components/sidepanels/SettingsSidepanel.vue";
import AppNavbar from "~/components/navbars/AppNavbar.vue";
import ProductTourModal from "~/components/modals/ProductTourModal";
import ImportZoomVideoModal from "~/components/modals/ImportZoomVideoModal";
import UploadProgressTracker from "~/components/popups/UploadProgressTracker.vue";
import pages from "~/constants/pages";
import TrackingMixin from "~/components/mixins/tracking";

export default {
  name: "app",
  mixins: [TrackingMixin],
  components: {
    AppSidepanel,
    SettingsSidepanel,
    ProductTourModal,
    ImportZoomVideoModal,
    AppNavbar,
    UploadProgressTracker,
  },
  data() {
    return {
      isPlayerPage: false,
    };
  },
  watch: {
    playlists() {
      this.$store.dispatch("meta/initPlaylists");
    },
    members() {
      this.$store.dispatch("meta/initUsers");
    },
    feed() {
      this.$store.dispatch("meta/initVideos");
    },
    videos() {
      this.$store.dispatch("meta/initVideos");
      this.publishVideosIfAny();
    },
    $route() {
      // if the route changes, close all open modals
      this.$root.$emit("modal:close:all");

      // Close sidebar on mobile screens
      if (window.outerWidth < 768) {
        this.$store.dispatch("closeSidebar");
      } else {
        this.handlePlayerPageLayout();
      }
      this.trackPageVisit();
    },
    defaultWorkspace: {
      handler(current, old) {
        if (current?.id && current.id !== old?.id) {
          this.bindWorkspaceResources();
          this.trackWorkspaceVisit();
        }
      },
      deep: true,
    },
    notifications: {
      handler(notifications) {
        this.$store.dispatch("user/computeUnreadPlaylists");
        this.$store.dispatch("user/computeUnreadVideos");
      },
      deep: true,
    },
  },
  mounted() {
    this.handleMobileLayout();
    this.handlePlayerPageLayout();
    this.bindWorkspaceResources();
    this.trackUserPresence();
    this.trackPageVisit();
    this.trackWorkspaceVisit();
    this.initProductTour();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.windowResizeHandler);
  },
  computed: {
    ...mapGetters(["isSidebarOpen"]),
    ...mapGetters({ isMobile: "isScreenSmall" }),
    ...mapGetters({
      viewer: "auth/viewer",
      defaultWorkspace: "workspace/defaultWorkspace",
      members: "workspace/members",
      playlists: "workspace/playlists",
      feed: "workspace/feed",
      videos: "video/videos",
      notifications: "user/notifications",
    }),
  },
  methods: {
    async publishVideoToWorkspace(videoId, options) {
      const { author, workspaceId } = options;
      this.$videoService.addWorkspaceToVideoACL(videoId, workspaceId, author);
    },
    async publishVideoToPlaylist(videoId, options) {
      const { playlistId } = options;
      await this.$playlistService.addVideoToPlaylist(playlistId, videoId);
    },
    async publishVideosIfAny() {
      this.videos.forEach(async (video) => {
        const { id: videoId, status } = video;
        if (status !== "ready") {
          return;
        }

        const opt1 = await this.$store.dispatch(
          "queue/canPublishVideoToWorkspace",
          videoId,
        );
        if (opt1) {
          await this.publishVideoToWorkspace(videoId, opt1);
          this.$store.dispatch(
            "queue/markVideoAsPublishedToWorkspace",
            videoId,
          );
        }

        const opt2 = await this.$store.dispatch(
          "queue/canPublishVideoToPlaylist",
          videoId,
        );
        if (opt2) {
          await this.publishVideoToPlaylist(videoId, opt2);
          this.$store.dispatch("queue/markVideoAsPublishedToPlaylist", videoId);
        }
      });
    },
    initProductTour() {
      const PRODUCT_TOUR_COOKIE = "product_tour";
      const ptCookie = this.$cookie.get(PRODUCT_TOUR_COOKIE);
      if (ptCookie) {
        this.$root.$emit("modal:open", ProductTourModal.name);
      }
    },
    trackWorkspaceVisit() {
      if (!this.defaultWorkspace?.id) {
        return;
      }
      this.groupCall();
      this.trackWebAppVisited();
    },
    trackPageVisit() {
      const groupId = this.defaultWorkspace?.id;
      const page = pages[this.$route.name];
      if (!page) {
        return;
      }
      this.$analytics.page(page, {}, { groupId });
    },
    trackUserPresence() {
      if (!this.viewer?.uid || !this.defaultWorkspace?.id) {
        return;
      }
      this.$presenceService.trackUserPresence({
        userId: this.viewer.uid,
        workspaceId: this.defaultWorkspace.id,
      });
    },
    bindWorkspaceResources() {
      this.fetchWorkspaces();
      this.fetchWorkspaceMembers();
      this.fetchWorkspacePlaylists();
      this.fetchVideoFeed();
      this.fetchPersonalLibrary();
      this.fetchUserNotifications();
    },
    handleMobileLayout() {
      let isMobile = window.outerWidth < 768;

      // Set isSmallScreen for the current device
      this.$store.dispatch("setScreenSmall", isMobile);

      //Add Resize handler
      window.addEventListener("resize", this.windowResizeHandler);

      // On mobile screens, the sidebar should initially be closed
      if (isMobile && this.isSidebarOpen) {
        this.$store.dispatch("closeSidebar");
      }

      // Toggle sidebar if not open already on non-mobile screens
      // On non-mobile screens, the sidebar should always be open
      if (!isMobile && !this.isSidebarOpen) {
        this.$store.dispatch("toggleSidebar");
      }
    },
    windowResizeHandler() {
      let isMobile = window.outerWidth < 768;

      // Set isSmallScreen for the current device
      this.$store.dispatch("setScreenSmall", isMobile);
    },
    handlePlayerPageLayout() {
      // The layout is different on the player page - Sidebar collapses on the left
      // so we need to check if we are on the player page
      this.isPlayerPage = this.$route.name === "player";
      if (this.isPlayerPage) {
        this.$store.dispatch("closeSidebar");
      }
      // If it's not a player page
      // and it's a non-mobile screen , open the sidebar
      else if (!this.isMobile) {
        this.$store.dispatch("openSidebar");
      }
    },
    async fetchWorkspaces() {
      if (!this.viewer?.uid) {
        return;
      }
      await this.$store.dispatch("workspace/bindWorkspaces", this.viewer);
      await this.$store.dispatch(
        "billing/bindSubscription",
        this.defaultWorkspace.id,
      );
    },
    async fetchUserNotifications() {
      if (!this.viewer?.uid || !this.defaultWorkspace?.id) {
        return;
      }
      await this.$store.dispatch("user/bindNotifications", {
        userId: this.viewer.uid,
        workspaceId: this.defaultWorkspace.id,
      });
      await this.$store.dispatch("user/fetchProductUpdates", {
        userId: this.viewer.uid,
      });
    },
    async fetchWorkspaceMembers() {
      if (!this.viewer?.uid || !this.defaultWorkspace?.id) {
        return;
      }
      await this.$store.dispatch(
        "workspace/bindMembers",
        this.defaultWorkspace.id,
      );
    },
    async fetchWorkspacePlaylists() {
      if (!this.viewer?.uid || !this.defaultWorkspace?.id) {
        return;
      }
      await this.$store.dispatch("workspace/bindPlaylists", {
        workspaceId: this.defaultWorkspace.id,
        userId: this.viewer.uid,
      });
      await this.$store.dispatch("workspace/fetchOnboardingVideos");
    },
    async fetchVideoFeed() {
      if (!this.viewer?.uid || !this.defaultWorkspace?.id) {
        return;
      }
      await this.$store.dispatch("workspace/bindVideoFeed", {
        workspaceId: this.defaultWorkspace.id,
        userId: this.viewer.uid,
      });
    },
    async fetchPersonalLibrary() {
      if (!this.viewer?.uid || !this.defaultWorkspace?.id) {
        return;
      }
      await this.$store.dispatch("video/bindVideos", {
        user: this.viewer,
        workspace: this.defaultWorkspace,
      });
    },
    captureCurrentRoute() {
      if (!this.viewer?.uid || !this.defaultWorkspace?.id) {
        return;
      }
      return this.$presenceService.trackCurrentRoute({
        userId: this.viewer.uid,
        workspaceId: this.defaultWorkspace.id,
        route: window.location.pathname + window.location.search,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  @apply w-full h-full bg-space-blue-default;
  &.sidebar {
    padding-left: 248px;
    @media (max-width: 768px) {
      padding-left: 0px;
    }
  }
}
</style>
