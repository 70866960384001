<template>
  <div
    v-if="showIntegrations && isIntegrationMenuOpen !== null"
    class="w-full flex flex-col py-2 px-2.5 mx-0.5 rounded-sm"
  >
    <div
      class="flex flex-row items-center cursor-pointer text-coconut-white-600 py-2 pl-0 active:text-coconut-white-400 rounded-sm responsive:hover:bg-slate-grey-200 transition-all"
      :class="{ 'bg-slate-grey-200': isIntegrationMenuOpen }"
      @click="toggleIntegrationMenu"
      id="connect-apps-dropdown"
    >
      <GearsFillIcon width="18px" style="margin-left: 14px" height="18px" />
      <span class="heading-title4 ml-2">Connect Apps</span>
      <ChevronDownIcon
        class="ml-auto transform -rotate-90"
        width="18px"
        height="18px"
      />
    </div>
    <transition
      enter-active-class="duration-700 ease-out"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      mode="out-in"
    >
      <div
        v-if="isIntegrationMenuOpen"
        v-on-clickaway="closeIntegrationMenu"
        class="absolute left-64 -ml-3 flex flex-col items-center body-text2 text-coconut-white-500 bg-coal-pop-default rounded"
        style="
           {
            height: 116px;
            width: 168px;
            bottom: inherit;
          }
        "
      >
        <div
          v-if="!isDriveConfigured"
          @click="onDriveConnect"
          class="dropdown-option h-10"
        >
          <DriveIcon class="int-logo" />
          <span class="focus:text-coconut-white-default focus:body-subtitle2"
            >Google Drive™</span
          >
        </div>
        <div
          v-if="!isSlackConfigured"
          @click="onSlackConnect"
          class="dropdown-option h-9"
        >
          <SlackIcon class="int-logo" />
          <span class="focus:text-coconut-white-default focus:body-subtitle2"
            >Slack</span
          >
        </div>
        <div
          v-if="!isZoomConfigured"
          @click="onZoomConnect"
          class="dropdown-option h-10"
        >
          <ZoomIcon class="int-logo" />
          <span class="focus:text-coconut-white-default focus:body-subtitle2"
            >Zoom</span
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  ChevronDownIcon,
  DriveIcon,
  GearsFillIcon,
  SlackIcon,
  ZoomIcon,
} from "@spitilabs/icon-library";
import { mapGetters } from "vuex";
import { directive as onClickaway } from "vue-clickaway";
import { ZOOM_REDIRECT_COOKIE } from "~/types/cookies";

export default {
  components: {
    GearsFillIcon,
    ChevronDownIcon,
    DriveIcon,
    SlackIcon,
    ZoomIcon,
  },
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      isIntegrationMenuOpen: null,
    };
  },
  mounted() {
    this.isIntegrationMenuOpen = false;
  },
  computed: {
    ...mapGetters({
      isZoomConfigured: "auth/isZoomConfigured",
      isDriveConfigured: "auth/isDriveConfigured",
      isSlackConfigured: "workspace/isSlackConfigured",
    }),
    showIntegrations: function () {
      return (
        !this.isZoomConfigured ||
        !this.isDriveConfigured ||
        !this.isSlackConfigured
      );
    },
  },
  methods: {
    toggleIntegrationMenu() {
      this.isIntegrationMenuOpen = !this.isIntegrationMenuOpen;
    },
    onZoomConnect() {
      const redirectParams = {
        name: this.$route.name,
        params: this.$route.params,
      };
      this.$cookie.set(ZOOM_REDIRECT_COOKIE, JSON.stringify(redirectParams), {
        expires: "5m",
      });
      window.location.href = this.$config.zoomConfigurationUrl;
    },
    onSlackConnect() {
      window.location.href = this.$config.slackConfigurationUrl;
    },
    onDriveConnect() {
      window.location.href = this.$config.driveConfigurationUrl;
    },
    closeIntegrationMenu() {
      this.isIntegrationMenuOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.int-logo {
  height: 18px;
  width: 18px;
  pointer-events: none;
  vertical-align: middle;
}
.dropdown-option {
  @apply flex flex-row p-3 space-x-2 items-center w-full;
  &:first-of-type {
    @apply rounded-t;
  }
  &:last-of-type {
    @apply rounded-b;
  }
  @media (hover: hover) {
    &:hover {
      @apply bg-coal-pop-200;
    }
  }
}
</style>
