/**
 * This file contains serializers for the various models in the store.
 * More often serializers can be reused across multiple models.
 */

/**
 * Firestore Document Serializer
 * This serializer takes a document snapshot and parses out the data properties along with the id of the document in to a plain object.
 * @param {DocumentSnapshot} snapshot of the firestore document
 */
const firestoreDocumentSerializer = (snapshot) => {
  return {
    ...snapshot.data(),
    id: snapshot.id,
  };
};

export { firestoreDocumentSerializer };
