import { FUNCTIONS } from "@/components";
import { pick, unionBy } from "lodash";
import { firestoreAction } from "vuexfire";
import { firestoreDocumentSerializer } from "~/utils/serializers";

/**
 * ALLOWED_UPDATE_FIELDS is a list of data properties that can (only) be updated by the user.
 * Firestore security rules anyway prevent the user from updating any other properties. This is just a safety measure.
 */
const ALLOWED_UPDATE_FIELDS = [
  "name",
  "description",
  "isPublic",
  "isLocked",
  "videos",
  "isEdited",
];

/**
 * Initial state of the video store.
 */
export const state = () => ({
  playlist: {
    videos: [],
  },
  playlistVideos: null,
  playlistConnections: [],
});

/**
 * Getters are used to retrieve data from the store.
 * Pages and components can use these getters to retrieve data and whenever accessed, the data is assumed to always be up-to-date.
 */
export const getters = {
  /**
   * Return the current playlist that is active.
   */
  playlist: (state) => {
    return state.playlist;
  },

  /**
   * Return the current playlist's videos - expanded view.
   */
  playlistVideos: (state) => {
    return state.playlistVideos;
  },

  /**
   * Return list of workspaces that are connected to the current playlist.
   */
  playlistConnections: (state) => {
    return state.playlistConnections;
  },
};

/**
 * Actions can be considered as an entry point to modify the state.
 * Infact actions are the only methods exposed to pages and components to help modify the state.
 */
export const actions = {
  /**
   * "bindPlaylist" action is used to listen in real-time to the playlist document updates in the database.
   * This action also ties the listener to the playlist property in this store.
   *
   * @param {String} playlistId as referenced in the firestore playlist collection
   */
  bindPlaylist: firestoreAction(async function (
    { bindFirestoreRef },
    playlistId,
  ) {
    await bindFirestoreRef(
      "playlist",
      this.$playlistService.fetchPlaylistByIdQuery(playlistId),
      {
        wait: true,
        serialize: firestoreDocumentSerializer,
      },
    );
  }),

  /**
   * "unbindPlaylist" action is used to break the listener to the playlist document updates in the database.
   */
  unbindPlaylist: firestoreAction(async function ({ unbindFirestoreRef }) {
    await unbindFirestoreRef("playlist", false);
  }),

  /**
   * "createPlaylist" action is used to create a new empty playlist in the current workspace.
   *
   * @param {String} name of the playlist
   * @param {String} description of the playlist
   * @param {String} ownerId refers to the user who is creating the playlist
   * @param {String} workspaceId refers to the workspace where the playlist is being created
   */
  async createPlaylist(
    { commit },
    { name, description, ownerId, workspaceId, type },
  ) {
    const playlist = await this.$playlistService.createPlaylist({
      name,
      description,
      ownerId,
      workspaceId,
      type,
    });
    commit("SET_PLAYLIST", playlist);
    return playlist;
  },

  /**
   * "fetchPlaylist" action is used to fetch playlist details into store.
   *
   * Note:
   * 1) This action does a local cache lookup first, if the playlist is not found in the workspace store, it fetches it from the database.
   * 2) In case of public app views and playlists controlled from another workspace, playlist owner is fetched using a function call since firestore rules prevent accessing unauthorized data.
   *
   * @param {String} id of the playlist to fetch
   * @param {String} appView
   */
  async fetchPlaylist({ commit, rootGetters }, { id }) {
    /**
     * Fetch and store the playlist document from workspace store if already present
     * Else fetch the playlist from the database.
     */
    const fetchPlaylistDocument = async (id) => {
      let playlist = (rootGetters["workspace/playlists"] || []).find(
        (each) => each.id === id,
      );
      if (!playlist) {
        playlist = await this.$playlistService.getPlaylist(id);
      }
      commit("SET_PLAYLIST", playlist);
    };

    await fetchPlaylistDocument(id);
  },

  /**
   * "fetchPlaylistVideos" action is used to expand the playlist's videos property to include the video's metadata.
   * This data can be used to display the videos in the playlist view.
   * This action does a local cache lookup first, if the video is not found in the workspace store and video store, it fetches it from the database.
   * NOTE: video store takes precedence over workspace store.
   *
   * Note: This action is a private method and is not exposed to the pages and components.
   */
  async fetchPlaylistVideos({ commit, dispatch, rootGetters, state }) {
    const { videos: videoIds } = state.playlist;
    if (!videoIds.length) {
      commit("SET_PLAYLIST_VIDEOS", []);
      return;
    }

    const videosAvailable = [
      ...(rootGetters["workspace/feed"] || []),
      ...(rootGetters["video/videos"] || []),
    ];
    const videoAvailabilityMappings = {};
    state.playlist.videos.forEach(
      (videoId) => (videoAvailabilityMappings[videoId] = false),
    );
    videosAvailable.forEach((video) => {
      if (
        Object.prototype.hasOwnProperty.call(
          videoAvailabilityMappings,
          video.id,
        )
      ) {
        videoAvailabilityMappings[video.id] = video;
      }
    });
    const videosToFetch = Object.keys(videoAvailabilityMappings).filter(
      (videoId) => videoAvailabilityMappings[videoId] === false,
    );

    try {
      const videoRefs = videosToFetch.map((videoId) =>
        this.$videoService.getVideo(videoId),
      );
      const videoDocs = await Promise.all(videoRefs);
      videoDocs.forEach(function (video) {
        // Ignore if video is trashed
        if (video.isTrashed) {
          return;
        }
        videoAvailabilityMappings[video.id] = video;
      });
    } catch (err) {
      // Fail safe - if any error occurs, set the playlist videos to whatever is available in the workspace store.
      console.error(err);
    }

    // Preserve the order of the videos in a playlist
    const videos = [];
    const videoOwners = [];
    state.playlist.videos.forEach((videoId) => {
      const video = videoAvailabilityMappings[videoId];
      if (video) {
        videos.push(video);
        videoOwners.push(video.ownerId);
      }
    });
    commit("SET_PLAYLIST_VIDEOS", videos);
    await dispatch("meta/fetchUsers", videoOwners, { root: true });
  },

  /**
   * "addVideoToPlaylist" action is used to add a video to a playlist.
   *
   * @param {String} playlistId refers to the playlist to which the video is to be added.
   * @param {String} videoId refers to the video to be added to the playlist.
   */
  async addVideoToPlaylist({ dispatch }, { playlistId, videoId }) {
    await this.$playlistService.addVideoToPlaylist(playlistId, videoId);
    await dispatch("fetchPlaylist", { id: playlistId });
  },

  /**
   * "removeVideoFromPlaylist" action is used to remove a video from a playlist.
   *
   * @param {String} playlistId refers to the playlist from which the video is to be removed.
   * @param {String} videoId refers to the video to be removed from the playlist.
   */
  async removeVideoFromPlaylist({ dispatch }, { playlistId, videoId }) {
    await this.$playlistService.removeVideoFromPlaylist(playlistId, videoId);
    await dispatch("fetchPlaylist", { id: playlistId });
  },

  /**
   * "updatePlaylist" action is used to update one (or more) data properties of the playlist in the database.
   * Before updating the database, it does a check to see if only allowed fields are being updated.
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   * @param {Map} fields with updated values
   */
  async updatePlaylist({ dispatch }, { playlistId, fields }) {
    const parsedFields = pick(fields, ALLOWED_UPDATE_FIELDS);
    await this.$playlistService.updatePlaylist(playlistId, parsedFields);
  },

  /**
   * "deletePlaylist" action is used to delete the playlist in the database.
   *
   * @param {String} playlistId as referenced in the firestore playlists collection
   */
  async deletePlaylist(_, playlistId) {
    await this.$playlistService.deletePlaylist(playlistId);
  },

  /**
   * "getPlaylistConnections" action is used to get the list of workspaces that this playlist is shared with.
   *
   * @param {String} playlistId refers to the playlist for which the connections are to be fetched.
   */
  async getPlaylistConnections({ commit }, playlistId) {
    const { data } = await this.$fire.functions.httpsCallable(
      FUNCTIONS.fetchPlaylistConnections,
    )({
      playlistId,
    });
    commit("SET_PLAYLIST_CONNECTIONS", data);
  },

  /**
   *
   * "clearStore" action sets the state of this store to the initial state.
   * This actions also unbinds any firestore query listeners.
   */
  async clearStore({ commit, dispatch }) {
    await dispatch("unbindPlaylist");
    commit("CLEAR_STATE");
  },
};

/**
 * Mutations are used to update the state of the store and to be used only by actions.
 * Consider mutations as helper functions to actions.
 *
 * Note: Always add a default value to the mutation (like below). This helps prevent the state properties from being undefined.
 */
export const mutations = {
  /**
   * "SET_PLAYLIST" mutation is used to set (replace) the playlist property in the store.
   */
  SET_PLAYLIST(state, data) {
    state.playlist = data || { videos: [] };
  },

  /**
   * "SET_LOCK_STATUS" mutation is used to set the lock status of the current playlist in the store.
   */
  SET_LOCK_STATUS(state, data) {
    state.playlist.isLocked = data;
  },

  /**
   * "SET_PLAYLIST_VIDEOS" mutation is used to set (replace) the videos associated with the current playlist in the store.
   */
  SET_PLAYLIST_VIDEOS(state, data) {
    state.playlistVideos = data;
  },

  /**
   * "SET_PLAYLIST_CONNECTIONS" mutation is used to set (replace) the external workspace connections associated with the current playlist in the store.
   */
  SET_PLAYLIST_CONNECTIONS(state, data) {
    state.playlistConnections = data;
  },

  /**
   * "CLEAR_STATE" mutation is used to set the state back to how it was when the application was started.
   */
  CLEAR_STATE(state) {
    state.playlist = { videos: [] };
    state.playlistVideos = null;
    state.playlistConnections = [];
  },
};
