<template>
  <button @click="$root.$emit('modal:close:all')">
    <XIcon size="1.2x"></XIcon>
  </button>
</template>

<script>
import { XIcon } from "vue-feather-icons";
export default {
  components: {
    XIcon,
  },
};
</script>

<style lang="scss" scoped>
button {
  @media (hover: hover) {
    &:hover {
      @apply text-gray-100;
    }
  }
  &:focus {
    @apply outline-none;
  }
}
</style>
