import { sanitize } from "dompurify";

/**
 * This file contains text formatters
 */

/**
 * Snippet formatter
 * This formatter takes a typesense search result snippet and formats it to be displayed
 */
const formatSearchResultSnippets = (snippet) => {
  return snippet[0] !== snippet[0].toUpperCase() &&
    snippet[snippet.length - 1] !== "."
    ? `...${snippet}...`
    : snippet[0] !== snippet[0].toUpperCase()
      ? `...${snippet}`
      : snippet[snippet.length - 1] !== "."
        ? `${snippet}...`
        : snippet;
};
/**
 * Initials Creator
 * This function takes a display name and returns a two (or one letter)
 */
const getInitials = (name) => {
  if (!name) return "";
  return name
    .split(" ")
    .slice(0, 2)
    .map((n) => n[0])
    .join("");
};

/**
 * Truncate string
 * This function takes a string and length to truncate and returns a truncated string If length exceeds the given number
 * Else returns the original
 */
const truncateString = (string, n) => {
  return string?.length > n ? string.substring(0, n) + "..." : string;
};

/**
 * Linkify text
 * This formatter takes a text block and string to be replaced with a linkfied version of it
 * NOTE: Currently the function only acceps a slug as a link any extenral links will return the original text
 */
const linkifyText = (text, stringToFind, link) => {
  // If not a link or stringToFind
  if (!link || !stringToFind) {
    return text;
  }
  // If the link is not a slug return the original text
  if (!link.match(/^\/[a-z0-9]+(?:-[a-z0-9]+)*$/)) {
    return text;
  }
  let searchRegex = RegExp(stringToFind, "i"); // Create the regext for the the string to find

  // Replace the string to find with a linkified version
  let formatted = text.replace(
    searchRegex,
    `<nuxt-link id="link" to="${link}">${stringToFind}</nuxt-link>`,
  );

  return formatted;
};

/**
 *
 * listifyText
 * This function takes a string and returns a listified html
 *
 * @param {String} string
 * @returns {String} listified html
 */

const listifyText = (string, delimiter, bulletPoint) => {
  let items = string.split(delimiter);
  let html = "<ul class='actionItems'>\n";

  for (let i = 0; i < items.length; i++) {
    let item = items[i];
    if (item.trim().startsWith(bulletPoint)) {
      html += "<li>" + item.trim().substring(1).trim() + "</li>\n";
    }
  }
  html += "</ul>";
  return html;
};

/**
 * sanitizeText
 * This function takes a string and returns a sanitized version of it
 * The sanitized version is for firestore queries
 * @param {String} string
 * @returns {String} sanitized string
 */

const sanitizeText = (text) => {
  return sanitize(text);
};

export {
  formatSearchResultSnippets,
  getInitials,
  truncateString,
  linkifyText,
  listifyText,
  sanitizeText,
};
