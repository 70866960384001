<template>
  <div class="bg-space-blue-default h-screen overflow-y-auto w-full">
    <article class="h-full">
      <Nuxt />
    </article>
  </div>
</template>

<script>

export default {
  name: "default",
  mounted() {
    this.handleMobileLayout();
  },
  methods: {
    handleMobileLayout() {
      let isMobile = window.outerWidth < 768;

      // Set isSmallScreen for the current device
      this.$store.dispatch("setScreenSmall", isMobile);

      //Add Resize handler
      window.addEventListener("resize", this.windowResizeHandler);
    },
    windowResizeHandler() {
      let isMobile = window.outerWidth < 768;

      // Set isSmallScreen for the current device
      this.$store.dispatch("setScreenSmall", isMobile);
    },
    beforeUnmount() {
      window.removeEventListener("resize", this.windowResizeHandler);
    },
  },
};
</script>
