/** Upload Progress Mixin keeps track of the video upload progress * */

export default {
  data() {
    return {
      timeout: null,
      estimatedProgress: 0,
    };
  },

  methods: {
    startEstimatedProgress() {
      this.calculateProgressed();
      this.updateEstimatedProgress();
    },
    updateEstimatedProgress() {
      // If estimatedProgress is less than 90 and will not go above 90 in the next increment
      // Update the estimatedProgress
      if (this.estimatedProgress <= 90) {
        this.timeout = setTimeout(() => {
          this.estimatedProgress = Math.min(
            90,
            this.incrementUnit + this.estimatedProgress,
          );
        }, 500);
      }
      // Else stop the Timeout and wait for it finish
      else {
        this.stopTimeout();
      }
    },
    stopTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    },
    finishProcessing() {
      this.stopTimeout();
      this.estimatedProgress = 100;
    },
    unloadHandler(e) {
      e.preventDefault();
      return "Are you sure? Uploads are in progress";
    },
    calculateProgressed() {
      if (this.video.status === "preparing") {
        const startedTs = this.video.asset["created_at"];
        this.estimatedProgress = Math.min(
          90,
          this.incrementUnit * (Date.now() / 1000 - startedTs),
        );
      }
    },
  },
  computed: {
    incrementUnit() {
      if (this.video.duration) return 300 / this.video.duration;
      else return 0;
    },
  },
  watch: {
    "video.status": {
      handler(newStatus, prevStatus) {
        // Add a unload handler that prevents accidental reloads for direct uploads while video object is uploading
        if (
          newStatus === "waiting_for_upload" &&
          this.video.source === "direct"
        ) {
          window.onbeforeunload = this.unloadHandler;
        } else if (
          newStatus === "preparing" &&
          prevStatus === "waiting_for_upload"
        ) {
          // Remove the unload handler
          window.onbeforeunload = null;
          this.updateEstimatedProgress();
        } else if (newStatus === "preparing" && prevStatus === undefined) {
          window.onbeforeunload = null;
          this.startEstimatedProgress();
        } else if (newStatus === "ready") {
          this.finishProcessing();
        }
      },
      immediate: true,
    },
    estimatedProgress: {
      handler() {
        if (this.estimatedProgress <= 90) {
          this.updateEstimatedProgress();
        }
      },
    },
  },
};
