import { COLLECTIONS } from "~/services/firebase/firestore/types";

/**
 * Comments Service provides helper methods to interact with firestore comments collection
 */
export default class {
  constructor($fire, $fireModule) {
    this.$fire = $fire;
    this.$fireModule = $fireModule;
  }

  /**
   *
   * @param {String} videoId of the video on which the comment is being made
   * @param {String} playlistId (optional) of the playlist on which the comment is being made
   * @param {String} workspaceId of the workspace user is currently in
   * @param {String} ownerId refers to the userId of the user who is making the comment
   * @param {String} comment text of the comment
   */
  createComment({ videoId, playlistId, workspaceId, comment, ownerId }) {
    return this.$fire.firestore.collection(COLLECTIONS.COMMENTS).add({
      comment,
      workspaceId,
      playlistId,
      videoId,
      ownerId,
      createdAt: this.$fireModule.firestore.FieldValue.serverTimestamp(),
    });
  }

  /**
   *
   * @param {String} videoId for which comments are queried
   * @param {String} workspaceId of the workspace user is currently in
   * @param {FirestoreQuery} raw query which when executed fetches all comments on a video from firestore
   */
  fetchVideoCommentsQuery({ videoId, workspaceId, limit = 100 }) {
    return this.$fire.firestore
      .collection(COLLECTIONS.COMMENTS)
      .where("workspaceId", "==", workspaceId)
      .where("videoId", "==", videoId)
      .orderBy("createdAt", "desc")
      .limit(limit);
  }

  /**
   *
   * @param {String} videoId for which comments are queried
   * @param {String} playlistId of the playlist in which the video is (Often this also acts as a channel when playlist is shared across workspaces)
   * @param {FirestoreQuery} raw query which when executed fetches all comments on a video from firestore
   */
  fetchChannelCommentsQuery({ videoId, playlistId, limit = 100 }) {
    return this.$fire.firestore
      .collection(COLLECTIONS.COMMENTS)
      .where("playlistId", "==", playlistId)
      .where("videoId", "==", videoId)
      .orderBy("createdAt", "desc")
      .limit(limit);
  }
}
