<template>
  <modal :name="modalName">
    <div class="add-video-modal flex flex-col">
      <div class="modal--body -m-4 md:space-y-4">
        <div
          @click="$root.$emit('modal:close:all')"
          class="flex flex-row items-center justify-between md:px-6 px-5 py-3 border-b border-ebony-grey-200 border-opacity-50 heading-title4"
        >
          <span class="text-coconut-white-default">Add videos to playlist</span>
          <modal-close-button
            class="md:block hidden text-coconut-white-700"
          ></modal-close-button>
          <chevron-down-icon
            class="block md:hidden text-coconut-white-700 w-4 h-4"
          ></chevron-down-icon>
        </div>

        <div
          class="flex flex-row items-center border-b border-ebony-grey-200 border-opacity-50 mx-6 md:mt-4"
        >
          <search-icon
            class="text-coconut-white-700 md:w-5 md:h-5 w-4 h-4"
          ></search-icon>
          <input
            autofocus
            :placeholder="placeholder"
            type="text"
            v-model="query"
            class="text-field border-0 bg-transparent text-coconut-white-default outline-none w-full md:p-2 p-2 placeholder-coconut-white-700"
          />
        </div>
        <div class="results-list">
          <slot
            name="results"
            v-bind:results="searchResults"
            v-bind:query="query"
          ></slot>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { ChevronDownIcon, SearchIcon } from "@spitilabs/icon-library";
import Modal from "~/components/Modal";
import ModalCloseButton from "~/components/ModalCloseButton";
import Fuse from "fuse.js";
import { searchFilter } from "~/components/filters/search";

export default {
  data() {
    return {
      index: null,
      query: "",
    };
  },
  props: {
    placeholder: {
      type: String,
      default: "Search...",
    },
    searchKeys: {
      type: Array,
      required: true,
    },
    results: {
      type: Array,
      required: true,
    },
    modalName: {
      type: String,
      required: true,
    },
  },
  watch: {
    results: function () {
      this.index = new Fuse(this.results, {
        ignoreLocation: true,
        threshold: 0.0,
        keys: this.searchKeys,
      });
    },
  },
  mounted() {
    this.index = new Fuse(this.results, {
      ignoreLocation: true,
      threshold: 0.0,
      keys: this.searchKeys,
    });
  },
  computed: {
    searchResults: function () {
      if (this.index) {
        const search = searchFilter(this.index, this.results);
        return search(this.query);
      }
    },
  },
  components: {
    Modal,
    SearchIcon,
    ModalCloseButton,
    ChevronDownIcon,
  },
};
</script>

<style scoped lang="scss">
.result-item + .result-item {
  @apply border-black-200 border-t;
}
.results-list {
  @apply overflow-y-auto md:pb-6 pb-2;
  @media (min-width: 640px) {
    max-height: calc(100vh - 190px);
  }
  @media (max-width: 640px) {
    height: calc(100% - 90px);
  }
}
</style>
