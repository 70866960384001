import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(RelativeTime);

/**
 * Firebase timestamp formatter
 * This formatter takes a firebase timestamp and parses out a human readable date string
 * @param {Object} timestamp - Firebase timestamp
 * @returns {string} - Human readable date string
 */
const formatFirebaseTimestamp = (timestamp) => {
  if (!timestamp) {
    return "";
  }
  return dayjs(
    timestamp.toDate ? timestamp.toDate() : new Date(timestamp._seconds * 1000),
  ).fromNow();
};

/**
 * Firebase timestamp parser
 * This parser takes a firebase timestamp and parses out JS Date object
 * @param {Object} timestamp - Firebase timestamp
 * @returns {Date} - JS Date object
 */
const parseFirebaseTimestampToDate = (timestamp) => {
  if (!timestamp) {
    return new Date();
  }
  return timestamp.toDate
    ? timestamp.toDate()
    : new Date(timestamp._seconds * 1000);
};

const formatVideoTime = (milliseconds) => {
  // Calculate hours, minutes, and seconds
  let seconds = Math.floor(milliseconds / 1000);
  let hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  let minutes = Math.floor(seconds / 60);
  seconds %= 60;

  // Create an array to store the time components
  let timeComponents = [];

  // Add hours to the array if greater than zero
  if (hours > 0) {
    timeComponents.push(hours.toString().padStart(2, "0"));
  }

  // Add minutes and seconds to the array
  timeComponents.push(minutes.toString().padStart(2, "0"));
  timeComponents.push(seconds.toString().padStart(2, "0"));

  // Join the array into the hh:mm:ss format
  let formattedTime = timeComponents.join(":");

  return formattedTime;
};

export {
  formatFirebaseTimestamp,
  parseFirebaseTimestampToDate,
  formatVideoTime,
};
