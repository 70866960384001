import Vue from "vue";
import { forEach, pick, cloneDeep, get } from "lodash";
import { firestoreAction } from "vuexfire";
import { AI_INSIGHTS, FUNCTIONS, TRANSCRIPT_STATUS } from "~/components";
import { firestoreDocumentSerializer } from "~/utils/serializers";
import { mergeFirestoreDocuments } from "~/utils/merge";
/**
 * ALLOWED_UPDATE_FIELDS is a list of data properties that can (only) be updated by the user.
 * Firestore security rules anyway prevent the user from updating any other properties. This is just a safety measure.
 */
const ALLOWED_UPDATE_FIELDS = ["name", "description", "isPublic"];

/**
 *
 * Initial state of the video store.
 * video: {} is the current video that is active.
 * transcriptPlots: [] is the list of transcript plots for the current video that is active.
 * chapters: [] is the list of chapters for the current video that is active.
 * aiInsights: {} is the list of aiInsights for the current video that is active.
 * queries: [] is the list of queries for the current video that is active.
 * speakerLabels: {} is the list of speakerLabels for the current video that is active.
 * comments: [] is the list of comments for the current video that is active.
 * videos: [] is the list of videos
 * importedZoomVideos: {} is the list of zoom video IDs that have already been imported
 * videoUploads: [] is the list of all uploading videos
 *
 */
export const state = () => ({
  video: {},
  transcriptPlots: [],
  chapters: [],
  aiInsights: {},
  queries: [],
  userBookmarks: [],
  publicBookmarks: [],
  speakerLabels: {},
  comments: [],
  videos: [],
  importedZoomVideos: {},
  videoUploads: [],
});

/**
 * Getters are used to retrieve data from the store.
 * Pages and components can use these getters to retrieve data and whenever accessed, the data is assumed to always be up-to-date.
 */
export const getters = {
  /**
   * Return the current video that is active.
   */
  video: (state) => {
    return state.video;
  },

  /**
   * Return transcripts for current video that is active.
   */
  transcriptPlots: (state) => {
    return state.transcriptPlots;
  },

  /**
   * Return chapters for current video that is active.
   */
  chapters: (state) => {
    return state.chapters;
  },

  /**
   * Return speaker labels for current video that is active.
   */
  speakerLabels: (state) => {
    return state.speakerLabels;
  },

  /**
   * Return comments for current video that is active.
   */
  comments: (state) => {
    return state.comments;
  },

  /**
   * Return all the videos that belong to this user.
   */
  videos: (state) => {
    return state.videos;
  },

  /**
   * Return the list of zoom video IDs that have already been imported
   */
  importedZoomVideos: (state) => {
    return state.importedZoomVideos;
  },

  /**
   * Return the list of all uploading videos
   */
  videoUploads: (state) => {
    return state.videoUploads;
  },

  /**
   * Return the summary for the current video
   * Seperated for component level access
   */
  aiInsights: (state) => {
    return state.aiInsights;
  },

  /**
   * Return the summary for the current video
   * Seperated for component level access
   */
  summary: (state) => {
    return state.aiInsights[AI_INSIGHTS.SUMMARIZE];
  },

  /**
   * Return the keyTakeaways for the current video
   * Seperated for component level access
   */
  keyTakeaways: (state) => {
    return state.aiInsights[AI_INSIGHTS.KEY_TAKEAWAYS];
  },

  /**
   * Return the actionItems for the current video
   * Seperated for component level access
   */
  actionItems: (state) => {
    return state.aiInsights[AI_INSIGHTS.ACTION_ITEMS];
  },

  /**
   * Return queries for the current video
   */
  queries: (state) => {
    return state.queries;
  },
  /**
   * Return bookmarks for the current video
   */
  bookmarks: (state) => {
    return mergeFirestoreDocuments(state.userBookmarks, state.publicBookmarks);
  },
};

/**
 * Actions can be considered as an entry point to modify the state.
 * Infact actions are the only methods exposed to pages and components to help modify the state.
 */
export const actions = {
  /**
   * "getVideo" action is used to get the video from the database.
   * Before connecting to the database, it does a local lookup in the workspace store to see if the video is already available.
   *
   * @param {String} videoId as referenced in the firestore video collection
   * @param {Boolean} persist whether to persist the video in the video store
   * @returns {Object} video document data from firestore
   */
  async getVideo({ commit, rootGetters }, { videoId, persist = true }) {
    const feed = rootGetters["workspace/feed"] || [];
    let video = feed.find((each) => each.id === videoId);
    if (!video) {
      video = await this.$videoService.getVideo(videoId);
      video.id = videoId;
    }
    if (persist) {
      commit("SET_VIDEO", video);
    }
    return video;
  },

  /**
   * "updateVideo" action is used to update one (or more) data properties of the video in the database.
   * Before updating the database, it does a check to see if only allowed fields are being updated.
   *
   * @param {String} videoId as referenced in the firestore video collection
   * @param {Map} fields with updated values
   */
  async updateVideo(_, { id, fields }) {
    const parsedFields = pick(fields, ALLOWED_UPDATE_FIELDS);
    await this.$videoService.updateVideo(id, parsedFields);
  },

  /**
   * "bindVideo" action is used to listen to the video document updates in the database.
   * This action also ties the listener to the video property in this store.
   *
   * @param {String} videoId as referenced in the firestore video collection
   */
  bindVideo: firestoreAction(async function ({ bindFirestoreRef }, videoId) {
    await bindFirestoreRef(
      "video",
      this.$videoService.fetchVideoByIdQuery(videoId),
      {
        wait: true,
        serialize: firestoreDocumentSerializer,
      },
    );
  }),

  /**
   * "unbindVideo" action is used to break the listener to the video document updates in the database.
   */
  unbindVideo: firestoreAction(async function ({ unbindFirestoreRef }) {
    await unbindFirestoreRef("video", false);
  }),

  /**
   * "getTranscripts" action is used to get all transcripts (speech content, chapters & speaker labels) for the current video.
   * Before connecting to the database, it does a local check to see if the transcripts are already available.
   *
   * Note: This action does not require any params because it is used in the context of the current video.
   */
  async getTranscripts({ commit, getters }) {
    const { transcript } = getters.video;
    if (transcript?.status !== TRANSCRIPT_STATUS.COMPLETED) {
      return;
    }

    const { speakers } = await this.$transcriptService.getTranscript(
      transcript.id,
    );
    const plots = await this.$transcriptService.getPlots(transcript.id);

    commit("SET_SPEAKER_LABELS", speakers);
    commit("SET_TRANSCRIPT_PLOTS", plots);
  },

  /**
   * "generateSummary" action is used to generate the summary for the current video.
   *
   * @param promptKey - the type of summary to generate
   * @param videoId - the workspace to generate the summary for
   * @param data - the data to generate the summary from
   *
   */
  async setAIInsight({ commit, getters }, { promptKey, data, videoId }) {
    if (videoId === getters.video.id) {
      // check if the video is still the same
      await commit("SET_AI_INSIGHT", { insight: data, type: promptKey }); // update the summary
    }
  },
  /**
   * "getAIInsights" action is used to get all AI insights (summary, key takeaways & action items) for the current video.
   * Before connecting to the database, it does a local check to see if the AI insights are already available.
   *
   * Note: This action does not require any params because it is used in the context of the current video.
   *
   */
  async getAIInsights({ dispatch, state, getters }) {
    const { aiInsights } = state;
    const promptKeys = Object.keys(AI_INSIGHTS);
    const videoId = getters.video.id;
    // TODO: Explore any simple libraries that can retry requests.
    promptKeys.map(async (promptKey) => {
      if (aiInsights[promptKey] && aiInsights[promptKey].videoId === videoId) {
        return;
      }
      await dispatch("getAIInsight", { type: promptKey });
    });
  },

  /**
   * "getAIInsight" action is used to get a specific AI insight (summary, key takeaways & action items) for the current video.
   *
   * This doesn't do local check as this is used for refreshing the AI insights.
   * As this is used in refresh actions, it will always fetch the latest data from the database.
   *
   * @param {String} type of the AI insight (SUMMARY, KEY_TAKEAWAYS, ACTION_ITEMS)
   */

  async getAIInsight({ commit, getters }, { type }) {
    const insight = await this.$summaryService.getSummaryByvideoId(
      getters.video.id,
      type,
    );
    commit("SET_AI_INSIGHT", { insight, type });
  },

  /**
   * "updateAIInsight" action is used to update a specific AI insight (summary, key takeaways & action items) for the current video.
   * @param {String} summaryId as referenced in the firestore summaries collection
   * @param {Object} data with updated values
   *
   */
  async updateAIInsight({ commit, getters }, { summaryId, data }) {
    const summary = await this.$summaryService.updateSummary(summaryId, data);
    commit("SET_AI_INSIGHT", { insight: summary, type: summary.prompt.key });
  },

  /**
   * "setQueries" action is used to get all queries for the current video.
   *
   * Note : This action only sets the queries in the store and not in the database.
   */
  async addQuery({ commit, state }, { query }) {
    commit("ADD_QUERY", { query });
  },

  /**
   * "updateQuery" action is used to update a specific query for the current video.
   * @param {Object} query with updated values
   *
   *Note : This action only updates the query in the store and not in the database.
   */

  async updateQuery({ commit, state }, { query }) {
    const index = state.queries.findIndex((q) => q.key === query.key);
    commit("UPDATE_QUERY", { query, index });
  },

  clearQueries({ commit }) {
    commit("SET_QUERIES", []);
  },

  /**
   * "bindBookmarks" action is used to listen to the video document updates in the database.
   * This action also ties the listener to the video property in this store.
   *
   * @param {String} videoId as referenced in the firestore video collection
   * @param {String} workspaceId as referenced in the firestore video collection
   * @param {String} userId as referenced in the firestore video collection
   */
  bindBookmarks: firestoreAction(async function (
    { bindFirestoreRef },
    { videoId, workspaceId, userId },
  ) {
    const userBookMarks = bindFirestoreRef(
      "userBookmarks",
      this.$videoService.fetchUserBookmarksQuery(videoId, workspaceId, userId),
      {
        wait: true,
        serialize: firestoreDocumentSerializer,
      },
    );
    const publicBookmarks = bindFirestoreRef(
      "publicBookmarks",
      this.$videoService.fetchPublicBookmarksQuery(
        videoId,
        workspaceId,
        userId,
      ),
      {
        wait: true,
        serialize: firestoreDocumentSerializer,
      },
    );

    await Promise.all([userBookMarks, publicBookmarks]);
  }),

  /**
   * "unbindBookmarks" action is used to break the listener to the bookmarks document updates in the database.
   */
  unbindBookmarks: firestoreAction(async function ({ unbindFirestoreRef }) {
    await unbindFirestoreRef("userBookMarks", false);
    await unbindFirestoreRef("publicBookmarks", false);
  }),

  /**
   * "updateSpeakerLabel" action is used to correct/update the map of speaker labels for the current video.
   * This action is used to correct or update the speaker names for transcripts.
   *
   * @param {String} label of the speaker as referenced by assemblyAI
   * @param {Stirng} name of the speaker as updated by the user
   */
  async updateSpeakerLabel({ commit, getters }, { label, name }) {
    const { transcript } = getters.video;
    const transcriptId = transcript.id;
    await this.$transcriptService.updateSpeakerLabel(transcriptId, label, name);
    commit("SET_SPEAKER_LABEL", { label, name });
  },

  /**
   * "updatePlot" action is used to correct/update text content of transcripts in a video
   * Note: Plots are basically chunked speaker turns in the video.
   *
   * @param {Object} plot in transcripts to be updated
   * @param {String} text of the plot to be updated
   */
  async updatePlot({ commit, getters }, { plot, text }) {
    const { transcript } = getters.video;
    const transcriptId = transcript.id;
    await this.$transcriptService.updatePlot(transcriptId, plot, text);

    const { plotIdx } = plot;
    commit("SET_TRANSCRIPT_PLOT", { plotIdx, text });
  },

  /**
   * "updateChapter" action is used to correct/update a (single) chapter's gist and summary in a video
   *
   * @param {Number} index of the chapter to update
   * @param {String} gist of the updated chapter
   * @param {String} summary of the updated chapter
   */
  async updateChapter({ commit, getters }, { index, gist, summary }) {
    const { transcript } = getters.video;
    const transcriptId = transcript.id;
    const chapters = cloneDeep(getters.chapters);
    chapters[index].gist = gist;
    chapters[index].summary = summary;

    await this.$transcriptService.updateChapters(transcriptId, chapters);
    commit("SET_CHAPTERS", chapters || []);
  },

  /**
   * "createComment" action is used to create a new comment in a video.
   *
   * @param {String} videoId refers to the video on which the comment is being created
   * @param {String} playlistId (optional) refers to the playlist which contains the video
   * @param {String} workspaceId refers to the workspace in which the user is a member of
   * @param {String} comment text of the comment
   * @param {String} ownerId refers to the user who is creating the comment
   */
  async createComment(
    _,
    { videoId, playlistId, workspaceId, comment, ownerId },
  ) {
    await this.$commentsService.createComment({
      videoId,
      playlistId,
      workspaceId,
      comment,
      ownerId,
    });
  },

  /**
   * "bindComments" action is used to listen to the comments in realtime for the current video.
   *
   * @param {String} videoId refers to the video for which the comments are being fetched
   * @param {String} playlistId (optional) refers to the playlist which contains the video
   * @param {String} workspaceId refers to the workspace in which the user is a member of
   * @param {Number} limit (optional) refers to the number of comments to be fetched
   */
  bindComments: firestoreAction(async function (
    { bindFirestoreRef },
    { playlistId, videoId, workspaceId, limit = 100 },
  ) {
    await bindFirestoreRef(
      "comments",
      playlistId
        ? this.$commentsService.fetchChannelCommentsQuery({
            videoId,
            playlistId,
            limit,
          })
        : this.$commentsService.fetchVideoCommentsQuery({
            videoId,
            workspaceId,
            limit,
          }),
      {
        wait: true,
        serialize: firestoreDocumentSerializer,
      },
    );
  }),

  /**
   * "unbindComments" action is used to break the listener to the comments of a video.
   * This action is used when moving away from the player page
   */
  unbindComments: firestoreAction(function ({ unbindFirestoreRef }) {
    unbindFirestoreRef("comments", false);
  }),

  /**
   * "bindVideos" action is used to listen to a given set of videos in the database.
   * This action is used to monitor in real-time the status (upload-progress etc) of the videos being uploaded.
   *
   * @param {Array} videoIds of videos as referenced in the firestore video collection
   */
  bindVideos: firestoreAction(async function (
    { bindFirestoreRef },
    { user, workspace },
  ) {
    await bindFirestoreRef(
      "videos",
      this.$videoService.fetchUserLibrayQuery(user.uid, workspace.id),
      {
        wait: true,
        serialize: firestoreDocumentSerializer,
      },
    );
  }),

  /**
   * "unbindVideos" action is used to break the listener to a user's video library.
   */
  unbindVideos: firestoreAction(function ({ unbindFirestoreRef }) {
    unbindFirestoreRef("videos", false);
  }),

  /**
   * "bindVideoUploads" action is used to listen to a given set of videos in the database.
   * This action is used to monitor in real-time the status (upload-progress etc) of the videos being uploaded.
   *
   * @param {Array} videoIds of videos as referenced in the firestore video collection
   */
  bindVideoUploads: firestoreAction(async function (
    { bindFirestoreRef },
    videoIds,
  ) {
    await bindFirestoreRef(
      "videoUploads",
      this.$videoService.fetchVideosByIdsQuery(videoIds),
      {
        wait: true,
        serialize: firestoreDocumentSerializer,
      },
    );
  }),

  /**
   * "unbindVideoUploads" action is used to break the listener to the videos being uploaded.
   */
  unbindVideoUploads: firestoreAction(function ({ unbindFirestoreRef }) {
    unbindFirestoreRef("videoUploads", false);
  }),

  /**
   * "fetchImportedZoomVideos" action is used to fetch the videos imported from Zoom by the current user.
   *
   * @param {String} userId refers to the current user
   * @param {String} workspaceId refers to the workspace in which the user is a member of
   */
  async fetchImportedZoomVideos({ commit }, { userId, workspaceId }) {
    const zoomImports = await this.$videoService.fetchZoomImports(
      userId,
      workspaceId,
    );
    const videos = {};
    forEach(zoomImports, function (video) {
      videos[video.sourceVideoId] = video.id;
    });
    commit("SET_IMPORTED_ZOOM_VIDEOS", videos);
  },

  /**
   *
   * "clearStore" action sets the state of this store to the initial state.
   * This actions also unbinds any firestore query listeners.
   */
  async clearStore({ commit, dispatch }) {
    await dispatch("unbindVideoUploads");
    await dispatch("unbindVideos");
    await dispatch("unbindVideo");
    await dispatch("unbindComments");
    await dispatch("unbindBookmarks");
    commit("CLEAR_STATE");
  },
};

/**
 * Mutations are used to update the state of the store and to be used only by actions.
 * Consider mutations as helper functions to actions.
 *
 * Note: Always add a default value to the mutation (like below). This helps prevent the state properties from being undefined.
 */
export const mutations = {
  /**
   * "SET_VIDEO" mutation is used to set (replace) the video property in the store.
   */
  SET_VIDEO(state, data) {
    state.video = data || {};
  },

  /**
   * "SET_IMPORTED_ZOOM_VIDEOS" mutation is used to set (replace) imported zoom videos in the store.
   */
  SET_IMPORTED_ZOOM_VIDEOS(state, data) {
    state.importedZoomVideos = data || {};
  },

  /**
   * "SET_TRANSCRIPT_PLOTS" mutation is used to set (replace) the transcript plots of a video in the store.
   */
  SET_TRANSCRIPT_PLOTS(state, data) {
    state.transcriptPlots = data || [];
  },

  /**
   * "SET_TRANSCRIPT_PLOT" mutation is used to update the text content of a single plot referenced by index.
   */
  SET_TRANSCRIPT_PLOT(state, { plotIdx, text }) {
    state.transcriptPlots[plotIdx].content = text || "";
  },

  /**
   * "SET_CHAPTERS" mutation is used to set (replace) the chapters of a video in the store.
   */
  SET_CHAPTERS(state, data) {
    state.chapters = data || [];
  },

  /**
   * "SET_AI_INSIGHT" mutation is used to set (replace) a aiInsight of a video in the store.
   */

  SET_AI_INSIGHT(state, data) {
    const { type, insight } = data;
    Vue.set(state.aiInsights, type, insight || null);
  },

  /**
   * "SET_SPEAKER_LABELS" mutation is used to set (replace) speaker mapping of a video in the store.
   */
  SET_SPEAKER_LABELS(state, data) {
    state.speakerLabels = data || {};
  },

  /**
   * "SET_SPEAKER_LABEL" mutation is used to update the name of a single speaker referenced by a label (given by assemblyAI).
   */
  SET_SPEAKER_LABEL(state, { label, name }) {
    Vue.set(state.speakerLabels, label, name || "");
  },

  /**
   * "SET_QUERIES" mutation is used to set queries to the store.
   */

  SET_QUERIES(state, data) {
    state.queries = data || [];
  },

  /**
   * "ADD_QUERY" mutation is used to add a query to the store.
   */
  ADD_QUERY(state, { query }) {
    state.queries.push(query);
  },

  /**
   * "UPDATE_QUERY" mutation is used to update a query in the store.
   */
  UPDATE_QUERY(state, { query, index }) {
    const newQueries = cloneDeep(state.queries);
    newQueries[index] = query;
    state.queries = newQueries;
  },

  /**
   * "CLEAR_STATE" mutation is used to set the state back to how it was when the application was started.
   */
  CLEAR_STATE(state) {
    state.video = {};
    state.videos = [];
    state.importedZoomVideos = {};
    state.aiInsights = {};
    state.queries = [];
    state.videoUploads = [];
    state.transcriptPlots = [];
    state.chapters = [];
    state.speakerLabels = {};
    state.comments = [];
    state.publicBookmarks = [];
    state.userBookmarks = [];
  },
};
