var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-space-blue-default min-h-screen w-full flex",staticStyle:{"min-width":"fit-content"},on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();}}},[_c('portal-target',{attrs:{"name":"modals"}}),_vm._v(" "),_c('app-navbar'),_vm._v(" "),_c('div',{staticClass:"app-main",class:{
      overlay: _vm.isSidebarOpen,
      'pl-0': _vm.isPlayerPage,
      'sm:pl-0 sidebar': !_vm.isPlayerPage,
    }},[(_vm.$route.name.includes('settings'))?_c('settings-sidepanel'):_c('app-sidepanel'),_vm._v(" "),_c('article',{staticClass:"dashboard-container",class:{
        'opacity-60 transition-opacity duration-100':
          _vm.isSidebarOpen && (_vm.isPlayerPage || _vm.isMobile),
      }},[_c('Nuxt')],1)],1),_vm._v(" "),_c('upload-progress-tracker'),_vm._v(" "),_c('product-tour-modal'),_vm._v(" "),_c('import-zoom-video-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }