<template>
  <portal to="modals">
    <div
      v-if="showModal"
      class="fixed inset-0 z-50 flex justify-center items-center"
    >
      <transition
        @before-leave="backdropLeaving = true"
        enter-active-class="transition-all transition-fast ease-out-quad"
        @after-leave="backdropLeaving = false"
        leave-active-class="transition-all transition-medium ease-in-quad"
        enter-class="opacity-0"
        enter-to-class="opacity-0"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        appear
      >
        <div
          v-if="showBackdrop"
          class="absolute inset-0 bg-space-blue-default opacity-80"
          @click="close"
        ></div>
      </transition>

      <transition
        @before-leave="cardLeaving = true"
        enter-active-class="transition-all transition-fast ease-out-quad"
        @after-leave="cardLeaving = false"
        leave-active-class="transition-all transition-medium ease-in-quad"
        enter-class="opacity-0 scale-70"
        enter-to-class="opacity-100 scale-100"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-70"
        appear
      >
        <div
          v-if="showContent"
          :class="align === ALIGN.TOP ? 'absolute top-24' : 'relative'"
        >
          <slot :payload="payload"></slot>
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>
const ALIGN = {
  TOP: "top",
  CENTER: "center",
};
import { mapGetters } from "vuex";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    align: {
      type: String,
      default: ALIGN.CENTER,
      validator: (value) => Object.values(ALIGN).includes(value),
    },
  },
  data() {
    return {
      ALIGN,
      open: false,
      showModal: false,
      showBackdrop: false,
      showContent: false,
      backdropLeaving: false,
      cardLeaving: false,
    };
  },
  computed: {
    ...mapGetters({
      modals: "modals",
    }),
    leaving() {
      return this.backdropLeaving || this.cardLeaving;
    },
    updateScrollBehavior() {
      // Only update scroll behavior if there is only one modal open
      return this.modals.length <= 1;
    },
  },
  watch: {
    open: {
      handler(newValue) {
        if (newValue) {
          this.show();
        } else {
          this.close();
        }
      },
      immediate: true,
    },
    leaving(newValue) {
      if (newValue === false) {
        this.showModal = false;
        this.$emit("close");
      }
    },
  },
  mounted() {
    const onEscape = (e) => {
      if (this.open && e.keyCode === 27) {
        this.close();
      }
    };
    document.addEventListener("keydown", onEscape);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", onEscape);
    });
    // Listen to events
    this.$root.$on("modal:open", this.handleOpen);
    this.$root.$on("modal:close", this.handleClose);
    this.$root.$on("modal:close:all", this.handleCloseAll);
  },
  beforeDestroy() {
    // Remove listeners
    this.$root.$off("modal:open", this.handleOpen);
    this.$root.$off("modal:close", this.handleClose);
    this.$root.$off("modal:close:all", this.handleCloseAll);
  },
  methods: {
    handleOpen(name, payload) {
      if (name === this.name) {
        this.payload = payload;
        this.show();
      }
    },
    handleClose(name) {
      if (name === this.name) {
        this.close();
      }
    },
    handleCloseAll() {
      this.close();
    },
    show() {
      this.showModal = true;
      this.showBackdrop = true;
      this.showContent = true;
      if (process.client && this.updateScrollBehavior) {
        //Disable Body Scroll
        document.documentElement.style.overflow = "hidden";
      }
      this.$root.$emit("modal:opened", this.name);
      this.setCurrentModalInStore();
    },
    close() {
      this.showBackdrop = false;
      this.showContent = false;
      if (process.client && this.updateScrollBehavior) {
        //Enable Body Scroll
        document.documentElement.style.overflow = "auto";
      }
      this.$root.$emit("modal:closed", this.name);
      this.unsetCurrentModalInStore();
    },
    setCurrentModalInStore() {
      this.$store.dispatch("setCurrentModal", this.name);
    },
    unsetCurrentModalInStore() {
      this.$store.dispatch("removeCurrentModal", this.name);
    },
  },
};
</script>
