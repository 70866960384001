import { COLLECTIONS } from "~/services/firebase/firestore/types";
import { TRANSCRIPT_PLOT_TYPE } from "~/components";

/**
 * Transcript Service provides helper methods to interact with firestore transcripts and nested paragraphs collection
 */
export default class {
  constructor($fire, $fireModule) {
    this.$fire = $fire;
    this.$fireModule = $fireModule;
  }

  /**
   *
   * @param {String} transcriptId as referenced in the firestore transcript collection
   * @returns transcript document data from firestore
   */
  async getTranscript(transcriptId) {
    const transcriptRef = await this.$fire.firestore
      .collection(COLLECTIONS.TRANSCRIPTS)
      .doc(transcriptId)
      .get();
    return transcriptRef.data();
  }

  /**
   *
   * @param {String} transcriptId as referenced in the firestore transcript collection
   * @param {Array} chapters with updated gist and summary
   */
  async updateChapters(transcriptId, chapters) {
    await this.$fire.firestore
      .collection(COLLECTIONS.TRANSCRIPTS)
      .doc(transcriptId)
      .update({
        chapters,
      });
  }

  /**
   *
   * @param {String} transcriptId as referenced in the firestore transcript collection
   * @returns {Firestore Paragraph Documents} from the sub-collection in transcript document
   */
  #getParagraphs(transcriptId) {
    return this.$fire.firestore
      .collection(COLLECTIONS.TRANSCRIPTS)
      .doc(transcriptId)
      .collection(COLLECTIONS.PARAGRAPHS)
      .orderBy("order", "asc")
      .get();
  }

  /**
   *
   * @param {Firestore Paragraph Documents} paragraphs
   * @returns {Array} of plots which are chunked paragraphs
   */
  #deconstructParagraphs(paragraphs) {
    const plots = [];
    let prevSpeaker = null;
    let plotIdx = 0;
    paragraphs.forEach((ref) => {
      const paragraph = ref.data();
      const paragraphId = ref.id;
      paragraph.turns.forEach((turn, turnIdx) => {
        if (turn.speaker !== prevSpeaker) {
          plots.push({
            plotIdx,
            type: TRANSCRIPT_PLOT_TYPE.SPEAKER_LABEL,
            speaker: turn.speaker,
            start: turn.start,
          });
          plotIdx++;
        }
        plots.push({
          plotIdx,
          type: TRANSCRIPT_PLOT_TYPE.SPEAKER_TURN,
          speaker: turn.speaker,
          content: turn.text,
          start: turn.start,
          end: turn.end,
          paragraphId,
          turnIdx,
        });
        plotIdx++;
        prevSpeaker = turn.speaker;
      });
    });
    return plots;
  }

  /**
   *
   * @param {String} transcriptId as referenced in the firestore transcript collection
   * @returns {Arrray} of transcript plots
   */
  async getPlots(transcriptId) {
    const paragraphs = await this.#getParagraphs(transcriptId);
    return this.#deconstructParagraphs(paragraphs);
  }

  /**
   *
   * @param {String} transcriptId as referenced in the firestore transcript collection
   * @param {Map} speakers with updated labels (speaker names)
   */
  async updateSpeakerLabel(transcriptId, speakerLabel, speakerName) {
    await this.$fire.firestore
      .collection(COLLECTIONS.TRANSCRIPTS)
      .doc(transcriptId)
      .set(
        {
          speakers: {
            [speakerLabel]: speakerName,
          },
        },
        { merge: true },
      );
  }

  /**
   *
   * @param {String} transcriptId as referenced in the firestore transcript collection
   * @param {Map} plot with updated text content
   */
  async updatePlot(transcriptId, plot, text) {
    const { paragraphId, turnIdx } = plot;
    const paragraph = await this.$fire.firestore
      .collection(COLLECTIONS.TRANSCRIPTS)
      .doc(transcriptId)
      .collection(COLLECTIONS.PARAGRAPHS)
      .doc(paragraphId)
      .get();
    const { turns } = paragraph.data();
    turns[turnIdx].text = text;
    await this.$fire.firestore
      .collection(COLLECTIONS.TRANSCRIPTS)
      .doc(transcriptId)
      .collection(COLLECTIONS.PARAGRAPHS)
      .doc(paragraphId)
      .update({
        turns,
      });
  }
}
