import { FUNCTIONS } from "~/components/index";
import * as UpChunk from "@mux/upchunk";
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { COLLECTIONS } from "~/store";
import TrackingMixin from "~/components/mixins/tracking";

const STATUS = {
  UPLOADING: "UPLOADING",
  UPLOADED: "UPLOADED",
  ERROR: "ERROR",
};

export default {
  mixins: [TrackingMixin],
  data() {
    return {
      STATUS,
      playlistId: null,
    };
  },
  methods: {
    createNewVideoEntry: async function (fileName, fileSize, duration) {
      const userId = this.viewer.uid;
      const videoData = {
        createdAt: this.$fireModule.firestore.FieldValue.serverTimestamp(),
        source: "direct",
        status: "waiting_for_upload",
        ownerId: userId,
        duration: duration,
        workspaceId: this.workspace.id,
        name: fileName || "Untitled Video",
        description: "",
        fileSizeMB: fileSize || 0,
        numViews: 0,
        numLikes: 0,
        playlists: [],
        isTrashed: false,
        isPublic: false,
        embedKey: uuidv4(),
        acl: {
          workspaceIds: [],
          workspaces: {},
          userIds: [userId],
          users: {
            [userId]: {
              createdAt:
                this.$fireModule.firestore.FieldValue.serverTimestamp(),
              createdBy: userId,
            },
          },
        },
      };
      const videoRef = await this.$fire.firestore
        .collection(COLLECTIONS.VIDEOS)
        .add(videoData);
      const video = await videoRef.get();
      return video.id;
    },
    getVideoMeta(file) {
      return new Promise((resolve, reject) => {
        try {
          const video = document.createElement("video");
          video.preload = "metadata";

          video.onloadedmetadata = () => {
            const meta = {
              name: this.formatFileName(file.name),
              durationInSeconds: video.duration,
            };
            window.URL.revokeObjectURL(video.src);
            video.src = "";
            video.load();
            video.remove();
            resolve(meta);
          };

          video.onerror = () => {
            reject(false);
          };

          video.src = window.URL.createObjectURL(file);
        } catch (e) {
          reject(e);
        }
      });
    },
    areVideosWithinPlanLimits: async function (files) {
      try {
        const videosMeta = [];
        let duration = 0;
        for (let idx = 0; idx < files.length; idx++) {
          const videoMeta = await this.getVideoMeta(files[idx]);
          duration += videoMeta.durationInSeconds / 60;
          videosMeta.push(videoMeta);
        }
        if (
          duration + this.workspace.videoMinutes >=
          this.workspaceLimits.videoMinutes
        ) {
          return false;
        }
        return videosMeta;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    uploadVideoFilesToPlaylist: async function (files, playlistId) {
      this.playlistId = playlistId;
      await this.uploadVideoFiles(files);
    },
    uploadVideoFiles: async function (files) {
      if (this.isWorkspacePlanLimitReached()) {
        this.$toast.error("You are importing beyond your plan limit");
        return;
      }
      const resp = await this.areVideosWithinPlanLimits(files);
      if (!resp) {
        this.$toast.error("You are uploading beyond your plan limit.");
        return;
      }

      // Start uploading
      for (let idx = 0; idx < files.length; idx++) {
        this.uploadFile(files[idx], resp[idx]);
      }
    },
    getUploadUrl: async function (videoId) {
      try {
        const { data } = await this.$fire.functions.httpsCallable(
          FUNCTIONS.getVideoDirectUploadUrl,
        )({
          videoId,
        });
        return data.url;
      } catch (err) {
        console.error(err);
        return;
      }
    },
    uploadFile: async function (file, meta) {
      const { durationInSeconds } = meta;
      const videoId = await this.createNewVideoEntry(
        this.formatFileName(file.name),
        this.getFileSizeMB(file),
        durationInSeconds,
      );
      this.$root.$emit("tracker:add", [videoId]);

      if (this.playlistId) {
        await this.$store.dispatch("queue/addVideoToPlaylist", {
          options: {
            playlistId: this.playlistId,
            author: this.viewer.uid,
            workspaceId: this.workspace.id,
          },
          videoId,
        });
      }

      const endpoint = await this.getUploadUrl(videoId);
      if (!endpoint) {
        this.$toast.error("Error uploading video");
        return;
      }
      const upload = UpChunk.createUpload({
        endpoint,
        file,
        chunkSize: 20480,
      });

      // subscribe to upload events
      upload.on("error", (err) => {
        console.error(err);
        this.onUploadErrored(videoId);
      });

      upload.on("progress", (progress) => {
        this.updateVideoUploadProgress(videoId, Math.round(progress.detail));
      });

      upload.on("success", async () => {
        await this.updateFileInfo(videoId, file.name, this.getFileSizeMB(file));
        this.trackVideoImported(videoId, {
          source: "direct",
          fileSizeMb: this.getFileSizeMB(file),
          durationInSeconds,
        });
      });
    },
    formatFileName: function (name) {
      const supportedFileFormats = ["mp4", "mkv", "3gp", "mov"];
      const fileExtension = name.split(".").pop();
      if (fileExtension && supportedFileFormats.includes(fileExtension)) {
        return name.replace(`.${fileExtension}`, "");
      }
      return name;
    },
    /**
     * Update upload progress in video doc
     */
    updateVideoUploadProgress: async function (id, progress) {
      await this.$fire.firestore.collection(COLLECTIONS.VIDEOS).doc(id).update({
        uploadProgress: progress,
      });
    },
    /**
     * Update error status in video doc
     */
    onUploadErrored: async function (id) {
      await this.$fire.firestore.collection(COLLECTIONS.VIDEOS).doc(id).update({
        status: "errored",
      });
    },
    /**
     * Update the video file's basic info after the upload in the DB
     */
    updateFileInfo: async function (id, name, fileSizeMB) {
      const videoRef = await this.$fire.firestore
        .collection(COLLECTIONS.VIDEOS)
        .doc(id);
      await videoRef.update({
        name: this.formatFileName(name),
        fileSizeMB: fileSizeMB,
      });
    },
    getFileSizeMB: function (file) {
      return parseFloat(file.size / 1000000).toFixed(1);
    },
    isWorkspacePlanLimitReached: function () {
      if (
        (this.workspace.videoMinutes || 0) >= this.workspaceLimits.videoMinutes
      ) {
        return true;
      }
      return false;
    },
  },
  computed: {
    ...mapGetters({
      viewer: "auth/viewer",
      workspace: "workspace/defaultWorkspace",
      workspaceLimits: "billing/limits",
    }),
  },
};
