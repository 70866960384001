<template>
  <div
    class="h-screen overflow-y-auto w-full bg z-20 flex flex-col md:flex-row relative"
  >
    <img
      src="./bg-404.png"
      class="hidden md:block z-10 absolute top-0 left-0 w-screen h-screen object-cover"
    />
    <img
      src="./bg-404-sm.png"
      class="block md:hidden z-10 absolute top-0 left-0 w-screen h-screen object-cover"
    />
    <img
      src="./cloud-elements.png"
      class="absolute top-0 left-0 w-screen h-screen object-cover"
    />
    <div
      class="p-8 z-20 w-full md:w-1/2 h-1/2 md:h-full flex items-end md:items-center justify-center"
    >
      <img
        src="~/assets/img/illustrations/astronaut.svg"
        class="max-w-full max-h-full sm:w-56 lg:w-max"
      />
    </div>
    <div
      class="p-8 z-30 w-full md:w-2/3 h-1/2 md:h-full text-center flex items-center justify-center"
    >
      <div
        class="max-w-sm md:max-w-xl flex flex-col items-center justify-center"
      >
        <span class="code-span">{{ statusCode }}</span>
        <span class="mt-2 md:-mt-8 body-span"
          >Oh noes! Whatever you're looking for can't be found. Don't worry
          though, everything is STILL UP and RUNNING.</span
        >
        <nuxt-link
          v-if="true"
          class="mt-8 button btn-lg primary w-max md:w-56 lg:w-64"
          to="/login"
        >
          Go home
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    displayFallback: {
      type: Boolean,
      default: false,
    },
    statusCode: {
      type: Number,
      default: 404,
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background: linear-gradient(112.33deg, #050533 2.02%, #260c5c 98.55%);
}

.container {
  @apply absolute top-0 left-0 w-screen h-screen;
}

.body-span {
  @apply text-coconut-white-default font-medium text-xs md:text-lg lg:text-2xl;
}

.code-span {
  font-family: "Atma";
  font-weight: 600;
  color: #f5cfc6;
  font-size: 64px;
  @media (min-width: 768px) {
    font-size: 200px;
  }
}
</style>
