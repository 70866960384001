/**
 * Initial state of the queue store.
 */
export const state = () => ({
  publishToWorkspace: {},
  publishToPlaylist: {},
});

/**
 * Getters are used to retrieve data from the store.
 * Pages and components can use these getters to retrieve data and whenever accessed, the data is assumed to always be up-to-date.
 */
export const getters = {
  publishToWorkspace: (state) => {
    return state.publishToWorkspace;
  },
  publishToPlaylist: (state) => {
    return state.publishToPlaylist;
  },
};

/**
 * Actions can be considered as an entry point to modify the state.
 * Infact actions are the only methods exposed to pages and components to help modify the state.
 */
export const actions = {
  /**
   * "addVideoToWorkspace" action is used to add a video to the workspace.
   */
  addVideoToWorkspace({ commit }, { videoId, options }) {
    commit("ADD_VIDEO_TO_WORKSPACE", { videoId, options });
  },

  /**
   * "canPublishVideoToWorkspace" action is used to check if the video can be published to the workspace.
   *
   * @param {String} videoId refers to the video id.
   */
  canPublishVideoToWorkspace({ getters }, videoId) {
    const { publishToWorkspace } = getters;
    if (publishToWorkspace[videoId]) {
      return publishToWorkspace[videoId];
    }
    return false;
  },

  /**
   * "markVideoAsPublishedToWorkspace" action is used to mark the video as published to the workspace.
   *
   * @param {String} videoId refers to the video id.
   */
  markVideoAsPublishedToWorkspace({ commit }, videoId) {
    commit("REMOVE_VIDEO_FROM_WORKSPACE", videoId);
  },

  /**
   * "addVideoToPlaylist" action is used to add a video to the playlist.
   */
  addVideoToPlaylist({ commit }, { videoId, options }) {
    commit("ADD_VIDEO_TO_PLAYLIST", { videoId, options });
  },

  /**
   * "canPublishVideoToPlaylist" action is used to check if the video can be published to the playlist.
   *
   * @param {String} videoId refers to the video id.
   */
  canPublishVideoToPlaylist({ getters }, videoId) {
    const { publishToPlaylist } = getters;
    if (publishToPlaylist[videoId]) {
      return publishToPlaylist[videoId];
    }
    return false;
  },

  /**
   * "markVideoAsPublishedToPlaylist" action is used to mark the video as published to the playlist.
   *
   * @param {String} videoId refers to the video id.
   */
  markVideoAsPublishedToPlaylist({ commit }, videoId) {
    commit("REMOVE_VIDEO_FROM_PLAYLIST", videoId);
  },

  /**
   *
   * "clearStore" action sets the state of this store to the initial state.
   * This actions also unbinds any firestore query listeners.
   */
  async clearStore({ commit }) {
    commit("CLEAR_STATE");
  },
};

/**
 * Mutations are used to update the state of the store and to be used only by actions.
 * Consider mutations as helper functions to actions.
 *
 * Note: Always add a default value to the mutation (like below). This helps prevent the state properties from being undefined.
 */
export const mutations = {
  /**
   * "ADD_VIDEO_TO_PLAYLIST" mutation is used to add a video to the playlist.
   */
  ADD_VIDEO_TO_PLAYLIST(state, { videoId, options }) {
    const { publishToPlaylist } = state;
    publishToPlaylist[videoId] = options;
  },

  /**
   * "ADD_VIDEO_TO_WORKSPACE" mutation is used to add a video to the workspace.
   */
  ADD_VIDEO_TO_WORKSPACE(state, { videoId, options }) {
    const { publishToWorkspace } = state;
    publishToWorkspace[videoId] = options;
  },

  /**
   * "REMOVE_VIDEO_FROM_PLAYLIST" mutation is used to remove a video from the playlist.
   */
  REMOVE_VIDEO_FROM_PLAYLIST(state, videoId) {
    const { publishToPlaylist } = state;
    delete publishToPlaylist[videoId];
  },

  /**
   * "REMOVE_VIDEO_FROM_WORKSPACE" mutation is used to remove a video from the workspace.
   */
  REMOVE_VIDEO_FROM_WORKSPACE(state, videoId) {
    const { publishToWorkspace } = state;
    delete publishToWorkspace[videoId];
  },

  /**
   * "CLEAR_STATE" mutation is used to set the state back to how it was when the application was started.
   */
  CLEAR_STATE(state) {
    state.publishToWorkspace = {};
    state.publishToPlaylist = {};
  },
};
