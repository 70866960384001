var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('button',{staticClass:"notification-icon",class:{
      active: _vm.isOpen,
    },on:{"click":function($event){$event.preventDefault();return _vm.toggleNotifications()}}},[_c('BellIcon',{staticClass:"md:h-6 h-4",style:({
        fill:
          _vm.unreadCount > 0
            ? _vm.isOpen
              ? 'rgba(115, 102, 171, 1)'
              : 'rgba(204, 204, 204, 1)'
            : 'rgba(204, 204, 204, 0)',
        color:
          _vm.unreadCount > 0
            ? _vm.isOpen
              ? 'rgba(115, 102, 171, 1)'
              : 'rgba(204, 204, 204, 1)'
            : _vm.isOpen
              ? 'rgba(115, 102, 171, 1)'
              : 'inherit',
      })}),_vm._v(" "),(_vm.unreadCount > 0)?_c('span',{staticClass:"notification-indicator"},[_c('span',{staticClass:"static",staticStyle:{"height":"8px","width":"8px"}}),_vm._v(" "),_c('span',{staticClass:"static opacity-40",staticStyle:{"height":"14px","width":"14px","top":"-3px","left":"-3px"}}),_vm._v(" "),_c('span',{staticClass:"pulse",staticStyle:{"height":"18px","width":"18px","top":"-5px","left":"-5px"}})]):_vm._e()],1),_vm._v(" "),(_vm.isOpen)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeNotifications),expression:"closeNotifications"}],staticClass:"notification-panel"},[_c('div',{staticClass:"px-6 py-2 flex justify-between border-b border-ebony-grey-200 border-opacity-50"},[_c('span',{staticClass:"text-coconut-white-500 heading-title4"},[_vm._v("Notifications")]),_vm._v(" "),_c('XIcon',{staticClass:"md:hidden block text-coconut-white-700 h-4",on:{"click":function($event){$event.preventDefault();return _vm.toggleNotifications()}}})],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col overflow-y-auto divide-y-1 divide-ebony-grey-200 divide-opacity-50 overscroll-contain",style:({ 'max-height': _vm.isMobile ? 'calc( 100vh - 40px )' : '480px' }),on:{"click":function($event){_vm.isOpen = false}}},_vm._l((_vm.allNotifications),function(each,index){return _c('NotificationItem',{key:index,attrs:{"status":each.status,"author":each.author,"icon":each.icon,"text":each.text,"link":each.link,"tags":each.tags,"createdAt":each.formattedTimestamp}})}),1),_vm._v(" "),(!_vm.allNotifications.length)?_c('div',{staticClass:"flex flex-col space-y-2 items-center justify-center text-gray-200 py-4"},[_c('BellIcon',{attrs:{"size":"4x"}}),_vm._v(" "),_c('span',{staticClass:"text-sm"},[_vm._v("Your notifications live here")])],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }