<template>
  <div class="flex items-center justify-center relative">
    <circle-counter
      :dash-spacing="0"
      :stroke="stroke"
      :width="width"
      :height="height"
      :active-stroke="activeStroke"
      :dash-count="100"
      :active-count="progress"
      :strokeWidth="strokeWidth"
      :activeWidth="activeWidth"
    />
    <slot name="child" />
  </div>
</template>

<script>
import CircleCounter from "vue-circle-counter";
export default {
  name: "circle-progress-bar",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    width: {
      type: String,
      default: "1.5rem",
    },
    height: {
      type: String,
      default: "1.5rem",
    },
    stroke: {
      type: String,
      default: "#2D3748",
    },
    activeStroke: {
      type: String,
      default: "#D4F49E",
    },
    strokeWidth: {
      type: Number,
      default: 20,
    },
    activeWidth: {
      type: Number,
      default: 20,
    },
  },

  components: {
    CircleCounter,
  },
};
</script>
