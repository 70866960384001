<template>
  <modal :name="NAME">
    <section class="bg-coal-pop-default flex flex-col p-7 max-w-sm">
      <div class="flex flex-row space-x-7">
        <img src="~/assets/img/illustrations/cat.svg" class="h-24" />
        <div class="flex flex-col space-y-3 text-coconut-white-default">
          <span class="heading-title2">Welcome to Spiti!</span>
          <span class="heading-title3"
            >Ready to start creating a powerful video library?</span
          >
        </div>
      </div>
      <button class="mt-7 button btn-md primary" @click="startTour">
        Start a quick tour
      </button>
      <div class="mt-4 flex flex-row">
        <button class="w-1/2 button btn-md tertiary" @click="skipTour">
          Skip the tour
        </button>
        <button class="w-1/2 button btn-md tertiary" @click="remindLater">
          Remind me later
        </button>
      </div>
    </section>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "~/components/Modal";
import { LinkIcon } from "vue-feather-icons";
import TrackingMixin from "~/components/mixins/tracking";
import { initTour } from "~/services/product-tour";

const NAME = "product-tour-modal";

const PRODUCT_TOUR_COOKIE = "product_tour";

export default {
  name: NAME,
  mixins: [TrackingMixin],
  components: {
    Modal,
    LinkIcon,
  },
  data() {
    return {
      NAME,
    };
  },
  computed: {
    ...mapGetters({
      defaultWorkspace: "workspace/defaultWorkspace",
      viewer: "auth/viewer",
      playlists: "workspace/playlists",
    }),
  },
  methods: {
    closeModal() {
      this.$root.$emit("modal:close:all");
    },
    removeCookie() {
      this.$cookie.delete(PRODUCT_TOUR_COOKIE);
    },
    startTour() {
      this.closeModal();
      this.removeCookie();
      setTimeout(() => {
        const playlist = this.playlists.find(
          (p) => p.isPrimary && !p.dismissible,
        );
        this.$router.push({
          name: "playlists-id",
          params: { id: playlist.id },
        });
        initTour();
      }, 1000);
    },
    skipTour() {
      this.closeModal();
      this.removeCookie();
    },
    remindLater() {
      this.closeModal();
      this.$cookie.set(PRODUCT_TOUR_COOKIE, true, {
        expires: "5D",
      });
    },
  },
};
</script>
