import * as FullStory from "@fullstory/browser";

import { COLLECTIONS } from ".";
import events from "~/constants/events";
import { FUNCTIONS } from "@/components";
import LogRocket from "logrocket";
import { firestoreAction } from "vuexfire";
import { isEmpty } from "lodash";

const AUTH_SCOPES = [
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/user.birthday.read",
];

const AUTH_COOKIE_NAME = "authz";

export const state = () => ({
  viewer: {
    workspaces: {},
    integrations: {},
  },
  secrets: {
    drive: {},
  },
});

export const getters = {
  viewer: (state) => {
    return state.viewer;
  },
  isViewerAuthenticated: (state) => {
    return !isEmpty(state.viewer) && Boolean(state.viewer.uid);
  },
  isZoomConfigured: (state) => {
    return (
      !isEmpty(state.viewer.integrations) &&
      !isEmpty(state.viewer.integrations.zoom)
    );
  },
  isDriveConfigured: (state) => {
    return (
      !isEmpty(state.viewer.integrations) &&
      !isEmpty(state.viewer.integrations.drive)
    );
  },
  driveAppSecrets: (state) => {
    return state.secrets.drive;
  },
  viewerRole: (state) => (workspaceId) => {
    return (
      !isEmpty(state.viewer) &&
      !isEmpty(state.viewer.workspaces) &&
      !isEmpty(state.viewer.workspaces[workspaceId]) &&
      state.viewer.workspaces[workspaceId].role
    );
  },
};

export const actions = {
  async signupWithEmail({}, { email, password, name, redirectUri }) {
    const { user } = await this.$fire.auth.createUserWithEmailAndPassword(
      email,
      password,
    );
    await this.$fire.auth.currentUser.updateProfile({
      displayName: name,
    });
    if (redirectUri) {
      await user.sendEmailVerification({ url: redirectUri });
      return;
    }
    await user.sendEmailVerification();
  },
  async loginWithEmail({ commit, dispatch }, { email, password }) {
    const { user } = await this.$fire.auth.signInWithEmailAndPassword(
      email,
      password,
    );
    const { uid, displayName, emailVerified } = user;
    if (!emailVerified) {
      return false;
    }

    const usersCollection = this.$fire.firestore.collection(COLLECTIONS.USERS);
    const userRef = await usersCollection.doc(uid).get();
    let userData = { uid, ...userRef.data() };

    if (!userRef.exists) {
      // Create user doc in firestore if it doesn't exist for verified users
      userData = {
        uid,
        displayName,
        avatar: null,
        email,
        isEmailVerified: true,
        createdAt: this.$fireModule.firestore.FieldValue.serverTimestamp(),
        workspaces: {},
        integrations: {},
        aliases: {
          zoom: {
            account_id: null,
            user_id: null,
          },
        },
        customerId: null,
        auth: "password",
      };
      await usersCollection.doc(uid).set(userData);
    }
    this.$analytics.identify(uid, {
      name: displayName,
      email,
      avatar: null,
      auth: "password",
    });
    this.$analytics.track(
      !userRef.exists ? events.SIGNED_UP : events.SIGNED_IN,
    );

    commit("SET_VIEWER", userData);
    dispatch("bindViewerProfile", uid);

    // set the signed in cookie
    this._vm.$cookie.set(AUTH_COOKIE_NAME, 1);
    return userData;
  },
  async sendPasswordResetEmail(_, { email, redirectUri }) {
    if (redirectUri) {
      return this.$fire.auth.sendPasswordResetEmail(email, {
        url: redirectUri,
      });
    }
    return this.$fire.auth.sendPasswordResetEmail(email);
  },
  async verifyPasswordResetCode(_, { code }) {
    return this.$fire.auth.verifyPasswordResetCode(code);
  },
  async verifyEmailVerificationCode(_, { code }) {
    return this.$fire.auth.applyActionCode(code);
  },
  async resetPassword(_, { code, newPassword }) {
    return this.$fire.auth.confirmPasswordReset(code, newPassword);
  },
  async signupWithGoogle({ commit, dispatch }) {
    const provider = new this.$fireModule.auth.GoogleAuthProvider();
    AUTH_SCOPES.forEach((scope) => {
      provider.addScope(scope);
    });

    try {
      const { user, credential } =
        await this.$fire.auth.signInWithPopup(provider);
      const { uid, displayName, email, photoURL, emailVerified, refreshToken } =
        user;

      let viewerData = {
        uid,
        displayName,
        avatar: photoURL,
        email,
        isEmailVerified: emailVerified,
        refreshToken,
        accessToken: credential.accessToken,
        createdAt: this.$fireModule.firestore.FieldValue.serverTimestamp(),
        workspaces: {},
        integrations: {},
        aliases: {
          zoom: {
            account_id: null,
            user_id: null,
          },
        },
        customerId: null,
        auth: "google",
      };

      const usersCollection = this.$fire.firestore.collection(
        COLLECTIONS.USERS,
      );
      // check if the user already exists in the database
      const userRef = await usersCollection.doc(uid).get();
      if (userRef.exists) {
        viewerData = userRef.data();
        viewerData.uid = uid;
      } else {
        await usersCollection.doc(uid).set(viewerData);

        // event to track conversion rate
        this.$fire.analytics.logEvent("joined_waitinglist");
      }

      this.$analytics.identify(uid, {
        name: displayName,
        email,
        avatar: photoURL,
        auth: "google",
      });
      this.$analytics.track(
        !userRef.exists ? events.SIGNED_UP : events.SIGNED_IN,
      );

      commit("SET_VIEWER", viewerData);
      dispatch("bindViewerProfile", uid);

      // set the signed in cookie
      this._vm.$cookie.set(AUTH_COOKIE_NAME, 1);

      return viewerData;
    } catch (err) {
      console.error(err);
      this._vm.$cookie.delete(AUTH_COOKIE_NAME);
      return null;
    }
  },
  async signout({ dispatch }) {
    this.$analytics.track(events.SIGNED_OUT);
    await this.$fire.auth.signOut();
    this.$analytics.reset();
    await dispatch("clearStore");
    await dispatch("billing/clearStore", null, { root: true });
    await dispatch("playlist/clearStore", null, { root: true });
    await dispatch("video/clearStore", null, { root: true });
    await dispatch("workspace/clearStore", null, { root: true });
    await dispatch("user/clearStore", null, { root: true });
    await dispatch("meta/clearStore", null, { root: true });

    try {
      FullStory.shutdown();
    } catch (err) {
      // do nothing
    }

    this._vm.$cookie.delete(AUTH_COOKIE_NAME);
  },
  async fetchViewer({ commit }, userId) {
    const viewer = await this.$fire.firestore
      .collection(COLLECTIONS.USERS)
      .doc(userId)
      .get();
    if (viewer.exists) {
      commit("SET_VIEWER", viewer.data());
    }
  },
  async fetchDriveAppSecrets({ commit }) {
    let secrets = {};
    try {
      const { data: token } = await this.$fire.functions.httpsCallable(
        FUNCTIONS.getDriveAccessTokenForUser,
      )({});
      secrets = { token, lastUpdatedAt: Date.now() };
      commit("SET_DRIVE_APP_SECRETS", secrets);
    } catch (err) {
      console.error(err);
    }
    return secrets;
  },
  async onAuthStateChangedAction({ dispatch }, { authUser, claims }) {
    if (!authUser?.uid) {
      this._vm.$cookie.delete(AUTH_COOKIE_NAME);
      // claims = null
      // Perform logout operations
    } else {
      const { uid, emailVerified, email, displayName } = authUser;
      if (!emailVerified) {
        this._vm.$cookie.delete(AUTH_COOKIE_NAME);
        await this.$fire.auth.signOut();
        return;
      }

      // fetch the user's profile from the database
      await dispatch("fetchViewer", uid);
      dispatch("bindViewerProfile", uid);

      // fetch the user's workspaces from the database
      await dispatch("workspace/getWorkspaces", uid, { root: true });
      this._vm.$cookie.set(AUTH_COOKIE_NAME, 1);

      this.$analytics.identify(uid, {
        name: displayName,
        email,
      });

      // Record sessions for logged in users
      if (this.$config.FIRE_ENV && this.$config.FIRE_ENV === "production") {
        try {
          LogRocket.identify(uid, {
            name: displayName,
            email,
          });

          FullStory.init({ orgId: "158NPR" });
          FullStory.identify(uid, {
            displayName,
            email,
          });
        } catch (err) {
          console.error(err);
        }
      }
    }
  },
  bindViewerProfile: firestoreAction(async function (
    { bindFirestoreRef },
    userId,
  ) {
    await bindFirestoreRef(
      "viewer",
      this.$fire.firestore.collection(COLLECTIONS.USERS).doc(userId),
      { wait: true },
    );
  }),
  unbindViewerProfile: firestoreAction(function ({ unbindFirestoreRef }) {
    unbindFirestoreRef("viewer", false);
  }),
  async clearStore({ commit, dispatch }) {
    await dispatch("unbindViewerProfile");

    commit("SET_VIEWER", { workspaces: {}, integrations: {} });
    commit("SET_DRIVE_APP_SECRETS", {});
  },
};

export const mutations = {
  SET_VIEWER(state, viewerData) {
    state.viewer = viewerData;
  },
  SET_DRIVE_APP_SECRETS(state, secrets) {
    state.secrets.drive = secrets;
  },
  ON_AUTH_STATE_CHANGED_MUTATION(state, { authUser }) {
    if (!authUser) {
      // claims = null
      // Perform logout operations
    } else {
      state.viewer = {
        uid: authUser.uid,
      };
    }
  },
};
