<template>
  <nav
    class="container"
    :class="isSidebarOpen ? '' : 'hidden'"
    v-on-clickaway="closeSidebar"
  >
    <slot></slot>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import { directive as onClickaway } from "vue-clickaway";

export default {
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      sidebarMenuRef: "hamburger-menu",
      isMobile: false,
    };
  },
  computed: {
    ...mapGetters({
      isSidebarOpen: "isSidebarOpen",
    }),
  },
  mounted() {
    this.isMobile = window.outerWidth < 768;
  },
  methods: {
    isEventSourceSidebarMenu(event) {
      if (event.srcElement?.id === this.sidebarMenuRef) {
        return true;
      }

      for (const eachElement of event.path || []) {
        if (eachElement?.id === this.sidebarMenuRef) {
          return true;
        }
      }
      return false;
    },
    closeSidebar(event) {
      // 1) Close the sidebar only if the click was outside of the sidebar
      // 2) Sidebar menu (hamburger icon) falls outside of the sidebar and should not trigger the close (edge case),
      //    because it's a driving event for the sidebar
      if (this.isEventSourceSidebarMenu(event)) {
        return;
      }

      if (this.isMobile || this.$route.name === "player") {
        this.$store.dispatch("closeSidebar");
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @apply fixed top-0 left-0 h-screen border-ebony-grey-default border-r z-30 bg-slate-grey-default;
  width: 248px;
  padding-top: 72px;
  @media (max-width: 640px) {
    padding-top: 56px;
  }
}
</style>
