var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseSidepanel',{staticClass:"hidden md:block"},[_c('div',{staticClass:"flex flex-col h-full"},[_c('div',{staticClass:"flex flex-col flex-grow max-h-full spiti-scroll py-6 pl-4 pr-1 space-y-6 overflow-x-hidden"},_vm._l((_vm.sections),function(section){return _c('div',{key:section.id,staticClass:"flex flex-col space-y-4"},[_c('button',{staticClass:"flex flex-row items-center space-x-2 text-coconut-white-700",on:{"click":function($event){return _vm.toggleSection(section.name)}}},[_c('ChevronDownFillIcon',{class:_vm.openSections[section.name] ? '' : 'transform -rotate-90'}),_vm._v(" "),_c('span',{class:section.name === _vm.activeSection
                  ? 'heading-title3 text-coconut-white-default'
                  : 'body-subtitle text-coconut-white-700'},[_vm._v(_vm._s(section.name))])],1),_vm._v(" "),(_vm.openSections[section.name])?_c('div',{staticClass:"flex flex-col space-y-3 pl-8"},_vm._l((section.articles),function(article){return _c('nuxt-link',{key:article.slug,staticClass:"flex flex-row space-x-2",class:_vm.activeArticle === article.slug
                  ? 'text-dew-jerry-100'
                  : 'text-coconut-white-700 hover:text-dew-jerry-100',attrs:{"to":{ name: 'docs-slug', params: { slug: article.slug } }}},[_c(article.icon,{tag:"component",staticClass:"mt-1 h-3.5 w-3.5"}),_vm._v(" "),_c('span',{staticClass:"body-text2 line-clamp-1"},[_vm._v(_vm._s(article.title))])],1)}),1):_vm._e()])}),0),_vm._v(" "),_c('div',{staticClass:"flex flex-col px-6 py-4 space-y-4 overflow-x-hidden border-t border-ebony-grey-400 overflow-hidden"},[_c('a',{staticClass:"flex flex-row space-x-2 text-coconut-white-700 hover:text-dew-jerry-100",attrs:{"href":"mailto:support@spiti.xyz"}},[_c('MailIcon',{staticClass:"mt-1 h-3.5 w-3.5"}),_vm._v(" "),_c('span',{staticClass:"body-text2 line-clamp-1"},[_vm._v("Send feedback")])],1),_vm._v(" "),_c('nuxt-link',{staticClass:"flex flex-row space-x-2 text-coconut-white-700 hover:text-dew-jerry-100",attrs:{"to":{ name: 'login' }}},[_c('SpitiIcon',{staticClass:"mt-1 h-3.5 w-3.5"}),_vm._v(" "),_c('span',{staticClass:"body-text2 line-clamp-1"},[_vm._v("Go to Spiti")])],1)],1)])]),_vm._v(" "),_c('div',{staticClass:"fixed md:hidden z-30 w-full top-14 sm:top-18 bg-black-300",class:{
      'h-full': _vm.isMobileDocsMenuOpen,
    }},[_c('div',{staticClass:"px-5 md:px-8 py-2.5 border-t border-b border-opacity-10 border-white flex flex-row items-center space-x-1 cursor-pointer",on:{"click":function($event){return _vm.toggleMobileDocsMenu()}}},[_c('span',{staticClass:"body-text1 text-white font-bold"},[_vm._v(_vm._s(_vm.activeSection))]),_vm._v(" "),_c('ChevronDownIcon',{staticClass:"w-5 h-5 text-white"})],1),_vm._v(" "),(_vm.isMobileDocsMenuOpen)?_c('div',{staticClass:"px-5 md:px-8 py-4 flex flex-col space-y-4"},_vm._l((_vm.sections),function(section,fgIndex){return _c('div',{key:("sidepanel-" + fgIndex),staticClass:"flex flex-col space-y-4 cursor-pointer"},[_c('div',{staticClass:"body-text1 font-bold text-white",on:{"click":function($event){_vm.expandMobileSection = section.name}}},[_vm._v("\n          "+_vm._s(section.name)+"\n        ")]),_vm._v(" "),(_vm.expandMobileSection === section.name)?_c('div',{staticClass:"flex flex-col space-y-3 pl-5 overflow-hidden duration-300 ease-in-out transition-max-height max-h-52"},_vm._l((section.articles),function(article,fIndex){return _c('div',{key:("sidepanel-fg" + fgIndex + "-f" + fIndex),staticClass:"flex flex-row space-x-2 cursor-pointer",class:article.slug !== _vm.activeArticle
                ? 'text-coconut-white-700'
                : 'text-dew-jerry-100',on:{"click":function($event){$event.stopPropagation();return _vm.goToArticle(article.slug)}}},[_c(article.icon,{tag:"component",staticClass:"mt-1 h-3.5 w-3.5"}),_vm._v(" "),_c('div',{staticClass:"body-text1 transition-opacity duration-300"},[_vm._v("\n              "+_vm._s(article.title)+"\n            ")])],1)}),0):_vm._e()])}),0):_vm._e(),_vm._v(" "),(_vm.isMobileDocsMenuOpen)?_c('div',{staticClass:"flex-grow",on:{"click":function($event){_vm.isMobileDocsMenuOpen = false}}}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }