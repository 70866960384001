/**
 * Since notifications are user specific, we mark them as read when they are read (implicitly or explicitly).
 */
const NOTIFICATION_STATUS = {
  READ: "READ",
  UNREAD: "UNREAD",
};

/**
 * We send out notifications to Spiti users to let them know of any missed activity in the workspace since they last checked in.
 * Each notification type has a different payload structure.
 *
 * Below are a few notifications that we support:
 * - When a new video is published to the workspace, we send out a notification to all workspace members.
 * - When a new comment is added to a video, we send out a notification to all workspace members.
 * - When a video has new likes, we send out a notification to all workspace members.
 * - When a new playlist is created, we send out a notification to all workspace members.
 * - When a new video is added to a playlist, we send out a notification to all users who have access to the playlist.
 * - When transcripts are ready for a video, we send out a notification to the video owner.
 */
const NOTIFICATION_TYPE = {
  VIDEO_READY_FOR_SUMMARIZATION: "VIDEO_READY_FOR_SUMMARIZATION",
  SUMMARY_GENERATED: "SUMMARY_GENERATED",
  TRANSCRIPTS_READY: "TRANSCRIPTS_READY",
  NEW_PLAYLIST_CREATED: "NEW_PLAYLIST_CREATED",
  PLAYLIST_SHARED: "PLAYLIST_SHARED",
  SPITI_CONNECT_INVITATION_ACCEPTED: "SPITI_CONNECT_INVITATION_ACCEPTED",
  NEW_VIDEOS_IN_PLAYLIST: "NEW_VIDEOS_IN_PLAYLIST",
  NEW_VIDEO_PUBLISHED: "NEW_VIDEO_PUBLISHED",
  NEW_VIDEO_LIKE: "NEW_VIDEO_LIKE",
  NEW_VIDEO_COMMENT: "NEW_VIDEO_COMMENT",
};

export { NOTIFICATION_STATUS, NOTIFICATION_TYPE };
