import Typesense from "typesense";

/**
 * Search Service provides helper methods to interact with typesense search engine
 */
export default class {
  constructor(apiKey, url) {
    const { hostname, protocol, port } = new URL(url);
    this.apiClient = new Typesense.SearchClient({
      nodes: [
        {
          host: hostname,
          protocol: protocol.replace(":", ""),
          port:
            (port && port.length) > 0
              ? port
              : protocol === "https:"
                ? "443"
                : "80",
        },
      ],
      apiKey,
      numRetries: 3,
      connectionTimeoutSeconds: 10,
      logLevel: "error",
    });
  }

  searchCollection(collection, query, limit = 100) {
    return this.apiClient.collections(collection).documents().search({
      q: query,
      per_page: limit,
    });
  }

  multiSearch(q) {
    const searchRequests = {
      searches: [
        {
          collection: "playlists",
          q,
          query_by: "name, description",
          highlight_affix_num_tokens: 30,
          highlight_start_tag: '<mark class="highlight-mark">',
          per_page: 20,
        },
        {
          collection: "videos",
          q,
          query_by: "name, description",
          highlight_affix_num_tokens: 30,
          highlight_start_tag: '<mark class="highlight-mark">',
          per_page: 60,
        },
        {
          collection: "transcript_plots",
          q,
          query_by: "text",
          group_by: "videoId",
          highlight_affix_num_tokens: 10,
          highlight_start_tag: '<mark class="highlight-mark">',
          per_page: 40,
        },
      ],
    };
    return this.apiClient.multiSearch.perform(searchRequests);
  }
}
