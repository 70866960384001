import { parseFirebaseTimestampToDate } from "@/utils/date";

/**
 * Merge algorithm with duplicate checking.
 * This fn takes two arrays and merges them together.
 * At the time of merging, it checks for duplicate values and skips them.
 * @param {Array} arr1 - Array to be merged
 * @param {Array} arr2 - Array to be merged
 * @returns {Array} - Merged array
 */
const mergeFirestoreDocuments = (a, b) => {
  const docIds = new Set();
  const merged = [];
  let i = 0;
  let j = 0;
  while (i < a.length && j < b.length) {
    const aCreatedAt = parseFirebaseTimestampToDate(a[i].createdAt);
    const bCreatedAt = parseFirebaseTimestampToDate(b[j].createdAt);
    if (aCreatedAt > bCreatedAt) {
      if (!docIds.has(a[i].id)) {
        merged.push(a[i]);
        docIds.add(a[i].id);
      }
      i++;
    } else {
      if (!docIds.has(b[j].id)) {
        merged.push(b[j]);
        docIds.add(b[j].id);
      }
      j++;
    }
  }
  while (i < a.length) {
    if (!docIds.has(a[i].id)) {
      merged.push(a[i]);
      docIds.add(a[i].id);
    }
    i++;
  }
  while (j < b.length) {
    if (!docIds.has(b[j].id)) {
      merged.push(b[j]);
      docIds.add(b[j].id);
    }
    j++;
  }
  return merged;
};

export { mergeFirestoreDocuments };
