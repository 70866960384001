var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item",class:{
    'cursor-pointer': _vm.link,
  },on:{"click":function($event){return _vm.onClickNotification()}}},[(_vm.author)?_c('div',{staticClass:"flex-shrink-0 mt-1 mr-3 items-center",class:{
      'opacity-70': _vm.status === _vm.NOTIFICATION_STATUS.READ,
    }},[(_vm.author.avatar)?_c('img',{staticClass:"rounded-full h-6 w-6",attrs:{"src":_vm.author.avatar}}):_c('div',{staticClass:"md:w-8 md:h-8 w-6 h-6 object-cover border uppercase rounded-full body-subtitle2 border-pastel-brown text-coconut-white-default bg-slate-grey-default flex items-center justify-center"},[_vm._v("\n      "+_vm._s(_vm.getInitials(_vm.author.displayName))+"\n    ")])]):(_vm.icon === 'clipboard')?_c('div',{staticClass:"flex-shrink-0 mt-1 mr-4 self-baseline items-center",class:{
      'opacity-70': _vm.status === _vm.NOTIFICATION_STATUS.READ,
    }},[_c('ClipboardIcon',{staticStyle:{"height":"18px","width":"18px"}})],1):(_vm.icon === 'sparkles')?_c('div',{staticClass:"flex-shrink-0 mt-1 self-baseline items-center",class:{
      'opacity-70': _vm.status === _vm.NOTIFICATION_STATUS.READ,
    },staticStyle:{"width":"34px"}},[_c('SparklesPulse',{staticClass:"md",attrs:{"pulse":_vm.status !== _vm.NOTIFICATION_STATUS.READ}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-grow-1 flex flex-col space-y-1 font-normal"},[_c('div',{staticClass:"body-subtitle3 text-coconut-white-600",domProps:{"innerHTML":_vm._s(_vm.sanitizeText(_vm.text))}}),_vm._v(" "),_c('span',{staticClass:"body-text3 text-coconut-white-700"},[_vm._v(_vm._s(_vm.createdAt))])]),_vm._v(" "),(_vm.tags.length > 0 && _vm.tags.includes(_vm.PLAYLIST_TAGS.SPITI_CONNECT))?_c('div',{staticClass:"text-pastel-pink"},[_c('SpitiConnectIcon',{attrs:{"size":"1x"}})],1):_vm._e(),_vm._v(" "),(_vm.tags.length > 0 && _vm.tags.includes('NEW'))?_c('div',{staticClass:"ml-1 flex-shrink-0 self-start items-center",class:{
      'opacity-70': _vm.status === _vm.NOTIFICATION_STATUS.READ,
    }},[_c('div',{staticClass:"new-tag1"},[_vm._v("New")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }