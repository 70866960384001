const generateMetaUtil = (name, description, image) => {
  return {
    title: name,
    meta: [
      { hid: "title", property: "title", content: name },
      { hid: "description", name: "description", content: description },
      { hid: "og:title", property: "og:title", content: name },
      { hid: "og:description", name: "og:description", content: description },
      { hid: "og:site_name", property: "og:site_name", content: name },
      {
        hid: "twitter:card",
        name: "twitter:card",
        content: "summary_large_image",
      },
      { hid: "twitter:title", name: "twitter:title", content: name },
      {
        hid: "twitter:description",
        name: "twitter:description",
        content: description,
      },
      { hid: "twitter:image:alt", name: "twitter:image:alt", content: name },
      ...(image
        ? [
            { hid: "og:image", property: "og:image", content: image },
            { hid: "twitter:image", name: "twitter:image", content: image },
          ]
        : []),
    ],
  };
};

const generateSiteMeta = ({
  title,
  description = "",
  image = "/images/meta/video-library.png",
  baseUrl,
  path,
}) => {
  return {
    title,
    link: [
      {
        rel: "canonical",
        href: `${baseUrl}/${path}`,
      },
    ],
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { hid: "description", name: "description", content: description },
      {
        hid: "og:description",
        name: "og:description",
        content: description,
      },
      { hid: "og:title", property: "og:title", content: title },
      { hid: "og:site_name", property: "og:site_name", content: "Spiti" },
      { hid: "og:image", property: "og:image", content: image },
      {
        hid: "twitter:card",
        name: "twitter:card",
        content: "summary_large_image",
      },
      { hid: "twitter:title", name: "twitter:title", content: title },
      { hid: "twitter:site", name: "twitter:site", content: "@spitidotxyz" },
      {
        hid: "twitter:creator",
        name: "twitter:creator",
        content: "@spitidotxyz",
      },
      {
        hid: "twitter:description",
        name: "twitter:description",
        content: description,
      },
      {
        hid: "twitter:image",
        name: "twitter:image",
        content: `${baseUrl}${image}`,
      },
      {
        hid: "twitter:image:alt",
        name: "twitter:image:alt",
        content: title,
      },
    ],
  };
};

const generateVideoPageMeta = (video = {}, meta = {}) => {
  const { title, description, image } = meta;
  return generateMetaUtil(
    title || video.name,
    description || video.description || "",
    image || video.artifacts?.thumbnail,
  );
};

const generatePlaylistPageMeta = (playlist = {}, meta = {}) => {
  const { title, description } = meta;
  return generateMetaUtil(
    title || playlist.name,
    description || playlist.description || "",
  );
};

const isAgentBrowser = (req) => {
  if (!req || !req.headers) {
    // By default return true to skip the meta generation
    return true;
  }

  return (
    req.headers["user-agent"].includes("Mozilla") ||
    req.headers["user-agent"].includes("Chrome") ||
    req.headers["user-agent"].includes("Safari") ||
    req.headers["user-agent"].includes("Firefox") ||
    req.headers["user-agent"].includes("Opera") ||
    req.headers["user-agent"].includes("OPR") ||
    req.headers["user-agent"].includes("Edge") ||
    req.headers["user-agent"].includes("Trident") ||
    req.headers["user-agent"].includes("MSIE")
  );
};

const isAgentMobile = (req) => {
  return (
    req.headers["user-agent"].includes("iPhone") ||
    req.headers["user-agent"].includes("Android")
  );
};

export {
  generateMetaUtil,
  generateSiteMeta,
  generatePlaylistPageMeta,
  generateVideoPageMeta,
  isAgentBrowser,
  isAgentMobile,
};
