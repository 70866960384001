/**
 * "isEmailGeneric" method helps us take some actions in the app for users who are logged in using company email.
 *
 * @param {String} email of the user who is logged in
 * @returns whether the user is logged in using a generic email or not
 */
const isEmailGeneric = (email) => {
  const emailDomain = email.split("@")[1];
  return [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "aol.com",
    "comcast.net",
    "verizon.net",
    "me.com",
    "msn.com",
    "live.com",
    "mac.com",
    "icloud.com",
    "comcast.com",
    "aim.com",
    "cox.net",
    "att.net",
    "sbcglobal.net",
  ].includes(emailDomain);
};

/**
 * "isMobileDevice" method helps us take some actions in the app for users who are logged in using company email.
 *
 * @param {String} email of the user who is logged in
 * @returns whether the user is logged in using a generic email or not
 */
const isMobileDevice = () => {
  let hasTouchScreen = false;
  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = matchMedia?.("(pointer:coarse)");
    if (mQ?.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }
  return hasTouchScreen;
};

export { isEmailGeneric, isMobileDevice };
