import Vue from "vue";
import { FUNCTIONS } from "~/components";
import { PLAYLIST_TAGS } from "~/types/playlist";

/**
 * Initial state of the video store.
 */
export const state = () => ({
  users: {},
  playlists: {},
  videos: {},
  workspaces: {},
});

/**
 * Getters are used to retrieve data from the store.
 * Pages and components can use these getters to retrieve data and whenever accessed, the data is assumed to always be up-to-date.
 */
export const getters = {
  /**
   * Return users meta data from the store.
   */
  users: (state) => {
    return state.users;
  },

  /**
   * Return playlists meta data from the store.
   */
  playlists: (state) => {
    return state.playlists;
  },

  /**
   * Return videos meta data from the store.
   */
  videos: (state) => {
    return state.videos;
  },

  /**
   * Return workspaces meta data from the store.
   */
  workspaces: (state) => {
    return state.workspaces;
  },
};

/**
 * Actions can be considered as an entry point to modify the state.
 * Infact actions are the only methods exposed to pages and components to help modify the state.
 */
export const actions = {
  /**
   * "fetchUsers" action triggers firebase function to fetch user meta data.
   * @param {Array} userIds - Array of user ids to fetch meta data for.
   */
  async fetchUsers({ commit, state }, userIds) {
    if (!userIds.length) {
      return;
    }

    const unknownUserIds = userIds.filter((userId) => !state.users[userId]);
    const { data } = await this.$fire.functions.httpsCallable(
      FUNCTIONS.fetchMeta,
    )({
      userIds: unknownUserIds,
    });
    const { users } = data;
    unknownUserIds.forEach((userId) => {
      commit("SET_USER", {
        userId,
        meta: users[userId],
      });
    });
  },

  /**
   * "fetchPlaylists" action triggers firebase function to fetch playlist meta data.
   * @param {Array} playlistIds - Array of playlist ids to fetch meta data for.
   */
  async fetchPlaylists({ commit }, playlistIds) {
    if (!playlistIds.length) {
      return;
    }
    const { data } = await this.$fire.functions.httpsCallable(
      FUNCTIONS.fetchMeta,
    )({
      playlistIds,
    });
    const { playlists } = data;
    playlistIds.forEach((playlistId) => {
      commit("SET_PLAYLIST", {
        playlistId,
        meta: playlists[playlistId],
      });
    });
  },

  /**
   * "fetchVideos" action triggers firebase function to fetch video meta data.
   * @param {Array} videoIds - Array of video ids to fetch meta data for.
   */
  async fetchVideos({ commit }, videoIds) {
    if (!videoIds.length) {
      return;
    }
    const { data } = await this.$fire.functions.httpsCallable(
      FUNCTIONS.fetchMeta,
    )({
      videoIds,
    });
    const { videos } = data;
    videoIds.forEach((videoId) => {
      commit("SET_VIDEO", {
        videoId,
        meta: videos[videoId],
      });
    });
  },

  /**
   * "fetchWorkspaces" action triggers firebase function to fetch workspace meta data.
   * @param {Array} workspaceIds - Array of workspace ids to fetch meta data for.
   */
  async fetchWorkspaces({ commit }, workspaceIds) {
    if (!workspaceIds.length) {
      return;
    }
    const { data } = await this.$fire.functions.httpsCallable(
      FUNCTIONS.fetchMeta,
    )({
      workspaceIds,
    });
    const { workspaces } = data;
    workspaceIds.forEach((workspaceId) => {
      commit("SET_WORKSPACE", {
        workspaceId,
        meta: workspaces[workspaceId],
      });
    });
  },

  /**
   * "initPlaylists" action populates the store with metadata for all playlists in the current workspace.
   */
  async initPlaylists({ commit, dispatch, rootGetters }) {
    const playlists = rootGetters["workspace/playlists"] || [];
    playlists.forEach((playlist) => {
      const {
        id: playlistId,
        name,
        isPrimary,
        connectedWorkspaces,
        isPublic,
      } = playlist;
      const tags = [];
      (!isPrimary || connectedWorkspaces.length > 0) &&
        tags.push(PLAYLIST_TAGS.SPITI_CONNECT);
      isPublic && tags.push(PLAYLIST_TAGS.PUBLIC);
      commit("SET_PLAYLIST", { playlistId, meta: { name, tags } });
    });

    const clonedPlaylists = playlists.filter((playlist) => !playlist.isPrimary);
    if (clonedPlaylists.length > 0) {
      await dispatch(
        "fetchPlaylists",
        clonedPlaylists.map((playlist) => playlist.id),
      );
    }
  },

  /**
   * "initVideos" action populates the store with metadata for all videos in the current workspace.
   */
  initVideos({ commit, rootGetters }) {
    const videos = [
      ...(rootGetters["workspace/feed"] || []),
      ...(rootGetters["video/videos"] || []),
    ];
    videos.forEach((video) => {
      const { id: videoId, name, description, artifacts, playlists } = video;
      commit("SET_VIDEO", {
        videoId,
        meta: {
          name,
          description,
          thumbnail: artifacts?.thumbnail,
          playlists: playlists || [],
        },
      });
    });
  },

  /**
   * "initUsers" action populates the store with metadata for all users in the current workspace.
   */
  initUsers({ commit, rootGetters }) {
    const users = rootGetters["workspace/members"] || [];
    users.forEach((user) => {
      const { id: userId, displayName, avatar } = user;
      commit("SET_USER", { userId, meta: { displayName, avatar } });
    });
  },

  /**
   * "pushVideos" action populates (adds to) the store video metadata provided.
   */
  pushVideos({ commit }, videos) {
    videos.forEach((video) => {
      const { id: videoId, name, description, thumbnail, playlists } = video;
      commit("SET_VIDEO", {
        videoId,
        meta: { name, description, thumbnail, playlists: playlists || [] },
      });
    });
  },

  /**
   *
   * "clearStore" action sets the state of this store to the initial state.
   * This actions also unbinds any firestore query listeners.
   */
  async clearStore({ commit }) {
    commit("CLEAR_STATE");
  },
};

/**
 * Mutations are used to update the state of the store and to be used only by actions.
 * Consider mutations as helper functions to actions.
 *
 * Note: Always add a default value to the mutation (like below). This helps prevent the state properties from being undefined.
 */
export const mutations = {
  /**
   * "SET_PLAYLIST" mutation is used to set the playlist meta for the given playlist id.
   */
  SET_PLAYLIST(state, { playlistId, meta }) {
    Vue.set(
      state.playlists,
      playlistId,
      state.playlists[playlistId]
        ? { ...state.playlists[playlistId], ...meta }
        : meta,
    );
  },

  /**
   * "SET_VIDEO" mutation is used to set the video name for the given video id.
   */
  SET_VIDEO(state, { videoId, meta }) {
    Vue.set(
      state.videos,
      videoId,
      state.videos[videoId] ? { ...state.videos[videoId], ...meta } : meta,
    );
  },

  /**
   * "SET_USER" mutation is used to set the user meta for the given user id.
   */
  SET_USER(state, { userId, meta }) {
    Vue.set(state.users, userId, meta);
  },

  /**
   * "SET_WORKSPACE" mutation is used to set the workspace meta for the given workspace id.
   */
  SET_WORKSPACE(state, { workspaceId, meta }) {
    Vue.set(state.workspaces, workspaceId, meta);
  },

  /**
   * "CLEAR_STATE" mutation is used to set the state back to how it was when the application was started.
   */
  CLEAR_STATE(state) {
    state.playlists = {};
    state.videos = {};
    state.users = {};
    state.workspaces = {};
  },
};
