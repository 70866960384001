import { COLLECTIONS } from ".";
import { FUNCTIONS } from "../components";
import { firestoreAction } from "vuexfire";
import { PRICING_PLANS } from "~/types/billing";

export const state = () => ({
  subscriptions: [],
});

export const getters = {
  /**
   * Return the current subscription.
   */
  subscription: (state) => {
    if (!state.subscriptions || state.subscriptions.length === 0) {
      return {};
    }
    return (
      state.subscriptions.find((s) => s.status === "ACTIVE") ||
      state.subscriptions[0]
    );
  },
  /**
   * Return true if subscription status is active.
   */
  isActive: (state, getters) => {
    return Boolean(getters.subscription.status);
  },
  /**
   * Return the plan limits for the current subscription
   */
  limits: (state, getters) => {
    return Boolean(getters.subscription.status)
      ? {
          videoMinutes: getters.subscription.videoMinutes,
          publicPlaylists: getters.subscription.publicPlaylists,
          spitiConnects: getters.subscription.spitiConnects,
        }
      : {
          videoMinutes: 100,
          publicPlaylists: 1,
          spitiConnects: 1,
        };
  },
};

export const actions = {
  /**
   * Create a new Stripe Checkout session for the specified priceId and redirect the user
   * to that.
   */
  async upgrade(_, data) {
    const sessionId = await this.$fire.functions.httpsCallable(
      FUNCTIONS.createCheckoutSession,
    )({
      priceId: data.priceId,
      workspaceId: data.workspaceId,
      successUrl: `${this.$config.appBaseUrl}/heathrow-subscription?session_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${this.$config.appBaseUrl}/login`,
    });
    const stripe = window.Stripe(this.$config.stripePublishableKey);
    return stripe.redirectToCheckout({ sessionId: sessionId.data });
  },

  bindSubscription: firestoreAction(async function (
    { bindFirestoreRef },
    workspaceId,
  ) {
    await bindFirestoreRef(
      "subscriptions",
      this.$fire.firestore
        .collection(COLLECTIONS.SUBSCRIPTIONS)
        .where("workspaceId", "==", workspaceId),
      {
        wait: true,
        serialize: (snapshot) => {
          return {
            ...snapshot.data(),
            id: snapshot.id,
            plan:
              snapshot.data().product ===
              this.$config.stripeStarterPlanProductId
                ? PRICING_PLANS.STARTER
                : snapshot.data().product ===
                    this.$config.stripeProPlanProductId
                  ? PRICING_PLANS.PRO
                  : "",
          };
        },
      },
    );
  }),

  unbindSubscription: firestoreAction(function ({ unbindFirestoreRef }) {
    unbindFirestoreRef("subscriptions", false);
  }),

  async clearStore({ commit, dispatch }) {
    await dispatch("unbindSubscription");
    commit("SET_SUBSCRIPTIONS", []);
  },
};

export const mutations = {
  SET_SUBSCRIPTIONS(state, data) {
    state.subscriptions = data;
  },
};
