/**
 *
 * Before routing to app pages, we jump in and check if user notifications should be marked as read.
 * - When navigating from playlist page to player page, we don't want to mark notifications as read.
 * - When navigating away from playlist page, we want to mark notifications as read.
 * - When navigating away from player page, we want to mark notifications as read.
 */
export default function ({ store, from, route, $userService }) {
  const userId = store.getters["auth/viewer"]?.uid;
  const workspaceId = store.getters["workspace/defaultWorkspace"]?.id;
  if (!userId || !workspaceId) {
    return;
  }

  if (route.name === "playlists-id") {
    $userService.markNewPlaylistNotificationsAsRead({
      userId,
      workspaceId,
      playlistId: route.params.id,
    });
  }

  if (!["playlists-id", "player"].includes(from.name)) {
    return;
  }

  // Case 1: When navigating from playlist page to player page.
  if (
    from.name === "playlists-id" &&
    route.name === "player" &&
    from.params.id === route.query.playlist
  ) {
    return;
  }

  // Case 2: When navigating away from playlist page.
  if (from.name === "playlists-id") {
    const playlistId = from.params.id;
    $userService.markPlaylistNotificationsAsRead({
      userId,
      workspaceId,
      playlistId,
    });
    return;
  }

  // Case 3: When navigating away from player page.
  if (from.name === "player") {
    const { playlist: playlistId, video: videoId } = from.query;
    $userService.markVideoNotificationsAsRead({
      userId,
      workspaceId,
      videoId,
    });
    playlistId &&
      $userService.markPlaylistNotificationsAsRead({
        userId,
        workspaceId,
        playlistId,
      });
    return;
  }
}
