<template>
  <div>
    <BaseSidepanel class="hidden md:block">
      <div class="flex flex-col h-full">
        <div
          class="flex flex-col flex-grow max-h-full spiti-scroll py-6 pl-4 pr-1 space-y-6 overflow-x-hidden"
        >
          <div
            class="flex flex-col space-y-4"
            v-for="section in sections"
            :key="section.id"
          >
            <button
              class="flex flex-row items-center space-x-2 text-coconut-white-700"
              @click="toggleSection(section.name)"
            >
              <ChevronDownFillIcon
                :class="
                  openSections[section.name] ? '' : 'transform -rotate-90'
                "
              />
              <span
                :class="
                  section.name === activeSection
                    ? 'heading-title3 text-coconut-white-default'
                    : 'body-subtitle text-coconut-white-700'
                "
                >{{ section.name }}</span
              >
            </button>
            <div
              class="flex flex-col space-y-3 pl-8"
              v-if="openSections[section.name]"
            >
              <nuxt-link
                v-for="article in section.articles"
                :key="article.slug"
                :to="{ name: 'docs-slug', params: { slug: article.slug } }"
                :class="
                  activeArticle === article.slug
                    ? 'text-dew-jerry-100'
                    : 'text-coconut-white-700 hover:text-dew-jerry-100'
                "
                class="flex flex-row space-x-2"
              >
                <component :is="article.icon" class="mt-1 h-3.5 w-3.5" />
                <span class="body-text2 line-clamp-1">{{ article.title }}</span>
              </nuxt-link>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col px-6 py-4 space-y-4 overflow-x-hidden border-t border-ebony-grey-400 overflow-hidden"
        >
          <a
            href="mailto:support@spiti.xyz"
            class="flex flex-row space-x-2 text-coconut-white-700 hover:text-dew-jerry-100"
          >
            <MailIcon class="mt-1 h-3.5 w-3.5" />
            <span class="body-text2 line-clamp-1">Send feedback</span>
          </a>
          <nuxt-link
            :to="{ name: 'login' }"
            class="flex flex-row space-x-2 text-coconut-white-700 hover:text-dew-jerry-100"
          >
            <SpitiIcon class="mt-1 h-3.5 w-3.5" />
            <span class="body-text2 line-clamp-1">Go to Spiti</span>
          </nuxt-link>
        </div>
      </div>
    </BaseSidepanel>
    <div
      class="fixed md:hidden z-30 w-full top-14 sm:top-18 bg-black-300"
      :class="{
        'h-full': isMobileDocsMenuOpen,
      }"
    >
      <div
        class="px-5 md:px-8 py-2.5 border-t border-b border-opacity-10 border-white flex flex-row items-center space-x-1 cursor-pointer"
        @click="toggleMobileDocsMenu()"
      >
        <span class="body-text1 text-white font-bold">{{ activeSection }}</span>
        <ChevronDownIcon class="w-5 h-5 text-white"></ChevronDownIcon>
      </div>
      <div
        class="px-5 md:px-8 py-4 flex flex-col space-y-4"
        v-if="isMobileDocsMenuOpen"
      >
        <div
          v-for="(section, fgIndex) of sections"
          :key="`sidepanel-${fgIndex}`"
          class="flex flex-col space-y-4 cursor-pointer"
        >
          <div
            class="body-text1 font-bold text-white"
            @click="expandMobileSection = section.name"
          >
            {{ section.name }}
          </div>
          <div
            class="flex flex-col space-y-3 pl-5 overflow-hidden duration-300 ease-in-out transition-max-height max-h-52"
            v-if="expandMobileSection === section.name"
          >
            <div
              v-for="(article, fIndex) of section.articles"
              :key="`sidepanel-fg${fgIndex}-f${fIndex}`"
              class="flex flex-row space-x-2 cursor-pointer"
              :class="
                article.slug !== activeArticle
                  ? 'text-coconut-white-700'
                  : 'text-dew-jerry-100'
              "
              @click.stop="goToArticle(article.slug)"
            >
              <component :is="article.icon" class="mt-1 h-3.5 w-3.5" />
              <div class="body-text1 transition-opacity duration-300">
                {{ article.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex-grow"
        v-if="isMobileDocsMenuOpen"
        @click="isMobileDocsMenuOpen = false"
      ></div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  ChevronDownFillIcon,
  ChevronDownIcon,
  WorkspaceIcon,
  UserIcon,
  UploadIcon,
  PlusIcon,
  PlaylistIcon,
  ZoomMonochromeIcon,
  DriveMonochromeIcon,
  SlackMonochromeIcon,
  MailIcon,
  SettingsIcon,
  SpitiIcon,
  PaperIcon,
  CreditCardIcon,
  RecordIcon,
  PlayCircleIcon,
  SparkleIcon,
  SpitiConnectIcon,
  GlobeIcon,
  UsersPlusIcon,
  SparkleFillIcon,
} from "@spitilabs/icon-library";
import BaseSidepanel from "~/components/sidepanels/BaseSidepanel";

const SECTIONS = {
  INTRODUCTION: "Getting Started",
  WORKSPACE_SETTINGS: "Users & Workspaces",
  INTEGRATIONS: "Integrations",
  SCREEN_RECORDER: "Screen Recorder",
  POWER_UPS: "Power-ups",
  SHARED_PLAYLISTS: "Shared Playlists",
};

export default {
  components: {
    BaseSidepanel,
    ChevronDownFillIcon,
    ChevronDownIcon,
    MailIcon,
    SpitiIcon,
  },
  async mounted() {
    this.onRouteChange();
  },
  data() {
    return {
      isMobileDocsMenuOpen: false,
      expandMobileSection: false,
      activeSection: "",
      activeArticle: "",
      sections: [],
      openSections: {
        [SECTIONS.INTRODUCTION]: true,
        [SECTIONS.WORKSPACE_SETTINGS]: true,
        [SECTIONS.INTEGRATIONS]: true,
        [SECTIONS.SCREEN_RECORDER]: true,
        [SECTIONS.POWER_UPS]: true,
        [SECTIONS.SHARED_PLAYLISTS]: true,
      },
      sections: [
        {
          id: 1,
          name: SECTIONS.INTRODUCTION,
          articles: [
            {
              title: "Overview",
              slug: "index",
              icon: PaperIcon,
            },
            {
              title: "What does Spiti do?",
              slug: "what-is-spiti",
              icon: SpitiIcon,
            },
            {
              title: "Creating a Workspace",
              slug: "create-workspace",
              icon: WorkspaceIcon,
            },
            {
              title: "Adding videos to Spiti",
              slug: "upload-videos",
              icon: UploadIcon,
            },
            {
              title: "Creating playlists",
              slug: "create-playlists",
              icon: PlusIcon,
            },
            {
              title: "Playlist controls",
              slug: "playlist-controls",
              icon: PlaylistIcon,
            },
          ],
        },
        {
          id: 2,
          name: SECTIONS.WORKSPACE_SETTINGS,
          articles: [
            {
              title: "Team Settings",
              slug: "team-settings",
              icon: SettingsIcon,
            },
            {
              title: "User roles & permissions",
              slug: "user-roles",
              icon: UserIcon,
            },
            {
              title: "Billing & Upgrading",
              slug: "billing",
              icon: CreditCardIcon,
            },
          ],
        },
        {
          id: 3,
          name: SECTIONS.INTEGRATIONS,
          articles: [
            {
              title: "Zoom",
              slug: "zoom-integration",
              icon: ZoomMonochromeIcon,
            },
            {
              title: "Slack",
              slug: "slack-integration",
              icon: SlackMonochromeIcon,
            },
            {
              title: "Google Drive",
              slug: "drive-integration",
              icon: DriveMonochromeIcon,
            },
          ],
        },
        {
          id: 4,
          name: SECTIONS.SCREEN_RECORDER,
          articles: [
            {
              title: "Spiti Screen Recorder",
              slug: "screen-recorder",
              icon: RecordIcon,
            },
          ],
        },
        {
          id: 5,
          name: SECTIONS.POWER_UPS,
          articles: [
            {
              title: "Video player short-cuts",
              slug: "video-player-shortcuts",
              icon: PlayCircleIcon,
            },
            {
              title: "AI Summary and more",
              slug: "ai-summary",
              icon: SparkleFillIcon,
            },
          ],
        },
        {
          id: 6,
          name: SECTIONS.SHARED_PLAYLISTS,
          articles: [
            {
              title: "Spiti Connect",
              slug: "spiti-connect",
              icon: SpitiConnectIcon,
            },
            {
              title: "Restricted access",
              slug: "restricted-access",
              icon: UsersPlusIcon,
            },
            {
              title: "Public Playlists",
              slug: "public-playlists",
              icon: GlobeIcon,
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route: async function () {
      this.onRouteChange();
    },
    sections: function () {
      this.onRouteChange();
    },
  },
  computed: {
    ...mapGetters({
      isSidebarOpen: "isSidebarOpen",
      viewer: "auth/viewer",
    }),
  },
  methods: {
    toggleMobileDocsMenu() {
      this.isMobileDocsMenuOpen = !this.isMobileDocsMenuOpen;
      if (this.isMobileDocsMenuOpen) {
        this.expandMobileSection = this.activeSection;
      }
    },
    goToArticle(slug) {
      this.$router.push({ name: "docs-slug", params: { slug } });
      this.isMobileDocsMenuOpen = false;
    },
    toggleSection(section) {
      Vue.set(this.openSections, section, !this.openSections[section]);
    },
    onRouteChange: function () {
      this.activeArticle = this.$route.params.slug || "index";
      this.activeSection = this.getSection(this.activeArticle);
    },
    getSection(slug) {
      const section = this.sections.find((section) =>
        section.articles.find((article) => article.slug === slug),
      );
      return section ? section.name : "";
    },
  },
};
</script>
<style scoped>
.selected {
  background-color: #a08eee;
}
</style>
