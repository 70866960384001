/**
 * Reroutes map will contain a map of all third party redirects.
 */
const reroutes = {
  "/what":
    "https://spiti.notion.site/So-what-is-Spiti-74908b3769d747c686f5017bde066f6a",
};

/**
 *
 * Before routing to app pages, we jump in and check if the routes fall in the reroutes object.
 */
export default function ({ route, redirect }) {
  if (route.path in reroutes) {
    redirect(reroutes[route.path]);
  }
}
