<script>
import DropdownMenu from "v-dropdown-menu";
import "v-dropdown-menu/dist/v-dropdown-menu.css"; // Base style, required.

/* Function that returns a Higher Order Component with given options */
function hoc(OrignalComponent, options) {
  return {
    ...options,
    render(h) {
      /* Forward the original slots , attributes and listeners to the component */
      return h(OrignalComponent, {
        on: this.$listeners,
        attrs: { ...this.$attrs, class: "marketing-dropdown" },
        scopedSlots: this.$scopedSlots,
      });
    },
  };
}

/* Create a higher order Component using hoc 
   that adds in a mount listener for the dropdown
   which handles the edge case of mouse movements when component isn't mounted
*/
const MarketingPageDropdown = hoc(DropdownMenu, {
  mounted() {
    if (this.$el.matches(":hover")) {
      this.$emit("premountHover");
    }
  },
});

export default MarketingPageDropdown;
</script>

<style lang="scss">
.v-dropdown-menu {
  $this: &;
  &__container {
    @apply rounded border-transparent bg-transparent #{!important};
    min-width: fit-content !important;
  }
}
</style>
