<template>
  <div class="bg-black flex flex-wrap" @dragover.prevent @drop.prevent>
    <article>
      <Nuxt />
    </article>
  </div>
</template>

<script>
export default {
  name: "embed-app",
  async mounted() {
    // TODO: track embed view
  },
};
</script>
