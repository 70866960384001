<template>
  <div class="h-screen overflow-y-auto w-full bg">
    <div
      class="px-8 md:px-4 lg:px-0 max-w-4xl mx-auto flex flex-col space-y-4 lg:space-y-6 text-center items-center justify-center h-full"
    >
      <img src="~/assets/img/illustrations/403.svg" />
      <div class="error-title">Oh boy! Access forbidden</div>
      <div class="error-body">
        <p>This is a restricted space.</p>
        <p>
          You don't have permission to access this page or the URL has been
          changed.
        </p>
        <p>Request the owner access.</p>
      </div>
      <nuxt-link
        v-if="true"
        class="button btn-lg primary w-max md:w-56 lg:w-64"
        to="/login"
      >
        Go home
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    displayFallback: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: #ffe8ef;
}
.error-title {
  @apply text-4xl md:text-5xl lg:text-6xl font-bold;
  letter-spacing: 0.01em;
  color: #061897;
}
.error-body {
  @apply text-base md:text-lg lg:text-xl font-medium flex flex-col items-center;
  color: #061897;
}
</style>
