import { firestoreAction } from "vuexfire";
import { FUNCTIONS } from "~/components";
import {
  NOTIFICATION_STATUS,
  NOTIFICATION_TYPE,
} from "~/components/notifications/types";
import { firestoreDocumentSerializer } from "~/utils/serializers";

/**
 * Initial state of the video store.
 */
export const state = () => ({
  notifications: [],
  productUpdates: [],
  unreadPlaylists: new Set(),
  unreadVideos: new Set(),
});

/**
 * Getters are used to retrieve data from the store.
 * Pages and components can use these getters to retrieve data and whenever accessed, the data is assumed to always be up-to-date.
 */
export const getters = {
  /**
   * Returns notifications for the logged in user in the current workspace.
   */
  notifications: (state) => {
    return state.notifications;
  },

  /**
   * Returns product updates for the logged in user
   */
  productUpdates: (state) => {
    return state.productUpdates;
  },

  /**
   * Returns a list of unread playlist ids for the logged in user in the current workspace.
   */
  unreadPlaylists: (state) => {
    return state.unreadPlaylists;
  },

  /**
   * Returns a list of unread video ids for the logged in user in the current workspace.
   */
  unreadVideos: (state) => {
    return state.unreadVideos;
  },
};

/**
 * Actions can be considered as an entry point to modify the state.
 * Infact actions are the only methods exposed to pages and components to help modify the state.
 */
export const actions = {
  /**
   * "trackLastVisitToBellNotifications" action is used to update the logged in user's last visit to the bell notifications in the current workspace.
   */
  async trackLastVisitToBellNotifications({ rootGetters }) {
    try {
      const { id: workspaceId } = rootGetters["workspace/defaultWorkspace"];
      await this.$fire.functions.httpsCallable(
        FUNCTIONS.trackLastVisitToBellNotifications,
      )({
        workspaceId,
      });
    } catch (err) {
      console.error(err);
    }
  },

  /**
   * "bindNotifications" action is used to listen in real-time to all notifications the logged in user has in the current workspace.
   * This action also ties the listener to the notifications property in this store.
   *
   * @param {String} userId refers to the logged in user.
   * @param {String} workspaceId refers to the workspace id of the current workspace.
   */
  bindNotifications: firestoreAction(async function (
    { bindFirestoreRef },
    { userId, workspaceId },
  ) {
    // App related activity
    await bindFirestoreRef(
      "notifications",
      this.$userService.fetchUserNotificationsQuery({ userId, workspaceId }),
      {
        wait: true,
        serialize: firestoreDocumentSerializer,
      },
    );
  }),

  /**
   * "fetchProductUpdates" action is used to fetch all product updates for the logged in user.
   */
  async fetchProductUpdates({ commit }, { userId }) {
    const snapshot = await this.$userService
      .fetchProductUpdatesQuery({ userId })
      .get();
    const updates = snapshot.docs.map(firestoreDocumentSerializer);
    commit("SET_PRODUCT_UPDATES", updates);
  },

  /**
   * "unbindNotifications" action is used to break the listener to the logged in user's notifications in the current workspace.
   */
  unbindNotifications: firestoreAction(function ({ unbindFirestoreRef }) {
    unbindFirestoreRef("notifications", false);
  }),

  /**
   * "computeUnreadPlaylists" action is used to filter out all unread playlist ids from the logged in user's notifications in the current workspace.
   */
  async computeUnreadPlaylists({ state, commit }) {
    const unreadPlaylists = new Set();
    for (const notification of state.notifications) {
      const { type, status, payload } = notification;
      if (status === NOTIFICATION_STATUS.READ) {
        continue;
      }

      const { playlistId } = payload;
      if (
        [
          NOTIFICATION_TYPE.NEW_PLAYLIST_CREATED,
          NOTIFICATION_TYPE.NEW_VIDEOS_IN_PLAYLIST,
          NOTIFICATION_TYPE.PLAYLIST_SHARED,
        ].includes(type)
      ) {
        unreadPlaylists.add(playlistId);
      }
    }
    commit("SET_UNREAD_PLAYLISTS", unreadPlaylists);
  },

  /**
   * "computeUnreadVideos" action is used to filter out all unread video ids from the logged in user's notifications in the current workspace.
   */
  async computeUnreadVideos({ state, commit, rootGetters }) {
    const unreadVideos = new Set();
    for (const notification of state.notifications) {
      const { type, status, payload } = notification;
      if (status === NOTIFICATION_STATUS.READ) {
        continue;
      }

      const { playlistId, videos, videoId } = payload;
      if (
        [
          NOTIFICATION_TYPE.NEW_VIDEOS_IN_PLAYLIST,
          NOTIFICATION_TYPE.NEW_VIDEO_PUBLISHED,
        ].includes(type)
      ) {
        videos && videos.forEach((video) => unreadVideos.add(video));
        videoId && unreadVideos.add(videoId);
      }

      if (
        [
          NOTIFICATION_TYPE.NEW_PLAYLIST_CREATED,
          NOTIFICATION_TYPE.PLAYLIST_SHARED,
        ].includes(type)
      ) {
        const playlist = (rootGetters["workspace/playlists"] || []).find(
          (each) => each.id === playlistId,
        );
        playlist && playlist.videos.forEach((video) => unreadVideos.add(video));
      }
    }
    commit("SET_UNREAD_VIDEOS", unreadVideos);
  },

  /**
   *
   * "clearStore" action sets the state of this store to the initial state.
   * This actions also unbinds any firestore query listeners.
   */
  async clearStore({ commit, dispatch }) {
    await dispatch("unbindNotifications");
    commit("CLEAR_STATE");
  },
};

/**
 * Mutations are used to update the state of the store and to be used only by actions.
 * Consider mutations as helper functions to actions.
 *
 * Note: Always add a default value to the mutation (like below). This helps prevent the state properties from being undefined.
 */
export const mutations = {
  /**
   * "SET_NOTIFICATIONS" mutation is used to set (replace) the notifications for the logged in user in the current workspace.
   */
  SET_NOTIFICATIONS(state, data) {
    state.notifications = data || [];
  },

  /**
   * "SET_PRODUCT_UPDATES" mutation is used to set (replace) the product updates for the logged in user
   */
  SET_PRODUCT_UPDATES(state, data) {
    state.productUpdates = data || [];
  },

  /**
   * "SET_UNREAD_PLAYLISTS" mutation is used to set (replace) the unread playlist ids for the logged in user in the current workspace.
   */
  SET_UNREAD_PLAYLISTS(state, data) {
    state.unreadPlaylists = data || new Set();
  },

  /**
   * "SET_UNREAD_VIDEOS" mutation is used to set (replace) the unread video ids for the logged in user in the current workspace.
   */
  SET_UNREAD_VIDEOS(state, data) {
    state.unreadVideos = data || new Set();
  },

  /**
   * "CLEAR_STATE" mutation is used to set the state back to how it was when the application was started.
   */
  CLEAR_STATE(state) {
    state.notifications = [];
    state.productUpdates = [];
    state.unreadPlaylists = new Set();
    state.unreadVideos = new Set();
  },
};
