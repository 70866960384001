<template>
  <modal :name="NAME">
    <template v-slot="{ payload }">
      <div class="modal flex flex-col">
        <div class="modal--header">
          <span class="flex-auto">Import from Zoom</span>
          <modal-close-button></modal-close-button>
        </div>
        <div class="modal--body -m-4 text-gray-100 flex flex-col flex-grow">
          <zoom-videos-list
            v-if="isZoomConfigured"
            :playlistId="payload && payload.playlistId"
          ></zoom-videos-list>
          <div
            class="flex flex-col items-center justify-center flex-grow space-y-4"
            v-else
          >
            <span>Your Zoom account connection isn't working.</span>
            <a
              :href="$config.zoomConfigurationUrl"
              class="flex flex-row space-x-2 items-center justify-center button btn-lg primary"
            >
              <ZoomMonochromeIcon />
              <span> Reconnect with Zoom </span>
            </a>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "~/components/Modal";
import ZoomVideosList from "~/components/ZoomVideosList";
import ModalCloseButton from "~/components/ModalCloseButton";
import { ZoomMonochromeIcon } from "@spitilabs/icon-library";

const NAME = "import-zoom-video-modal";

export default {
  name: NAME,
  components: {
    Modal,
    ZoomVideosList,
    ModalCloseButton,
    ZoomMonochromeIcon,
  },
  data() {
    return {
      NAME,
    };
  },
  computed: {
    ...mapGetters({
      isZoomConfigured: "auth/isZoomConfigured",
      viewer: "auth/viewer",
    }),
  },
};
</script>

<style scoped lang="scss">
.tabs {
  .tab + .tab {
    @apply border-l border-black-200;
  }
  .tab {
    @apply p-4 text-gray-100 uppercase tracking-widest text-xs sm:text-sm text-center align-middle outline-none;

    &.active {
      @apply bg-black-200;
    }
  }
}
</style>
