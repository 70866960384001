<template>
  <BaseSidepanel>
    <SidebarPlaceholder v-if="isServer" />
    <div v-else class="flex flex-col p-2.5">
      <div class="flex flex-row justify-between items-center pl-6 py-3">
        <span class="heading-header2 text-coconut-white-700"
          >TEAM SETTINGS</span
        >
        <nuxt-link
          :to="{
            name: 'home',
            params: { slug: defaultWorkspace && defaultWorkspace.shortcode },
          }"
          class="icon-btn w-6 h-6"
        >
          <ChevronLeftIcon size="1x" />
        </nuxt-link>
      </div>
      <nuxt-link
        v-for="(each, index) in settings"
        :key="index"
        class="tab text-coconut-white-default"
        :to="{ name: each.page }"
        :class="{ 'bg-slate-grey-100': activePage === each.page }"
      >
        <component :is="each.icon" size="1x" />
        <span class="body-subtitle2">{{ each.label }}</span>
      </nuxt-link>
    </div>
  </BaseSidepanel>
</template>

<script>
import { mapGetters } from "vuex";
import {
  SettingsIcon,
  UsersIcon,
  CreditCardIcon,
  ChevronLeftIcon,
  LayersIcon,
} from "vue-feather-icons";
import { SidebarPlaceholder } from "~/components/placeholders";
import BaseSidepanel from "~/components/sidepanels/BaseSidepanel";

export default {
  components: {
    ChevronLeftIcon,
    BaseSidepanel,
    SidebarPlaceholder,
  },
  data() {
    return {
      settings: [
        {
          label: "General",
          icon: SettingsIcon,
          page: "settings-general",
        },
        {
          label: "Members",
          icon: UsersIcon,
          page: "settings-members",
        },
        {
          label: "Integrations",
          icon: LayersIcon,
          page: "settings-integrations",
        },
        {
          label: "Billing",
          icon: CreditCardIcon,
          page: "settings-billing",
        },
      ],
      activePage: "",
    };
  },
  mounted() {
    this.setPage(this.$route.name);
  },
  watch: {
    $route() {
      this.setPage(this.$route.name);
    },
  },
  computed: {
    ...mapGetters({
      defaultWorkspace: "workspace/defaultWorkspace",
    }),
    isServer() {
      return process.server;
    },
  },
  methods: {
    setPage(page) {
      this.activePage = page;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  @apply flex flex-row gap-x-2.5 items-center px-6 py-3 responsive:hover:bg-slate-grey-200 active:bg-slate-grey-100;
}
</style>
