<template>
  <Modal :name="NAME">
    <template v-slot="{ payload }">
      <div class="add-playlist-modal">
        <div
          class="heading-title4 border-b flex flex-row px-6 py-3 border-ebony-grey-200 capitalize text-coconut-white-default"
        >
          <div class="flex items-center">
            New {{ payload.type.toLowerCase() }} playlist
            <playlist-icons class="ml-2 bg-coal-pop-200" :type="payload.type" />
          </div>

          <modal-close-button
            class="text-coconut-white-700 ml-auto"
          ></modal-close-button>
        </div>
        <div class="px-6 pb-6 pt-4 overflow-hidden flex flex-col space-y-4">
          <text-input
            refId="playlist-title"
            type="text"
            label="Playlist title*"
            size="sm"
            v-model="name"
            placeholder="Give your playlist a name"
            id="playlist-name"
            @keyup.enter="create(payload.type)"
          />

          <text-input
            refId="playlist-description"
            v-model="description"
            rows="11"
            label="Playlist description"
            size="sm"
            :long="true"
            id="playlist-desc"
            placeholder="Tell your team what this playlist is about"
            @keypress="handleKeyPress"
          ></text-input>
          <div class="flex flex-row justify-end">
            <button
              class="save-btn btn-md flex items-center space-x-2"
              :disabled="name.trim().length === 0 || loading"
              :class="{
                'bg-coal-pop-200 text-coconut-white-700': loading,
                'bg-coal-pop-200 text-coconut-white-700': name === '',
              }"
              @click.prevent="create(payload.type)"
            >
              <RotateCwIcon
                v-if="loading"
                size="1.2x"
                class="inline-block animate-spin"
              ></RotateCwIcon>
              <SaveIcon v-else size="1.2x"></SaveIcon>
              <span>Save playlist</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "~/components/Modal.vue";
import { SaveIcon, RotateCwIcon } from "@spitilabs/icon-library";
import ModalCloseButton from "~/components/ModalCloseButton";
import TextInput from "~/components/form/TextInput";
import TrackingMixin from "~/components/mixins/tracking";
import PlaylistIcons from "@/components/icons/PlaylistIcons.vue";

const NAME = "new-playlist-modal";

export default {
  name: NAME,
  mixins: [TrackingMixin],
  components: {
    Modal,
    SaveIcon,
    RotateCwIcon,
    ModalCloseButton,
    TextInput,
    PlaylistIcons,
  },
  data() {
    return {
      NAME,
      name: "",
      description: "",
      loading: false,
    };
  },
  methods: {
    handleKeyPress(event) {
      if (event.keyCode == 13 && !event.shiftKey) {
        this.create();
      }
    },
    create: async function (type) {
      if (!this.name || this.name == "") {
        this.$toast.error("Playlist title can't be empty");
        return;
      }
      this.loading = true;

      try {
        const { id } = await this.$store.dispatch("playlist/createPlaylist", {
          name: this.name,
          description: this.description,
          ownerId: this.viewer.uid,
          workspaceId: this.workspace.id,
          type,
        });

        this.loading = false;
        this.$root.$emit("modal:close:all");

        // Temp fix – there seems to be a delay in firestore acknowledging the new playlist
        setTimeout(() => {
          this.$router.push({
            name: "playlists-id",
            params: { id },
          });
        }, 100);
        this.trackPlaylistCreated(id);
        this.resetForm();
      } catch (err) {
        this.loading = false;
        console.error(err);
        this.$toast.error("Something went wrong");
      }
    },
    resetForm: function () {
      this.name = "";
      this.description = "";
    },
  },
  computed: {
    ...mapGetters({
      viewer: "auth/viewer",
      workspace: "workspace/defaultWorkspace",
    }),
  },
};
</script>
