import UserService from "@/services/firebase/firestore/user";
import VideoService from "@/services/firebase/firestore/video";
import CommentsService from "@/services/firebase/firestore/comments";
import SummaryService from "~/services/firebase/firestore/summary";
import PlaylistService from "@/services/firebase/firestore/playlist";
import TranscriptService from "@/services/firebase/firestore/transcript";
import WorkspaceService from "@/services/firebase/firestore/workspace";
import PresenceService from "@/services/firebase/database";

export default function ({ $fire, $fireModule }, inject) {
  inject("userService", new UserService($fire, $fireModule));
  inject("videoService", new VideoService($fire, $fireModule));
  inject("commentsService", new CommentsService($fire, $fireModule));
  inject("summaryService", new SummaryService($fire, $fireModule));
  inject("playlistService", new PlaylistService($fire, $fireModule));
  inject("transcriptService", new TranscriptService($fire, $fireModule));
  inject("workspaceService", new WorkspaceService($fire, $fireModule));
  inject("presenceService", new PresenceService($fire, $fireModule));
}
